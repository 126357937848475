import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import config from '../../config';
import { AvatarLarge, Button, Form, IconPerson, SecondaryButton } from '../../components';
import { ensureListing } from '../../util/data';

import css from './EditListingExpertsForm.module.css';

const required = value => {
  // Intl Message checkbox required Message
  const errors = {};
  if (value.language.length === 0) {
    errors.emptyForm = 'EditListingExpertsForm.checkboxRequired';
  }
  return errors;
};

export const EditListingExpertsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    validate={required}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        touched,
        handleExpertsModal,
        listing,
        experts,
        expertsPagination,
        allExpertsPagination,
      } = formRenderProps;


      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id='EditListingExpertsForm.updateFailed' />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id='EditListingExpertsForm.showListingFailed' />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || formRenderProps.errors.invalid;
      const isAnyTouched = Object.values(touched).includes(true);
      const messageEmptyForm = (formRenderProps.errors.emptyForm && isAnyTouched) ? intl.formatMessage({ id: formRenderProps.errors.emptyForm }) :
        <span>&nbsp;</span>;

      // Intl Message language checkbox
      const FindAnExpertTip = intl.formatMessage({
        id: 'EditListingExpertsForm.findAnExpertTip',
      });

      const currentListing = ensureListing(listing);
      const privateData = currentListing.attributes &&
        currentListing.attributes.privateData && currentListing.attributes.privateData;
      const invitedExpertsIds = privateData && privateData.invitedExpertsIds;
      const invitedExperts = invitedExpertsIds && invitedExpertsIds.length > 0 ? experts.filter(e => invitedExpertsIds.includes(e.id.uuid)) : null;

      const sendJobInfo = invitedExpertsIds && invitedExpertsIds.length > 0 ?
        <p className={css.connectInfo}>
          <FormattedMessage id='EditListingExpertsForm.sendJobInfo' />
        </p> : null;
      return (
        <>
          <Form
            className={classes}
            onSubmit={handleSubmit}
          >
            {errorMessage}
            {errorMessageShowListing}
            <div>
              <p className={css.tip}>{FindAnExpertTip}</p>
              <SecondaryButton className={css.findAnExpertButton} onClick={(e) => {
                e.preventDefault();
                handleExpertsModal(true);
              }}>
                <span><IconPerson className={css.iconPerson}></IconPerson></span>
                <span><FormattedMessage id='EditListingExpertsForm.findAnExpertButton'
                                        values={{ count: expertsPagination?.totalItems || 0 }} /></span>
              </SecondaryButton>
              <p>
                <FormattedMessage id='EditListingExpertsForm.expertsInfo'
                                  values={{ count: allExpertsPagination?.totalItems || 0 }} />
              </p>
            </div>
            {
              invitedExperts && invitedExperts.length > 0 ?
                <>
                  <div className={css.publishTo}>
                    <FormattedMessage id='EditListingExpertsForm.publishTo' />
                  </div>
                  <div className={css.invitedExperts}>
                    {
                      invitedExperts.map(ie => {
                        const expertAuthor = ie.author;
                        const expertName = expertAuthor && expertAuthor.attributes &&
                          expertAuthor.attributes.profile && expertAuthor.attributes.profile.displayName;
                        return (
                          <div className={css.expert}>
                            <AvatarLarge className={css.avatar} user={expertAuthor} />
                            <span>{expertName}</span>
                          </div>
                        );
                      })
                    }
                  </div>
                </>
                : null
            }
            <div className={css.error}>
              {messageEmptyForm}
            </div>
            {sendJobInfo}
            <Button
              className={css.submitButton}
              type='submit'
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>

        </>
      );
    }}
  />
);

EditListingExpertsFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
  filterConfig: config.custom.filters,
};

EditListingExpertsFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingExpertsFormComponent);
