import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLock.module.css';

const IconLock = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="35px" height="45px" viewBox="0 0 35 45" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Lock-3">
      <g id="Group">
        <path d="M21 33.5C23 31.5 23 28.4 21 26.4C19 24.4 15.9 24.4 13.9 26.4C11.9 28.4 11.9 31.5 13.9 33.5C15.9 35.5 19.1 35.5 21 33.5L21 33.5ZM30 15L30 12.5C30 5.6 24.4 0 17.5 0C10.6 0 5 5.6 5 12.5L5 15L0 15L0 45L35 45L35 15L30 15L30 15ZM7 12.5C7 6.7 11.7 2 17.5 2C23.3 2 28 6.7 28 12.5L28 15L7 15L7 12.5L7 12.5ZM33 43L2 43L2 17L33 17L33 43L33 43Z"
        id="Shape"
        fill="#000000"
        fillRule="evenodd"
        stroke="none" />
      </g>
    </g>
  </svg>
  );
};

IconLock.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconLock.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconLock;
