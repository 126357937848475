import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import { Form, PrimaryButton, FieldTextInput, ExternalLink, FieldSelect } from '../../components';

import css from './ConfirmSignupForm.module.css';

const KEY_CODE_ENTER = 13;

const ConfirmSignupFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
        authInfo,
        idp,
      } = formRenderProps;

      const myDialuxLink = (
        <ExternalLink href="https://my.dialux.com/" className={css.termsLink}>
          <FormattedMessage id=  "ConfirmSignupPage.myDialuxLink"/>
        </ExternalLink>
      );

      const countryOptions = findOptionsForSelectFilter('country', config.profileConfig.filters);



      // email
      const emailLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      const countryLabel = intl.formatMessage({
        id: 'SignupForm.countryLabel',
      });
      const countryPlaceholder = intl.formatMessage({
      id: 'SignupForm.countryPlaceholder',
      });
      const countryRequiredMessage = intl.formatMessage({
        id: 'SignupForm.countryRequired',
      });
      const countryRequired = validators.required(countryRequiredMessage);

      const companyLabel = intl.formatMessage({
        id: 'SignupForm.companyLabel',
      });
      const companyPlaceholder = intl.formatMessage({
        id: 'SignupForm.companyPlaceholder',
      });
      const companyRequiredMessage = intl.formatMessage({
        id: 'SignupForm.companyRequired',
      });
      const companyRequired = validators.required(companyRequiredMessage);

      const jobLabel = intl.formatMessage({
        id: 'SignupForm.jobLabel',
      });
      const jobPlaceholder = intl.formatMessage({
        id: 'SignupForm.jobPlaceholder',
      });
      const jobRequiredMessage = intl.formatMessage({
        id: 'SignupForm.jobRequired',
      });
      const jobRequired = validators.required(jobRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;
      const buttonID = "DIALux ID";

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="ConfirmSignupForm.termsAndConditionsLinkText" />
        </span>
      );

      const emailLink = (
        <ExternalLink href="mailto:contact@lightshift.co">contact(at)lightshift.co</ExternalLink>
      );

      // If authInfo is not available we should not show the ConfirmForm
      if (!authInfo) {
        return;
      }

      // Initial values from idp provider
      const { email, firstName, lastName, emailVerified, dialuxIdAlreadyExists } = authInfo;

      if(dialuxIdAlreadyExists){
        return(
          <div>
            <FormattedMessage
              id="ConfirmSignupForm.dialuxIdAlreadyExists"
              values={{ emailLink }}
            />
          </div>
        )
      }

      if(!emailVerified){
        return(
          <div>
            <FormattedMessage
              id="ConfirmSignupForm.dialuxIdNotVerified"
              values={{ myDialuxLink }}
            />
          </div>
        )
      }

      else
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                initialValue={email}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.firstName` : 'firstName'}
                  name="firstName"
                  autoComplete="given-name"
                  label={firstNameLabel}
                  placeholder={firstNamePlaceholder}
                  initialValue={firstName}
                  validate={firstNameRequired}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lastName` : 'lastName'}
                  name="lastName"
                  autoComplete="family-name"
                  label={lastNameLabel}
                  placeholder={lastNamePlaceholder}
                  initialValue={lastName}
                  validate={lastNameRequired}
                />
              </div>
              <FieldTextInput
                className={css.job}
                type="textarea"
                id={formId ? `${formId}.job` : 'job'}
                name="jobTitle"
                autoComplete="job"
                label={jobLabel}
                placeholder={jobPlaceholder}
                validate={jobRequired}
                initialValue=''
              />
              <FieldTextInput
                className={css.job}
                type="company"
                id={formId ? `${formId}.company` : 'company'}
                name="company"
                autoComplete="company-name"
                label={companyLabel}
                placeholder={companyPlaceholder}
                validate={companyRequired}
                initialValue=''
              />
              <FieldSelect
              className={css.job}
              id={formId ? `${formId}.country` : 'country'}
              validate={countryRequired}
              name="country"
              options={countryOptions}
              label={countryLabel}
              >
              <option value="">{countryPlaceholder}</option>
                {countryOptions.map(o => (
                <FormattedMessage key={o.key} id={o.translationID}>
                  {txt => <option value={o.value}>{txt}</option>}
                </FormattedMessage>
                ))}
              </FieldSelect>
            </div>
          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="ConfirmSignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="ConfirmSignupForm.signUp" values={{ idp: buttonID }} />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

ConfirmSignupFormComponent.defaultProps = {
  inProgress: false,
  filterConfig: config.custom.filters,
};

const { bool, func } = PropTypes;

ConfirmSignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ConfirmSignupForm = compose(injectIntl)(ConfirmSignupFormComponent);
ConfirmSignupForm.displayName = 'ConfirmSignupForm';

export default ConfirmSignupForm;
