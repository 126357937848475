import { FormattedMessage, intlShape } from './util/reactIntl';
import React, { useState, useEffect } from 'react';
export const productPortfolio = [
  {
    id: 'indoor',
    label: <FormattedMessage id={'ManufacturerProductPortfolio.IndoorLabel'} />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_indoor'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'offices', label: <FormattedMessage id={'ManufacturerProductPortfolioIndoor.labelOffices' } />},
        { key: 'industrialAndCraftAreas', label: <FormattedMessage id={'ManufacturerProductPortfolioIndoor.labelIndurstrialAndCraftAreas' } />},
        { key: 'salesAreas', label: <FormattedMessage id={'ManufacturerProductPortfolioIndoor.labelSalesAreas' } />},
        { key: 'residentialAreas', label: <FormattedMessage id={'ManufacturerProductPortfolioIndoor.labelResidentialAreas' } />},
        { key: 'trainingFacilities', label: <FormattedMessage id={'ManufacturerProductPortfolioIndoor.labelTrainingFacilities' } />},
        { key: 'gastronomy', label: <FormattedMessage id={'ManufacturerProductPortfolioIndoor.labelGastronomy' } />},
        { key: 'emergencySafetyLighting', label: <FormattedMessage id={'ManufacturerProductPortfolioIndoor.labelEmergencySafetyLighting' } />},
        { key: 'sportsLeisureFacilities', label: <FormattedMessage id={'ManufacturerProductPortfolioIndoor.labelSportsLeisureFacilities' } />},
        { key: 'healthFacilities', label: <FormattedMessage id={'ManufacturerProductPortfolioIndoor.labelHealthFacilities' } />},
        { key: 'railwayStationsAirports', label: <FormattedMessage id={'ManufacturerProductPortfolioIndoor.labelRailwayStationsAirports' } />},
        { key: 'sacredBuildings', label: <FormattedMessage id={'ManufacturerProductPortfolioIndoor.labelSacredBuildings' } />},
        { key: 'greenhouses', label: <FormattedMessage id={'ManufacturerProductPortfolioIndoor.labelGreenhouses' } />},

      ],
    },
   },
   {
    id: 'outdoor',
    label: <FormattedMessage id={'ManufacturerProductPortfolio.OutdoorLabel'} />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_outdoor'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'industrialFacilities', label: <FormattedMessage id={'ManufacturerProductPortfolioOutdoor.labelIndustrialFacilities' } />},
        { key: 'roadsParkingLots', label: <FormattedMessage id={'ManufacturerProductPortfolioOutdoor.labelRoadsParkingLots' } />},
        { key: 'agriculturalFacilities', label: <FormattedMessage id={'ManufacturerProductPortfolioOutdoor.labelAgriculturalFacilities' } />},
        { key: 'gasStations', label: <FormattedMessage id={'ManufacturerProductPortfolioOutdoor.labelGasStations' } />},
        { key: 'railroadFacilities', label: <FormattedMessage id={'ManufacturerProductPortfolioOutdoor.labelRailroadFacilities' } />},
        { key: 'powerPlants', label: <FormattedMessage id={'ManufacturerProductPortfolioOutdoor.labelPowerPlants' } />},
        { key: 'gasOilProductionPlants', label: <FormattedMessage id={'ManufacturerProductPortfolioOutdoor.labelGasOilProductionPlants' } />},
        { key: 'waterSewagePlants', label: <FormattedMessage id={'ManufacturerProductPortfolioOutdoor.labelWaterSewagePlants' } />},
        { key: 'airports', label: <FormattedMessage id={'ManufacturerProductPortfolioOutdoor.labelAirports' } />},
        { key: 'canalsLocksPortFacilities', label: <FormattedMessage id={'ManufacturerProductPortfolioOutdoor.labelCanalsLocksPortFacilities' } />},
        { key: 'sportsRecreationalFacilities', label: <FormattedMessage id={'ManufacturerProductPortfolioOutdoor.labelSportsRecreationalFacilities' } />},
        { key: 'parksSquares', label: <FormattedMessage id={'ManufacturerProductPortfolioOutdoor.labelParksSquares' } />},
        { key: 'facadesMonuments', label: <FormattedMessage id={'ManufacturerProductPortfolioOutdoor.labelFacadesMonuments' } />},

      ],
    },
   },
];
export const servicePortfolio = [
  {
    id: 'categories',
    label: <FormattedMessage id={'ManufacturerServices.RangeOfServiceLabel' } />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_categories'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'findLuminaire', label: <FormattedMessage id={'ManufacturerServicePortfolioRangeOfService.labelFindLuminaire' } />},
        { key: 'lightingDesign', label: <FormattedMessage id={'ManufacturerServicePortfolioRangeOfService.labelLightingDesign' } />},
        { key: 'lightControl', label: <FormattedMessage id={'ManufacturerServicePortfolioRangeOfService.labelLightControl' } />},
      ],
    },
   },
   {
    id: 'support',
    label: <FormattedMessage id={'ManufacturerServices.OfferedKindLabel' } />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_support'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'call', label: <FormattedMessage id={'ManufacturerServicePortfolioOfferedKind.labelConsultationByPhone' } />},
        { key: 'appointmentInOffice', label: <FormattedMessage id={'ManufacturerServicePortfolioOfferedKind.labelConsultationOffice' } />},
        { key: 'appointmentOnSite', label: <FormattedMessage id={'ManufacturerServicePortfolioOfferedKind.labelConsultationConstructionSite' } />},
        { key: 'appointmentWithManufacturer', label: <FormattedMessage id={'ManufacturerServicePortfolioOfferedKind.labelConsultationManufacturer' } />},

      ],
    },
   },

];
export const countries = [
  {
    id: 'europe',
    label: <FormattedMessage id={'ManufacturerCountry.europeLabel' } />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_country'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'Albania', label: <FormattedMessage id={'CountryFilter.labelAlbania' } />, translationID: 'CountryFilter.labelAlbania', code: 'AL'},
        { key: 'Andorra', label: <FormattedMessage id={'CountryFilter.labelAndorra' } />, translationID: 'CountryFilter.labelAndorra', code: 'AD'},
        { key: 'Austria', label: <FormattedMessage id={'CountryFilter.labelAustria' } />, translationID: 'CountryFilter.labelAustria', code: 'AT'},
        { key: 'Belarus', label: <FormattedMessage id={'CountryFilter.labelBelarus'}/>, translationID: 'CountryFilter.labelBelarus', code: 'BY'},
        { key: 'Belgium', label: <FormattedMessage id={'CountryFilter.labelBelgium' } />, translationID: 'CountryFilter.labelBelgium', code: 'BE'},
        { key: 'Bosnia and Herzegovina', label: <FormattedMessage id={'CountryFilter.labelBosniaAndHerzegovina' } />, translationID: 'CountryFilter.labelBosniaAndHerzegovina', code: 'BA'},
        { key: 'Bulgaria', label: <FormattedMessage id={'CountryFilter.labelBulgaria'}/>, translationID: 'CountryFilter.labelBulgaria', code: 'BG'},
        { key: 'Croatia', label: <FormattedMessage id={'CountryFilter.labelCroatia'}/>, translationID: 'CountryFilter.labelCroatia', code: 'HR'},
        { key: 'Cyprus', label: <FormattedMessage id={'CountryFilter.labelCyprus'}/>, translationID: 'CountryFilter.labelCyprus', code: 'CY'},
        { key: 'Czech Republic', label: <FormattedMessage id={'CountryFilter.labelCzechRepublic'}/>, translationID: 'CountryFilter.labelCzechRepublic', code: 'CZ'},
        { key: 'Denmark', label: <FormattedMessage id={'CountryFilter.labelDenmark'}/>, translationID: 'CountryFilter.labelDenmark', code: 'DK'},
        { key: 'Estonia', label: <FormattedMessage id={'CountryFilter.labelEstonia'}/>, translationID: 'CountryFilter.labelEstonia', code: 'EE'},
        { key: 'Finland', label: <FormattedMessage id={'CountryFilter.labelFinland'}/>, translationID: 'CountryFilter.labelFinland', code: 'FI'},
        { key: 'France', label: <FormattedMessage id={'CountryFilter.labelFrance'}/>, translationID: 'CountryFilter.labelFrance', code: 'FR'},
        { key: 'Germany', label: <FormattedMessage id={'CountryFilter.labelGermany' } />, translationID: 'CountryFilter.labelGermany', code: 'DE'},
        { key: 'Gibraltar', label: <FormattedMessage id={'CountryFilter.labelGibraltar' } />, translationID: 'CountryFilter.labelGibraltar', code: 'GI'},
        { key: 'Greece', label: <FormattedMessage id={'CountryFilter.labelGreece' } />, translationID: 'CountryFilter.labelGreece', code: 'GR'},
        { key: 'Greenland', label: <FormattedMessage id={'CountryFilter.labelGreenland' } />, translationID: 'CountryFilter.labelGreenland', code: 'GL'},
        { key: 'Hungary', label: <FormattedMessage id={'CountryFilter.labelHungary' } />, translationID: 'CountryFilter.labelHungary', code: 'HU'},
        { key: 'Iceland', label: <FormattedMessage id={'CountryFilter.labelIceland' } />, translationID: 'CountryFilter.labelIceland', code: 'IS'},
        { key: 'Ireland', label: <FormattedMessage id={'CountryFilter.labelIreland' } />, translationID: 'CountryFilter.labelIreland', code: 'IE'},
        { key: 'Italy', label: <FormattedMessage id={'CountryFilter.labelItaly'}/>, translationID: 'CountryFilter.labelItaly', code: 'IT'},
        { key: 'Kosovo', label: <FormattedMessage id={'CountryFilter.labelKosovo'}/>, translationID: 'CountryFilter.labelKosovo', code: 'XK'},
        { key: 'Latvia', label: <FormattedMessage id={'CountryFilter.labelLatvia'}/>, translationID: 'CountryFilter.labelLatvia', code: 'LV'},
        { key: 'Liechtenstein', label: <FormattedMessage id={'CountryFilter.labelLiechtenstein'}/>, translationID: 'CountryFilter.labelLiechtenstein', code: 'LI'},
        { key: 'Lithuania', label: <FormattedMessage id={'CountryFilter.labelLithuania'}/>, translationID: 'CountryFilter.labelLithuania', code: 'LT'},
        { key: 'Luxembourg', label: <FormattedMessage id={'CountryFilter.labelLuxembourg'}/>, translationID: 'CountryFilter.labelLuxembourg', code: 'LU'},
        { key: 'Macedonia', label: <FormattedMessage id={'CountryFilter.labelMacedonia'}/>, translationID: 'CountryFilter.labelMacedonia', code: 'MK'},
        { key: 'Malta', label: <FormattedMessage id={'CountryFilter.labelMalta'}/>, translationID: 'CountryFilter.labelMalta', code: 'MT'},
        { key: 'Moldova', label: <FormattedMessage id={'CountryFilter.labelMoldova'}/>, translationID: 'CountryFilter.labelMoldova', code: 'MD'},
        { key: 'Monaco', label: <FormattedMessage id={'CountryFilter.labelMonaco'}/>, translationID: 'CountryFilter.labelMonaco', code: 'MC'},
        { key: 'Montenegro', label: <FormattedMessage id={'CountryFilter.labelMontenegro'}/>, translationID: 'CountryFilter.labelMontenegro', code: 'ME'},
        { key: 'Netherlands', label: <FormattedMessage id={'CountryFilter.labelNetherlands'}/>, translationID: 'CountryFilter.labelNetherlands', code: 'NL'},
        { key: 'Norway', label: <FormattedMessage id={'CountryFilter.labelNorway'}/>, translationID: 'CountryFilter.labelNorway', code: 'NO'},
        { key: 'Poland', label: <FormattedMessage id={'CountryFilter.labelPoland'}/>, translationID: 'CountryFilter.labelPoland', code: 'PL'},
        { key: 'Portugal', label: <FormattedMessage id={'CountryFilter.labelPortugal'}/>, translationID: 'CountryFilter.labelPortugal', code: 'PT'},
        { key: 'Romania', label: <FormattedMessage id={'CountryFilter.labelRomania'}/>, translationID: 'CountryFilter.labelRomania', code: 'RO'},
        { key: 'Russia', label: <FormattedMessage id={'CountryFilter.labelRussia'}/>, translationID: 'CountryFilter.labelRussia', code: 'RU'},
        { key: 'San Marino', label: <FormattedMessage id={'CountryFilter.labelSanMarino'}/>, translationID: 'CountryFilter.labelSanMarino', code: 'SM'},
        { key: 'Serbia', label: <FormattedMessage id={'CountryFilter.labelSerbia'}/>, translationID: 'CountryFilter.labelSerbia', code: 'RS'},
        { key: 'Slovakia', label: <FormattedMessage id={'CountryFilter.labelSlovakia'}/>, translationID: 'CountryFilter.labelSlovakia', code: 'SK'},
        { key: 'Slovenia', label: <FormattedMessage id={'CountryFilter.labelSlovenia'}/>, translationID: 'CountryFilter.labelSlovenia', code: 'SI'},
        { key: 'Spain', label: <FormattedMessage id={'CountryFilter.labelSpain'}/>, translationID: 'CountryFilter.labelSpain', code: 'ES'},
        { key: 'Sweden', label: <FormattedMessage id={'CountryFilter.labelSweden'}/>, translationID: 'CountryFilter.labelSweden', code: 'SE'},
        { key: 'Switzerland', label: <FormattedMessage id={'CountryFilter.labelSwitzerland' } />, translationID: 'CountryFilter.labelSwitzerland', code: 'CH'},
        { key: 'Turkey', label: <FormattedMessage id={'CountryFilter.labelTurkey' } />, translationID: 'CountryFilter.labelTurkey', code: 'TR'},
        { key: 'Ukraine', label: <FormattedMessage id={'CountryFilter.labelUkraine' } />, translationID: 'CountryFilter.labelUkraine', code: 'UA'},
        { key: 'United Kingdom', label: <FormattedMessage id={'CountryFilter.labelUK'}/>, translationID: 'CountryFilter.labelUK', code: 'GB'},
        { key: 'Vatican', label: <FormattedMessage id={'CountryFilter.labelVaticanCityState' } />, translationID: 'CountryFilter.labelVaticanCityState', code: 'VA'},

      ],
    },
   },
   {
    id: 'northAmerica',
    label: <FormattedMessage id={'ManufacturerCountry.northAmericaLabel' } />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_country'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'Bahamas', label: <FormattedMessage id={'CountryFilter.labelBahamas' } />, translationID: 'CountryFilter.labelBahamas', code: 'BS'},
        { key: 'Canada', label: <FormattedMessage id={'CountryFilter.labelCanada'}/>, translationID: 'CountryFilter.labelCanada', code: 'CA'},
        { key: 'Costa Rica', label: <FormattedMessage id={'CountryFilter.labelCostaRica'}/>, translationID: 'CountryFilter.labelCostaRica', code: 'CR'},
        { key: 'Cuba', label: <FormattedMessage id={'CountryFilter.labelCuba'}/>, translationID: 'CountryFilter.labelCuba', code: 'CU'},
        { key: 'Dominican Republic', label: <FormattedMessage id={'CountryFilter.labelDominicanRepublic'}/>, translationID: 'CountryFilter.labelDominicanRepublic', code: 'DO'},
        { key: 'El Salvador', label: <FormattedMessage id={'CountryFilter.labelElSalvador'}/>, translationID: 'CountryFilter.labelElSalvador', code: 'SV'},
        { key: 'Guatemala', label: <FormattedMessage id={'CountryFilter.labelGuatemala' } />, translationID: 'CountryFilter.labelGuatemala', code: 'GT'},
        { key: 'Honduras', label: <FormattedMessage id={'CountryFilter.labelHonduras' } />, translationID: 'CountryFilter.labelHonduras', code: 'HN'},
        { key: 'Mexico', label: <FormattedMessage id={'CountryFilter.labelMexico'}/>, translationID: 'CountryFilter.labelMexico', code: 'MX'},
        { key: 'Nicaragua', label: <FormattedMessage id={'CountryFilter.labelNicaragua'}/>, translationID: 'CountryFilter.labelNicaragua', code: 'NI'},
        { key: 'Panama', label: <FormattedMessage id={'CountryFilter.labelPanama'}/>, translationID: 'CountryFilter.labelPanama', code: 'PA'},
        { key: 'United States', label: <FormattedMessage id={'CountryFilter.labelUnitedStates'}/>, translationID: 'CountryFilter.labelUnitedStates', code: 'US'},
      ],
    },
   },
   {
    id: 'southAmerica',
    label: <FormattedMessage id={'ManufacturerCountry.southAmericaLabel' } />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_country'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'Argentina', label: <FormattedMessage id={'CountryFilter.labelArgentina' } />, translationID: 'CountryFilter.labelArgentina', code: 'AR'},
        { key: 'Bolivia', label: <FormattedMessage id={'CountryFilter.labelBolivia' } />, translationID: 'CountryFilter.labelBolivia', code: 'BO'},
        { key: 'Brazil', label: <FormattedMessage id={'CountryFilter.labelBrazil'}/>, translationID: 'CountryFilter.labelBrazil', code: 'BR'},
        { key: 'Chile', label: <FormattedMessage id={'CountryFilter.labelChile'}/>, translationID: 'CountryFilter.labelChile', code: 'CL'},
        { key: 'Colombia', label: <FormattedMessage id={'CountryFilter.labelColombia'}/>, translationID: 'CountryFilter.labelColombia', code: 'CO'},
        { key: 'Ecuador', label: <FormattedMessage id={'CountryFilter.labelEcuador'}/>, translationID: 'CountryFilter.labelEcuador', code: 'EC'},
        { key: 'French Guiana', label: <FormattedMessage id={'CountryFilter.labelFrenchGuiana'}/>, translationID: 'CountryFilter.labelFrenchGuiana', code: 'GF'},
        { key: 'French Polynesia', label: <FormattedMessage id={'CountryFilter.labelFrenchPolynesia'}/>, translationID: 'CountryFilter.labelFrenchPolynesia', code: 'PF'},
        { key: 'Guadeloupe', label: <FormattedMessage id={'CountryFilter.labelGuadeloupe' } />, translationID: 'CountryFilter.labelGuadeloupe', code: 'GP'},
        { key: 'Haiti', label: <FormattedMessage id={'CountryFilter.labelHaiti' } />, translationID: 'CountryFilter.labelHaiti', code: 'HT'},
        { key: 'Jamaica', label: <FormattedMessage id={'CountryFilter.labelJamaica'}/>, translationID: 'CountryFilter.labelJamaica', code: 'JM'},
        { key: 'Martinique', label: <FormattedMessage id={'CountryFilter.labelMartinique' } />, translationID: 'CountryFilter.labelMartinique', code: 'MQ'},
        { key: 'Papua New Guinea', label: <FormattedMessage id={'CountryFilter.labelPapuaNewGuinea'}/>, translationID: 'CountryFilter.labelPapuaNewGuinea', code: 'PG'},
        { key: 'Paraguay', label: <FormattedMessage id={'CountryFilter.labelParaguay'}/>, translationID: 'CountryFilter.labelParaguay', code: 'PY'},
        { key: 'Peru', label: <FormattedMessage id={'CountryFilter.labelPeru'}/>, translationID: 'CountryFilter.labelPeru', code: 'PE'},
        { key: 'Puerto Rico', label: <FormattedMessage id={'CountryFilter.labelPuertoRico'}/>, translationID: 'CountryFilter.labelPuertoRico', code: 'PR'},
        { key: 'Suriname', label: <FormattedMessage id={'CountryFilter.labelSuriname'}/>, translationID: 'CountryFilter.labelSuriname', code: 'SR'},
        { key: 'Uruguay', label: <FormattedMessage id={'CountryFilter.labelUruguay'}/>, translationID: 'CountryFilter.labelUruguay', code: 'UY'},
        { key: 'Venezuela', label: <FormattedMessage id={'CountryFilter.labelVenezuela'}/>, translationID: 'CountryFilter.labelVenezuela', code: 'VE'},
      ],
    },
   },
   {
    id: 'asia',
    label: <FormattedMessage id={'ManufacturerCountry.asiaLabel' } />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_country'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'Afghanistan', label: <FormattedMessage id={'CountryFilter.labelAfghanistan' } />, translationID: 'CountryFilter.labelAfghanistan', code: 'AF'},
        { key: 'Armenia', label: <FormattedMessage id={'CountryFilter.labelArmenia'}/>, translationID: 'CountryFilter.labelArmenia', code: 'AM'},
        { key: 'Azerbaijan', label: <FormattedMessage id={'CountryFilter.labelAzerbaijan'}/>, translationID: 'CountryFilter.labelAzerbaijan', code: 'AZ'},
        { key: 'Bahrain', label: <FormattedMessage id={'CountryFilter.labelBahrain'}/>, translationID: 'CountryFilter.labelBahrain', code: 'BH'},
        { key: 'Bangladesh', label: <FormattedMessage id={'CountryFilter.labelBangladesh'}/>, translationID: 'CountryFilter.labelBangladesh', code: 'BD'},
        { key: 'Bhutan', label: <FormattedMessage id={'CountryFilter.labelBhutan'}/>, translationID: 'CountryFilter.labelBhutan', code: 'BT'},
        { key: 'Brunei Darussalam', label: <FormattedMessage id={'CountryFilter.labelBruneiDarussalam'}/>, translationID: 'CountryFilter.labelBruneiDarussalam', code: 'BN'},
        { key: 'Cambodia', label: <FormattedMessage id={'CountryFilter.labelCambodia'}/>, translationID: 'CountryFilter.labelCambodia', code: 'KH'},
        { key: 'China', label: <FormattedMessage id={'CountryFilter.labelChina'}/>, translationID: 'CountryFilter.labelChina', code: 'CN'},
        { key: 'Georgia', label: <FormattedMessage id={'CountryFilter.labelGeorgia'}/>, translationID: 'CountryFilter.labelGeorgia', code: 'GE'},
        { key: 'Hong Kong', label: <FormattedMessage id={'CountryFilter.labelHongKong'}/>, translationID: 'CountryFilter.labelHongKong', code: 'HK'},
        { key: 'India', label: <FormattedMessage id={'CountryFilter.labelIndia' } />, translationID: 'CountryFilter.labelIndia', code: 'IN'},
        { key: 'Indonesia', label: <FormattedMessage id={'CountryFilter.labelIndonesia' } />, translationID: 'CountryFilter.labelIndonesia', code: 'ID'},
        { key: 'Iran', label: <FormattedMessage id={'CountryFilter.labelIran' } />, translationID: 'CountryFilter.labelIran', code: 'IR'},
        { key: 'Iraq', label: <FormattedMessage id={'CountryFilter.labelIraq'}/>, translationID: 'CountryFilter.labelIraq', code: 'IQ'},
        { key: 'Israel', label: <FormattedMessage id={'CountryFilter.labelIsrael' } />, translationID: 'CountryFilter.labelIsrael', code: 'IL'},
        { key: 'Japan', label: <FormattedMessage id={'CountryFilter.labelJapan'}/>, translationID: 'CountryFilter.labelJapan', code: 'JP'},
        { key: 'Jordan', label: <FormattedMessage id={'CountryFilter.labelJordan'}/>, translationID: 'CountryFilter.labelJordan', code: 'JO'},
        { key: 'Kazakhstan', label: <FormattedMessage id={'CountryFilter.labelKazakhstan'}/>, translationID: 'CountryFilter.labelKazakhstan', code: 'KZ'},
        { key: 'Kuwait', label: <FormattedMessage id={'CountryFilter.labelKuwait'}/>, translationID: 'CountryFilter.labelKuwait', code: 'KW'},
        { key: 'Kyrgyzstan', label: <FormattedMessage id={'CountryFilter.labelKyrgyzstan'}/>, translationID: 'CountryFilter.labelKyrgyzstan', code: 'KG'},
        { key: 'Laos', label: <FormattedMessage id={'CountryFilter.labelLaos'}/>, translationID: 'CountryFilter.labelLaos', code: 'LA'},
        { key: 'Lebanon', label: <FormattedMessage id={'CountryFilter.labelLebanon'}/>, translationID: 'CountryFilter.labelLebanon', code: 'LB'},
        { key: 'Lesotho', label: <FormattedMessage id={'CountryFilter.labelLesotho'}/>, translationID: 'CountryFilter.labelLesotho', code: 'LS'},
        { key: 'Liberia', label: <FormattedMessage id={'CountryFilter.labelLiberia'}/>, translationID: 'CountryFilter.labelLiberia', code: 'LR'},
        { key: 'Macao', label: <FormattedMessage id={'CountryFilter.labelMacao'}/>, translationID: 'CountryFilter.labelMacao', code: 'MO'},
        { key: 'Malaysia', label: <FormattedMessage id={'CountryFilter.labelMalaysia'}/>, translationID: 'CountryFilter.labelMalaysia', code: 'MY'},
        { key: 'Maldives', label: <FormattedMessage id={'CountryFilter.labelMaldives'}/>, translationID: 'CountryFilter.labelMaldives', code: 'MV'},
        { key: 'Mongolia', label: <FormattedMessage id={'CountryFilter.labelMongolia'}/>, translationID: 'CountryFilter.labelMongolia', code: 'MN'},
        { key: 'Myanmar', label: <FormattedMessage id={'CountryFilter.labelMyanmar'}/>, translationID: 'CountryFilter.labelMyanmar', code: 'MM'},
        { key: 'Nepal', label: <FormattedMessage id={'CountryFilter.labelNepal'}/>, translationID: 'CountryFilter.labelNepal', code: 'NP'},
        { key: 'Oman', label: <FormattedMessage id={'CountryFilter.labelOman'}/>, translationID: 'CountryFilter.labelOman', code: 'OM'},
        { key: 'Pakistan', label: <FormattedMessage id={'CountryFilter.labelPakistan'}/>, translationID: 'CountryFilter.labelPakistan', code: 'PK'},
        { key: 'Palestinian Territory', label: <FormattedMessage id={'CountryFilter.labelPalestinianTerritory'}/>, translationID: 'CountryFilter.labelPalestinianTerritory', code: 'PS'},
        { key: 'Philippines', label: <FormattedMessage id={'CountryFilter.labelPhilippines'}/>, translationID: 'CountryFilter.labelPhilippines', code: 'PH'},
        { key: 'Qatar', label: <FormattedMessage id={'CountryFilter.labelQatar'}/>, translationID: 'CountryFilter.labelQatar', code: 'QA'},
        { key: 'Saudi Arabia', label: <FormattedMessage id={'CountryFilter.labelSaudiArabia'}/>, translationID: 'CountryFilter.labelSaudiArabia', code: 'SA'},
        { key: 'Singapore', label: <FormattedMessage id={'CountryFilter.labelSingapore'}/>, translationID: 'CountryFilter.labelSingapore', code: 'SG'},
        { key: 'South Korea', label: <FormattedMessage id={'CountryFilter.labelSouthKorea'}/>, translationID: 'CountryFilter.labelSouthKorea', code: 'KR'},
        { key: 'Sri Lanka', label: <FormattedMessage id={'CountryFilter.labelSriLanka'}/>, translationID: 'CountryFilter.labelSriLanka', code: 'LK'},
        { key: 'Syria', label: <FormattedMessage id={'CountryFilter.labelSyria' } />, translationID: 'CountryFilter.labelSyria', code: 'SY'},
        { key: 'Taiwan', label: <FormattedMessage id={'CountryFilter.labelTaiwan' } />, translationID: 'CountryFilter.labelTaiwan', code: 'TW'},
        { key: 'Tajikistan', label: <FormattedMessage id={'CountryFilter.labelTajikistan' } />, translationID: 'CountryFilter.labelTajikistan', code: 'TJ'},
        { key: 'Turkmenistan', label: <FormattedMessage id={'CountryFilter.labelTurkmenistan' } />, translationID: 'CountryFilter.labelTurkmenistan', code: 'TM'},
        { key: 'Thailand', label: <FormattedMessage id={'CountryFilter.labelThailand' } />, translationID: 'CountryFilter.labelThailand', code: 'TH'},
        { key: 'United Arab Emirates', label: <FormattedMessage id={'CountryFilter.labelUnitedArabEmirates' } />, translationID: 'CountryFilter.labelUnitedArabEmirates', code: 'AE'},
        { key: 'Uzbekistan', label: <FormattedMessage id={'CountryFilter.labelUzbekistan'}/>, translationID: 'CountryFilter.labelUzbekistan', code: 'UZ'},
        { key: 'Vietnam', label: <FormattedMessage id={'CountryFilter.labelVietnam'}/>, translationID: 'CountryFilter.labelVietnam', code: 'VN'},
        { key: 'Yemen', label: <FormattedMessage id={'CountryFilter.labelYemen'}/>, translationID: 'CountryFilter.labelYemen', code: 'YE'}
      ],
    },
   },
   {
    id: 'africa',
    label: <FormattedMessage id={'ManufacturerCountry.africaLabel' } />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_country'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'Algeria', label: <FormattedMessage id={'CountryFilter.labelAlgeria' } />, translationID: 'CountryFilter.labelAlgeria', code: 'DZ'},
        { key: 'Angola', label: <FormattedMessage id={'CountryFilter.labelAngola' } />, translationID: 'CountryFilter.labelAngola', code: 'AO'},
        { key: 'Benin', label: <FormattedMessage id={'CountryFilter.labelBenin'}/>, translationID: 'CountryFilter.labelBenin', code: 'BJ'},
        { key: 'Botswana', label: <FormattedMessage id={'CountryFilter.labelBotswana' } />, translationID: 'CountryFilter.labelBotswana', code: 'BW'},
        { key: 'Burkina Faso', label: <FormattedMessage id={'CountryFilter.labelBurkinaFaso'}/>, translationID: 'CountryFilter.labelBurkinaFaso', code: 'BF'},
        { key: 'Burundi', label: <FormattedMessage id={'CountryFilter.labelBurundi'}/>, translationID: 'CountryFilter.labelBurundi', code: 'BI'},
        { key: 'Cameroon', label: <FormattedMessage id={'CountryFilter.labelCameroon'}/>, translationID: 'CountryFilter.labelCameroon', code: 'CM'},
        { key: 'Central African Republic', label: <FormattedMessage id={'CountryFilter.labelCentralAfricanRepublic'}/>, translationID: 'CountryFilter.labelCentralAfricanRepublic', code: 'CF'},
        { key: 'Chad', label: <FormattedMessage id={'CountryFilter.labelChad'}/>, translationID: 'CountryFilter.labelChad', code: 'TD'},
        { key: 'Democratic Republic of the Congo', label: <FormattedMessage id={'CountryFilter.labelCongoDemocraticRepublic'}/>, translationID: 'CountryFilter.labelCongoDemocraticRepublic', code: 'CD'},
        { key: 'Djibouti', label: <FormattedMessage id={'CountryFilter.labelDjibouti'}/>, translationID: 'CountryFilter.labelDjibouti', code: 'DJ'},
        { key: 'Egypt', label: <FormattedMessage id={'CountryFilter.labelEgypt'}/>, translationID: 'CountryFilter.labelEgypt', code: 'EG'},
        { key: 'Equatorial Guinea', label: <FormattedMessage id={'CountryFilter.labelEquatorialGuinea'}/>, translationID: 'CountryFilter.labelEquatorialGuinea', code: 'GQ'},
        { key: 'Eritrea', label: <FormattedMessage id={'CountryFilter.labelEritrea'}/>, translationID: 'CountryFilter.labelEritrea', code: 'ER'},
        { key: 'Ethiopia', label: <FormattedMessage id={'CountryFilter.labelEthiopia'}/>, translationID: 'CountryFilter.labelEthiopia', code: 'ET'},
        { key: 'Gabon', label: <FormattedMessage id={'CountryFilter.labelGabon'}/>, translationID: 'CountryFilter.labelGabon', code: 'GA'},
        { key: 'Gambia', label: <FormattedMessage id={'CountryFilter.labelGambia'}/>, translationID: 'CountryFilter.labelGambia', code: 'GM'},
        { key: 'Ghana', label: <FormattedMessage id={'CountryFilter.labelGhana' } />, translationID: 'CountryFilter.labelGhana', code: 'GH'},
        { key: 'Ivory Coast', label: <FormattedMessage id={'CountryFilter.labelIvoryCoast'}/>, translationID: 'CountryFilter.labelIvory Coast', code: 'CI'},
        { key: 'Kenya', label: <FormattedMessage id={'CountryFilter.labelKenya'}/>, translationID: 'CountryFilter.labelKenya', code: 'KE'},
        { key: 'Libya', label: <FormattedMessage id={'CountryFilter.labelLibya'}/>, translationID: 'CountryFilter.labelLibya', code: 'LY'},
        { key: 'Madagascar', label: <FormattedMessage id={'CountryFilter.labelMadagascar'}/>, translationID: 'CountryFilter.labelMadagascar', code: 'MG'},
        { key: 'Malawi', label: <FormattedMessage id={'CountryFilter.labelMalawi'}/>, translationID: 'CountryFilter.labelMalawi', code: 'MW'},
        { key: 'Mali', label: <FormattedMessage id={'CountryFilter.labelMali'}/>, translationID: 'CountryFilter.labelMali', code: 'ML'},
        { key: 'Mauritius', label: <FormattedMessage id={'CountryFilter.labelMauritius'}/>, translationID: 'CountryFilter.labelMauritius', code: 'MU'},
        { key: 'Morocco', label: <FormattedMessage id={'CountryFilter.labelMorocco'}/>, translationID: 'CountryFilter.labelMorocco', code: 'MA'},
        { key: 'Mozambique', label: <FormattedMessage id={'CountryFilter.labelMozambique'}/>, translationID: 'CountryFilter.labelMozambique', code: 'MZ'},
        { key: 'Namibia', label: <FormattedMessage id={'CountryFilter.labelNamibia'}/>, translationID: 'CountryFilter.labelNamibia', code: 'NA'},
        { key: 'Niger', label: <FormattedMessage id={'CountryFilter.labelNiger'}/>, translationID: 'CountryFilter.labelNiger', code: 'NE'},
        { key: 'Nigeria', label: <FormattedMessage id={'CountryFilter.labelNigeria'}/>, translationID: 'CountryFilter.labelNigeria', code: 'NG'},
        { key: 'Reunion', label: <FormattedMessage id={'CountryFilter.labelReunion'}/>, translationID: 'CountryFilter.labelReunion', code: 'RE'},
        { key: 'Rwanda', label: <FormattedMessage id={'CountryFilter.labelRwanda'}/>, translationID: 'CountryFilter.labelRwanda', code: 'RW'},
        { key: 'Senegal', label: <FormattedMessage id={'CountryFilter.labelSenegal'}/>, translationID: 'CountryFilter.labelSenegal', code: 'SN'},
        { key: 'Seychelles', label: <FormattedMessage id={'CountryFilter.labelSeychelles'}/>, translationID: 'CountryFilter.labelSeychelles', code: 'SC'},
        { key: 'Sierra Leone', label: <FormattedMessage id={'CountryFilter.labelSierraLeone'}/>, translationID: 'CountryFilter.labelSierraLeone', code: 'SL'},
        { key: 'Somalia', label: <FormattedMessage id={'CountryFilter.labelSomalia'}/>, translationID: 'CountryFilter.labelSomalia', code: 'SO'},
        { key: 'South Africa', label: <FormattedMessage id={'CountryFilter.labelSouthAfrica'}/>, translationID: 'CountryFilter.labelSouthAfrica', code: 'ZA'},
        { key: 'South Sudan', label: <FormattedMessage id={'CountryFilter.labelSouthSudan'}/>, translationID: 'CountryFilter.labelSouthSudan', code: 'SS'},
        { key: 'Sudan', label: <FormattedMessage id={'CountryFilter.labelSudan'}/>, translationID: 'CountryFilter.labelSudan', code: 'SD'},
        { key: 'Swaziland', label: <FormattedMessage id={'CountryFilter.labelSwaziland'}/>, translationID: 'CountryFilter.labelSwaziland', code: 'SZ'},
        { key: 'Tanzania', label: <FormattedMessage id={'CountryFilter.labelTanzania' } />, translationID: 'CountryFilter.labelTanzania', code: 'TZ'},
        { key: 'Togo', label: <FormattedMessage id={'CountryFilter.labelTogo' } />, translationID: 'CountryFilter.labelTogo', code: 'TG'},
        { key: 'Tunisia', label: <FormattedMessage id={'CountryFilter.labelTunisia' } />, translationID: 'CountryFilter.labelTunisia', code: 'TN'},
        { key: 'Uganda', label: <FormattedMessage id={'CountryFilter.labelUganda' } />, translationID: 'CountryFilter.labelUganda', code: 'UG'},
        { key: 'Western Sahara', label: <FormattedMessage id={'CountryFilter.labelWesternSahara'}/>, translationID: 'CountryFilter.labelWesternSahara', code: 'EH'},
        { key: 'Zambia', label: <FormattedMessage id={'CountryFilter.labelZambia'}/>, translationID: 'CountryFilter.labelZambia', code: 'ZM'},
        { key: 'Zimbabwe', label: <FormattedMessage id={'CountryFilter.labelZimbabwe'}/>, translationID: 'CountryFilter.labelZimbabwe', code: 'ZW'},

      ],
    },
   },
   {
    id: 'oceania',
    label: <FormattedMessage id={'ManufacturerCountry.oceaniaLabel' } />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_country'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'Australia', label: <FormattedMessage id={'CountryFilter.labelAustralia' } />, translationID: 'CountryFilter.labelAustralia', code: 'AU'},
        { key: 'New Caledonia', label: <FormattedMessage id={'CountryFilter.labelNewCaledonia'}/>, translationID: 'CountryFilter.labelNewCaledonia', code: 'NC'},
        { key: 'New Zealand', label: <FormattedMessage id={'CountryFilter.labelNewZealand'}/>, translationID: 'CountryFilter.labelNewZealand', code: 'NZ'},
        { key: 'Samoa', label: <FormattedMessage id={'CountryFilter.labelSamoa'}/>, translationID: 'CountryFilter.labelSamoa', code: 'WS'},
      ],
    },
   },

];

export const dateFilters = [
  {
    id: 'applicationDeadline',
    label: <FormattedMessage id={'ManufacturerDateFilter.applicationDeadlineFilterLabel'}/>,
    type: 'DeadlineFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['pub_applicationDeadline'],
    config: {},
  },
  {
    id: 'realisationRange',
    label: <FormattedMessage id={'ManufacturerDateFilter.realisationRangeFilterLabel'}/>,
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['pub_realisationDate'],
    config: {},
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'pub_applicationDeadlineUNIX', label: <FormattedMessage id={'sortBy.Newest'} />},
    { key: '-pub_applicationDeadlineUNIX', label: <FormattedMessage id={'sortBy.Oldest'} />},
     /*{ key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },*/

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    //{ key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
