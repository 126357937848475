import React, { Component } from 'react';
import { string, func, oneOfType } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import truncate from 'lodash/truncate';
import classNames from 'classnames';
import { AvatarLarge, NamedLink, InlineTextButton } from '..';
import { ensureUser, ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';

import css from './QA_Card.module.css';
import { isNull } from 'lodash';

// Approximated collapsed size so that there are ~three lines of text
// in the desktop layout in the host section of the ListingPage.
const QA_LENGTH = 170;

const truncated = s => {
  return truncate(s, {
    length: QA_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

class ExpandableQa extends Component {
  constructor(props) {
    super(props);
    this.state = { expand: false };
  }
  render() {
    const { expand } = this.state;
    const { className, qa } = this.props;
    const truncatedQa = truncated(qa);

    const handleShowMoreClick = () => {
      this.setState({ expand: true });
    };

    const handleShowLessClick = () => {
      this.setState({ expand: false });
    };

    const showMore = (
      <InlineTextButton rootClassName={css.showMore} onClick={handleShowMoreClick}>
        <FormattedMessage id="ExpandableQa.showFullQaLink" />
      </InlineTextButton>
    );

    const showLess = (
      <InlineTextButton rootClassName={css.showMore} onClick={handleShowLessClick}>
        <FormattedMessage id="ExpandableQa.showLessQaLink" />
      </InlineTextButton>
    );


    return (
      <div className={className}>
        {expand ? qa : truncatedQa}
        {qa !== truncatedQa && !expand ? showMore : qa === truncatedQa && !expand ? null : showLess}
      </div>
    );
  }
}

const QaDiv = props => {
  const {className, qa } = props;

  return (
        <ExpandableQa className={className} qa={qa}/>
  );
};

QaDiv.defaultProps = {
  className: null
};

QaDiv.propTypes = {
  className: string
};

export default QaDiv;
