import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  IconAdd,
  IconEdit,
} from '../../components';
import { openWorkingExperienceModal } from '../../ducks/WorkingExperience.duck';
import { useTranslation } from '../../hooks';
import css from './ProfileSkillsForm.module.css';

export function SectionWorkingExperience() {
  const dispatch = useDispatch();
  const setTranslation = useTranslation('WorkingExperience');
  const workingExperience = useSelector(x => x?.WorkingExperience?.items);

  const addNewButtonClicked = () => {
    dispatch(openWorkingExperienceModal());
  };

  const editButtonClicked = (item) => {
    dispatch(openWorkingExperienceModal(item));
  };

  const formatDuration = ({ startMonth, startYear, endMonth, endYear }) => {
    let startDate = '', endDate = '', output = '';
    startDate += !!startMonth ? setTranslation(`monthNo${startMonth}`) : '';
    startDate += !!startYear ? ` ${startYear}` : '';
    endDate += !!endMonth ? setTranslation(`monthNo${endMonth}`) : '';
    endDate += !!endYear ? ` ${endYear}` : '';
    output += startDate;
    output += ' - ';
    output += !!endDate ? endDate : setTranslation('today');

    return output;
  };

  return (
    <div className={css.workingExperience__section}>
      <div className={css.workingExperience__headline}>{setTranslation('sectionTitle')}</div>

      <a onClick={addNewButtonClicked}>
        <IconAdd width={18} height={18} className={css.workingExperience__add}/>
      </a>

      <div className={css.workingExperience__wrapper}>
        {workingExperience && workingExperience.map(xp =>
          <div key={xp.id} className={css.workingExperience__item}>
            <span className={css.workingExperience__jobTitle}>{xp.jobTitle}</span>

            <a onClick={() => editButtonClicked(xp)}>
              <IconEdit width={18} height={18} pencilClassName={css.workingExperience__pencil}/>
            </a>

            <span className={css.workingExperience__company}>{xp.companyName}</span>
            <span className={css.workingExperience__duration}>
              {formatDuration(xp)}
            </span>

          </div>,
        )}
      </div>
    </div>
  );
}
