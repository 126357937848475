import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  TRANSITION_SEND_MESSAGE_BY_PROVIDER_PRE_PROPOSAL_RESPONSE,
  TRANSITION_ACCEPT_PROPOSAL,
  TRANSITION_ACCEPT_PROPOSAL_PENDING_REPLY,
  TRANSITION_REJECT_PROPOSAL,
  TRANSITION_REJECT_PROPOSAL_PENDING_REPLY,
  TRANSITION_SEND_MESSAGE_BY_PROVIDER,
  TRANSITION_SEND_MESSAGE_BY_CUSTOMER,
  nextPossibleSendMessageTransition,
} from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  updateProposal,
  getProposals,
  getInvitations,
  getInteractions,
  getTransactions,
  archiveListing,
  sendMessage,
  seenProposal,
} from '../../util/api';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import * as log from '../../util/log';
import _ from "lodash";
import { formatCustomApiData } from '../../util/data';
import { VALID_STATES_TO_CLOSE_LISTING } from '../../util/constants';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

const onlyFilterValues = {
  client_interactions: 'client_interactions',
  expert_interactions: 'expert_interactions',
  manufacturer_interactions: 'manufacturer_interactions',
  client_archive: 'client_archive',
  expert_archive: 'expert_archive',
  manufacturer_archive: 'manufacturer_archive',
  listings: 'listings',
};

const RESULT_PAGE_SIZE = 42;

const requestAction = actionType => params => ({ type: actionType, payload: { params } });
const successAction = actionType => result => ({ type: actionType, payload: result.data });
const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const FETCH_INTERACTIONS_REQUEST = 'app/InboxPage/FETCH_INTERACTIONS_REQUEST';
export const FETCH_INTERACTIONS_SUCCESS = 'app/InboxPage/FETCH_INTERACTIONS_SUCCESS';
export const FETCH_INTERACTIONS_ERROR = 'app/InboxPage/FETCH_INTERACTIONS_ERROR';
export const CLEAR_INTERACTIONS = 'app/InboxPage/CLEAR_INTERACTIONS';

export const FETCH_INVITATIONS_REQUEST = 'app/InboxPage/FETCH_INVITATIONS_REQUEST';
export const FETCH_INVITATIONS_SUCCESS = 'app/InboxPage/FETCH_INVITATIONS_SUCCESS';
export const FETCH_INVITATIONS_ERROR = 'app/InboxPage/FETCH_INVITATIONS_ERROR';

export const FETCH_PROPOSALS_REQUEST = 'app/InboxPage/FETCH_PROPOSALS_REQUEST';
export const FETCH_PROPOSALS_SUCCESS = 'app/InboxPage/FETCH_PROPOSALS_SUCCESS';
export const FETCH_PROPOSALS_ERROR = 'app/InboxPage/FETCH_PROPOSALS_ERROR';

export const FETCH_TRANSACTIONS_REQUEST = 'app/InboxPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/InboxPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/InboxPage/FETCH_TRANSACTIONS_ERROR';

export const FETCH_LISTING_SUCCESS = 'app/InboxPage/FETCH_LISTING_SUCCESS';
export const FETCH_LISTING_ERROR = 'app/InboxPage/FETCH_LISTING_ERROR';

export const FETCH_USER_SUCCESS = 'app/InboxPage/FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'app/InboxPage/FETCH_USER_ERROR';

export const ACCEPT_PROPOSAL_REQUEST = 'app/InboxPage/ACCEPT_PROPOSAL_REQUEST';
export const ACCEPT_PROPOSAL_SUCCESS = 'app/InboxPage/ACCEPT_PROPOSAL_SUCCESS';
export const ACCEPT_PROPOSAL_ERROR = 'app/InboxPage/ACCEPT_PROPOSAL_ERROR';

export const REJECT_PROPOSAL_REQUEST = 'app/InboxPage/REJECT_PROPOSAL_REQUEST';
export const REJECT_PROPOSAL_SUCCESS = 'app/InboxPage/REJECT_PROPOSAL_SUCCESS';
export const REJECT_PROPOSAL_ERROR = 'app/InboxPage/REJECT_PROPOSAL_ERROR';

export const OPEN_ACCEPTPROPOSALMODAL_REQUEST = 'app/InboxPage/OPEN_DELETEMODAL_REQUEST';
export const CLOSE_ACCEPTPROPOSALMODAL_REQUEST = 'app/InboxPage/CLOSE_DELETEMODAL_REQUEST';

export const ARCHIVE_LISTING_REQUEST = 'app/InboxPage/ARCHIVE_LISTING_REQUEST';
export const ARCHIVE_LISTING_SUCCESS = 'app/InboxPage/ARCHIVE_LISTING_SUCCESS';
export const ARCHIVE_LISTING_ERROR = 'app/InboxPage/ARCHIVE_LISTING_ERROR';

// Manage listings
export const FETCH_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/ManageListingsPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/ManageListingsPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManageListingsPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ManageListingsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ManageListingsPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManageListingsPage/CLOSE_LISTING_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/EditListingPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/EditListingPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/EditListingPage/UPDATE_LISTING_ERROR';

export const DELETE_DRAFT_REQUEST = 'app/ManageListingsPage/DELETE_DRAFT_REQUEST';
export const DELETE_DRAFT_SUCCESS = 'app/ManageListingsPage/DELETE_DRAFT_SUCCESS';
export const DELETE_DRAFT_ERROR = 'app/ManageListingsPage/DELETE_DRAFT_ERROR';

export const DELETE_LISTING_SUCCESS = 'app/ManageListingsPage/DELETE_LISTING_SUCCESS';

export const ADD_OWN_ENTITIES = 'app/ManageListingsPage/ADD_OWN_ENTITIES';

export const OPEN_DELETEMODAL_REQUEST = 'app/ManageListingsPage/OPEN_DELETEMODAL_REQUEST';
export const CLOSE_DELETEMODAL_REQUEST = 'app/ManageListingsPage/CLOSE_DELETEMODAL_REQUEST';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const SEEN_PROPOSAL_REQUEST = 'app/ListingPage/SEEN_PROPOSAL_REQUEST';
export const SEEN_PROPOSAL_SUCCESS = 'app/ListingPage/SEEN_PROPOSAL_SUCCESS';
export const SEEN_PROPOSAL_ERROR = 'app/ListingPage/SEEN_PROPOSAL_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInteractionsInProgress: false,
  fetchInteractionsError: null,
  interactionsRefs: [],
  pagination: null,
  fetchInvitationsInProgress: false,
  fetchInvitationsError: null,
  invitations: [],
  invitationsPagination: null,
  fetchProposalsInProgress: false,
  fetchProposalsError: null,
  proposals: [],
  proposalsPagination: null,
  fetchTransactionsInProgress: false,
  fetchTransactionsError: null,
  transactionRefs: [],
  transactionsPagination: null,
  fetchedLisitng: null,
  fetchedLisitngError: null,
  fetchedUser: null,
  fetchedUserError: null,
  rejectProposalInProgress: null,
  rejectProposalError: null,
  acceptProposalInProgress: null,
  acceptProposalError: null,
  archiveListingInProgress: false,
  archiveListingError: null,
  archiveListingSuccess: null,

  // Manage listings
  listingsPagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  updateListingError: null,
  updateInProgress: false,
  deletingDraft: null,
  deletingDraftError: null,

  sendEnquiryInProgress: false,
  sendEnquiryError: null,

  seenProposalInProgressId: null,
  seenProposalError: null,
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const ownListingEntities = {
    ...state.ownEntities.ownListing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
  };
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_INTERACTIONS_REQUEST:
      return { ...state, fetchInteractionsInProgress: true, fetchInteractionsError: null, interactionsRefs: [] };
    case FETCH_INTERACTIONS_SUCCESS: {
      return {
        ...state,
        fetchInteractionsInProgress: false,
        interactionsRefs: entityRefs(payload.data),
        pagination: payload.meta,
      };
    }
    case FETCH_INTERACTIONS_ERROR:
      return { ...state, fetchInteractionsInProgress: false, fetchInteractionsError: payload };

    case CLEAR_INTERACTIONS:
      return { ...state, invitations: [], invitationsPagination: null, proposals: [], proposalsPagination: null, transactionRefs: [], transactionsPagination: null  };

    case FETCH_LISTING_SUCCESS:
      return {
        ...state,
        fetchedLisitng: payload,
      };
    case FETCH_LISTING_ERROR:
      return { ...state, fetchedLisitngError: payload };

    case FETCH_USER_SUCCESS:
      return {
        ...state,
        fetchedUser: payload,
      };
    case FETCH_USER_ERROR:
      return { ...state, fetchedUserError: payload };

    case FETCH_PROPOSALS_REQUEST:
      return { ...state, fetchProposalsInProgress: true, fetchProposalsError: null};
    case FETCH_PROPOSALS_SUCCESS:
      return { ...state, fetchProposalsInProgress: false, proposals: _.uniqBy(
        [ ...state.proposals , ...payload.proposals],
        function (e) {
          return e.id;
        }
      ), proposalsPagination: payload.meta };
    case FETCH_PROPOSALS_ERROR:
      return { ...state, fetchProposalsInProgress: false, fetchProposalsError: payload };

    case ACCEPT_PROPOSAL_REQUEST:
      return { ...state, acceptProposalInProgress: payload, acceptProposalError: null };
    case ACCEPT_PROPOSAL_SUCCESS:
      const acceptProposalIndex = state.proposals.findIndex( p => p.id === payload.id )
      state.proposals[acceptProposalIndex] = payload;
      return { ...state, acceptProposalInProgress: null, acceptProposalError: null, proposals: state.proposals};
    case ACCEPT_PROPOSAL_ERROR:
      return { ...state, acceptProposalInProgress: null, acceptProposalError: payload };

    case REJECT_PROPOSAL_REQUEST:
      return { ...state, rejectProposalInProgress: payload, rejectProposalError: null};
    case REJECT_PROPOSAL_SUCCESS:
      const proposalIndex = state.proposals.findIndex(p => p.id === payload.id )
      state.proposals[proposalIndex] = payload;
      return { ...state, rejectProposalInProgress: null, rejectProposalError: null, proposals: state.proposals};
    case REJECT_PROPOSAL_ERROR:
      return { ...state, rejectProposalInProgress: null, rejectProposalError: payload };

    case FETCH_INVITATIONS_REQUEST:
      return { ...state, fetchInvitationsInProgress: true, fetchInvitationsError: null};
    case FETCH_INVITATIONS_SUCCESS:
      return { ...state, fetchInvitationsInProgress: false, invitations: _.uniqBy(
        [ ...state.invitations , ...payload.invitations],
        function (e) {
          return e.id;
        }
      ), invitationsPagination: payload.meta };
    case FETCH_INVITATIONS_ERROR:
      return { ...state, fetchInvitationsInProgress: false, fetchInvitationsError: payload };

    case FETCH_TRANSACTIONS_REQUEST:
      return { ...state, fetchTransactionsInProgress: true, fetchTransactionsError: null};
    case FETCH_TRANSACTIONS_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data || payload.data);
      return {
        ...state,
        fetchTransactionsInProgress: false,
        transactionRefs: _.uniqBy(
          [ ...state.transactionRefs , ...entityRefs(transactions)],
          function (e) {
            return e.id.uuid;
          }
        ),
        transactionsPagination: payload.meta,
      };
    }
    case FETCH_TRANSACTIONS_ERROR:
      return { ...state, fetchTransactionsInProgress: false, fetchTransactionsError: payload };

    case OPEN_ACCEPTPROPOSALMODAL_REQUEST:
      return {
        ...state,

        showAcceptProposalModal: true,
      };
    case CLOSE_ACCEPTPROPOSALMODAL_REQUEST:
      return {
        ...state,

        showAcceptProposalModal: false,
      };

    case ARCHIVE_LISTING_REQUEST:
      return { ...state, archiveListingInProgress: true, archiveListingError: null, archiveListingSuccess: null};
    case ARCHIVE_LISTING_SUCCESS:
      return { ...state, archiveListingInProgress: false, archiveListingError: null, archiveListingSuccess: true};
    case ARCHIVE_LISTING_ERROR:
      return { ...state, archiveListingInProgress: false, archiveListingError: payload && payload.error, archiveListingSuccess: null };

    // Manage listings
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        listingsPagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }
    case OPEN_DELETEMODAL_REQUEST:
      return {
        ...state,
        listingIdForDeletion: payload.listingId,
        showDeletionModal: true,
      };
    case CLOSE_DELETEMODAL_REQUEST:
      return {
        ...state,
        listingIdForDeletion: null,
        showDeletionModal: false,
      };
    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case DELETE_DRAFT_REQUEST:
      return {
        ...state,
        deletingDraft: payload.listingId,
        deletingDraftError: null,
      };
    case DELETE_DRAFT_SUCCESS:
      return Object.assign({}, _.omit(state, ['deletingDraft']));
    case DELETE_DRAFT_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        deletingDraft: null,
        deletingDraftError: {
          listingId: state.deletingDraft,
          error: payload,
        },
      };
    }
    case UPDATE_LISTING_REQUEST:
      return { ...state, updateInProgress: true, updateListingError: null };
    case UPDATE_LISTING_SUCCESS:
      return { ...state, updateInProgress: false };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateInProgress: false, updateListingError: payload };
    case ADD_OWN_ENTITIES:
      return merge(state, payload);
    case DELETE_LISTING_SUCCESS:
      return { ...state, currentPageResultIds: state.currentPageResultIds.filter(id => id !== payload) };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case SEEN_PROPOSAL_REQUEST:
      return { ...state, seenProposalInProgressId: payload, seenProposalError: null };
    case SEEN_PROPOSAL_SUCCESS:{
      const seenProposalIndex = state.proposals.findIndex( p => p.id === payload.id )
      state.proposals[seenProposalIndex] = payload;
      return { ...state, seenProposalInProgressId: null, proposals: state.proposals};
    }
    case SEEN_PROPOSAL_ERROR:
      return { ...state, seenProposalInProgressId: null, seenProposalError: payload };

    default:
      return state;
  }
}

// ================ Manage listings =========== //
// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state?.InboxPage ?? {};
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

const fetchInteractionsRequest = () => ({ type: FETCH_INTERACTIONS_REQUEST });
const fetchInteractionsSuccess = response => ({
  type: FETCH_INTERACTIONS_SUCCESS,
  payload: response,
});
const fetchInteractionsError = e => ({
  type: FETCH_INTERACTIONS_ERROR,
  error: true,
  payload: e,
});


const fetchListingSuccess = response => ({ type: FETCH_LISTING_SUCCESS, payload: response });
const fetchListingError = e => ({ type: FETCH_LISTING_ERROR, error: true, payload: e });

const fetchUserSuccess = response => ({ type: FETCH_USER_SUCCESS, payload: response });
const fetchUserError = e => ({ type: FETCH_USER_ERROR, error: true, payload: e });

const fetchProposalsRequest = () => ({ type: FETCH_PROPOSALS_REQUEST });
const fetchProposalsSuccess = (response) => ({ type: FETCH_PROPOSALS_SUCCESS, payload: response, });
const fetchProposalsError = e => ({ type: FETCH_PROPOSALS_ERROR, error: true, payload: e });

const acceptProposalRequest = (id) => ({ type: ACCEPT_PROPOSAL_REQUEST, payload: id });
const acceptProposalSuccess = (response) => ({ type: ACCEPT_PROPOSAL_SUCCESS, payload: response });
const acceptProposalError = e => ({ type: ACCEPT_PROPOSAL_ERROR, error: true, payload: e });

const rejectProposalRequest = (id) => ({ type: REJECT_PROPOSAL_REQUEST, payload: id });
const rejectProposalSuccess = (response) => ({ type: REJECT_PROPOSAL_SUCCESS, payload: response });
const rejectProposalError = e => ({ type: REJECT_PROPOSAL_ERROR, error: true, payload: e });

const fetchInvitationsRequest = () => ({ type: FETCH_INVITATIONS_REQUEST });
const fetchInvitationsSuccess = (response) => ({ type: FETCH_INVITATIONS_SUCCESS, payload: response, });
const fetchInvitationsError = e => ({ type: FETCH_INVITATIONS_ERROR, error: true, payload: e });

const fetchTransactionsRequest = () => ({ type: FETCH_TRANSACTIONS_REQUEST });
const fetchTransactionsSuccess = (response) => ({ type: FETCH_TRANSACTIONS_SUCCESS, payload: response, });
const fetchTransactionsError = e => ({ type: FETCH_TRANSACTIONS_ERROR, error: true, payload: e });

const archiveListingRequest = () => ({ type: ARCHIVE_LISTING_REQUEST });
const archiveListingSuccess = (response) => ({ type: ARCHIVE_LISTING_SUCCESS, payload: response, });
const archiveListingError = e => ({ type: ARCHIVE_LISTING_ERROR, error: true, payload: e });

export const clearInteractions = () => ({
  type: CLEAR_INTERACTIONS
});

// ================ Manage listings ================ //
// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const updateListing = requestAction(UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction(UPDATE_LISTING_SUCCESS);
export const updateListingError = errorAction(UPDATE_LISTING_ERROR);

export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const deleteDraftRequest = listingId => ({
  type: DELETE_DRAFT_REQUEST,
  payload: { listingId },
});

export const deleteDraftSuccess = response => ({
  type: DELETE_DRAFT_SUCCESS,
  payload: response.data,
});

export const deleteDraftError = e => ({
  type: DELETE_DRAFT_ERROR,
  error: true,
  payload: e,
});

export const deleteListingSuccess = listingId => ({
  type: DELETE_LISTING_SUCCESS,
  payload: listingId
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const openDeleteModalRequest = listingId => ({
  type: OPEN_DELETEMODAL_REQUEST,
  payload: { listingId },
});

export const closeDeleteModalRequest = () => ({
  type: CLOSE_DELETEMODAL_REQUEST
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const seenProposalRequest = (id) => ({ type: SEEN_PROPOSAL_REQUEST, payload: id });
export const seenProposalSuccess = (response) => ({ type: SEEN_PROPOSAL_SUCCESS, payload: response, });
export const seenProposalError = e => ({ type: SEEN_PROPOSAL_ERROR, error: true, payload: e });

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

// ================ Manage listings ================ //
// Throwing error for new (loadData may need that info)
export const queryOwnListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, per_page: perPage };
  return sdk.ownListings
    .query(params)
    .then(response => {
      const res = {...response, data: {...response.data, data: response.data.data}}
      dispatch(addOwnEntities(res));
      dispatch(queryListingsSuccess(res));
      return res;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(closeListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));
    });
};

export const requestDeleteListing = (listingId) => (dispatch, getState, sdk) => {
    const date = new Date().toISOString();
    const data = {
      id: listingId,
      privateData: {
        deleted: date
      }
    }
    const state = getState()
    const {
      queryParams
    } = state.InboxPage
    dispatch(updateListing(data));
    const { id } = listingId;
    let updateResponse;
    return sdk.ownListings
      .update(data)
      .then(response => {
        updateResponse = response;
      })
      .then(() => {
        dispatch(updateListingSuccess(updateResponse));
        dispatch(deleteListingSuccess(listingId))
        return updateResponse;
      })
      .then(() => {
        return sdk.ownListings.show({id: listingId})
          .then(listingResponse => {
            const listingState = listingResponse.data.data.attributes.state;
            if (VALID_STATES_TO_CLOSE_LISTING.includes(listingState)){
              return dispatch(closeListing(listingId));
            }
        })
      })
      .then(() => {
        return queryOwnListings(queryParams)(dispatch, getState, sdk);
      })
      .catch(e => {
        log.error(e, 'update-listing-failed', { listingData: data });
        return dispatch(updateListingError(storableError(e)));
      });
}

//Hier anpassen
export const requestDeleteDraft = listingId => (dispatch, getState, sdk) => {
  const state = getState()
  const {
    queryParams
  } = state.InboxPage
  dispatch(deleteDraftRequest(listingId));

  return sdk.ownListings
    .discardDraft({ id: listingId }, { expand: false })
    .then(response => {
      dispatch(deleteDraftSuccess(response));
      return response;
    })
    .then(() => {
      return queryOwnListings(queryParams)(dispatch, getState, sdk);
    }
    )
    .catch(e => {
      dispatch(deleteDraftError(storableError(e)));
    });
};
//Hier anpassen ENDE

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(openListingError(storableError(e)));
    });
};

export const loadListingsData = (params, search) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  return queryOwnListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

// ================ Manage listings ================ //

export const fetchListing = listingId => async (dispatch, getState, sdk) => {
  return sdk.listings
    .show({
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchListingSuccess(response.data));
      return response;
    })
    .catch(e => dispatch(fetchListingError(storableError(e))));
};

export const fetchUser = userId => (dispatch, getState, sdk) => {
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchUserSuccess());
      return response;
    })
    .catch(e => dispatch(fetchUserError(storableError(e))));
};

export const openAcceptProposalModalRequest = () => ({
  type: OPEN_ACCEPTPROPOSALMODAL_REQUEST,

});

export const closeAcceptProposalModalRequest = () => ({
  type:CLOSE_ACCEPTPROPOSALMODAL_REQUEST
});

export const transitionTransaction = params => async (dispatch, getState, sdk) => {
  const { transactionId, transition} = params;
  const cancelReason = params.cancelReason;
  const transitionParams = cancelReason ? {protectedData: {cancelReason: cancelReason}} : {}
  try {
    const res = await sdk.transactions.transition(
      {
        id: transactionId,
        transition: transition,
        params: transitionParams,
      },
      {
        expand: true,
      }
    );

    dispatch(addMarketplaceEntities(res));
  } catch (e) {
    console.error(e);
  }
};

export const acceptProposal = (id) => (dispatch, getState, sdk) => {
  const handleSucces = response => {
    dispatch(acceptProposalSuccess(response.data.proposal));
    if (response?.data?.proposal?.proposal_type === 'lead'){
      const transactionId = response?.data?.proposal?.transaction_id;
      if (transactionId){
        sdk.transactions
        .show(
          {
            id: transactionId,
          },
          { expand: true }
        )
        .then(response => {
          const lastTransition = response?.data?.data?.attributes?.lastTransition;
          if(lastTransition && (lastTransition === TRANSITION_SEND_MESSAGE_BY_PROVIDER || lastTransition === TRANSITION_SEND_MESSAGE_BY_CUSTOMER || lastTransition === TRANSITION_SEND_MESSAGE_BY_PROVIDER_PRE_PROPOSAL_RESPONSE))
          {
            dispatch(transitionTransaction({transactionId: transactionId, transition: TRANSITION_ACCEPT_PROPOSAL_PENDING_REPLY}))
          }
          else{
            dispatch(transitionTransaction({transactionId: transactionId, transition: TRANSITION_ACCEPT_PROPOSAL}))
          }
        })
      }
    }
    return response
  };

  const handleError = e => {
    dispatch(acceptProposalError(e.error))
  };

  dispatch(acceptProposalRequest(id));

  return updateProposal({id, confirmPayment: true})
    .then(handleSucces)
    .catch(handleError);
};

export const rejectProposal = (values) => (dispatch, getState, sdk) => {
  const handleSuccess = response => {
    dispatch(rejectProposalSuccess(response.data.proposal));
    if (response?.data?.proposal?.proposal_type === 'lead'){
      const transactionId = response?.data?.proposal?.transaction_id;
      if (transactionId){
        sdk.transactions
        .show(
          {
            id: transactionId,
          },
          { expand: true }
        )
        .then(response => {
          const lastTransition = response?.data?.data?.attributes?.lastTransition;
          if(lastTransition && (lastTransition === TRANSITION_SEND_MESSAGE_BY_PROVIDER || lastTransition === TRANSITION_SEND_MESSAGE_BY_CUSTOMER || lastTransition === TRANSITION_SEND_MESSAGE_BY_PROVIDER_PRE_PROPOSAL_RESPONSE))
          {
            dispatch(transitionTransaction({transactionId: transactionId, transition: TRANSITION_REJECT_PROPOSAL_PENDING_REPLY, cancelReason: values.reasons}))
          }
          else{
            dispatch(transitionTransaction({transactionId: transactionId, transition: TRANSITION_REJECT_PROPOSAL, cancelReason: values.reasons}))
          }
        })
      }
    }
  };

  const handleError = e => {
    dispatch(rejectProposalError(e.error))
  };

  dispatch(rejectProposalRequest(values.proposalId));
  const updateParams = {
    id: values.proposalId,
    reasons: values.reasons,
  }
  return updateProposal(updateParams)
    .then(handleSuccess)
    .catch(handleError);
};

export const fetchInvitations = (tab, userId, listingId, page) => (dispatch, getState, sdk) => {
  const handleSuccess = response => {
    response.data.invitations.map(({ listing_id }) => {
      dispatch(fetchListing(listing_id));
    });

    dispatch(fetchInvitationsSuccess(response.data));
    return response
  };

  const handleError = e => {
    dispatch(fetchInvitationsError(e.error));
  };

  dispatch(fetchInvitationsRequest());

  return getInvitations({tab, userId, listingId, page, perPage: INBOX_PAGE_SIZE,})
    .then(handleSuccess)
    .catch(handleError);
};

export const fetchProposals = (tab, userId, listingId, page) => (dispatch, getState, sdk) => {
  const handleSuccess = response => {
    response.data.proposals.map(({ listing_id,  expert_lisitng_id}) => {
      dispatch(fetchListing(listing_id));
      if(expert_lisitng_id){
        dispatch(fetchListing(expert_lisitng_id));
      }
    });
    response.data.proposals.map(({ expert_id }) => {
      dispatch(fetchUser(expert_id));
    });

    dispatch(fetchProposalsSuccess(response.data));
    return response
  };

  const handleError = e => {
    dispatch(fetchProposalsError(e.error));
  };

  dispatch(fetchProposalsRequest());

  return getProposals({tab, userId, listingId, page, perPage: INBOX_PAGE_SIZE,})
    .then(handleSuccess)
    .catch(handleError);
};

export const fetchTransactions = (tab, userId, userListingId, listingId, page) => (dispatch, getState, sdk) => {
  const handleSuccess = response => {
    const formatedResponse = formatCustomApiData(response);
    dispatch(addMarketplaceEntities(formatedResponse));
    dispatch(fetchTransactionsSuccess(formatedResponse.data));
    return formatedResponse
  };

  const handleError = e => {
    dispatch(fetchTransactionsError(e.error));
  };

  dispatch(fetchTransactionsRequest());

  return getTransactions({tab, userId, userListingId, listingId, page, perPage: INBOX_PAGE_SIZE,})
    .then(handleSuccess)
    .catch(handleError);
};

export const loadInvitations = (params) => (dispatch, getState, sdk) => {
  const { tab, page, listingId } = params;
  const onlyFilter = onlyFilterValues[tab];
    return Promise.all([dispatch(fetchCurrentUser())]).then(response => {
      const currentUser = getState().user.currentUser;
      const userListingId = currentUser && currentUser.attributes && currentUser.attributes.profile.privateData.listingID;
      if (currentUser && userListingId) {
        dispatch(fetchInvitations(onlyFilter, userListingId, listingId, page));
      }
    });
};

export const loadProposals = (params) => (dispatch, getState, sdk) => {
  const { tab, page, listingId } = params;
  const onlyFilter = onlyFilterValues[tab];
  return Promise.all([dispatch(fetchCurrentUser())]).then(response => {
    const currentUser = getState().user.currentUser;
    if (currentUser) {
      dispatch(fetchProposals(onlyFilter, currentUser.id.uuid, listingId, page));
    }
  });
};

export const loadTransactions = (params) => (dispatch, getState, sdk) => {
  const { tab, page, listingId } = params;
  const onlyFilter = onlyFilterValues[tab];
  return Promise.all([dispatch(fetchCurrentUser())]).then(response => {
    const currentUser = getState().user.currentUser;
    const userListingId = currentUser && currentUser.attributes && currentUser.attributes.profile.privateData.listingID;
    if (currentUser) {
      dispatch(fetchTransactions(onlyFilter, currentUser.id.uuid, userListingId, listingId, page));
    }
  });
};

export const loadInteractions = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;
  const onlyFilter = onlyFilterValues[tab];
  const { page = 1 } = parse(search);
  return Promise.all([dispatch(fetchCurrentUser())]).then(response => {
    const currentUser = getState().user.currentUser;
    const userListingId = currentUser && currentUser.attributes && currentUser.attributes.profile.privateData.listingID;
    if (currentUser) {
      const handleSuccess = response => {
        const formattedResponse = formatCustomApiData(response);
        dispatch(addMarketplaceEntities(formattedResponse));
        dispatch(fetchInteractionsSuccess(formattedResponse.data));
        return formattedResponse
      };
      const handleError = e => {
        dispatch(fetchInteractionsError(e.error));
      };
      dispatch(fetchInteractionsRequest());

      return getInteractions({userId: currentUser.id.uuid, userListingId, page, per_page: INBOX_PAGE_SIZE, tab: onlyFilter})
        .then(handleSuccess)
        .catch(handleError);
    }
  });
};

export const archive = (id, params, search) => (dispatch, getState, sdk) => {
  dispatch(archiveListingRequest(id));
  const { tab } = params;
  const onlyFilter = onlyFilterValues[tab];
  const handleSuccess = response => {
    dispatch(archiveListingSuccess(response.data.proposal));
    dispatch(loadInteractions(params, search));
  };

  const handleError = e => {
    dispatch(archiveListingError(e.error))
  };

  return archiveListing({id, tab: onlyFilter})
    .then(handleSuccess)
    .catch(handleError);
};

export const sendEnquiry = (transactionId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());

  const bodyParams = {
    transactionId: transactionId,
    content: message,
  };

  const handleSuccess = response => {
    const messageId = response.data.data.id;
    if (transactionId){
      sdk.transactions
      .show(
        {
          id: transactionId,
        },
        { expand: true }
      )
      .then(response => {
        const transaction = response?.data?.data;
        const nextTransition = nextPossibleSendMessageTransition(true, transaction)
        dispatch(transitionTransaction({transactionId: transactionId, transition: nextTransition}))
        dispatch(sendEnquirySuccess());
        return messageId;
      })
    }
  };

  const handleError = e => {
    dispatch(sendEnquiryError(storableError(e)));
    throw e;
  };

  return sendMessage(bodyParams)
    .then(handleSuccess)
    .catch(handleError);
};

export const seenLeadProposal = (values) => (dispatch, getState, sdk) => {
  const { id } = values;
  dispatch(seenProposalRequest(id));

  const handleSuccess = response => {
    const responseData = response.data.data;
    dispatch(seenProposalSuccess(responseData));
    return responseData;
  };

  const handleError = e => {
    dispatch(seenProposalError(storableError(e)));
  };

  return seenProposal(values)
    .then(handleSuccess)
    .catch(handleError);
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;
  const onlyFilter = onlyFilterValues[tab];

  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  if (onlyFilter === 'listings') {
    return dispatch(loadListingsData(params, search))
  }
  else{
    return dispatch(loadInteractions(params, search));
  }
};
