import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconShield.module.css';

const IconShield = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="36px" height="45px" viewBox="0 0 36 45" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 0L0 6.8v13.4c0 5.7 1.7 10.9 5.1 15.7S12.8 43.7 18 45c5.2-1.3 9.6-4.4 12.9-9.1s5.1-10 5.1-15.7V6.8L18 0zm16 20.1c0 5.3-1.6 10.2-4.7 14.6-3 4.3-6.7 7-11.3 8.2-4.6-1.3-8.3-4-11.3-8.2C3.6 30.3 2 25.4 2 20.1v-12l16-6 16 6v12zm-25.2.6l-1.4 1.4 6.4 6.4 1.4 1.4 1.4-1.4 12-12-1.4-1.4-12 12z"
        color="#000000"
        fillRule="evenodd"
      />
    </svg>
  );
};

IconShield.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconShield.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconShield;
