import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconWorldwide.module.css';

const IconWorldwide = props => {
  const { rootClassName, className, globeClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
    className={classes}
    width="28"
    height="28"
    viewBox="6 6 28 28"
    xmlns="http://www.w3.org/2000/svg">
      <g
        className={globeClassName || css.globe}
        strokeWidth="0"        
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
      <path d="M34 20A14 14 0 0 0 20 6h0a14 14 0 0 0 0 28h0a14 14 0 0 0 14-14zm-17.35 5h6.7A14.61 14.61 0 0 1 20 31.29 14.59 14.59 0 0 1 16.65 25zm-.4-2a18.87 18.87 0 0 1 0-6h7.53a19.6 19.6 0 0 1 .23 3 19.8 19.8 0 0 1-.23 3zM20 8.7A14.64 14.64 0 0 1 23.36 15h-6.7A14.55 14.55 0 0 1 20 8.71zm5.78 8.3h5.83a11.73 11.73 0 0 1 0 6h-5.83a22.77 22.77 0 0 0 .22-3 22.77 22.77 0 0 0-.22-3zm5.1-2h-5.5a17.12 17.12 0 0 0-3.22-6.79A12 12 0 0 1 30.89 15zM17.83 8.2a17.16 17.16 0 0 0-3.22 6.8H9.1a12 12 0 0 1 8.72-6.8zM8.4 17h5.84a22.77 22.77 0 0 0-.23 3 22.77 22.77 0 0 0 .23 3H8.4a11.73 11.73 0 0 1 0-6zm.72 8h5.5a17.31 17.31 0 0 0 3.21 6.79A12 12 0 0 1 9.11 25zm13.07 6.8A17.19 17.19 0 0 0 25.4 25h5.5a12 12 0 0 1-8.71 6.79z" />
      </g>
    </svg>
  );
};

IconWorldwide.defaultProps = {
  rootClassName: null,
  className: null,
  globeClassName: null,
};

const { string } = PropTypes;

IconWorldwide.propTypes = {
  rootClassName: string,
  className: string,
  globeClassName: string,
};

export default IconWorldwide;
