import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink, ListingLinkUnnamed } from '../../components';
import { EditListingLocationsForm } from '../../forms';

import css from './EditListingLocationsPanel.module.css';

class EditListingLocationsPanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);

    this.state = {
      initialValues: this.getInitialValues(),
    };
  }

  getInitialValues() {
    const { listing, currentUser } = this.props;
    const currentListing = ensureOwnListing(listing);
    const authorProfile = currentListing?.author?.attributes?.profile;
    const authorProtectedData = authorProfile?.protectedData;
    const authorPublicData = authorProfile?.publicData;
    const { geolocation, publicData, privateData } = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;
    const location = publicData && publicData.location ? publicData.location : {};
    const { address, building } = location;
    const officeDetailValues = privateData?.officeDetails;
    const officeDetails = {
      company: officeDetailValues?.company || authorPublicData?.company,
      fname: officeDetailValues?.fname || authorProfile?.firstName,
      lname: officeDetailValues?.lname || authorProfile?.lastName,
      address: {
        street: officeDetailValues?.address?.street || authorProtectedData?.address?.street,
        houseNumber: officeDetailValues?.address?.houseNumber || authorProtectedData?.address?.houseNumber,
        postalCode: officeDetailValues?.address?.postalCode || authorProtectedData?.address?.postalCode,
        city: officeDetailValues?.address?.city || authorProtectedData?.address?.city,
        country: officeDetailValues?.address?.country || authorPublicData?.country,
      },
      email: authorProtectedData?.email || currentUser?.attributes?.email,
      phoneNumber: officeDetailValues?.phoneNumber || authorProtectedData?.phoneNumber,
    };
    return {
      location: locationFieldsPresent
        ? {
          search: address,
          selectedPlace: { address, origin: geolocation },
        }
        : null,
      officeDetails,
    };
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      currentUser,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id='EditListingLocationsPanel.title'
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id='EditListingLocationsPanel.createListingTitle' />
    );

    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <EditListingLocationsForm
          className={css.form}
          initialValues={this.state.initialValues}
          onSubmit={values => {
            const { location, officeDetails } = values;
            const address = location.selectedPlace.address;
            const origin = location.selectedPlace.origin;
            const postcode = location.selectedPlace.postcode;
            const country = location.selectedPlace.country;
            const updateValues = {
              geolocation: origin,
              publicData: {
                location: { address },
                country,
                postcode,
              },
              privateData: {
                officeDetails,
              },
            };
            this.setState({
              initialValues: {
                location: { search: address, selectedPlace: { address, origin } },
                officeDetails,
              },
            });
            onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
        />
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingLocationsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingLocationsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingLocationsPanel;
