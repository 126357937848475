import moment from 'moment';
import { types as sdkTypes } from './sdkLoader';
import toPairs from 'lodash/toPairs';
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { findOptionsForSelectFilter } from './search';
import { checkVAT, countries } from 'jsvat';
import config from '../config';
import { value } from 'lodash/seq';

const { LatLng, Money } = sdkTypes;

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 256;

const isNonEmptyString = val => {
  return typeof val === 'string' && val.trim().length > 0;
};

/**
 * Validator functions and helpers for Final Forms
 */

// Final Form expects and undefined value for a successful validation
const VALID = undefined;

export const required = message => value => {
  if (typeof value === 'undefined' || value === null) {
    // undefined or null values are invalid
    return message;
  }
  if (typeof value === 'string') {
    // string must be nonempty when trimmed
    return isNonEmptyString(value) ? VALID : message;
  }
  return VALID;
};

export const minOfferPrice = message => value => {
  if (value && typeof value.amount === 'number') {
    return value.amount >= 1000 ? VALID : message;
  }
  return VALID;
};

export const requiredStringNoTrim = message => value => {
  return typeof value === 'string' && value.length > 0 ? VALID : message;
};

// DEPRECATED in favor of required
export const requiredBoolean = message => value => {
  return typeof value === 'boolean' ? VALID : message;
};

// DEPRECATED in favor of required
export const requiredAndNonEmptyString = message => value => {
  return isNonEmptyString(value) ? VALID : message;
};

export const requiredFieldArrayCheckbox = message => value => {
  if (!value) {
    return message;
  }

  const entries = toPairs(value);
  const hasSelectedValues = entries.filter(e => !!e[1]).length > 0;
  return hasSelectedValues ? VALID : message;
};

export const minLength = (message, minimumLength) => value => {
  const hasLength = value && typeof value.length === 'number';
  return hasLength && value.length >= minimumLength ? VALID : message;
};

export const maxLength = (message, maximumLength) => value => {
  if (!value) {
    return VALID;
  }
  const hasLength = value && typeof value.length === 'number';
  return hasLength && value.length <= maximumLength ? VALID : message;
};

export const nonEmptyArray = message => value => {
  return value && Array.isArray(value) && value.length > 0 ? VALID : message;
};

export const autocompleteSearchRequired = message => value => {
  return value && value.search ? VALID : message;
};

export const autocompletePlaceSelected = message => value => {
  const selectedPlaceIsValid =
    value &&
    value.selectedPlace &&
    value.selectedPlace.address &&
    value.selectedPlace.origin instanceof LatLng;
  return selectedPlaceIsValid ? VALID : message;
};

export const bookingDateRequired = inValidDateMessage => value => {
  const dateIsValid = value && value.date instanceof Date;
  return !dateIsValid ? inValidDateMessage : VALID;
};

export const bookingDatesRequired = (inValidStartDateMessage, inValidEndDateMessage) => value => {
  const startDateIsValid = value && value.startDate instanceof Date;
  const endDateIsValid = value && value.endDate instanceof Date;

  if (!startDateIsValid) {
    return inValidStartDateMessage;
  } else if (!endDateIsValid) {
    return inValidEndDateMessage;
  } else {
    return VALID;
  }
};

// Source: http://www.regular-expressions.info/email.html
// See the link above for an explanation of the tradeoffs.
const EMAIL_RE = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const emailFormatValid = message => value => {
  return value && EMAIL_RE.test(value) ? VALID : message;
};

export const phoneNumberFormatValid = message => value => {
  return value && isPossiblePhoneNumber(value) ? VALID : message;
};

export const moneySubUnitAmountAtLeast = (message, minValue) => value => {
  return value instanceof Money && value.amount >= minValue ? VALID : message;
};

const parseNum = str => {
  const num = Number.parseInt(str, 10);
  return Number.isNaN(num) ? null : num;
};

export const ageAtLeast = (message, minYears) => value => {
  const { year, month, day } = value;
  const dayNum = parseNum(day);
  const monthNum = parseNum(month);
  const yearNum = parseNum(year);

  // day, month, and year needs to be numbers
  if (dayNum !== null && monthNum !== null && yearNum !== null) {
    const now = moment();
    const age = new Date(yearNum, monthNum - 1, dayNum);
    const ageInYears = now.diff(moment(age), 'years', true);

    return age && age instanceof Date && ageInYears >= minYears ? VALID : message;
  }
  return message;
};

export const validBusinessURL = message => value => {
  if (typeof value === 'undefined' || value === null) {
    return message;
  }

  const disallowedChars = /[^-A-Za-z0-9+&@#/%?=~_|!:,.;()]/;
  const protocolTokens = value.split(':');
  const includesProtocol = protocolTokens.length > 1;
  const usesHttpProtocol = includesProtocol && !!protocolTokens[0].match(/^(https?)/);

  const invalidCharacters = !!value.match(disallowedChars);
  const invalidProtocol = !(usesHttpProtocol || !includesProtocol);
  // Stripe checks against example.com
  const isExampleDotCom = !!value.match(/^(https?:\/\/example\.com|example\.com)/);
  const isLocalhost = !!value.match(/^(https?:\/\/localhost($|:|\/)|localhost($|:|\/))/);
  return invalidCharacters || invalidProtocol || isExampleDotCom || isLocalhost ? message : VALID;
};

export const validSsnLast4 = message => value => {
  return value.length === 4 ? VALID : message;
};

export const validHKID = message => value => {
  // Accept value 000000000 for testing Stripe
  if (value.length === 9 && value.match(/([0]{9})/)) {
    return VALID;
  }

  // HKID format example: AB364912(5)
  // ID can start with one or two letters and the check digit in the end can be in brackets or not
  if (value.length < 8) {
    return message;
  }

  // Handle possible brackets in value
  if (value.charAt(value.length - 3) === '(' && value.charAt(value.length - 1) === ')') {
    value = value.substring(0, value.length - 3) + value.charAt(value.length - 2);
  }
  value = value.toUpperCase();

  // Check that pattern is correct and split value to array
  const hkidPattern = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
  const matchArray = value.match(hkidPattern);

  if (!matchArray) {
    return message;
  }

  const charPart = matchArray[1];
  const numPart = matchArray[2];
  const checkDigit = matchArray[3];

  // Calculate the checksum for character part.
  // Transfer letters to numbers so that A=10, B=11, C=12 etc.
  // If there is only one letter in the ID use 36 as the first value
  // Total calculation is weighted so that 1st digit is x9, 2nd digit x8, 3rd digit x7 etc.

  const strValidChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let checkSum = 0;

  if (charPart.length === 2) {
    checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
    checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
  } else {
    checkSum += 9 * 36;
    checkSum += 8 * (10 + strValidChars.indexOf(charPart));
  }

  // Calculate the checksum for numeric part

  for (let i = 0, j = 7; i < numPart.length; i++, j--) {
    checkSum += j * numPart.charAt(i);
  }

  // Verify the check digit
  const remaining = checkSum % 11;
  let verify = remaining === 0 ? 0 : 11 - remaining;
  verify = verify.toString();
  const isValid = verify === checkDigit || (verify === 10 && checkDigit === 'A');

  return isValid ? VALID : message;
};

export const validSGID = message => value => {
  return value.length === 9 ? VALID : message;
};

export const isNegBool = message => value => {
  return value === false;
}

export const supportedFederalStates = [

];

export const supportedCountries = [
  "AE", //United Arab Emirates
  "AU", //Australia
  "AT", //Austria
  "BE", //Belgium
  "BG", //Bulgaria
  //"BR", //Brazil
  "CA", //Canada
  "CY", //Cyprus
  "CZ", //Czech Republic
  "DK", //Denmark
  "EE", //Estonia
  "FI", //Finland
  "FR", //France
  "DE", //Germany
  "GR", //Greece
  "HK", //Hong Kong
  "HU", //Hungary
  "IE", //Ireland
  "IT", //Italy
  "JP", //Japan
  "KR", //South Korea
  "LV", //Latvia
  "LT", //Lithuania
  "LU", //Luxembourg
  "MT", //Malta
  //"MV", //Maldives
  "MX", //Mexico
  "MY", //Malaysia
  "NL", //Netherlands
  "NZ", //New Zealand
  "NO", //Norway
  "PL", //Poland
  "PT", //Portugal
  "RO", //Romania
  "RU", //Russian Federation (included in jsvat)
  "SG", //Singapore
  "SK", //Slovakia Republic
  "SI", //Slovenia
  "TH", //Thailand
  "TW", //Taiwan
  "ES", //Spain
  "SE", //Sweden
  "CH", //Switzerland
  "GB", //United Kingdom
  "US", //United States
]

export const COUNTRIES_TAX_ID_REGEX = {
  "AE": [/^(\d{15})$/], //123456789012345
  "AU": [/^(\d{12})$/, /^(\d{11})$/],
  "CA": [/^([A-Z]{3}\-\d{4}\-\d{4})$/,
    /^(\d{9}[A-Z]{2}\d{4})$/,
    /^(\d{10}[A-Z]{2}\d{4})$/,
    /^(\d{6}\-\d{1})$/,
    /^(\d{9})$/,
    /^(\d{7})$/,
  ],
  //"CN": [/^(\d{18})$/, /^(\d{15})$/],
  "HK": [/^(\d{8})$/],
  //"IN": [/^(\d{2}[A-Z]{5}\d{4}[A-Z]{4})$/],
  "JP": [/^(\d{13})$/, /^(\d{5})$/],
  "KR": [/^(\d{3}\-\d{2}\-\d{5})$/],
  "MX": [/^([A-Z]{3}\d{6}[A-Z]{2}\d{1})$/],
  "MY": [/^([A-Z]{1}\d{2}\-\d{4}\-\d{8})$/,/^([A-Z]{1}\s\d{10})$/],
  "NZ": [/^(\d{9})$/],
  "US": [/^(\d{2}\-\d{7})$/],
  "SG": [/^([A-Z]{1}\d{8}[A-Z]{1})$/, /^(\d{9}[A-Z]{1})$/],
  "TH": [/^(\d{13})$/],
  //"TR": [/^(\d{10})$/],
  "TW": [/^(\d{8})$/],
  //"VN": [/^(\d{7})$/],


  //Present in jsvat but regex is not correct
  "RU": [/^(\d{9})$/, /^(\d{10})$/],
  "HU": [/^(HU)?\d{11}$/, /^(\d{8}\-\d{1}\-\d{2})$/],
  "SE": [/^(SE)?[0-9]{12}$/],
  "ES": [/^(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]$/],
  "EE": [/^(EE)?[0-9]{9}$/],
  "IE": [/^(IE)?[0-9]{7}[A-Z]{1,2}$/, /^(IE)?[0-9][A-Z][0-9]{5}[A-Z]$/],
  "NO": [/^(\d{9})(MVA)$/],
  "GB": [/^(GB)?(\d{9})$/, /^(GB)?(\d{12})$/, /^(GB)?(GD\d{3})$/, /^(GB)?(HA\d{3})$/, /^(XI)?(\d{9})$/],
  //"BR": [/^(BR)?(\d{14}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/, /^(BR)?(\d{11}|\d{3}\.\d{3}\.\d{3}\-\d{2})$/],
}

  //Present in jsvat, it passes jsvat validation but stripe validation give error,
  //so restricting switzerland to use only custom validation
  export const ONLY_CUSTOM_VALIDATION = {
    "CH": [/^(CHE)(\-)(\d{3})(\.)(\d{3})(\.)(\d{3})(\s)(MWST)$/, /^(CHE)(\-)(\d{3})(\.)(\d{3})(\.)(\d{3})(\s)(IVA)$/, /^(CHE)(\-)(\d{3})(\.)(\d{3})(\.)(\d{3})(\s)(TVA)$/],
  }

export const extendedCountries = countryCode => {
  return COUNTRIES_TAX_ID_REGEX[countryCode]
}

export const validTaxId = message => (value, allValues) => {
  const { country, taxId, vatAvailable } = allValues;
  const countryKey = 'country';
  const countryOptions = findOptionsForSelectFilter(countryKey, config.profileConfig.filters);
  const countryCode = countryOptions.filter(countryOption => countryOption.key === country)[0]?.code;
  const countryValidator = countries.filter(c => c.codes.includes(countryCode));
  const extendedCountryRegex = extendedCountries(countryCode)
  const onlyCustomValidation = ONLY_CUSTOM_VALIDATION[countryCode]
  const isVatAvailable = vatAvailable === 'vat is available' ? true : false;
  if (!supportedCountries.includes(countryCode)){
    return VALID
  }
  else if(onlyCustomValidation){
    return onlyCustomValidation && taxId && onlyCustomValidation.some(rx => rx.test(taxId)) ? VALID : message;
  }
  else if (countryValidator && countryValidator.length > 0 && taxId){
    const vatCheckResult = checkVAT(taxId, countryValidator);
    return vatCheckResult.isValidFormat ? VALID : extendedCountryRegex && extendedCountryRegex.some(rx => rx.test(taxId)) ? VALID : message;
  }
  else if (!isVatAvailable){
    return VALID
  }
  else{
    return extendedCountryRegex && taxId && extendedCountryRegex.some(rx => rx.test(taxId)) ? VALID : message;
  }
};

export const composeValidators = (...validators) => (value, allValues) =>{
  return validators.reduce((error, validator) => error || validator(value, allValues), VALID);
}

export const supportedVatCountry = (countryCode) => {
    const isSupportedVatCountry = supportedCountries.includes(countryCode);
  return(
    isSupportedVatCountry
  );
}

