import pick from 'lodash/pick';
import moment from 'moment';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { transactionLineItems, updateAttachments } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities, ensureUser } from '../../util/data';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import axios from 'axios';
import {
  createProposal,
  isProposalSent,
  addQuestion,
  addAnswer,
  getQuestions,
  seenByUser,
  getListingPostalCode,
} from '../../util/api';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_POSTAL_CODE_REQUEST = 'app/ListingPage/FETCH_POSTAL_CODE_REQUEST';
export const FETCH_POSTAL_CODE_SUCCESS = 'app/ListingPage/FETCH_POSTAL_CODE_SUCCESS';
export const FETCH_POSTAL_CODE_ERROR = 'app/ListingPage/FETCH_POSTAL_CODE_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const SEND_PROPOSAL_REQUEST = 'app/ListingPage/SEND_PROPOSAL_REQUEST';
export const SEND_PROPOSAL_SUCCESS = 'app/ListingPage/SEND_PROPOSAL_SUCCESS';
export const SEND_PROPOSAL_ERROR = 'app/ListingPage/SEND_PROPOSAL_ERROR';

export const SEND_QUESTION_REQUEST = 'app/ListingPage/SEND_QUESTION_REQUEST';
export const SEND_QUESTION_SUCCESS = 'app/ListingPage/SEND_QUESTION_SUCCESS';
export const SEND_QUESTION_ERROR = 'app/ListingPage/SEND_QUESTION_ERROR';

export const SEND_ANSWER_REQUEST = 'app/ListingPage/SEND_ANSWER_REQUEST';
export const SEND_ANSWER_SUCCESS = 'app/ListingPage/SEND_ANSWER_SUCCESS';
export const SEND_ANSWER_ERROR = 'app/ListingPage/SEND_ANSWER_ERROR';

export const FETCH_QUESTIONS_REQUEST = 'app/ListingPage/FETCH_QUESTIONS_REQUEST';
export const FETCH_QUESTIONS_SUCCESS = 'app/ListingPage/FETCH_QUESTIONS_SUCCESS';
export const FETCH_QUESTIONS_ERROR = 'app/ListingPage/FETCH_QUESTIONS_ERROR';

export const FETCH_USER_SUCCESS = 'app/ListingPage/FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'app/ListingPage/FETCH_USER_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/ListingPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/ListingPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/ListingPage/UPDATE_LISTING_ERROR';

export const QUERY_LISTINGS_COUNT_REQUEST = 'app/ListingPage/QUERY_LISTINGS_COUNT_REQUEST';
export const QUERY_LISTINGS_COUNT_SUCCESS = 'app/ListingPage/QUERY_LISTINGS_COUNT_SUCCESS';
export const QUERY_LISTINGS_COUNT_ERROR = 'app/ListingPage/QUERY_LISTINGS_COUNT_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  sendProposalInProgress: false,
  sendProposalError: null,
  sendProposalMessage: null,
  sendQuestionInProgress: false,
  sendQuestionError: false,
  sendAnswerInProgress: false,
  sendAnswerError: false,
  questions: [],
  totalQuestionsCount: 0,
  fetchQuestionsInProgress: false,
  fetchQuestionsError: false,
  fetchedUser: null,
  fetchedUserError: null,
  updateListingInProgress: false,
  updateListingError: null,
  listingUpdated: false,
  fetchListingsCountInProgress: false,
  fetchListingsCountError: null,
  totalListingsCount: {},
  postalCode: null,
  fetchPostalCodeError: null,
  fetchPostalCodeInProgress: false,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null, sendProposalMessage: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case UPDATE_LISTING_REQUEST:
      return { ...state, updateListingInProgress: true, updateListingError: null };
    case UPDATE_LISTING_SUCCESS:
      return { ...state, updateListingInProgress: false, listingUpdated: true };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateListingInProgress: false, updateListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_POSTAL_CODE_REQUEST:
      return { ...state, fetchPostalCodeInProgress: true, fetchPostalCodeError: null };
    case FETCH_POSTAL_CODE_SUCCESS:
      return { ...state, postalCode: payload, fetchPostalCodeInProgress: false };
    case FETCH_POSTAL_CODE_ERROR:
      return { ...state, fetchPostalCodeError: payload, fetchPostalCodeInProgress: false };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case SEND_PROPOSAL_REQUEST:
      return {
        ...state,
        sendProposalInProgress: true,
        sendProposalError: null,
        sendProposalMessage: null,
      };
    case SEND_PROPOSAL_SUCCESS:
      return { ...state, sendProposalInProgress: false, sendProposalMessage: payload };
    case SEND_PROPOSAL_ERROR:
      return {
        ...state,
        sendProposalInProgress: false,
        sendProposalError: payload,
        sendProposalMessage: payload,
      };

    case SEND_QUESTION_REQUEST:
      return { ...state, sendQuestionInProgress: true, sendQuestionError: null };
    case SEND_QUESTION_SUCCESS:
      return {
        ...state,
        sendQuestionInProgress: false,
        questions: payload.questions,
        totalQuestionsCount: payload.totalQuestionsCount,
      };
    case SEND_QUESTION_ERROR:
      return { ...state, sendQuestionInProgress: false, sendQuestionError: payload };

    case SEND_ANSWER_REQUEST:
      return { ...state, sendAnswerInProgress: true, sendAnswerError: null };
    case SEND_ANSWER_SUCCESS:
      return {
        ...state,
        sendAnswerInProgress: false,
        questions: payload.questions,
        totalQuestionsCount: payload.totalQuestionsCount,
      };
    case SEND_ANSWER_ERROR:
      return { ...state, sendAnswerInProgress: false, sendAnswerError: payload };

    case FETCH_QUESTIONS_REQUEST:
      return { ...state, fetchQuestionsInProgress: true, fetchQuestionsError: null };
    case FETCH_QUESTIONS_SUCCESS:
      return {
        ...state,
        fetchQuestionsInProgress: false,
        questions: payload.questions,
        totalQuestionsCount: payload.totalQuestionsCount,
      };
    case FETCH_QUESTIONS_ERROR:
      return { ...state, fetchQuestionsInProgress: false, fetchQuestionsError: payload };

    case FETCH_USER_SUCCESS:
      return {
        ...state,
        fetchedUser: payload,
      };
    case FETCH_USER_ERROR:
      return { ...state, fetchedUserError: payload };

    case QUERY_LISTINGS_COUNT_REQUEST:
      return { ...state, fetchListingsCountInProgress: true, fetchListingsCountError: null };
    case QUERY_LISTINGS_COUNT_SUCCESS:
      return { ...state, fetchListingsCountInProgress: false, totalListingsCount: payload };
    case QUERY_LISTINGS_COUNT_ERROR:
      return { ...state, fetchListingsCountInProgress: false, fetchListingsCountError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = (initialValues) => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = (id) => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = (e) => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = (reviews) => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = (error) => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchPostalCodeRequest = () => ({ type: FETCH_POSTAL_CODE_REQUEST });
export const fetchPostalCodeSuccess = (postalCode) => ({
  type: FETCH_POSTAL_CODE_SUCCESS,
  payload: postalCode,
});
export const fetchPostalCodeError = (error) => ({
  type: FETCH_POSTAL_CODE_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
export const fetchTimeSlotsSuccess = (timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
export const fetchTimeSlotsError = (error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: error,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = (lineItems) => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = (error) => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = (e) => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const sendProposalRequest = () => ({ type: SEND_PROPOSAL_REQUEST });
export const sendProposalSuccess = (message) => ({ type: SEND_PROPOSAL_SUCCESS, payload: message });
export const sendProposalError = (e) => ({ type: SEND_PROPOSAL_ERROR, error: true, payload: e });

export const sendQuestionRequest = () => ({ type: SEND_QUESTION_REQUEST });
export const sendQuestionSuccess = (questions, totalQuestionsCount) => ({
  type: SEND_QUESTION_SUCCESS,
  payload: { questions: questions, totalQuestionsCount: totalQuestionsCount },
});
export const sendQuestionError = (e) => ({ type: SEND_QUESTION_ERROR, error: true, payload: e });

export const sendAnswerRequest = () => ({ type: SEND_ANSWER_REQUEST });
export const sendAnswerSuccess = (questions, totalQuestionsCount) => ({
  type: SEND_ANSWER_SUCCESS,
  payload: { questions: questions, totalQuestionsCount: totalQuestionsCount },
});
export const sendAnswerError = (e) => ({ type: SEND_ANSWER_ERROR, error: true, payload: e });

export const fetchQuestionsRequest = () => ({ type: FETCH_QUESTIONS_REQUEST });
export const fetchQuestionsSuccess = (questions, totalQuestionsCount) => ({
  type: FETCH_QUESTIONS_SUCCESS,
  payload: { questions: questions, totalQuestionsCount: totalQuestionsCount },
});
export const fetchQuestionsError = (e) => ({
  type: FETCH_QUESTIONS_ERROR,
  error: true,
  payload: e,
});

const fetchUserSuccess = (response) => ({ type: FETCH_USER_SUCCESS, payload: response });
const fetchUserError = (e) => ({ type: FETCH_USER_ERROR, error: true, payload: e });

export const updateListingRequest = () => ({ type: UPDATE_LISTING_REQUEST });
export const updateListingSuccess = (payload) => ({
  type: UPDATE_LISTING_SUCCESS,
  payload: payload,
});
export const updateListingError = (e) => ({ type: UPDATE_LISTING_ERROR, error: true, payload: e });

export const queryListingsCountRequest = () => ({ type: QUERY_LISTINGS_COUNT_REQUEST });
export const queryListingsCountSuccess = (payload) => ({
  type: QUERY_LISTINGS_COUNT_SUCCESS,
  payload: payload,
});
export const queryListingsCountError = (e) => ({
  type: QUERY_LISTINGS_COUNT_ERROR,
  error: true,
  payload: e,
});
// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
    dispatch(showListingRequest(listingId));
    dispatch(fetchCurrentUser());
    const params = {
      id: listingId,
      include: ['author', 'author.profileImage', 'images', 'metadata'],
      'fields.image': [
        // Listing page
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    };

    const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

    return show
      .then((data) => {
        dispatch(addMarketplaceEntities(data));
        const { isAuthenticated } = getState().Auth;
        if (isAuthenticated) {
          dispatch(
            seen({
              listingId: data.data.data.id.uuid,
              authorId: data.data.data.relationships.author.data.id.uuid,
            })
          );
          const isLead = data?.data?.data?.attributes?.publicData?.listingCategory === 'lead';
          if (isLead) {
            dispatch(isProposalAlreadySent({ listingId: data.data.data.id.uuid }));
          }
        }
        dispatch(
          queryListingsCount({ authorId: data?.data?.data?.relationships?.author?.data?.id?.uuid })
        );
        return data;
      })
      .catch((e) => {
        dispatch(showListingError(storableError(e)));
      });
  };

export const fetchReviews = (listingId) => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then((response) => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch((e) => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

export const fetchPostalCode = (listingId) => (dispatch, getState, sdk) => {
  const uuid = { uuid: listingId };
  dispatch(fetchPostalCodeRequest());

  return getListingPostalCode(uuid)
    .then((response) => {
      const postalCode = response.data;
      dispatch(fetchPostalCodeSuccess(postalCode));
    })
    .catch((e) => {
      dispatch(fetchPostalCodeError(storableError(e)));
    });
};

const timeSlotsRequest = (params) => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then((response) => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId) => (dispatch, getState, sdk) => {
  dispatch(fetchTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const start = moment.utc().startOf('day').toDate();
  const end = moment().utc().startOf('day').add(timeSlotsRange, 'days').toDate();
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then((timeSlots) => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: moment(end).add(secondRange, 'days').toDate(),
        };

        return dispatch(timeSlotsRequest(secondParams)).then((secondBatch) => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(fetchTimeSlotsSuccess(combined));
        });
      } else {
        dispatch(fetchTimeSlotsSuccess(timeSlots));
      }
    })
    .catch((e) => {
      dispatch(fetchTimeSlotsError(storableError(e)));
    });
};

export const sendEnquiry = (listingId, message, metadata) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then((response) => {
      const transactionId = response.data.data.id;
      return updateAttachments({ transactionId, metadata })
        .then((response) => {
          // Send the message to the created transaction
          return sdk.messages.send({ transactionId, content: message }).then(() => {
            dispatch(sendEnquirySuccess());
            dispatch(fetchCurrentUserHasOrdersSuccess(true));
            return transactionId;
          });
        })
        .catch((e) => {
          dispatch(sendEnquiryError(storableError(e)));
          throw e;
        });
    })
    .catch((e) => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const fetchTransactionLineItems =
  ({ bookingData, listingId, isOwnListing }) =>
  (dispatch) => {
    dispatch(fetchLineItemsRequest());
    transactionLineItems({ bookingData, listingId, isOwnListing })
      .then((response) => {
        const lineItems = response.data;
        dispatch(fetchLineItemsSuccess(lineItems));
      })
      .catch((e) => {
        dispatch(fetchLineItemsError(storableError(e)));
        log.error(e, 'fetching-line-items-failed', {
          listingId: listingId.uuid,
          bookingData: bookingData,
        });
      });
  };

export const sendProposal = (values) => (dispatch, getState, sdk) => {
  dispatch(sendProposalRequest());

  const handleSuccess = (response) => {
    dispatch(sendProposalSuccess(response.data.message));
    return response;
  };

  const handleError = (e) => {
    dispatch(sendProposalError(e.error));
  };

  return createProposal(values).then(handleSuccess).catch(handleError);
};

export const isProposalAlreadySent = (values) => (dispatch, getState, sdk) => {
  dispatch(sendProposalRequest());

  const handleSuccess = (response) => {
    dispatch(sendProposalSuccess(response.data.message));
    return response;
  };

  const handleError = (e) => {
    dispatch(sendProposalError(e.error));
  };

  return isProposalSent(values).then(handleSuccess).catch(handleError);
};

export const sendQuestion =
  ({ listingId, userId, authorId, question, all }) =>
  (dispatch, getState, sdk) => {
    dispatch(sendQuestionRequest());

    const handleSuccess = (response) => {
      dispatch(sendQuestionSuccess(response.data.questions, response.data.total_questions_count));
      return response;
    };

    const handleError = (e) => {
      dispatch(sendQuestionError(e.error));
      throw e;
    };

    return addQuestion({ listingId, userId, authorId, question, all })
      .then(handleSuccess)
      .catch(handleError);
  };

export const sendAnswer =
  ({ id, listingId, authorId, answer, all }) =>
  (dispatch, getState, sdk) => {
    dispatch(sendAnswerRequest());

    const handleSuccess = (response) => {
      dispatch(sendAnswerSuccess(response.data.questions, response.data.total_questions_count));
      return response;
    };

    const handleError = (e) => {
      dispatch(sendAnswerError(e.error));
      throw e;
    };

    return addAnswer({ id, listingId, authorId, answer, all })
      .then(handleSuccess)
      .catch(handleError);
  };

export const fetchUser = (userId) => (dispatch, getState, sdk) => {
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then((response) => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchUserSuccess());
      return response;
    })
    .catch((e) => dispatch(fetchUserError(storableError(e))));
};

export const fetchQuestions =
  ({ listingId, all = false }) =>
  (dispatch, getState, sdk) => {
    dispatch(fetchQuestionsRequest());

    const handleSuccess = (response) => {
      dispatch(fetchQuestionsSuccess(response.data.questions, response.data.total_questions_count));
      response.data.questions.map(({ user_id }) => {
        dispatch(fetchUser(user_id));
      });
      return response;
    };

    const handleError = (e) => {
      dispatch(fetchQuestionsError(e.error));
    };

    return getQuestions({ listingId, all }).then(handleSuccess).catch(handleError);
  };

export function editTender(data) {
  return (dispatch, getState, sdk) => {
    dispatch(updateListingRequest());
    const { id } = data;
    return sdk.ownListings
      .update(data)
      .then((response) => {
        return dispatch(showListing(id));
      })
      .then((updateResponse) => {
        dispatch(updateListingSuccess(updateResponse));
        return updateResponse;
      })
      .catch((e) => {
        log.error(e, 'update-listing-failed', { listingData: data });
        return dispatch(updateListingError(storableError(e)));
      });
  };
}

export const seen =
  ({ listingId, authorId }) =>
  (dispatch, getState, sdk) => {
    const handleSuccess = (response) => {
      return response;
    };

    const handleError = (e) => {
      console.log(e);
    };

    return seenByUser({ listingId, authorId }).then(handleSuccess).catch(handleError);
  };

export const queryListingsCount = (authorId) => (dispatch, getState, sdk) => {
  dispatch(queryListingsCountRequest());
  return sdk.listings
    .query(authorId)
    .then((response) => {
      dispatch(queryListingsCountSuccess(response));
    })
    .catch((e) => {
      dispatch(queryListingsCountError(storableError(e)));
    });
};

export const loadData = (params, search) => (dispatch) => {
  const listingId = new UUID(params.id);
  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  const isOwn = ownListingVariants.includes(params.variant);
  if (isOwn) {
    return Promise.all([dispatch(showListing(listingId, true))]);
  }

  if (config.enableAvailability) {
    return Promise.all([
      dispatch(showListing(listingId)),
      dispatch(fetchTimeSlots(listingId)),
      dispatch(fetchReviews(listingId)),
    ]);
  } else {
    return Promise.all([dispatch(showListing(listingId)), dispatch(fetchReviews(listingId))]);
  }
};
