import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconProposalPartner.module.css';

const IconProposalPartner = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg version="1.1" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
      <path d="M0,0v36h36V0H0z M34,34H2V2h32V34z"/>
      <polygon points="12.9 12.6 28.3 12.6 28.3 10.6 12.9 10.6 12.9 7.7 7.7 11.6 12.9 15.4"/>
      <polygon points="23.1 28.3 28.3 24.4 23.1 20.6 23.1 23.4 7.7 23.4 7.7 25.4 23.1 25.4"/>
    </svg>
  );
};

IconProposalPartner.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconProposalPartner.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconProposalPartner;
