import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;


const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });


// ================ Action types ================ //

export const SAVE_MANUFACTURER_SETTINGS_REQUEST = 'app/ProfileBConsPage/SAVE_MANUFACTURER_SETTINGS_REQUEST';
export const SAVE_MANUFACTURER_SETTINGS_SUCCESS = 'app/ProfileBConsPage/SAVE_MANUFACTURER_SETTINGS_SUCCESS';
export const SAVE_MANUFACTURER_SETTINGS_ERROR = 'app/ProfileBConsPage/SAVE_MANUFACTURER_SETTINGS_ERROR';
export const SAVE_MANUFACTURER_SETTINGS_CLEAR = 'app/ProfileBConsPage/SAVE_MANUFACTURER_SETTINGS_CLEAR';

export const CREATE_LISTING_REQUEST = 'app/ProfileBConsPage/CREATE_LISTING_REQUEST';
export const CREATE_LISTING_SUCCESS = 'app/ProfileBConsPage/CREATE_LISTING_SUCCESS';
export const CREATE_LISTING_ERROR = 'app/ProfileBConsPage/CREATE_LISTING_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/ProfileBConsPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/ProfileBConsPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/ProfileBConsPage/UPDATE_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  saveProfileManufacturerInProgress: false,
  saveManufacturerSettingsError: null,
  profileBConsChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_MANUFACTURER_SETTINGS_REQUEST:
      return {
        ...state,
        saveProfileManufacturerInProgress: true,
        saveManufacturerSettingsError: null,
        profileBConsChanged: false,
      };
    case SAVE_MANUFACTURER_SETTINGS_SUCCESS:
      return { ...state, saveProfileManufacturerInProgress: false, profileBConsChanged: true };
    case SAVE_MANUFACTURER_SETTINGS_ERROR:
      return { ...state, saveProfileManufacturerInProgress: false, saveManufacturerSettingsError: payload };
    case SAVE_MANUFACTURER_SETTINGS_CLEAR:
      return {
        ...state,
        saveProfileManufacturerInProgress: false,
        saveManufacturerSettingsError: null,
        profileBConsChanged: false,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveManufacturerSettingsClear = () => ({ type: SAVE_MANUFACTURER_SETTINGS_CLEAR });
export const saveManufacturerSettingsRequest = () => ({ type: SAVE_MANUFACTURER_SETTINGS_REQUEST });
export const saveManufacturerSettingsSuccess = () => ({ type: SAVE_MANUFACTURER_SETTINGS_SUCCESS });
export const saveManufacturerSettingsError = error => ({
  type: SAVE_MANUFACTURER_SETTINGS_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

/**
 * Make a update request to the API and return the current user.
 */
const saveProfileManufacturerRequest = params => (dispatch, getState, sdk) => {

  const africa = params.africa;
  const northAmerica = params.northAmerica;
  const southAmerica = params.southAmerica;
  const asia = params.asia;
  const oceania = params.oceania;
  const europe = params.europe;
  const indoor = params.indoor;
  const outdoor = params.outdoor;
  const categories = params.categories;
  const support = params.support;


  const {
     manufacturerTermsAndConditions, manufacturerPrivacyPolicies, manufacturerOwnTcAndPp, manufacturerOwnTermsAndConditions, manufacturerOwnPrivacyPolicy
  } = params;

  return sdk.currentUser
    .updateProfile({
        publicData: {
          africa,
          northAmerica,
          southAmerica,
          asia,
          oceania,
          europe,
          indoor,
          outdoor,
          categories,
          support,
          deliverToCountries: [...africa, ...northAmerica, ...southAmerica, ...asia, ...oceania, ...europe],
        },
        privateData: {manufacturerTermsAndConditions, manufacturerPrivacyPolicies, manufacturerOwnTcAndPp, manufacturerOwnTermsAndConditions, manufacturerOwnPrivacyPolicy },
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }
      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveManufacturerSettingsError(storableError(e)));
      // pass the same error so that the SAVE_MANUFACTURER_SETTINGS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/** Save HourlyRate, Expert Option and Services, update the current user.
 */
 const saveManufacturerSettings = params => (dispatch, getState, sdk) => {
  return (
    dispatch(saveProfileManufacturerRequest(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveManufacturerSettingsSuccess());
      })
      // error action dispatched in requestBConsRequest
      .catch(e => null)
  );
};

export const saveProfileManufacturer = params => (dispatch, getState, sdk) => {
  dispatch(saveManufacturerSettingsRequest());
  const { africa, northAmerica, southAmerica, asia, oceania, europe, indoor, outdoor, categories, support } = params.publicData;
  const { manufacturerTermsAndConditions, manufacturerPrivacyPolicies, manufacturerOwnTcAndPp, manufacturerOwnTermsAndConditions, manufacturerOwnPrivacyPolicy } = params.privateData;

  return dispatch(saveManufacturerSettings({ africa, northAmerica, southAmerica, asia, oceania, europe, indoor, outdoor, categories, support,
    manufacturerTermsAndConditions, manufacturerPrivacyPolicies, manufacturerOwnTcAndPp, manufacturerOwnTermsAndConditions, manufacturerOwnPrivacyPolicy }));
};
