import pick from 'lodash/pick';
import config from '../../config';
import {
  initiatePrivileged,
  transitionPrivileged,
  createPaymentIntent,
  confirmPaymentIntent,
} from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_INITIATE_ORDER,
  TRANSITION_CONFIRM_LEAD_PAYMENT,
  isPrivileged,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { sendStripeCustomer } from '../../ducks/Auth.duck';
import { updateProposal } from '../../util/api';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  offerData: null,
  bookingData: null,
  bookingDates: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = (initialValues) => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = (order) => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = (e) => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = (orderId) => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = (e) => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = (transaction) => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = (e) => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = (e) => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  const transition = TRANSITION_INITIATE_ORDER;

  const bodyParams = {
    processAlias: config.leadProcessAlias,
    transition,
    params: orderParams,
  };

  const queryParams = {
    include: ['provider'],
    expand: true,
  };

  const handleSucces = (response) => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = (e) => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
    });
    throw e;
  };

  return createPaymentIntent()
    .then((response) => {
      const params = {
        ...bodyParams,
        params: {
          ...bodyParams.params,
          protectedData: {
            stripePaymentIntents: {
              default: { stripePaymentIntentClientSecret: response.data.clientSecret },
            },
            paymentIntentId: response.data.id,
          },
        },
      };
      return sdk.transactions.initiate(params, queryParams).then(handleSucces);
    })
    .catch(handleError);
};

export const confirmPayment = (orderParams) => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());
  const proposal = orderParams?.data?.proposal;
  const proposalId = proposal?.id;
  const transactionId = proposal?.transaction_id;
  const optionalParams = proposalId ? { protectedData: { proposalId } } : {};

  const bodyParams = {
    id: transactionId,
    transition: TRANSITION_CONFIRM_LEAD_PAYMENT,
    params: optionalParams,
  };

  return sdk.transactions
    .transition(bodyParams)
    .then((response) => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch((e) => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const acceptProposal = (id, transactionId) => (dispatch, getState, sdk) => {
  const handleSucces = (response) => {
    return response;
  };

  const handleError = (e) => {
    console.log(e.error);
  };

  return updateProposal({ id, transactionId, confirmPayment: true })
    .then(handleSucces)
    .catch(handleError);
};

export const sendMessage = (params) => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch((e) => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());
  const transition = TRANSITION_INITIATE_ORDER;

  const params = {
    ...orderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = {
    processAlias: config.leadProcessAlias,
    transition,
    params,
  };

  const queryParams = {
    include: ['provider'],
    expand: true,
  };

  const handleSuccess = (response) => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = (e) => {
    const { listingId } = params;
    log.error(e, 'speculate-transaction-failed', {
      listingId: listingId.uuid,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  // initiate non-privileged
  return sdk.transactions
    .initiateSpeculative(bodyParams, queryParams)
    .then(handleSuccess)
    .catch(handleError);
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then((response) => {
      console.log(response);
      dispatch(stripeCustomerSuccess());
    })
    .then(() => {
      sdk.currentUser.show({ include: ['stripeAccount'] }).then((response) => {
        const isImportedUser = response.data.data.attributes.profile.publicData.imported
          ? true
          : false;
        const currentUserhasStripeAccount =
          response.data.data.relationships.stripeAccount &&
          response.data.data.relationships.stripeAccount.data
            ? true
            : false;
        const currentUserhasStripeCustomerId = response.data.data.attributes.profile.privateData
          .stripeCustomerId
          ? true
          : false;
        if (isImportedUser && !currentUserhasStripeAccount && !currentUserhasStripeCustomerId) {
          const email = response.data.data.attributes.email;
          const firstName = response.data.data.attributes.profile.firstName;
          const lastName = response.data.data.attributes.profile.lastName;
          const name = firstName + ' ' + lastName;
          const country = response.data.data.attributes.profile.publicData.country
            ? response.data.data.attributes.profile.publicData.country
            : null;
          const countryCodeImported = response.data.data.attributes.profile.privateData.countryCode
            ? response.data.data.attributes.profile.privateData.countryCode
            : null;
          let countryCodeGenerated = null;
          if (country) {
            const countryOptions = findOptionsForSelectFilter(
              'country',
              config.profileConfig.filters
            );
            const countryFiltered = countryOptions.filter(
              (countryOption) => countryOption.key === country
            );
            countryCodeGenerated = countryFiltered[0].code;
          }
          const countryCode = countryCodeImported
            ? countryCodeImported
            : countryCodeGenerated
            ? countryCodeGenerated
            : 'EN';
          console.log('send stripe customer');
          dispatch(sendStripeCustomer({ name, email, countryCode }));
        }
      });
    })
    .catch((e) => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};
