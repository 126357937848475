import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureListing } from '../../util/data';
import { LISTING_TYPES } from '../../util/constants';
import { getEditListingPageName } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import {
  EditListingDescriptionPanel,
  EditListingCategoriesPanel,
  EditListingLocationPanel,
  EditListingLanguagePanel,
  EditListingPhotosPanel,
  EditListingTenderPanel,
  EditListingExpertsPanel,
  EditListingApplicationPanel,
  EditListingSupportCategoriesPanel,
  EditListingDetailsPanel,
  EditListingLocationsPanel,
  EditListingSupportPanel,
  EditListingLeadDescriptionPanel,
  EditListingEvoLeadPanel
} from '../../components';

import css from './EditListingWizard.module.css';

export const DESCRIPTION = 'description';
export const CATEGORIES = 'categories';
export const LOCATION = 'location';
export const LANGUAGE = 'language';
export const PHOTOS = 'photos';
export const TENDER = 'tender';
export const EXPERTS = 'experts';

// Specific tabs for listing type lead
export const APPLICATION = 'application'
export const SUPPORT_CATEGORIES = 'support-categories'
export const DETAILS = 'details'
export const LOCATIONS = 'locations'
export const SUPPORT = 'support'
export const LANGUAGES = 'languages'
export const LEAD_PHOTOS = 'lead-photos'
export const LEAD_DESCRIPTION = 'lead-description'
export const LEAD_EVO = 'lead-from-evo'

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [
  CATEGORIES,
  LOCATION,
  LANGUAGE,
  PHOTOS,
  TENDER,
  DESCRIPTION,
  EXPERTS,
  // Specific tabs for listing type lead
  LEAD_EVO,
  APPLICATION,
  SUPPORT_CATEGORIES,
  DETAILS,
  LOCATIONS,
  SUPPORT,
  LANGUAGES,
  LEAD_PHOTOS,
  LEAD_DESCRIPTION,
];

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, listingType, params, tab, marketplaceTabs, history) => {
  const routeName = getEditListingPageName(listingType);

  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString(routeName, routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString(routeName, routes, nextPathParams, {});
  history.push(to);
};

const EditListingWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    newListingPublished,
    history,
    images,
    //availability,
    listing,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onChange,
    updatedTab,
    updateInProgress,
    intl,
    handleExpertsModal,
    experts,
    expertsPagination,
    allExpertsPagination,
    listingType,
    currentUser,
  } = props;


  const { type, expertId } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const currentListing = ensureListing(listing);
  const imageIds = images => {
    return images ? images.map(img => img.imageId || img.id) : null;
  };

  const onCompleteEditListingWizardTab = (tab, updateValues) => {
    // Normalize images for API call
    const { images: updatedImages, ...otherValues } = updateValues;
    const imageProperty =
      typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
    const updateValuesWithImages = { ...otherValues, ...imageProperty };

    if (isNewListingFlow) {
      const onUpsertListingDraft = isNewURI
        ? (tab, updateValues) => onCreateListingDraft(updateValues)
        : onUpdateListing;

      const upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, id: currentListing.id };

      onUpsertListingDraft(tab, upsertValues)
        .then(r => {
          if (tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
            // Create listing flow: smooth scrolling polyfill to scroll to correct tab
            handleCreateFlowTabScrolling(false);

            // After successful saving of draft data, user should be redirected to next tab
            redirectAfterDraftUpdate(r.data.data.id.uuid, listingType, params, tab, marketplaceTabs, history);
          } else {
            handlePublishListing(currentListing.id);
          }
        })
        .catch(e => {
          // No need for extra actions
        });
    } else {
      onUpdateListing(tab, { ...updateValuesWithImages, id: currentListing.id });
    }
  };

  const panelProps = tab => {
    return {
      className: css.panel,
      listingType,
      errors,
      listing,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      // newListingPublished is flag for the last wizard tab
      ready: newListingPublished,
      expertId,
      handleExpertsModal,
      currentUser,
    };
  };

  const isDirectConnect = !isNewListingFlow || expertId || listing.attributes &&
    listing.attributes.privateData && listing.attributes.privateData.isDirectConnect;

  switch (tab) {
    case DESCRIPTION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? isDirectConnect ? 'EditListingWizard.publish' : 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <EditListingDescriptionPanel
          {...panelProps(DESCRIPTION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            const updatedValues = {
              ...values,
              publicData: {
                ...values.publicData,
                listingCategory: listingType,
              },
            };
            onCompleteEditListingWizardTab(tab, updatedValues);
          }}
          experts={experts}
          expertsPagination={expertsPagination}
          allExpertsPagination={allExpertsPagination}
        />
      );
    }
    case CATEGORIES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewCategories'
        : 'EditListingWizard.saveEditCategories';
      return (
        <EditListingCategoriesPanel
          {...panelProps(CATEGORIES)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          accordion={params.accordion}
        />
      );
    }
    case LOCATION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewLocation'
        : 'EditListingWizard.saveEditLocation';
      return (
        <EditListingLocationPanel
          {...panelProps(LOCATION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case LANGUAGE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewLanguage'
        : 'EditListingWizard.saveEditLanguage';
      return (
        <EditListingLanguagePanel
          {...panelProps(LANGUAGE)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    /*case DELIVERYDATE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDeliveryDate'
        : 'EditListingWizard.saveEditDeliveryDate';
      return (
        <EditListingDeliveryDatePanel
          {...panelProps(DELIVERYDATE)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case PRICING: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPricing'
        : 'EditListingWizard.saveEditPricing';
      return (
        <EditListingPricingPanel
          {...panelProps(PRICING)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case AVAILABILITY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewAvailability'
        : 'EditListingWizard.saveEditAvailability';
      return (
        <EditListingAvailabilityPanel
          {...panelProps(AVAILABILITY)}
          availability={availability}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }*/
    case PHOTOS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPhotos'
        : 'EditListingWizard.saveEditPhotos';

      return (
        <EditListingPhotosPanel
          {...panelProps(PHOTOS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          images={images}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          onUpdateImageOrder={onUpdateImageOrder}
        />
      );
    }
    case TENDER: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewTender'
        : 'EditListingWizard.saveEditTender';

      return (
        <EditListingTenderPanel
          {...panelProps(TENDER)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case EXPERTS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewExperts'
        : 'EditListingWizard.saveEditExperts';
      return (
        <EditListingExpertsPanel
          {...panelProps(EXPERTS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          experts={experts}
          expertsPagination={expertsPagination}
          allExpertsPagination={allExpertsPagination}
        />
      );
    }
    case APPLICATION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewApplication'
        : 'EditListingWizard.saveEditApplication';
      return (
        <EditListingApplicationPanel
          {...panelProps(APPLICATION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            const updatedValues = {
              ...values,
              publicData: {
                ...values.publicData,
                listingCategory: listingType,
              },
            };
            onCompleteEditListingWizardTab(tab, updatedValues);
          }}
        />
      );
    }
    case SUPPORT_CATEGORIES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewSupportCategories'
        : 'EditListingWizard.saveEditSupportCategories';
      return (
        <EditListingSupportCategoriesPanel
          {...panelProps(SUPPORT_CATEGORIES)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case DETAILS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDetails'
        : 'EditListingWizard.saveEditDetails';
      return (
        <EditListingDetailsPanel
          {...panelProps(DETAILS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case LOCATIONS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewLocations'
        : 'EditListingWizard.saveEditLocations';
      return (
        <EditListingLocationsPanel
          {...panelProps(LOCATIONS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case SUPPORT: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewSupport'
        : 'EditListingWizard.saveEditSupport';
      return (
        <EditListingSupportPanel
          {...panelProps(SUPPORT)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case LANGUAGES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewLanguage'
        : 'EditListingWizard.saveEditLanguage';
      return (
        <EditListingLanguagePanel
          {...panelProps(LANGUAGE)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case LEAD_PHOTOS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPhotos'
        : 'EditListingWizard.saveEditPhotos';

      return (
        <EditListingPhotosPanel
          {...panelProps(PHOTOS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          images={images}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          onUpdateImageOrder={onUpdateImageOrder}
        />
      );
    }
    case LEAD_DESCRIPTION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.publish'
        : 'EditListingWizard.saveEditLeadDescription';
      return (
        <EditListingLeadDescriptionPanel
          {...panelProps(LEAD_DESCRIPTION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case LEAD_EVO: {
      const submitButtonTranslationKey = 'EditListingWizard.saveNewCategories';
      //console.log('Lead Evo Tab');
      return (
        <EditListingEvoLeadPanel
          {...panelProps(LEAD_EVO)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    default:
      return null;
  }
};

EditListingWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
};

const { array, bool, func, object, oneOf, shape, string } = PropTypes;

EditListingWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,
  availability: object.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,

  intl: intlShape.isRequired,
  listingType: oneOf([LISTING_TYPES.JOB, LISTING_TYPES.LEAD]).isRequired,
};

export default EditListingWizardTab;
