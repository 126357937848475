import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useSelector } from 'react-redux';
import { FieldCheckboxGroup } from '../../components';
import config from '../../config';
import { selectCurrentUserPublicData } from '../../ducks/user.duck';
import { useTranslation } from '../../hooks';
import { findOptionsForSelectFilter } from '../../util/search';
import css from './ProfileSkillsForm.module.css';

export const SectionMyServices = () => {
  const publicData = useSelector(selectCurrentUserPublicData);

  const setTranslation = useTranslation('ProfileSkillsForm');

  const {
    dialux, lightingDesign, documentation, photoWork, others,
  } = publicData;

  const filterConfig = config.profileConfig.filters;

  const selectServiceOptions = findOptionsForSelectFilter('setService', filterConfig);

  const countOrEmpty = arr => arr?.length > 0 ? arr.length : ' ';
  const getCssClass = arr => arr?.length > 0 ? css.countVisible : css.countUnVisible;

  const skills = [
    {
      key: 'dialux',
      count: countOrEmpty(dialux),
      cssClass: getCssClass(dialux),
      headline: setTranslation('dialuxLabel'),
      options: findOptionsForSelectFilter('dialux', filterConfig),
    }, {
      key: 'lightingDesign',
      count: countOrEmpty(lightingDesign),
      cssClass: getCssClass(lightingDesign),
      headline: setTranslation('lightingDesignLabel'),
      options: findOptionsForSelectFilter('lightingDesign', filterConfig),
    }, {
      key: 'documentation',
      count: countOrEmpty(documentation),
      cssClass: getCssClass(documentation),
      headline: setTranslation('documentationLabel'),
      options: findOptionsForSelectFilter('documentation', filterConfig),
    }, {
      key: 'photoWork',
      count: countOrEmpty(photoWork),
      cssClass: getCssClass(photoWork),
      headline: setTranslation('picturesLabel'),
      options: findOptionsForSelectFilter('photoWork', filterConfig),
    }, {
      key: 'others',
      count: countOrEmpty(others),
      cssClass: getCssClass(others),
      headline: setTranslation('otherLabel'),
      options: findOptionsForSelectFilter('others', filterConfig),
    }];

  return (
    <>
      <div className={css.headline}>
        {setTranslation('skillsHeadline')}
      </div>

      <div className={css.serviceField}>
        <FieldCheckboxGroup
          setFocus={true} id='setService' name='setService' options={selectServiceOptions}
        />
      </div>

      <div className={css.tip}>
        {setTranslation('skillsInformationText')}
      </div>

      <Accordion className={css.accordion} allowZeroExpanded={true}>
        {skills.map((item, idx) =>
          <AccordionItem key={idx} className={css.accordion__item}>
            <AccordionItemHeading>
              <AccordionItemButton className={css.accordion__button}>
                <div className={item.cssClass}>
                  <span>{item.count}</span>
                </div>
                <div className={css.headlineAccordion}>
                  {item.headline}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={css.accordion__panel}>
              <FieldCheckboxGroup
                setFocus={true}
                setHighlight={true}
                setAccordion={true}
                sortByChecked={false}
                id={item.key}
                name={item.key}
                options={item.options}
                label=''
              />
            </AccordionItemPanel>
          </AccordionItem>,
        )}
      </Accordion>
    </>
  );
};
