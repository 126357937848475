import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconQuestionmark.module.css';

const IconQuestionmark = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="45" height="45" viewBox="-1 -1 48 48" xmlns="http://www.w3.org/2000/svg">
      <g
        className={css.marketplaceColorStroke}
        fillRule="evenodd"
        stroke="none"
      >
        <path d="M22.5 2C33.8 2 43 11.2 43 22.5C43 33.8 33.8 43 22.5 43C11.2 43 2 33.8 2 22.5C2 11.2 11.2 2 22.5 2M22.5 0C10.1 0 0 10.1 0 22.5C0 34.9 10.1 45 22.5 45C34.9 45 45 34.9 45 22.5C45 10.1 34.9 0 22.5 0L22.5 0L22.5 0ZM20.4 27.9C20.4 26.6 20.6 25.5 20.9 24.7C21.2 23.9 21.8 23.1 22.6 22.1L24.8 19.8C25.7 18.7 26.2 17.6 26.2 16.4C26.2 15.2 25.9 14.3 25.3 13.6C24.7 12.9 23.8 12.6 22.6 12.6C21.5 12.6 20.5 12.9 19.8 13.5C19.1 14.1 18.8 14.9 18.8 15.9L15.7 15.9C15.7 14.1 16.4 12.6 17.6 11.5C18.9 10.4 20.5 9.8 22.6 9.8C24.7 9.8 26.4 10.4 27.6 11.5C28.8 12.6 29.4 14.2 29.4 16.2C29.4 18.2 28.5 20.1 26.7 22L24.8 24C24 24.9 23.6 26.2 23.6 27.9L20.4 27.9L20.4 27.9ZM20.3 33.2C20.3 32.7 20.5 32.3 20.8 31.9C21.1 31.6 21.6 31.4 22.2 31.4C22.8 31.4 23.3 31.6 23.6 31.9C23.9 32.2 24.1 32.7 24.1 33.2C24.1 33.7 23.9 34.1 23.6 34.5C23.3 34.8 22.8 35 22.2 35C21.6 35 21.1 34.8 20.8 34.5C20.4 34.2 20.3 33.7 20.3 33.2L20.3 33.2Z"
        />
      </g>
    </svg>
  );
};

IconQuestionmark.defaultProps = {
  rootClassName: null,
  className: null,
};

IconQuestionmark.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconQuestionmark;
