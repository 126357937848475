import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import { propTypes } from '../../util/types';
import { Form, PrimaryButton, FieldTextInput, FieldSelect, FieldCheckboxBool} from '../../components';

import css from './SignupForm.module.css';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
        onOpenDataSecurityPolicy,
        filterConfig,
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // job title
      const jobLabel = intl.formatMessage({
        id: 'SignupForm.jobLabel',
      });
      const jobPlaceholder = intl.formatMessage({
        id: 'SignupForm.jobPlaceholder',
      });
      const jobRequiredMessage = intl.formatMessage({
        id: 'SignupForm.jobRequired',
      });
      const jobRequired = validators.required(jobRequiredMessage);

      // company
      const companyLabel = intl.formatMessage({
        id: 'SignupForm.companyLabel',
      });
      const companyPlaceholder = intl.formatMessage({
        id: 'SignupForm.companyPlaceholder',
      });
      const companyRequiredMessage = intl.formatMessage({
        id: 'SignupForm.companyRequired',
      });
      const companyRequired = validators.required(companyRequiredMessage);

      /* language
      const languageLabel = intl.formatMessage({
        id: 'SignupForm.languageLabel',
      });
      const languagePlaceholder = intl.formatMessage({
      id: 'SignupForm.languagePlaceholder',
      });
      const languageRequiredMessage = intl.formatMessage({
        id: 'SignupForm.languageRequired',
      });
      const languageRequired = validators.required(languageRequiredMessage);
      */

      // country
      const countryLabel = intl.formatMessage({
        id: 'SignupForm.countryLabel',
      });
      const countryPlaceholder = intl.formatMessage({
      id: 'SignupForm.countryPlaceholder',
      });
      const countryRequiredMessage = intl.formatMessage({
        id: 'SignupForm.countryRequired',
      });
      const countryRequired = validators.required(countryRequiredMessage);


      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      //agb
      const agbCheckedRequired = value => (value ? false : true)

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const countryOptions = findOptionsForSelectFilter('country', config.profileConfig.filters);

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };

      const handleDseKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenDataSecurityPolicy();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );
      const dseLink = (
        <span
          className={css.dseLink}
          onClick={onOpenDataSecurityPolicy}
          role="button"
          tabIndex="0"
          onKeyUp={handleDseKeyUp}
        >
          <FormattedMessage id="SignupForm.dataSecurityLinkText" />
        </span>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              className={css.email}
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <FieldTextInput
            className={css.job}
            type="textarea"
            id={formId ? `${formId}.job` : 'job'}
            name="jobTitle"
            autoComplete="job"
            label={jobLabel}
            placeholder={jobPlaceholder}
            validate={jobRequired}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="fname"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="lname"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
            </div>
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
            />
            <div>
            <FieldTextInput
              className={css.job}
              type="company"
              id={formId ? `${formId}.company` : 'company'}
              name="company"
              autoComplete="company-name"
              label={companyLabel}
              placeholder={companyPlaceholder}
              validate={companyRequired}
            />
            <FieldSelect
              className={css.job}
              id={formId ? `${formId}.country` : 'country'}
              validate={countryRequired}
              name="country"
              options={countryOptions}
              label={countryLabel}
            >
              <option value="">{countryPlaceholder}</option>
                {countryOptions.map(o => (
                <FormattedMessage key={o.key} id={o.translationID}>
                  {txt => <option value={o.value}>{txt}</option>}
                </FormattedMessage>
                ))}
            </FieldSelect>

            </div>
          </div>

          <div className={css.bottomWrapper}>

              <div>
                <FieldCheckboxBool
                  className={css.agb}
                  id={formId ? `${formId}.agbCheckbox` : 'agbCheckbox'}
                  name="agbCheckbox"
                  label={<FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink, dseLink }}/>}
                  validate = {agbCheckedRequired}
                />
              </div>
          </div>
          <div>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);


SignupFormComponent.defaultProps = {
  inProgress: false,
  filterConfig: config.custom.filters,
};

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,
  onOpenDataSecurityPolicy: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  filterConfig: propTypes.filterConfig,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
