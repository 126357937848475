import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCircleFive.module.css';

const IconCircleFive = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.5 0C7.8 0 0 7.8 0 17.5S7.8 35 17.5 35 35 27.2 35 17.5 27.2 0 17.5 0zm0 32C9.5 32 3 25.5 3 17.5S9.5 3 17.5 3 32 9.5 32 17.5 25.5 32 17.5 32zm.4-16.8c-.9 0-1.7.2-2.4.6l.4-3.7h6.2V9.9h-8.4l-.8 7.9 2.1.5c.4-.4.7-.6 1.1-.7a3.73 3.73 0 0 1 1.2-.2c.9 0 1.5.3 2 .8.5.6.7 1.3.7 2.3s-.2 1.8-.7 2.3c-.4.6-1.1.9-1.9.9-.7 0-1.3-.2-1.7-.6s-.7-1-.8-1.7h-2.5c.1 1.3.6 2.4 1.5 3.2s2.1 1.2 3.6 1.2c1.6 0 2.9-.5 3.8-1.4s1.4-2.2 1.4-3.8-.4-2.9-1.2-3.8c-.9-1.1-2.1-1.6-3.6-1.6z"/>
    </svg>
  );
};

IconCircleFive.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCircleFive.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCircleFive;
