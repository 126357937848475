import React from 'react';
import { bool, func, object, string, oneOfType } from 'prop-types';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink, ListingLinkUnnamed } from '../../components';
import { EditListingDetailsForm } from '../../forms';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
import moment from 'moment';
import css from './EditListingDetailsPanel.module.css';
import { getDateOrDefault } from '../../util/getDateOrDefault';

const EditListingDetailsPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    expertId,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const measureType = publicData?.measureType || 'space';
  const length = publicData?.length;
  const width = publicData?.width;
  const floorSpace = publicData?.floorSpace;
  const dimensionsUnit = publicData?.dimensionsUnit || (measureType === 'dimensions' ? 'meter' : 'meterSquare');
  const height = publicData?.height;
  const heightUnit = publicData?.heightUnit || 'meter';
  const floors = publicData?.floors;
  const luminaires = publicData?.luminaires;
  const budget = publicData?.budget ? new Money(publicData.budget, config.currency) : null

  const realisationStartDate = getDateOrDefault(publicData?.realisationStartDate, d => d.add(60, 'd'));
  const realisationEndDate = getDateOrDefault(publicData?.realisationEndDate, d => d.add(60 + 1, 'd'));
  const applicationDeadline = getDateOrDefault(publicData?.applicationDeadline, d => d.add(30, 'd'));

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDetailsPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) :
  (
    <FormattedMessage id="EditListingDetailsPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDetailsForm
        className={css.form}
        initialValues={{ measureType, length, width, floorSpace, dimensionsUnit, height, heightUnit, floors, luminaires, budget, realisationDate: {startDate: realisationStartDate, endDate: realisationEndDate}, applicationDeadline: {date: applicationDeadline} }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {realisationDate, ...restValues} = values;
          const realisationStartDateUNIX = moment(realisationDate.startDate).endOf('day').utc().unix();
          const realisationStartDate = moment(realisationDate.startDate).endOf('day').utc().format();
          const realisationEndDateUNIX = realisationDate.endDate ? moment(realisationDate.endDate).endOf('day').utc().unix() : realisationStartDateUNIX;
          const realisationEndDate = realisationDate.endDate ? moment(realisationDate.endDate).endOf('day').utc().format() : realisationStartDate;
          const applicationDeadlineUNIX = moment(values.applicationDeadline.date).endOf('day').utc().unix();
          const applicationDeadline = moment(values.applicationDeadline.date).endOf('day').utc().format();

          const updateValues = {
            publicData: { ...restValues,
              budget: values?.budget?.amount,
              realisationStartDateUNIX,
              realisationStartDate,
              realisationEndDateUNIX,
              realisationEndDate,
              applicationDeadlineUNIX,
              applicationDeadline,
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingDetailsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDetailsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: oneOfType([propTypes.listing, propTypes.ownListing, propTypes.draftListing]),

  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDetailsPanel;
