import { useState } from 'react';
import { Field } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  FieldCheckboxBool,
  IconClose,
  IconSpinner,
} from '../../components';
import { selectCurrentUserSubscription } from '../../ducks/user.duck';
import { useTranslation } from '../../hooks';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ProfileSkillsForm.module.css';

export const SectionLegalDocuments = ({ values,
  form,
  onFileUploadHandler,
  onFileRemove,
  uploadFileInProgress,
  privacyPolicy,
  termsAndConditions
}) => {
  const subscription = useSelector(selectCurrentUserSubscription);
  const setTranslation = useTranslation('ProfileSkillsForm');
  const intl = useIntl();
  const privateData = useSelector(
    rx => rx.user?.currentUser?.attributes?.profile?.privateData ?? {});

  const isGoldSubscription = subscription === 'gold';
  const isSilverSubscription = subscription === 'silver';
  const isPayedSubscription = isGoldSubscription || isSilverSubscription

  if (!isPayedSubscription) {
    return null;
  }

  const ownTcAndPpLabel = setTranslation('ownTcAndPpLabel');

  const ownTermsAndConditionsLabel = intl.formatMessage({
    id: 'ProfileSkillsForm.ownTermsAndConditionsLabel',
  });
  const ownPrivacyPolicyLabel = intl.formatMessage({
    id: 'ProfileSkillsForm.ownPrivacyPolicyLabel',
  });

  const chooseFile = intl.formatMessage({
    id: 'ProfileSkillsForm.chooseFile',
  });

  const dateFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  const {
    privacyPolicies: userPrivacyPolicies,
    termsAndConditions: userTermsAndConditions,
  } = privateData;

  return (
    <>
      <div className={css.sectionContainer}>
        <FieldCheckboxBool name="ownTcAndPp" id={'ownTcAndPp'} label={ownTcAndPpLabel} />
      </div>

      {values.ownTcAndPp && (
        <div className={css.tcAndPpWrapper}>
          <div className={css.ownTermsAndConditionsWrapper}>
            <FieldCheckboxBool
              className={css.ownTermsAndConditions}
              name="ownTermsAndConditions"
              id={'ownTermsAndConditions'}
              label={ownTermsAndConditionsLabel}
            />
            <Field
              id="ownTermsAndConditionsFile"
              name="ownTermsAndConditionsFile"
              label={chooseFile}
              type="file"
            >
              {(fieldprops) => {
                const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                const { name, type } = input;
                const onChange = (e) => {
                  const file = e.target.files[0];
                  onFileUploadHandler(e, form, file, 'termsAndConditions');
                };
                const inputProps = { accept, id: name, name, onChange, type };
                return (
                  <div className={css.uploadFieldWrapper}>
                    <div className={css.aspectRatioWrapper}>
                      {fieldDisabled ? null : <input {...inputProps} className={css.uploadInput} />}
                      <label htmlFor={name} className={css.addFile}>
                        {uploadFileInProgress === 'termsAndConditions' ? <IconSpinner /> : label}
                      </label>
                    </div>
                  </div>
                );
              }}
            </Field>
          </div>
          <div className={css.ownPrivacyPolicyWrapper}>
            <FieldCheckboxBool
              className={css.ownPrivacyPolicy}
              name="ownPrivacyPolicy"
              id={'ownPrivacyPolicy'}
              label={ownPrivacyPolicyLabel}
            />
            <Field
              id="ownPrivacyPolicyFile"
              name="ownPrivacyPolicyFile"
              label={chooseFile}
              type="file"
              form={null}
            >
              {(fieldprops) => {
                const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                const { name, type } = input;
                const onChange = (e) => {
                  const file = e.target.files[0];
                  onFileUploadHandler(e, form, file, 'privacyPolicy');
                };
                const inputProps = { accept, id: name, name, onChange, type };
                return (
                  <div className={css.uploadFieldWrapper}>
                    {fieldDisabled ? null : <input {...inputProps} className={css.uploadInput} />}
                    <label htmlFor={name} className={css.addFile}>
                      {uploadFileInProgress === 'privacyPolicy' ? <IconSpinner /> : label}
                    </label>
                  </div>
                );
              }}
            </Field>
          </div>
        </div>
      )}

      {values.ownTcAndPp && (userTermsAndConditions?.length > 0 || termsAndConditions) && (
        <div className={css.userTermsAndConditionsContainer}>
          <div className={css.headline}>
            <FormattedMessage id="ProfileSkillsForm.userTermsAndConditions" />
          </div>
          {termsAndConditions && (
            <div className={css.newItem}>
              <div>
                <a
                  key={termsAndConditions.public_id}
                  target="_blank"
                  href={termsAndConditions?.secure_url}
                >
                  {termsAndConditions.original_filename}
                </a>
                <div className={css.date}>
                  {intl.formatDate(termsAndConditions.created_at, dateFormatOptions)}
                </div>
                <div className={css.removeItem} onClick={() => onFileRemove('termsAndConditions')}>
                  <IconClose size="normal" className={css.removeIcon} />
                </div>
              </div>
            </div>
          )}
          {userTermsAndConditions?.length > 0 && (
            <div className={css.userTcPp}>
              {userTermsAndConditions.map((t, index) => (
                <div key={index}>
                  <a key={t.public_id} target="_blank" href={t?.secure_url}>
                    {t.original_filename}
                  </a>
                  <div className={css.date}>{intl.formatDate(t.created_at, dateFormatOptions)}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {values.ownTcAndPp && (userPrivacyPolicies?.length > 0 || privacyPolicy) && (
        <div className={css.userPrivacyPoliciesContainer}>
          <div className={css.headline}>
            <FormattedMessage id="ProfileSkillsForm.privacyPolicies" />
          </div>
          {privacyPolicy && (
            <div className={css.newItem}>
              <div>
                <a key={privacyPolicy.public_id} target="_blank" href={privacyPolicy?.secure_url}>
                  {privacyPolicy.original_filename}
                </a>
                <div className={css.date}>
                  {intl.formatDate(privacyPolicy.created_at, dateFormatOptions)}
                </div>
                <div className={css.removeItem} onClick={() => onFileRemove('privacyPolicy')}>
                  <IconClose size="normal" className={css.removeIcon} />
                </div>
              </div>
            </div>
          )}
          {userPrivacyPolicies?.length > 0 && (
            <div className={css.userTcPp}>
              {userPrivacyPolicies.map((pp, index) => (
                <div key={index}>
                  <a key={pp.public_id} target="_blank" href={pp?.secure_url}>
                    {pp.original_filename}
                  </a>
                  <div className={css.date}>
                    {intl.formatDate(pp.created_at, dateFormatOptions)}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};
