import moment from 'moment-timezone';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureTransaction, ensureUser } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  TRANSITION_ACCEPT_PROPOSAL,
  TRANSITION_ACCEPT_PROPOSAL_PENDING_REPLY,
  TRANSITION_VIEW_ACCEPTED_PROPOSAL,
} from '../../util/transaction';

const { Money } = sdkTypes;
const LIGHTSHIFT_FEE = 5900;

export class TransactionDataReader {
  #transaction;
  #listing;
  #provider;
  #customer;
  #intl;
  #transactionRole;
  #totalListingsCount;
  #providerOfficeDetails;
  #customerOfficeDetails;
  #postalCode;

  constructor(transaction, intl) {
    this.#transaction = ensureTransaction(transaction);
    this.#listing = ensureListing(this.#transaction.listing);
    this.#provider = ensureUser(this.#transaction.provider);
    this.#customer = ensureUser(this.#transaction.customer);

    this.#intl = intl;
  }

  setTransactionRole(role) {
    this.#transactionRole = role;
  }

  setTotalListingCount(amount) {
    this.#totalListingsCount = amount;
  }

  setOfficeDetails(provider, customer, postalCode = '') {
    this.#providerOfficeDetails = provider;
    this.#customerOfficeDetails = customer;
    this.#postalCode = postalCode;
  }

  isLeadListing() {
    return this.#listing?.attributes?.publicData?.listingCategory === 'lead';
  }

  isAccepted = () =>
    this.#transaction?.attributes?.transitions?.some((tx) =>
      [
        TRANSITION_VIEW_ACCEPTED_PROPOSAL,
        TRANSITION_ACCEPT_PROPOSAL,
        TRANSITION_ACCEPT_PROPOSAL_PENDING_REPLY,
      ].includes(tx?.transition)
    );

  getListingTitle() {
    if (this.#listing.attributes.deleted) {
      return this.#intl.formatMessage({
        id: 'TransactionPanel.deletedListingTitle',
      });
    } else {
      return this.#listing.attributes.title;
    }
  }

  #isGermanCustomer() {
    return this.#customer?.attributes?.profile?.publicData?.country === 'Germany';
  }

  getRealisationDate() {
    return [
      this.#listing.attributes?.publicData?.realisationStartDate,
      this.#listing.attributes?.publicData?.realisationEndDate,
    ];
  }

  getLeadTotalPrice() {
    let label = '';

    if (this.#isGermanCustomer()) {
      label = this.#intl.formatMessage({ id: 'LeadCheckoutPageBookingBreakdown.totalDE' });
    } else {
      label = this.#intl.formatMessage({ id: 'BookingBreakdown.total' });
    }

    const fee = this.#isGermanCustomer() ? LIGHTSHIFT_FEE * 1.19 : LIGHTSHIFT_FEE;
    const price = formatMoney(this.#intl, new Money(fee, config.currency));

    return [label, price];
  }

  isCustomer() {
    return this.#transactionRole === 'customer';
  }

  isExpert() {
    return this.#listing?.attributes?.publicData?.listingCategory === 'expert';
  }

  getOtherUser() {
    if (this.isCustomer()) {
      return this.#provider;
    } else {
      return this.#customer;
    }
  }

  getOthersProfileData() {
    const txProtectedData = this.#transaction?.attributes?.protectedData;

    const attr = this.isCustomer() ? this.#provider?.attributes : this.#customer?.attributes;

    const addr = this.isCustomer()
      ? txProtectedData?.providerAddress
      : txProtectedData?.customerAddress;

    const email = this.isCustomer()
      ? this.#providerOfficeDetails?.email || txProtectedData?.providerEmail || ''
      : txProtectedData?.customerEmail || '';

    const phone = this.isCustomer()
      ? this.#providerOfficeDetails?.phoneNumber || txProtectedData?.providerPhoneNumber || ''
      : txProtectedData?.customerPhoneNumber || '';

    const company = this.#providerOfficeDetails?.company || '';

    const postalCode = addr?.postalCode || this.#postalCode || '';

    return {
      name: attr?.profile?.displayName,
      jobTitle: attr?.profile?.publicData?.jobTitle,
      joined: this.#formatDateMonthAndYear(attr?.createdAt),

      company: company,
      street: addr?.street || '',
      houseNumber: addr?.houseNumber || '',
      postalCode: postalCode,
      postalCodeAnon: this.#getAnonymizedPostalCode(postalCode),
      city: addr?.city || '',
      country: attr?.profile?.publicData?.country,
      tenderCount: this.#totalListingsCount?.data?.meta?.totalItems ?? 0,

      email,
      phone,

      //attr,
      txProtectedData,
      office: this.#providerOfficeDetails,
    };
  }

  #formatDateMonthAndYear(jointed) {
    if (!jointed) {
      return '';
    }

    const LTZ = moment.tz.guess();

    const clientJoinedAtLTZ = moment.tz(jointed, LTZ).format();
    const format = {
      month: 'short',
      year: 'numeric',
    };

    return this.#intl.formatDate(clientJoinedAtLTZ, format);
  }

  #getAnonymizedPostalCode(postalCode) {
    return postalCode?.substring(2, -1)?.padEnd(postalCode.length, 'X') ?? '';
  }
}
