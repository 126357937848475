import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import isEqual from 'lodash/isEqual';
import { bool, string } from 'prop-types';
import React, { Component } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import {
  Button,
  Form,
  NamedRedirect,
} from '../../components';
import config from '../../config';
import {
  selectCurrentUserAddress,
  selectCurrentUserSubscription,
} from '../../ducks/user.duck';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { WorkingExperienceModal } from '../WorkingExperienceForm/WorkingExperienceModal';
import { BronzeSubscriptionDetails } from './BronzeSubscriptionDetails';
import { BronzeSubscriptionInformation } from './BronzeSubscriptionInformation';
import { GoldSubscriptionDetails } from './GoldSubscriptionDetails';
import css from './ProfileSkillsForm.module.css';
import { SectionBio } from './SectionBio';
import { SectionDocumentUpload } from './SectionDocumentUpload';
import { SectionEmailNotification } from './SectionEmailNotification';
import { SectionFreelancerSelection } from './SectionFreelancerSelection';
import { SectionHourlyRate } from './SectionHourlyRate';
import { SectionIncognitoMode } from './SectionIncognitoMode';
import { SectionLegalDocuments } from './SectionLegalDocuments';
import { SectionMyServices } from './SectionMyServices';
import { SectionPaymentMethods } from './SectionPaymentMethods';
import { SectionSocialLinks } from './SectionSocialLinks';
import { SectionStripeConnectHint } from './SectionStripeConnectHint';
import { SectionWorkingExperience } from './SectionWorkingExperience';
import { SilverSubscriptionDetails } from './SilverSubscriptionDetails';
import { SilverSubscriptionInformation } from './SilverSubscriptionInformation';

class ProfileSkillsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsAndConditions: null,
      privacyPolicy: null,
      isUploading: null,
    };
    this.onFileUploadHandler = this.onFileUploadHandler.bind(this);
    this.onFileRemove = this.onFileRemove.bind(this);
    this.handleLink = this.handleLink.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);

    this.loadWorkingExperienceForCurrentUser(props);

    this.submittedValues = {};
  }

  loadWorkingExperienceForCurrentUser(props) {
    const wxp = props.user?.attributes?.profile?.publicData?.workingExperience ?? [];
    props.onInitWorkingExperience(wxp);
  }

  onFileUploadHandler(e, form, file, field, onSuccess) {
    this.setState({
      isUploading: field,
    });

    const url = 'https://api.cloudinary.com/v1_1/lightshift/upload';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'vbh822gi');

    fetch(url, { method: 'POST', body: formData })
      .then((response) => response.text())
      .then((data) => {
        const res = JSON.parse(data);
        if (res && res.secure_url) {
          this.setState({
            [field]: res,
          });
          onSuccess?.(res);

          this.setState({
            isUploading: null,
          });

          form?.change(field, res);
          e.target.value = null;
        }
      });
  }

  onFileRemove = (id) => {
    this.setState({
      [id]: null,
    });

    return (e) => e.stopPropagation();
  };

  handleLink = (link) => {
    return window.open(link);
  };

  handleDeleteFile = (publicID, userId) => {
    return this.props.onDeleteFileHandler(publicID, userId);
  };

  render = () => (
    <>
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={(fieldRenderProps) => {
          const {
            form,
            className,
            handleSubmit,
            invalid,
            pristine,
            rootClassName,
            updateInProgress: submitInProgress,
            updateProfileError,
            values,
            ready,
            onUploadHandler,
            uploadFileInProgress,
            uploadFileError,
            documents,
            isSubscribed,
          } = fieldRenderProps;
          const { termsAndConditions, privacyPolicy } = this.state;

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSkillsForm.updateProfileFailed" />
            </div>
          ) : null;

          const isWorkingExperiencePristine = useSelector((x) => x.WorkingExperience.isPristine);
          const subscription = useSelector(selectCurrentUserSubscription);

          if (!subscription) {
            return <NamedRedirect name={"SubscriptionPage"}/>
          }

          const addressMissing = !useSelector(selectCurrentUserAddress);
          const classes = classNames(rootClassName || css.root, className);
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit =
            submittedOnce && isEqual(values, this.submittedValues) && ready;
          let submitDisabled =
            (invalid ||
              (pristine && isWorkingExperiencePristine) ||
              pristineSinceLastSubmit ||
              submitInProgress) &&
            !(privacyPolicy || termsAndConditions) &&
            !isSubscribed;

          if (addressMissing) {
            submitDisabled = true;
          }

          return (
            <Form
              className={classes}
              onSubmit={(e) => {
                this.submittedValues = values;
                this.setState({
                  privacyPolicy: null,
                  termsAndConditions: null,
                });
                handleSubmit(e);
              }}
            >
              <SectionFreelancerSelection values={values} />

              <GoldSubscriptionDetails />
              <SilverSubscriptionDetails />
              <BronzeSubscriptionDetails />
              <BronzeSubscriptionInformation />
              <SilverSubscriptionInformation />

              <SectionBio />
              <SectionWorkingExperience />
              <SectionSocialLinks />
              <SectionPaymentMethods />

              <SectionLegalDocuments
                form={form}
                values={values}
                onFileUploadHandler={this.onFileUploadHandler}
                onFileRemove={this.onFileRemove}
                privacyPolicy={this.state.privacyPolicy}
                termsAndConditions={this.state.termsAndConditions}
                uploadFileInProgress={this.state.isUploading}
              />

              <SectionHourlyRate />

              <SectionDocumentUpload
                documents={documents}
                documentType={'qualifications'}
                uploadButtonId={'ownQualificationFile'}
                uploadFileInProgress={uploadFileInProgress}
                onUploadHandler={onUploadHandler}
                uploadFileError={uploadFileError}
                handleLink={this.handleLink}
                handleDeleteFile={this.handleDeleteFile}
                wrongFileTypeErrorMessage={'wrongFileTypeQualificationsMessage'}
              />
              <SectionDocumentUpload
                documents={documents}
                documentType={'references'}
                uploadButtonId={'ownReferenceFile'}
                uploadFileInProgress={uploadFileInProgress}
                onUploadHandler={onUploadHandler}
                uploadFileError={uploadFileError}
                handleLink={this.handleLink}
                handleDeleteFile={this.handleDeleteFile}
                wrongFileTypeErrorMessage={'wrongFileTypeReferencesMessage'}
              />

              <SectionMyServices />

              <SectionIncognitoMode />

              <SectionStripeConnectHint />

              <SectionEmailNotification values={values} />

              <div className={css.stickyButton}>
                {submitError}

                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={pristineSinceLastSubmit}
                >
                  <FormattedMessage id="ProfileSkillsForm.saveChanges" />
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <WorkingExperienceModal />
    </>
  );
}

ProfileSkillsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  updateProfileError: null,
  updateProfileReady: false,
  jobTitle: null,
  filterConfig: config.profileConfig.filters,
};

ProfileSkillsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,
  intl: intlShape.isRequired,
  filterConfig: propTypes.filterConfig,
};

const ProfileSkillsForm = compose(injectIntl)(ProfileSkillsFormComponent);

ProfileSkillsForm.displayName = 'ProfileSkillsForm';

export default ProfileSkillsForm;
