import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form } from '../../components';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import css from './EditListingApplicationForm.module.css';

const required = value => {
  // Intl Message checkbox required Message
  const errors = {}
  if (value.indoor.length===0
    && value.outdoor.length===0)
    {
      errors.emptyForm = 'EditListingApplicationForm.checkboxRequired'
    }
  return errors
}

const EditListingApplicationFormComponent = props => (

  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    validate={required}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        intl,
        touched,
        values,
        accordionID,
      } = formRenderProps;

      const isAnyTouched = Object.values(touched).includes(true);
      const messageEmptyForm = (formRenderProps.errors.emptyForm && isAnyTouched) ? intl.formatMessage({id: formRenderProps.errors.emptyForm}): <span>&nbsp;</span>;


      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress || formRenderProps.invalid;
      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};

      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingApplicationForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingApplicationForm.showListingFailed" />
        </p>
      ) : null;

      const indoorOptions = findOptionsForSelectFilter('indoor', filterConfig);
      const outdoorOptions = findOptionsForSelectFilter('outdoor', filterConfig);

      const countIndoor = values?.indoor?.length || null;
      const countClassIndoor = countIndoor ? css.countVisible : css.countUnVisible;

      const countOutdoor = values?.outdoor?.length || null;
      const countClassOutdoor = countOutdoor ? css.countVisible : css.countUnVisible;

      //Accordion Headlines
      const headlineIndoor = intl.formatMessage({
        id: 'EditListingApplicationForm.indoor',
      });
      const headlineOutdoor = intl.formatMessage({
        id: 'EditListingApplicationForm.outdoor',
      });

      const tip = intl.formatMessage({
        id: 'EditListingApplicationForm.tip',
      });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessage}
          {errorMessageShowListing}
          <p className={css.tooltip}>{tip}</p>
          <Accordion
          className = {css.accordion}
          allowZeroExpanded= {false}
          preExpanded= {accordionID}
          >
            <AccordionItem
              className = {css.accordion__item}
              uuid='1'
            >
              <AccordionItemHeading>
                <AccordionItemButton
                  className={css.accordion__button}
                >
                  <div className={countClassIndoor}>
                  <span>{countIndoor}</span>
                  </div>
                  <div className={css.headline}>
                  {headlineIndoor}
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel
                className={css.accordion__panel}
              >
                <FieldCheckboxGroup
                  setFocus={true}
                  setHighlight={true}
                  setAccordion={true}
                  sortByChecked={false}
                  className={css.checkbox}
                  id="indoor"
                  name='indoor'
                  options={indoorOptions}
                  label=''
                />
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem
              className = {css.accordion__item}
              uuid='2'
            >
              <AccordionItemHeading>
                <AccordionItemButton
                  className={css.accordion__button}
                >
                <div className={countClassOutdoor}>
                  <span>{countOutdoor}</span>
                </div>
                <div className={css.headline}>
                {headlineOutdoor}
                </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel
                className={css.accordion__panel}
              >
              <FieldCheckboxGroup
                  setFocus={false}
                  setHighlight={true}
                  setAccordion={true}
                  sortByChecked={false}
                  className={css.checkbox}
                  id="outdoor"
                  name='outdoor'
                  options={outdoorOptions}
                  label=''
                />
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <div className={css.error}>
          {messageEmptyForm}
          </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingApplicationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.manufacturerConfig.productPortfolio,
};

EditListingApplicationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,

  // from injectIntl
  intl: intlShape.isRequired,
};


const EditListingApplicationForm = compose(injectIntl)(EditListingApplicationFormComponent);


export default EditListingApplicationForm;
