import dialux1 from '../assets/stockImages/dialux/1.jpg';
import dialux2 from '../assets/stockImages/dialux/2.jpg';
import dialux3 from '../assets/stockImages/dialux/3.jpg';
import dialux4 from '../assets/stockImages/dialux/4.jpg';
import dialux5 from '../assets/stockImages/dialux/5.jpg';
import lightingDesign1 from '../assets/stockImages/lightingDesign/1.jpg';
import lightingDesign2 from '../assets/stockImages/lightingDesign/2.jpg';
import lightingDesign3 from '../assets/stockImages/lightingDesign/3.jpg';
import lightingDesign4 from '../assets/stockImages/lightingDesign/4.jpg';
import lightingDesign5 from '../assets/stockImages/lightingDesign/5.jpg';
import lightingDesign6 from '../assets/stockImages/lightingDesign/6.jpg';
import lightingDesign7 from '../assets/stockImages/lightingDesign/7.jpg';
import lightingDesign8 from '../assets/stockImages/lightingDesign/8.jpg';
import lightingDesign9 from '../assets/stockImages/lightingDesign/9.jpg';
import lightingDesign10 from '../assets/stockImages/lightingDesign/10.jpg';
import photoWork1 from '../assets/stockImages/photoWork/1.jpg';
import photoWork2 from '../assets/stockImages/photoWork/2.jpg';
import photoWork3 from '../assets/stockImages/photoWork/3.jpg';
import photoWork4 from '../assets/stockImages/photoWork/4.jpg';
import photoWork5 from '../assets/stockImages/photoWork/5.jpg';
import documentation1 from '../assets/stockImages/documentation/1.jpg';
import documentation2 from '../assets/stockImages/documentation/2.jpg';
import documentation3 from '../assets/stockImages/documentation/3.jpg';
import documentation4 from '../assets/stockImages/documentation/4.jpg';
import documentation5 from '../assets/stockImages/documentation/5.jpg';
import others1 from '../assets/stockImages/others/1.jpg';
import others2 from '../assets/stockImages/others/2.jpg';
import others3 from '../assets/stockImages/others/3.jpg';
import others4 from '../assets/stockImages/others/4.jpg';
import others5 from '../assets/stockImages/others/5.jpg';

export const LISTING_TYPES = {
  JOB: 'job',
  EXPERT: 'expert',
  LEAD: 'lead',
};

export const STOCK_IMAGES = {
  dialux: [
    dialux1,
    dialux2,
    dialux3,
    dialux4,
    dialux5,
  ],
  lightingDesign: [
    lightingDesign1,
    lightingDesign2,
    lightingDesign3,
    lightingDesign4,
    lightingDesign5,
    lightingDesign6,
    lightingDesign7,
    lightingDesign8,
    lightingDesign9,
    lightingDesign10,
  ],
  photoWork: [
    photoWork1,
    photoWork2,
    photoWork3,
    photoWork4,
    photoWork5,
  ],
  documentation: [
    documentation1,
    documentation2,
    documentation3,
    documentation4,
    documentation5,
  ],
  others: [
    others1,
    others2,
    others3,
    others4,
    others5,
  ],
}

export const VALID_STATES_TO_CLOSE_LISTING = ['published'];
