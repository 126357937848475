import React from 'react';
import { bool, node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.module.css';

const IconCheckbox = props => {
  const { className, checkedClassName, setAccordion, large} = props;

  let cssBox = css.box
  if(setAccordion){
    cssBox = css.boxAccordion
  }

  let iconCheckbox;

  if(large){
    iconCheckbox=
    <svg className={className} width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(2 2)">
          <path
            className={checkedClassName || css.checked}
            d="M20.007 0.00161535L19.9514 20L0.000201635 20L0.000201635 1.6266e-13L20.007 0.00161535Z"
            />
          <path
            className={cssBox}
            strokeWidth="2"
            d="M20.007 0.00161535L19.9514 20L0.000201635 20L0.000201635 1.12947e-13L20.007 0.00161535Z"
            />
        </g>
        <g fill="none" stroke="#000000" strokeWidth="4" transform="translate(2 2)">
          <path
          d="M1.03126 0L0 6L13.4687 5.93876"
          transform="matrix(0.5591929 -0.82903755 0.82903755 0.5591929 3.4938965 12.644531)"
          id="New-shape"
          fill="none"
          fillRule="evenodd"
          stroke="#FFFFFF"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  }
  else {
    iconCheckbox=
    <svg className={className} width="14" height="14" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(2 2)">
          <path
            className={checkedClassName || css.checked}
            d="M10.0035 0.000807677L9.97569 10L0.000100817 10L0.000100817 8.48951e-14L10.0035 0.000807677Z"
            />
          <path
            className={cssBox}
            strokeWidth="2"
            d="M10.0035 0.000807677L9.97569 10L0.000100817 10L0.000100817 7.77897e-14L10.0035 0.000807677Z"
            />
        </g>
        <path
          d="M5.636621 10.7824771L3.3573694 8.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254782 2.8256927-4.5462307c.3934117-.5431636 1.1545778-.6695372 1.7055985-.278265.5473554.3912721.6731983 1.150729.2797866 1.6951077l-3.6650524 5.709111c-.2199195.306213-.5803433.5067097-.9920816.5067097-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
          fill="#FFF"
        />
      </g>
    </svg>
  }

  return (
    iconCheckbox
  );
};

IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null, large: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string, large: bool };

const FieldCheckboxComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    id,
    label,
    useSuccessColor,
    setFocus,
    setHighlight,
    setAccordion,
    large,
    icon,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);
  };


  const successColorVariantMaybe = useSuccessColor
    ? {
        checkedClassName: css.checkedSuccess,
        boxClassName: css.boxSuccess,
      }
    : {};

   let cssClassName = css.input;
    if(setHighlight){
     cssClassName = css.inputHighlight;
   }

   let cssCheckboxWrapper = css.checkboxWrapper;
   let cssLabel = css.label;
    if(setAccordion){
      cssCheckboxWrapper = css.checkboxWrapperAccordion;
      cssLabel = css.labelAccordion
  }

  const iconImage = <span className={css.labelAndImage}><span>{label}</span><img src={icon} className={css.paymentIcon} alt={label}/></span>;
  const labelCheckbox = icon ? iconImage : label;

  return (
    <span className={classes}>
      <Field type="checkbox" {...rest}>
        {props => {
          const input = props.input;
          if(setFocus){
            return (
              <input
                autoFocus
                id={id}
                className={cssClassName}
                {...input}
                onChange={event => handleOnChange(input, event)}
              />
            );
          }
          return (
              <input
                id={id}
                className={cssClassName}
                {...input}
                onChange={event => handleOnChange(input, event)}
              />
          );
        }}
      </Field>
      <label htmlFor={id} className={cssLabel}>
        <span className={cssCheckboxWrapper}>
          <IconCheckbox className={svgClassName} setAccordion={setAccordion} {...successColorVariantMaybe} large={large}/>
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>
          {labelCheckbox}
        </span>
      </label>
    </span>
  );
};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
  setFocus: false,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,
  setFocus: bool,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxComponent;
