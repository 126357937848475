import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSpecialSoftware.module.css';

const IconSpecialSoftware = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg enableBackground="new 0 0 36 36" version="1.1" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
      <path d="m34 2v32h-32v-32h32m2-2h-36v36h36v-36zm-28.5 11.4h3.2c4.7 0 6.6 2.7 6.6 6.6 0 4.8-3.2 6.6-7.5 6.6h-2.3v-13.2zm1.3 12h1.1c3.8 0 6.1-1.5 6.1-5.6s-2.4-5.3-5.3-5.3h-1.9v10.9zm12.9-3.9-3.3 5.1h2.7l2.2-3.6 2.2 3.6h3l-3.2-5.1 2.9-4.6h-2.7l-1.9 3.2-1.9-3.2h-3l3 4.6z"/>
    </svg>
  );
};

IconSpecialSoftware.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSpecialSoftware.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSpecialSoftware;
