import React, { Component } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';
import config from '../../config';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { required, bookingDateRequired, composeValidators, maxLength } from '../../util/validators';

import * as validators from '../../util/validators';
import { DELIVERY_DATE } from '../../util/dates';
import { propTypes } from '../../util/types';
import { Form, IconSpinner, PrimaryButton, FieldDateInput, FieldCurrencyInput, FieldTextInput, FieldRadioButton, GenericMessage, FieldCheckboxBool } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import { types as sdkTypes } from '../../util/sdkLoader';
import Breakdown from './Breakdown';

import css from './BookingDatesForm.module.css';

const identity = v => v;
const { Money } = sdkTypes;
const SERVICE_MAX_LENGTH = 500;

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      step: 1 };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);

  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    const { date } = e.bookingDates || {};
    if (!date) {
      e.preventDefault();
      this.setState({ focusedInput: DELIVERY_DATE });
    } else {
      this.props.onSubmit(e);
    }
  }


  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);
/*
    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }
*/
    return (
      <FinalForm
        {...rest}
        //unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            submitButtonWrapperClassName,
            unitType,
            values,
            timeSlots,
            fetchTimeSlotsError,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            title,
            pristine,
            sendProposalInProgress,
            sendProposalMessage,
            sendProposalError,
            currentUser,
            onOpenTermsOfService,
            onOpenDataSecurityPolicy,
            hasValidationErrors,
            isOwnListing,
          } = fieldRenderProps;

          const { date } = values && values.bookingDates ? values.bookingDates : {};

          const informationText = (<FormattedMessage
            id="BookingDatesForm.informationText"/>
          );

          const serviceLabel = intl.formatMessage({
            id: 'BookingDatesForm.serviceLabel',
          });
          const servicePlaceholder = intl.formatMessage({
            id: 'BookingDatesForm.servicePlaceholder',
          });
          const serviceRequiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredServicePlaceholder',
          });
          const maxLengthMessage = intl.formatMessage({
            id: 'BookingDatesForm.maxLengthService',
          });
          const maxLength500Message = maxLength(maxLengthMessage, SERVICE_MAX_LENGTH);

          const deliveryDateLabel = intl.formatMessage({
            id: 'BookingDatesForm.deliveryDateLabel',
          });
          const requiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredDate',
          });
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidStartDate',
          });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          const bookingData =
            date
              ? {
                  unitType,
                  date,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;


          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const now = moment();
          const today = now.startOf('day').toDate();
          const tomorrow = now
            .startOf('day')
            .add(1, 'days')
            .toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(today, dateFormatOptions);

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const isGermanUser = currentUser && currentUser.attributes && currentUser.attributes.profile.privateData.countryCode === "DE" ? true : false;
          const offerAmount = values.offerPrice ? values.offerPrice.amount: {};
          const offerCurrency = values.offerPrice ? values.offerPrice.currency: {};
          const feeAmount = offerAmount && isGermanUser ? (offerAmount * config.commission) + ((offerAmount * config.commission) * config.germanVAT) :
            offerAmount ? (offerAmount * config.commission) : 0;
          const vat = feeAmount ? ((offerAmount * config.commission) * config.germanVAT) : 0;
          const netAmount =  vat ? (offerAmount * config.commission) : feeAmount;
          const payoutAmount = offerAmount - feeAmount;
          const vatAsMoney = new Money(vat, offerCurrency);
          const netAmountAsMoney = new Money(netAmount, offerCurrency);
          const feeAsMoney =new Money(feeAmount, offerCurrency);
          const payoutAsMoney =new Money(payoutAmount, offerCurrency);
          const offerAmountAsMoney = new Money(offerAmount, offerCurrency);
          const vatMoney = values.offerPrice ? formatMoney(intl, vatAsMoney) : '-';
          const netAmountMoney = values.offerPrice ? formatMoney(intl, netAmountAsMoney) : '-';
          const offerAmountMoney = values.offerPrice ? formatMoney(intl, offerAmountAsMoney) : '-';
          const feeAmountMoney = values.offerPrice ? formatMoney(intl, feeAsMoney) : '-';
          const payoutAmountMoney = values.offerPrice ? formatMoney(intl, payoutAsMoney) : '-';
          const offerSubmitted = sendProposalMessage ? (sendProposalMessage === "offer_submitted") : false;
          const alreadyApplied = sendProposalMessage ? (sendProposalMessage === "already_offered") : false;
          const isDeadlinePassed = sendProposalMessage ? (sendProposalMessage === "deadline_passed") : false;
          const submitDisabled = (hasValidationErrors && sendProposalInProgress) || offerSubmitted || alreadyApplied;
          const sendProposalRequestMessage = sendProposalError ?
          intl.formatMessage({
            id: 'BookingDatesForm.sendProposalErrorMessage',
          }) :
          alreadyApplied ? intl.formatMessage({
            id: 'BookingDatesForm.alreadyAppliedMessage',
          }) :
          offerSubmitted ? intl.formatMessage({
            id: 'BookingDatesForm.offerSubmittedMessage',
          }) :
          isDeadlinePassed ? intl.formatMessage({
            id: 'BookingDatesForm.deadlinePassedMessage',
          }) : null;

          const offerPriceMessage = intl.formatMessage({
            id: 'BookingDatesForm.offerPriceMessage',
            });

          const offerPricePlaceholderMessage =intl.formatMessage({
            id: 'BookingDatesForm.offerPricePlaceholder',
          });

          const offerPriceRequiredMessage =intl.formatMessage({
            id: 'BookingDatesForm.offerPriceError',
          });

          const offerPriceMinMessage =intl.formatMessage({
            id: 'BookingDatesForm.offerPriceMinMessage',
          });

          const offerPriceRequired = validators.required(offerPriceRequiredMessage);

          const offerPriceMin = validators.minOfferPrice(offerPriceMinMessage);

          const showAsRequired = pristine

          const agbCheckedRequired = value => (value ? false : true)
          const dseCheckedRequired = value => (value ? false : true)

          const handleTermsKeyUp = e => {
            // Allow click action with keyboard like with normal links
            if (e.keyCode === KEY_CODE_ENTER) {
              onOpenTermsOfService();
            }
          };

          const handleDseKeyUp = e => {
            // Allow click action with keyboard like with normal links
            if (e.keyCode === KEY_CODE_ENTER) {
              onOpenDataSecurityPolicy();
            }
          };
          const termsLink = (
            <span
              className={css.link}
              onClick={onOpenTermsOfService}
              role="button"
              tabIndex="0"
              onKeyUp={handleTermsKeyUp}
            >
              <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
            </span>
          );
          const dseLink = (
            <span
              className={css.link}
              onClick={onOpenDataSecurityPolicy}
              role="button"
              tabIndex="0"
              onKeyUp={handleDseKeyUp}
            >
              <FormattedMessage id="SignupForm.dataSecurityLinkText" />
            </span>
          );

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              {timeSlotsError}
                <FieldDateInput
                  className={css.bookingDates}
                  name="bookingDates"
                  id={`${formId}.bookingStartDate`}
                  label={deliveryDateLabel}
                  placeholderText={startDatePlaceholderText}
                  format={identity}
                  timeSlots={timeSlots}
                  useMobileMargins={false}
                  validate={composeValidators(required(requiredMessage), bookingDateRequired(startDateErrorMessage))}
                  disabled={fetchLineItemsInProgress}
                />
                <FieldTextInput
                className={css.fieldCurrencyInput}
                name="service"
                id={`${formId}.service`}
                label={serviceLabel}
                placeholder={servicePlaceholder}
                type="textarea"
                maxLength={SERVICE_MAX_LENGTH}
                validate={maxLength500Message}
              />
              <FieldCurrencyInput
              id="offerPrice"
              name="offerPrice"
              className={css.fieldCurrencyInput}
              label={offerPriceMessage}
              placeholder={offerPricePlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={composeValidators(offerPriceMin, offerPriceRequired)}
              />
                <Breakdown
                  title= {title}
                  currentUser={currentUser}
                  offerAmountMoney={offerAmountMoney}
                  feeAmountMoney={feeAmountMoney}
                  payoutAmountMoney={payoutAmountMoney}
                  informationText={informationText}
                  netAmount={netAmountMoney}
                  vat={vatMoney}
                />
              <div className={css.agb}>
                <FieldCheckboxBool
                  className={css.agb}
                  id={formId ? `${formId}.agbCheckbox` : 'agbCheckbox'}
                  name="agbCheckbox"
                  label={<FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink, dseLink }}/>}
                  validate = {agbCheckedRequired}
                />
              </div>
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit" disabled={submitDisabled} inProgress={sendProposalInProgress}>
                  <FormattedMessage id="BookingDatesForm.requestToBook" />
                </PrimaryButton>
              </div>

              <GenericMessage show={sendProposalMessage} message={sendProposalRequestMessage} />
              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {/*bookingInfoErrorMaybe*/}
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
