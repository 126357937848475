import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import {NamedLink, ExternalLink} from '../../components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { manageDisplayConsent } from '../../ducks/UI.duck';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicyComponent = props => {
  const { rootClassName, className, onManageDisplayConsent } = props;
  const classes = classNames(rootClassName || css.root, className);

  const lightshiftLink = (
    <ExternalLink href="https://dialux.services/" className={css.termsLink}>
      <FormattedMessage id=  "PrivacyPolicy.lightshiftLink"
      />
    </ExternalLink>
  );

  const progressorgLink = (
    <ExternalLink href="https://progressorg.de/" className={css.termsLink}>
      <FormattedMessage id=  "PrivacyPolicy.progressorgLink"
      />
    </ExternalLink>
  );

  const addressDial = (
    <div className={css.address}>
      DIAL GmbH <br/>
      Bahnhofsallee 18 <br/>
      58507 Lüdenscheid <br/>
      <FormattedMessage id="PrivacyPolicy.countryGermany"></FormattedMessage> <br/>
      Tel. +49 (0) 2351 5674 0 <br/>
      Fax +49 (0) 2351 5674 410 <br/>
      <FormattedMessage id="PrivacyPolicy.email"></FormattedMessage>: dialog@dial.de <br/>
      <FormattedMessage id="PrivacyPolicy.website"></FormattedMessage>: {lightshiftLink} <br/>
    </div>
  );

  const addressDataPrivacy = (
    <div className={css.address}>
      progressorg GmbH <br/>
      Höveler Weg 2 <br/>
      D-58553 Halver <br/>
      Tel: + 49 (0) 2353 9096 31 <br/>
      Fax: + 49 (0) 2353 9096 49 <br/>
      <FormattedMessage id="PrivacyPolicy.email"></FormattedMessage>: datenschutz@progressorg.de <br/>
      <FormattedMessage id="PrivacyPolicy.website"></FormattedMessage>: {progressorgLink} <br/>
    </div>
  );

  const underlinedWord1 = (
    <span className={css.underlined}>
      <FormattedMessage id="PrivacyPolicy.underlinedWord1"></FormattedMessage>
    </span>
  )

  const ldiNRWLink = (
    <ExternalLink href="https://www.ldi.nrw.de/" className={css.termsLink}>
      <FormattedMessage id=  "PrivacyPolicy.ldiNRWLink"
      />
    </ExternalLink>
  );

  const googleLink = (
    <ExternalLink href="https://www.google.de/intl/de/policies/privacy/" className={css.termsLink}>
      <FormattedMessage id=  "PrivacyPolicy.googleLink"
      />
    </ExternalLink>
  );

  const stripeLink = (
    <ExternalLink href="https://stripe.com/de/guides/general-data-protection-regulation#stripe-und-die-dsgvo" className={css.termsLink}>
      <FormattedMessage id=  "PrivacyPolicy.stripeLink"
      />
    </ExternalLink>
  );

  const googleTagManagerLink_1 = (
    <ExternalLink href="https://www.google.com/analytics/terms/tag-manager/" className={css.termsLink}>
      <FormattedMessage id=  "PrivacyPolicy.googleTagManagerLink_1"
      />
    </ExternalLink>
  );

  const googleTagManagerLink_2 = (
    <ExternalLink href="https://www.google.com/intl/de/policies/privacy/index.html" className={css.termsLink}>
      <FormattedMessage id=  "PrivacyPolicy.googleTagManagerLink_2"
      />
    </ExternalLink>
  );

  const matomoLink = (
    <ExternalLink href="https://matomo.org/privacy/" className={css.termsLink}>
      <FormattedMessage id=  "PrivacyPolicy.matomoLink"
      />
    </ExternalLink>
  );

  const googleAnalyticsLink_1 = (
    <ExternalLink href="https://marketingplatform.google.com/about/analytics/terms/de/" className={css.termsLink}>
      <FormattedMessage id=  "PrivacyPolicy.googleAnalyticsLink_1"
      />
    </ExternalLink>
  );

  const googleAnalyticsLink_2 = (
    <ExternalLink href="https://support.google.com/analytics/answer/6004245?hl=de/" className={css.termsLink}>
      <FormattedMessage id=  "PrivacyPolicy.googleAnalyticsLink_2"
      />
    </ExternalLink>
  );

  const googleAnalyticsLink_3 = (
    <ExternalLink href="https://policies.google.com/privacy?hl=de&gl=de" className={css.termsLink}>
      <FormattedMessage id=  "PrivacyPolicy.googleAnalyticsLink_3"
      />
    </ExternalLink>
  );

  const googleAnalyticsLink_4 = (
    <ExternalLink href="https://tools.google.com/dlpage/gaoptout?hl=de/" className={css.termsLink}>
      <FormattedMessage id=  "PrivacyPolicy.googleAnalyticsLink_4"
      />
    </ExternalLink>
  );

  const googleAnalyticsLink_5 = (
    <ExternalLink href="https://business.safety.google/adssubprocessors/" className={css.termsLink}>
      <FormattedMessage id=  "PrivacyPolicy.googleAnalyticsLink_5"
      />
    </ExternalLink>
  );

  const googleAdSenseLink_1 = (
    <ExternalLink href="https://support.google.com/ads/answer/7395996" className={css.termsLink}>
      <FormattedMessage id=  "PrivacyPolicy.googleAdSenseLink_1"/>
    </ExternalLink>
  );

  const googleAdSenseLink_2 = (
    <ExternalLink href="https://policies.google.com/technologies/ads?hl=de&gl=de" className={css.termsLink}>
      <FormattedMessage id=  "PrivacyPolicy.googleAdSenseLink_2"/>
    </ExternalLink>
  );

  const consentManagerLink = <a className={css.termsLink} onClick={() => onManageDisplayConsent(true)}><FormattedMessage id="PrivacyPolicyconsentManagerLink" /></a>;


  // prettier-ignore
  return (
    <div className={classes}>
    <p className={css.lastUpdated}><FormattedMessage id="PrivacyPolicy.lastUpdated" /></p>
    <p> <span className={css.information}><FormattedMessage id="PrivacyPolicy.information" /></span></p>

    <h2><FormattedMessage id="PrivacyPolicy.headline1" /></h2>
    <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.CollectionOfPersonalData_1" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.CollectionOfPersonalData_2" />
      </li>
      {addressDial}
      <FormattedMessage id="PrivacyPolicy.CollectionOfPersonalData_2_1" />
      {addressDataPrivacy}
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.CollectionOfPersonalData_3" />
      </li>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headline2" /></h2>
    <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.General_1" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.General_2" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.General_3" />
      </li>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headline3" /></h2>
    <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.DataCollection_1" />
      </li>
      <ol type="a" className={css.list}>
        <li className={css.listElement}>
          <FormattedMessage id="PrivacyPolicy.DataCollection_1_1" />
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="PrivacyPolicy.DataCollection_1_2" />
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="PrivacyPolicy.DataCollection_1_3" />
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="PrivacyPolicy.DataCollection_1_4" />
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="PrivacyPolicy.DataCollection_1_5" />
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="PrivacyPolicy.DataCollection_1_6" />
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="PrivacyPolicy.DataCollection_1_7" />
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="PrivacyPolicy.DataCollection_1_8" />
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="PrivacyPolicy.DataCollection_1_9" />
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="PrivacyPolicy.DataCollection_1_10" />
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="PrivacyPolicy.DataCollection_1_11" />
        </li>
      </ol>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.DataCollection_2" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.DataCollection_3" />
      </li>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headline4" /></h2>
    <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.FurtherFunctions_1" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.FurtherFunctions_2" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.FurtherFunctions_3" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.FurtherFunctions_4" />
      </li>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headline5" /></h2>
    <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Opposition_1" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Opposition_2" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Opposition_3"
        values={{underlinedWord1}} />
      </li>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headline6" /></h2>
    <ol start="1" className={css.list}>
      <div className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Deletion_1" />
      </div>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headline7" /></h2>
    <ol start="1" className={css.list}>
      <div className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.ThirdPartyCountries_1" />
      </div>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headline8" /></h2>
    <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.YourRights_1" />
        <ol type="a" className={css.list}>
          <li className={css.listElementUnderline}>
            <FormattedMessage id="PrivacyPolicy.YourRights_1_1_Headline" />
          </li>
          <FormattedMessage id="PrivacyPolicy.YourRights_1_1" />
          <li className={css.listElementUnderline}>
            <FormattedMessage id="PrivacyPolicy.YourRights_1_2_Headline" />
          </li>
          <FormattedMessage id="PrivacyPolicy.YourRights_1_2" />
          <li className={css.listElementUnderline}>
            <FormattedMessage id="PrivacyPolicy.YourRights_1_3_Headline" />
          </li>
          <FormattedMessage id="PrivacyPolicy.YourRights_1_3" />
          <li className={css.listElementUnderline}>
            <FormattedMessage id="PrivacyPolicy.YourRights_1_4_Headline" />
          </li>
          <FormattedMessage id="PrivacyPolicy.YourRights_1_4" />
        </ol>
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.YourRights_2"
        values={{ldiNRWLink}} />
      </li>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headline9" /></h2>
    <ol start="1" className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.SSL_1" />
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headline10" /></h2>
    <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Cookies_1" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Cookies_2" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Cookies_3" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Cookies_4" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Cookies_5" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Cookies_6" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Cookies_7"
        values = {{consentManagerLink}}/>
      </li>
    </ol>


    <h2><FormattedMessage id="PrivacyPolicy.headlineGoogleTagManager" /></h2>
    <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.GoogleTagManager_1"
        values={{googleTagManagerLink_1, googleTagManagerLink_2}} />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.GoogleTagManager_2" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.GoogleTagManager_3" />
      </li>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headline11" /></h2>
    <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Signup_1" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Signup_2" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Signup_3" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Signup_4" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Signup_5" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Signup_6" />
      </li>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headlineUseOfContactForm" /></h2>
    <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.UseOfContactForm_1" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.UseOfContactForm_2" />
      </li>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headline12" /></h2>
    <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Newsletter_1" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Newsletter_2" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Newsletter_3" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Newsletter_4" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Newsletter_5" />
        <ul className={css.list2}>
          <li type='disc' className={css.listElement2}>
            <FormattedMessage id="PrivacyPolicy.Newsletter_5_1" />
          </li>
          <li type='disc' className={css.listElement2}>
            <FormattedMessage id="PrivacyPolicy.Newsletter_5_2" />
          </li>
          <li type='disc' className={css.listElement2}>
            <FormattedMessage id="PrivacyPolicy.Newsletter_5_3" />
          </li>
          <li type='disc' className={css.listElement2}>
            <FormattedMessage id="PrivacyPolicy.Newsletter_5_4" />
          </li>
          <li type='disc' className={css.listElement2}>
            <FormattedMessage id="PrivacyPolicy.Newsletter_5_5" />
          </li>
          <li type='disc' className={css.listElement2}>
            <FormattedMessage id="PrivacyPolicy.Newsletter_5_6" />
          </li>
        </ul>
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Newsletter_6" />
      </li>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headline13" /></h2>
    <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Youtube_1" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Youtube_2" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Youtube_3" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Youtube_4"
        values={{googleLink}} />
      </li>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headline14" /></h2>
    <ol start="1" className={css.list}>
        <div className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Payment_1" />
        </div>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headlineMatomo" /></h2>
    <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Matomo_1" />
        <ul className={css.list2}>
          <li type='disc' className={css.listElement2}>
            <FormattedMessage id="PrivacyPolicy.Matomo_1_1" />
          </li>
          <li type='disc' className={css.listElement2}>
            <FormattedMessage id="PrivacyPolicy.Matomo_1_2" />
          </li>
          <li type='disc' className={css.listElement2}>
            <FormattedMessage id="PrivacyPolicy.Matomo_1_3" />
          </li>
          <li type='disc' className={css.listElement2}>
            <FormattedMessage id="PrivacyPolicy.Matomo_1_4" />
          </li>
          <li type='disc' className={css.listElement2}>
            <FormattedMessage id="PrivacyPolicy.Matomo_1_5" />
          </li>
        </ul>
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Matomo_2" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Matomo_3" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Matomo_4" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Matomo_5"
        values={{matomoLink}} />
      </li>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headlineGoogleAnalytics" /></h2>
    <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.GoogleAnalytics_1" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.GoogleAnalytics_2" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.GoogleAnalytics_3" />
        <ul className={css.list2}>
          <li className={css.listElement}>
            <FormattedMessage id="PrivacyPolicy.GoogleAnalytics_3_1" />
          </li>
          <li className={css.listElement}>
            <FormattedMessage id="PrivacyPolicy.GoogleAnalytics_3_2" />
          </li>
          <li className={css.listElement}>
            <FormattedMessage id="PrivacyPolicy.GoogleAnalytics_3_3" />
          </li>
          <li className={css.listElement}>
            <FormattedMessage id="PrivacyPolicy.GoogleAnalytics_3_4" />
          </li>
        </ul>
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.GoogleAnalytics_4" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.GoogleAnalytics_5"
        values = {{googleAnalyticsLink_1, googleAnalyticsLink_2, googleAnalyticsLink_3}} />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.GoogleAnalytics_6"
        values = {{googleAnalyticsLink_4}} />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.GoogleAnalytics_7"
        values = {{googleAnalyticsLink_5}} />
      </li>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headlineGoogleAdSense" /></h2>
    <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.GoogleAdSense_1" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.GoogleAdSense_2" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.GoogleAdSense_3"
        values = {{googleAdSenseLink_1}} />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.GoogleAdSense_4"
        values = {{googleAdSenseLink_2}} />
      </li>
    </ol>

    <h2><FormattedMessage id="PrivacyPolicy.headline15" /></h2>
    <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Stripe_1" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Stripe_2" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="PrivacyPolicy.Stripe_3"
        values={{stripeLink}} />
      </li>
    </ol>
    </div>
  );
};

PrivacyPolicyComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicyComponent.propTypes = {
  rootClassName: string,
  className: string,
};


const mapDispatchToProps = dispatch => ({
  onManageDisplayConsent: (show) => dispatch(manageDisplayConsent(show)),
});

const PrivacyPolicy = compose(
  connect(
    null,
    mapDispatchToProps,
  )
)(PrivacyPolicyComponent);

export default PrivacyPolicy;
