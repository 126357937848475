import React from 'react';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import config from '../../config';

import css from './BookingDatesForm.module.css';

const { Money } = sdkTypes;

const Breakdown = props => {

  if(config.isFreeLeadPeriod) {
    return null;
  }

  const { fee, currentUser, intl} = props;
  const isGermanUser = currentUser ? currentUser.attributes.profile.privateData.countryCode === "DE" : false;

  const totalAmount = isGermanUser ? fee * 1.19 : fee;
  const taxes = isGermanUser ? fee * 0.19 : 0;
  const lightshiftFeeFormatted = formatMoney(intl, new Money(fee, config.currency))
  const taxesFormatted = formatMoney(intl, new Money(taxes, config.currency))
  const totalAmountFormatted = formatMoney(intl, new Money(totalAmount, config.currency))

  const offerBreakdown = (
    <div className={css.breakdown}>
      <table className={css.table}>
        <tbody>
          <tr className={css.tr}>
            <td className={css.td13}><FormattedMessage id="LeadBookingDatesForm.leadTotalPriceText" /></td>
            <td className={css.td23}>{totalAmountFormatted}</td>
          </tr>
        </tbody>
      </table>
    </div>);

    const offerBreakdownGermanUser = (
      <div className={css.breakdown}>
        <table className={css.tableWithoutBorder}>
          <tbody>
            <tr className={css.trWithoutBorder}>
              <td className={css.td1WithoutBorder}><FormattedMessage id="LeadBookingDatesForm.leadPriceText" /></td>
              <td className={css.td2WithoutBorder}><span className={css.deliveryDate}>{lightshiftFeeFormatted}</span></td>
            </tr>
            <tr className={css.trWithoutBorder}>
              <td className={css.td1}><FormattedMessage id="LeadBookingDatesForm.taxesText" /></td>
              <td className={css.td2}><span className={css.fee}>{taxesFormatted}</span></td>
            </tr>
            <tr className={css.trWithoutBorder}>
              <td className={css.td13WithoutBorder}><FormattedMessage id="LeadBookingDatesForm.leadTotalPriceText" /></td>
              <td className={css.td23WithoutBorder}>{totalAmountFormatted}</td>
            </tr>
          </tbody>
        </table>
      </div>);



  return (
    isGermanUser ? offerBreakdownGermanUser : offerBreakdown
  );

};

export default Breakdown;
