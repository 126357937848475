import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconInfo.module.css';
const SIZE_SMALL = 'small';

const IconInfo = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);

    return (
      <svg className={classes} width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
        <path
        d="M12.5 0C5.6 0 0 5.6 0 12.5C0 19.4 5.6 25 12.5 25C19.4 25 25 19.4 25 12.5C25 5.6 19.4 0 12.5 0L12.5 0ZM12.5 23C6.7 23 2 18.3 2 12.5C2 6.7 6.7 2 12.5 2C18.3 2 23 6.7 23 12.5C23 18.3 18.3 23 12.5 23L12.5 23ZM11.5 5.8L13.4 5.8L13.4 7.7L11.5 7.7L11.5 5.8ZM11.5 9.6L13.4 9.6L13.4 19.2L11.5 19.2L11.5 9.6Z"
        fillRule="evenodd"
        stroke="none"
        />
      </svg>
    );
}

const { string } = PropTypes;

IconInfo.defaultProps = {
  className: null,
  rootClassName: null,
};

IconInfo.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconInfo;
