import { useSelector } from 'react-redux';
import { selectCurrentUserSubscription } from '../../ducks/user.duck';
import { useTranslation } from '../../hooks';
import css from './ProfileSkillsForm.module.css';

export const SectionStripeConnectHint = () => {
  const setTranslation = useTranslation('ProfileSkillsForm');
  const subscription = useSelector(selectCurrentUserSubscription);

  if (subscription !== 'bronze') {
    return null;
  }

  return (
    <div className={css.connectAccountContainer}>{setTranslation('connectAccountInfoText')}</div>
  );
};
