import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCircleEight.module.css';

const IconCircleEight = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.5 0C7.8 0 0 7.8 0 17.5S7.8 35 17.5 35 35 27.2 35 17.5 27.2 0 17.5 0zm0 32C9.5 32 3 25.5 3 17.5S9.5 3 17.5 3 32 9.5 32 17.5 25.5 32 17.5 32zm2.6-14.7c.7-.3 1.2-.8 1.6-1.4s.6-1.3.6-2c0-1.3-.4-2.4-1.3-3.2s-2-1.2-3.5-1.2-2.6.4-3.5 1.2-1.3 1.8-1.3 3.2c0 .8.2 1.4.6 2s.9 1.1 1.6 1.4c-.8.4-1.4.9-1.9 1.5-.4.7-.7 1.4-.7 2.3 0 1.4.5 2.5 1.4 3.3s2.2 1.2 3.7 1.2 2.8-.4 3.7-1.2 1.4-1.9 1.4-3.3c0-.8-.2-1.6-.7-2.2-.3-.7-.9-1.3-1.7-1.6zm-4.2-5.1c.4-.4.9-.6 1.6-.6s1.2.2 1.6.7c.4.4.6 1 .6 1.7s-.2 1.3-.6 1.7-.9.6-1.6.6-1.2-.2-1.6-.6-.6-1-.6-1.7c0-.8.2-1.4.6-1.8zm3.5 10.6c-.4.4-1.1.7-1.8.7-.8 0-1.4-.2-1.9-.7-.4-.5-.7-1.1-.7-1.8 0-.8.2-1.4.7-1.9s1.1-.7 1.8-.7c.8 0 1.4.2 1.8.7.5.5.7 1.1.7 1.9 0 .7-.2 1.3-.6 1.8z"/>
    </svg>
  );
};

IconCircleEight.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCircleEight.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCircleEight;
