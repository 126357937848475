import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Modal, FieldRadioButton, Button, FieldCheckboxGroup, NamedLink } from '../../components';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { requiredFieldArrayCheckbox } from '../../util/validators';

import css from './ReasonsModal.module.css';

const ReasonsModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmit,
    reasons,
    title,
    description,
    multiple,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const reasonRequiredMessage = intl.formatMessage({
    id: 'ReasonsModal.reasonRequiredMessage',
  });

  const reasonsTip = intl.formatMessage({
    id: 'ReasonsModal.reasonTip',
  });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <p className={css.modalTitle}>
        {title}
      </p>
      <p className={css.modalMessage}>
        {description}
      </p>
      <p className={css.tip}>
        {reasonsTip}
      </p>
      <FinalForm
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        formId={id}
        render={fieldRenderProps => {
          const { form, handleSubmit, invalid, pristine, submitting, values } = fieldRenderProps;
          const submitDisabled = invalid || pristine || submitting;
          return (
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSubmit(e);
              }}
              className={css.expertsWrapper}
            >
              {
                multiple ?
                <FieldCheckboxGroup
                  id={`${id}-reasons`}
                  name="reasons"
                  options={reasons?.map(r => {return {key: r.key, label: r.label}})}
                  validate= {requiredFieldArrayCheckbox(reasonRequiredMessage)}
                /> :
                reasons.map((r, index) => (
                  <FieldRadioButton
                    id={`${id}-reason-${index}`}
                    key={`${id}-reason-${index}`}
                    name="reason"
                    label={r}
                    value={r}
                    showAsRequired={true}
                  />
                ))
              }

              <Button type="submit" disabled={submitDisabled}>
                <FormattedMessage id="ReasonsModal.submit" />
              </Button>
              {<NamedLink name="PrivacyPolicyPage" className={css.privacyPolicy}>
                <FormattedMessage id="Footer.privacyPolicy" />
              </NamedLink>}
            </form>
          );
        }}
      />
    </Modal>
  );
};

const { bool, string, func } = PropTypes;

ReasonsModal.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  errorMessageId: null,
  isOpen: false,
};

ReasonsModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  inProgress: bool,
  errorMessageId: string,
  onSubmit: func.isRequired,
  onCloseModal: func.isRequired,
  isOpen: bool,
};

export default injectIntl(ReasonsModal);
