import moment from 'moment-timezone';
import { useIntl } from 'react-intl';

export const useDateFormatter =
  (format) =>
  (date, prefixTranslation = null) => {
    const intl = useIntl();

    const timeZone = moment.tz.guess();
    const dateWithTimeZone = moment.tz(date, timeZone).format();
    const formatted = intl.formatDate(dateWithTimeZone, format);

    if (prefixTranslation) {
      return [intl.formatMessage({ id: prefixTranslation }, { dates: formatted }), formatted];
    } else {
      return [formatted, formatted];
    }
  };
