import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEmailSent.module.css';

const IconEmailSent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="45"
      height="45"
      viewBox="0 0 45 45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#000000" d="M0 5v30h45V5H0zm40.83 2L22.5 20.19 4.17 7h36.66zM43 33H2V8l20.5 14.66L43 8v25z" />
    </svg>
  );
};

IconEmailSent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconEmailSent.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconEmailSent;
