import { useSelector } from 'react-redux';
import {
  FieldCheckboxBool,
  NamedLink,
} from '../../components';
import {
  selectCurrentUserAddress,
  selectCurrentUserSubscription,
  selectHasGoldSubscription,
  selectHasSilverSubscription,
} from '../../ducks/user.duck';
import { useTranslation } from '../../hooks';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ProfileSkillsForm.module.css';

export const SectionFreelancerSelection = ({ values }) => {
  const setTranslation = useTranslation('ProfileBConsForm');
  const address = useSelector(selectCurrentUserAddress);
  const isGoldSubscriber = useSelector(selectHasGoldSubscription)
  const isSilverSubscriber = useSelector(selectHasSilverSubscription)
  const hasPayedSubscription = isGoldSubscriber || isSilverSubscriber;


  const addressMissing = !address;

  const profileAddressPageLink = (
    <NamedLink name={'ProfileSettingsPage'}>
      {setTranslation('SubscriptionPage.gotoAddress')}
    </NamedLink>
  );

  const membershipLink = (
    <NamedLink name="SubscriptionPage" className={css.link}>
      {setTranslation('membershipLinkLabel')}
    </NamedLink>
  );

  const tooltip = hasPayedSubscription ? (
    setTranslation('membershipAccountInfo')
  ) : (
    setTranslation('membershipLink', { membershipLink })
  );

  return (
    <>
      {addressMissing && (
        <div className={css.missingAddress}>
          <FormattedMessage
            id="SubscriptionPage.missingAddressText"
            values={{ link: profileAddressPageLink }}
          />
        </div>
      )}
      <div className={css.tip}>
      {tooltip}
        </div>
    </>
  );
};
