import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconArrowRight.module.css';

const IconArrowRight = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="100px" height="18px" viewBox="0 0 100 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M91.2.8l-1.4 1.5L96 8H0v2h96l-6.2 5.7 1.4 1.5L100 9z"
        fillRule="evenodd"
      />
    </svg>
  );
};

IconArrowRight.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconArrowRight.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconArrowRight;
