import messagesInLocaleDE from '../translations/de.json';
//import messagesInLocaleES from '../translations/es.json';
//import messagesInLocaleFR from '../translations/fr.json';
//import messagesInLocaleIT from '../translations/it.json';
import defaultMessages from '../translations/en.json';
import difference from 'lodash/difference';
import { updatePlatformLanguage } from './user.duck';
import moment from 'moment';

// ================ Action types ================ //

export const DISABLE_SCROLLING = 'app/UI/DISABLE_SCROLLING';
export const DISPLAY_CONSENT = 'app/UI/DISPLAY_CONSENT';
export const SWITCH_LANGUAGE = 'app/UI/SWITCH_LANGUAGE';
export const LOAD_DATA_COMPLETED = 'app/UI/LOAD_DATA_COMPLETED';

//const knownLanguages = ['de', 'en', 'it', 'es', 'fr'];
const knownLanguages = ['de', 'en'];
const defaultLanguage = 'en';

// ================ Reducer ================ //

const initialState = {
  disableScrollRequests: [],
  displayConsent: false,
  lang: "en",
  messages: getMessages(defaultLanguage),
  languageChanged: false,
  loadDataCompleted: false,
};

export function getLanguageFromNavigatorOrDefault() {
  try {

    const globalLanguage = (typeof navigator !== 'undefined') && navigator?.language;
    const windowLanguage = (typeof window !== 'undefined') && window?.navigator?.language;
    const userLanguage = globalLanguage || windowLanguage || "en-GB";

    const detectedLanguage =  getAsLanguage(userLanguage);

    return detectedLanguage;
  } catch (ex) {
    console.error(ex)
    return defaultLanguage;
  }
}

function getAsLanguage(locale) {
  if (!locale) return defaultLanguage;
  const splitted = locale.split('-');
  return getAsKnownLanguage(splitted[0]);
}

function getAsKnownLanguage(lang) {
  const isKnownLanguage = ['de', 'en'].includes(lang);
  if (isKnownLanguage) {
    return lang;
  } else {
    return defaultLanguage;
  }
}

function getMessages(locale) {
  if (locale === 'de') return addMissingTranslations(defaultMessages, messagesInLocaleDE);
  // if (locale === 'it') return addMissingTranslations(defaultMessages, messagesInLocaleIT);
  // if (locale === 'fr') return addMissingTranslations(defaultMessages, messagesInLocaleFR);
  // if (locale === 'es') return addMissingTranslations(defaultMessages, messagesInLocaleES);
  return defaultMessages;
}

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SWITCH_LANGUAGE: {
      return {
        ...state,
        lang: payload.lang,
        messages: payload.messages,
        languageChanged: true,
        changedByUser: payload.changedByUser,
      };
    }
    case DISABLE_SCROLLING: {
      const { componentId, disableScrolling } = payload;
      const disableScrollRequests = state.disableScrollRequests;
      const componentIdExists = disableScrollRequests.find(c => c.componentId === componentId);

      if (componentIdExists) {
        const disableScrollRequestArray = disableScrollRequests.map(c => {
          return c.componentId === componentId ? { ...c, disableScrolling } : c;
        });
        return { ...state, disableScrollRequests: [...disableScrollRequestArray] };
      }

      const disableScrollRequestArray = [
        ...disableScrollRequests,
        { componentId, disableScrolling },
      ];
      return {
        ...state,
        disableScrollRequests: disableScrollRequestArray,
      };
    }
    case DISPLAY_CONSENT: {
      return { ...state, displayConsent: payload.show };
    }
    case LOAD_DATA_COMPLETED:
      return {...state, loadDataCompleted: payload.isLoadDataCompleted}

    default:
      return getStateWithMissingMessages(state);
  }
}

function getStateWithMissingMessages(state) {
  const enrichedState = { ...state };
  if (!state.messages) {
    enrichedState.messages = getMessages(state.lang);
  }
  return enrichedState;
}

// ================ Action creators ================ //

export const manageDisableScrolling = (componentId, disableScrolling) => ({
  type: DISABLE_SCROLLING,
  payload: { componentId, disableScrolling },
});

export const manageDisplayConsent = (show) => ({
  type: DISPLAY_CONSENT,
  payload: { show },
});

export const saveUserLanguage = (lang, messages, changedByUser) => ({
  type: SWITCH_LANGUAGE,
  payload: {
    lang: lang,
    messages: messages,
    changedByUser: changedByUser,
  },
});

export const setLoadDataCompleted=(isCompleted)=>({
  type: LOAD_DATA_COMPLETED,
  payload: {
    isLoadDataCompleted: isCompleted
  }
});

// ================ Selectors ================ //

export const isScrollingDisabled = state => {
  const { disableScrollRequests } = state.UI;
  return disableScrollRequests.some(r => r.disableScrolling);
};

// ================ Thunks ================ //

export const manageUserLanguageDE = () => (dispatch) => {
  return dispatch(changeUILanguage('de', true));
};

export const manageUserLanguageEN = () => (dispatch) => {
  return dispatch(changeUILanguage('en', true));
};

export const manageUserLanguageFR = () => (dispatch) => {
  return dispatch(changeUILanguage('fr', true));
};

export const manageUserLanguageIT = () => (dispatch) => {
  return dispatch(changeUILanguage('it', true));
};

export const manageUserLanguageES = () => (dispatch) => {
  return dispatch(changeUILanguage('es', true));
};

export const changeUILanguage = (platformLanguage, changedByUser) => dispatch => {
  if (typeof window !== 'undefined') {
    moment.locale(platformLanguage);
    const lang = getAsLanguage(platformLanguage);
    const messages = getMessages(lang);
    const path = window.location.pathname + window.location.search;
    const newUrl = getNewUrl(lang, path);
    window.history.pushState(window.history.state, '', newUrl);
    if (!changedByUser) return dispatch(saveUserLanguage(lang, messages, changedByUser));
    dispatch(saveUserLanguage(lang, messages, changedByUser));
    return dispatch(updatePlatformLanguage({ lang: lang }));
  }

};

function getNewUrl(newLang, path) {
  if (typeof window !== 'undefined') {
    const foundIndex = path.indexOf('/', 1);
    if (foundIndex === -1) return window.location.origin + '/' + newLang;
    const pathWithoutLang = path.substring(foundIndex, path.length);
    return window.location.origin + '/' + newLang + pathWithoutLang;
  }
}

export const loadDataStarted = () => dispatch =>
  dispatch(setLoadDataCompleted(false));

export const loadDataCompleted = () => dispatch =>
  dispatch(setLoadDataCompleted(true));

// ================ add messages ================ //

function addMissingTranslations(sourceLangTranslations, targetLangTranslations) {
  const sourceKeys = Object.keys(sourceLangTranslations);
  const targetKeys = Object.keys(targetLangTranslations);
  const missingKeys = difference(sourceKeys, targetKeys);

  const addMissingTranslation = (translations, missingKey) => ({
    ...translations,
    [missingKey]: sourceLangTranslations[missingKey],
  });

  return missingKeys.reduce(addMissingTranslation, targetLangTranslations);
}
