import React from 'react';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BookingDatesForm.module.css';

const Breakdown = props => {
  const { title, offerAmountMoney, feeAmountMoney, payoutAmountMoney, informationText, currentUser, vat, netAmount} = props;
  const isGermanUser = currentUser ? currentUser.attributes.profile.privateData.countryCode === "DE" : false;

  let feeAmountMoneyMinus = '-';
  if (feeAmountMoney !== '-'){
    feeAmountMoneyMinus = '-'+feeAmountMoney;
  }

  const offerBreakdown = (
    <div className={css.breakdown}>
      <div className={css.offerBreakdown}>
        {title}
      </div>
      <table className={css.table}>
        <tbody>
          <tr className={css.tr}>
            <td className={css.td1}><FormattedMessage id="BookingDatesForm.offerPriceText" /></td>
            <td className={css.td2}><span className={css.deliveryDate}>{offerAmountMoney}</span></td>
          </tr>
          <tr className={css.tr}>
            <td className={css.td1}><FormattedMessage id="BookingDatesForm.feeText" /></td>
            <td className={css.td2}><span className={css.deliveryDate}>{feeAmountMoneyMinus}</span></td>
          </tr>
          <tr className={css.tr}>
            <td className={css.td13}><FormattedMessage id="BookingDatesForm.payoutText" /></td>
            <td className={css.td23}>{payoutAmountMoney}</td>
          </tr>
        </tbody>
      </table>
      <div className={css.informationText}>{informationText}</div>
    </div>);

    const offerBreakdownGermanUser = (
      <div className={css.breakdown}>
        <div className={css.offerBreakdown}>
          {title}
        </div>
        <table className={css.table}>
          <tbody>
            <tr className={css.tr}>
              <td className={css.td1}><FormattedMessage id="BookingDatesForm.offerPriceText" /></td>
              <td className={css.td2}><span className={css.deliveryDate}>{offerAmountMoney}</span></td>
            </tr>
            <tr className={css.trCosts}>
              <td className={css.td1CostsHead}><FormattedMessage id="BookingDatesForm.feeText" /></td>
              <td className={css.td2CostsHead}><span className={css.fee}>{feeAmountMoneyMinus}</span></td>
            </tr>
            <tr className={css.trCosts}>
              <td className={css.td1Costs}><FormattedMessage id="BookingDatesForm.netAmountText" /></td>
              <td className={css.td2Costs}><span className={css.dfee}>{netAmount}</span></td>
            </tr>
            <tr className={css.trCosts}>
              <td className={css.td1CostsLast}><FormattedMessage id="BookingDatesForm.vatText" /></td>
              <td className={css.td2CostsLast}><span className={css.fee}>{vat}</span></td>
            </tr>
            <tr className={css.tr}>
              <td className={css.td13}><FormattedMessage id="BookingDatesForm.payoutText" /></td>
              <td className={css.td23}>{payoutAmountMoney}</td>
            </tr>
          </tbody>
        </table>
        <div className={css.informationText}>{informationText}</div>
      </div>);



  return (
    isGermanUser ? offerBreakdownGermanUser : offerBreakdown
  );

};

export default Breakdown;
