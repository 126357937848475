import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFactory.module.css';

const IconFactory = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg enableBackground="new 0 0 36 36" version="1.1" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
      <path d="m27.8 16.1v-5.8l-8.2 5.8v-5.8l-8.2 5.8v-16.1h-11.4v36h36v-25.7l-8.2 5.8zm6.2 17.9h-32v-32h7.3v18l3.2-2.2 5.1-3.6v5.8l3.2-2.2 5.1-3.6v5.8l3.2-2.2 5.1-3.6v19.8z"/>
    </svg>
  );
};

IconFactory.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconFactory.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconFactory;
