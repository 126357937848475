import { FieldTextInput } from '../../components';
import { useTranslation } from '../../hooks';
import css from './ProfileSkillsForm.module.css';

export function SectionSocialLinks() {
  const setTranslation = useTranslation('ProfileSettingsForm');

  const socials = [
    {
      id: 'homepage',
      label: setTranslation('homepageLabel'),
      placeholder: setTranslation('homepagePlaceholder'),
    }, {
      id: 'linkedIn',
      label: setTranslation('linkedInLabel'),
      placeholder: setTranslation('linkedInPlaceholder'),
    }, {
      id: 'facebook',
      label: setTranslation('facebookLabel'),
      placeholder: setTranslation('facebookPlaceholder'),
    }, {
      id: 'xing',
      label: setTranslation('xingLabel'),
      placeholder: setTranslation('xingPlaceholder'),
    }, {
      id: 'twitter',
      label: setTranslation('twitterLabel'),
      placeholder: setTranslation('twitterPlaceholder'),
    },
  ];

  return (
    <>
      <div className={css.headline}>
        {setTranslation('yourHomepage')}
      </div>
      {socials.map(link =>
        <FieldTextInput
          key={link.id}
          className={css.homepage}
          type='text'
          id={link.id}
          name={link.id}
          label={link.label}
          placeholder={link.placeholder}
        />,
      )}
    </>
  );
}
