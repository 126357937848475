import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconExclamationMark.module.css';

const IconExclamationMark = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="45" height="45" viewBox="-1 -1 48 48" xmlns="http://www.w3.org/2000/svg">
      <g
        className={css.marketplaceColorStroke}
        fillRule="evenodd"
        stroke="none"
      >
        <path d="M22.5 31.5c-.6 0-1.1.2-1.4.5-.3.4-.5.8-.5 1.3s.2 1 .5 1.3.8.5 1.4.5 1.1-.2 1.4-.5.5-.8.5-1.3-.2-1-.5-1.3c-.3-.4-.8-.5-1.4-.5zm1.3-3.7l.3-17.9h-3.4l.3 17.9zM22.5 0C10.1 0 0 10.1 0 22.5S10.1 45 22.5 45 45 34.9 45 22.5 34.9 0 22.5 0zm0 43C11.2 43 2 33.8 2 22.5S11.2 2 22.5 2 43 11.2 43 22.5 33.8 43 22.5 43z"/>
      </g>
    </svg>
  );
};

IconExclamationMark.defaultProps = {
  rootClassName: null,
  className: null,
};

IconExclamationMark.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconExclamationMark;
