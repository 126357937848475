import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingDeliveryDateForm } from '../../forms';
import { ListingLink } from '..';
import moment from "moment-timezone";
import 'moment-timezone';

import css from './EditListingDeliveryDatePanel.module.css';

const EditListingDeliveryDatePanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;


  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const listingInterval = currentListing.attributes.availabilityPlan || {};
  const deliveryDate = publicData.deliveryDate || '';
  const timezoneFull = deliveryDate.timezone || 'Europe/Amsterdam';

  let timezone = 'Europe/Amsterdam';
  if(timezoneFull===null){
    timezone='Europe/Amsterdam';
  }
  else if(timezoneFull!==null){
    timezone = timezoneFull;
  }

  const endDate = publicData.deliveryDate || '';
  const deliveryDateLTZ = moment.tz(endDate.deliveryDateUTC, endDate.timezone).format();



  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDeliveryDatePanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDeliveryDatePanel.createListingTitle" />
  );


  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDeliveryDateForm
        className={css.form}
        initialValues={{endDate: deliveryDateLTZ, timezone: timezone}}
        deliveryDate={deliveryDateLTZ}
        onSubmit={values => {
          const {endDate, timezone} = values;
          const UTCDate = moment.utc(endDate).format();
          const updateValues = {
            publicData: {deliveryDate: {
              deliveryDateUTC: UTCDate,
              timezone: timezone.value,
            }
            },
            };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingDeliveryDatePanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingDeliveryDatePanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDeliveryDatePanel;
