import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  Form,
  Avatar,
  Button,
  ImageFromFile,
  IconSpinner,
  FieldTextInput,
  FieldCheckboxGroup,
  PhoneNumberInput,
  FieldRadioButton,
  FieldCheckboxBool,
  FieldSelect,
  NamedLink,
} from '../../components';

import css from './ProfileSettingsForm.module.css';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import { isNegBool, supportedVatCountry } from '../../util/validators';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset
const SHOW_EMAIL_SENT_TIMEOUT = 2000;
const identity = (v) => v;

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={(fieldRenderProps) => {
          const {
            className,
            formId,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            pristine,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
            filterConfig,
            savePhoneNumberError,
            errors,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);
          const { phoneNumber, email, showPhone, showAddress } = values;
          if (!user.id) {
            return null;
          }

          const languageKey = 'language';
          const languageOptions = findOptionsForSelectFilter(languageKey, filterConfig);
          const countryKey = 'country';
          const countryOptions = findOptionsForSelectFilter(
            countryKey,
            config.profileConfig.filters
          );
          const isVatAvailable = values.vatAvailable === 'vat is available' ? true : false;
          const countryCode = countryOptions.filter(
            (countryOption) => countryOption.key === values.country
          )[0]?.code;
          const isSupportedVatCountry = supportedVatCountry(countryCode);

          // Language
          const languageLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.languageLabel',
          });

          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // Job Title
          const jobTitleLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.JobTitleLabel',
          });
          const jobTitlePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.JobTitlePlaceholder',
          });
          const jobTitleRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.JobTitleRequiredMessage',
          });
          const jobTitleRequired = validators.required(jobTitleRequiredMessage);

          const languageInformationText = intl.formatMessage({
            id: 'ProfileSettingsForm.informationText',
          });

          // country
          const countryLabel = intl.formatMessage({
            id: 'ProfileAddressForm.countryLabel',
          });
          const countryPlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.countryPlaceholder',
          });
          const countryRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.countryRequired',
          });
          const invalidCountryMessage = intl.formatMessage({
            id: 'ProfileAddressForm.invalidCountryMessage',
          });
          const countryRequired = validators.required(countryRequiredMessage);

          const validCountry = validators.validTaxId(invalidCountryMessage);
          const countryValidators = validators.composeValidators(countryRequired, validCountry);

          const supportedCountries = validators.supportedCountries;
          const countryNotSupportedMessage = (
            <div className={css.countryNotSupportedMessage}>
              <FormattedMessage id="ProfileAddressForm.countryNotSupportedMessage" />
            </div>
          );
          // company

          const companyLabel = intl.formatMessage({
            id: 'ProfileAddressForm.companyLabel',
          });
          const companyPlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.companyPlaceholder',
          });
          const companyRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.companyRequired',
          });
          const companyRequired = validators.required(companyRequiredMessage);

          //street
          const streetLabel = intl.formatMessage({
            id: 'ProfileAddressForm.streetLabel',
          });
          const streetPlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.streetPlaceholder',
          });
          const streetRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.streetRequired',
          });
          const streetRequired = validators.required(streetRequiredMessage);
          // house number
          const houseNumberLabel = intl.formatMessage({
            id: 'ProfileAddressForm.houseNumberLabel',
          });
          const houseNumberPlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.houseNumberPlaceholder',
          });
          const houseNumberRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.houseNumberRequired',
          });
          const houseNumberRequired = validators.required(houseNumberRequiredMessage);
          // postal code
          const postalCodeLabel = intl.formatMessage({
            id: 'ProfileAddressForm.postalCodeLabel',
          });
          const postalCodePlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.postalCodePlaceholder',
          });
          const postalCodeRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.postalCodeRequired',
          });
          const postalCodeRequired = validators.required(postalCodeRequiredMessage);
          // city
          const cityLabel = intl.formatMessage({
            id: 'ProfileAddressForm.cityLabel',
          });
          const cityPlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.cityPlaceholder',
          });
          const cityRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.cityRequired',
          });
          const cityRequired = validators.required(cityRequiredMessage);

          const taxIdLabel = intl.formatMessage({
            id: 'ProfileAddressForm.taxIdLabel',
          });

          const taxIdPlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.taxIdPlaceholder',
          });

          const taxIdRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.taxIdRequiredMessage',
          });

          const invalidTaxIdMessage = intl.formatMessage({
            id: 'ProfileAddressForm.invalidtaxIdMessage',
          });

          const b2bRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.b2bRequiredMessage',
          });

          const informationText = intl.formatMessage({
            id: 'ProfileAddressForm.informationText',
          });

          const b2bRequired = validators.required(b2bRequiredMessage);
          const taxIdRequired = validators.required(taxIdRequiredMessage);
          const validTaxId = validators.validTaxId(invalidTaxIdMessage);
          const taxIdValidators = validators.composeValidators(taxIdRequired, validTaxId);

          const showAddressLabel = intl.formatMessage({
            id: 'ContactDetailsForm.showAddressLabel',
          });

          const b2bLabel = intl.formatMessage({
            id: 'ProfileAddressForm.b2bLabel',
          });

          const vatAvailableLabel = intl.formatMessage({
            id: 'ProfileAddressForm.vatAvailableLabel',
          });

          const noVatAvailableLabel = intl.formatMessage({
            id: 'ProfileAddressForm.noVatAvailableLabel',
          });

          const taxInfoText = supportedCountries.includes(countryCode)
            ? null
            : countryNotSupportedMessage;

          const showAsRequired = pristine;

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>
                  <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                </div>
              </div>
            ) : (
              <div className={css.avatarPlaceholder}>
                <div className={css.avatarPlaceholderText}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePicture" />
                </div>
                <div className={css.avatarPlaceholderTextMobile}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePictureMobile" />
                </div>
              </div>
            );

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          // phone
          const showPhoneLabel = intl.formatMessage({
            id: 'ContactDetailsForm.showPhoneLabel',
          });
          const currentPhoneNumber = values.phoneNumber || '';

          // has the phone number changed
          const phoneNumberChanged = currentPhoneNumber !== phoneNumber;
          const showPhoneChanged = values.showPhone !== showPhone;
          const showAddressChanged = values.showAddress !== showAddress;

          //phone-number info
          let phoneNumberInfo;
          if (errors.phoneNumber !== undefined && pristine === false) {
            phoneNumberInfo = (
              <span className={css.error}>
                <FormattedMessage id="ContactDetailsForm.phoneInvalid" />
              </span>
            );
          } else {
            phoneNumberInfo = <span className={css.error}>&nbsp;</span>;
          }

          let phoneNumberSuccess = false;

          if (errors.phoneNumber === undefined && phoneNumber !== '') {
            phoneNumberSuccess = true;
          }

          const emailInfo = intl.formatMessage({
            id: 'ContactDetailsForm.emailInfo',
          });

          const phonePlaceholder = intl.formatMessage({
            id: 'ContactDetailsForm.phonePlaceholder',
          });
          const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });

          const phoneInvalidMessage = intl.formatMessage({
            id: 'ContactDetailsForm.phoneInvalid',
          });

          const phoneValid = validators.phoneNumberFormatValid(phoneInvalidMessage);

          const emailLabel = intl.formatMessage({
            id: 'ContactDetailsForm.emailLabel',
          });
          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const b2bUnchecked = !(validators.isNegBool(values.b2b));
          const submitDisabled =
            invalid ||
            pristine ||
            pristineSinceLastSubmit ||
            uploadInProgress ||
            submitInProgress ||
            phoneNumberChanged ||
            showPhoneChanged ||
            showAddressChanged ||
            b2bUnchecked;

          return (
            <Form
              className={classes}
              onSubmit={(e) => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.sectionContainer}>
                <Field
                  accept={ACCEPT_IMAGES}
                  id="profileImage"
                  name="profileImage"
                  label={chooseAvatarLabel}
                  type="file"
                  form={null}
                  uploadImageError={uploadImageError}
                  disabled={uploadInProgress}
                >
                  {(fieldProps) => {
                    const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                    const { name, type } = input;
                    const onChange = (e) => {
                      const file = e.target.files[0];
                      form.change(`profileImage`, file);
                      form.blur(`profileImage`);
                      if (file != null) {
                        const tempId = `${file.name}_${Date.now()}`;
                        onImageUpload({ id: tempId, file });
                      }
                    };

                    let error = null;

                    if (isUploadImageOverLimitError(uploadImageError)) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                        </div>
                      );
                    } else if (uploadImageError) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                        </div>
                      );
                    }

                    return (
                      <div className={css.uploadAvatarWrapper}>
                        <label className={css.label} htmlFor={id}>
                          {label}
                        </label>
                        <input
                          accept={accept}
                          id={id}
                          name={name}
                          className={css.uploadAvatarInput}
                          disabled={disabled}
                          onChange={onChange}
                          type={type}
                        />
                        {error}
                      </div>
                    );
                  }}
                </Field>
                <div className={css.tip}>
                  <FormattedMessage id="ProfileSettingsForm.tip" />
                </div>
                <div className={css.fileInfo}>
                  <FormattedMessage id="ProfileSettingsForm.fileInfo" />
                </div>
              </div>
              <div className={css.nameContainer}>
                <FieldTextInput
                  className={css.firstName}
                  type="text"
                  id="firstName"
                  name="firstName"
                  label={firstNameLabel}
                  placeholder={firstNamePlaceholder}
                  validate={firstNameRequired}
                />
                <FieldTextInput
                  className={css.lastName}
                  type="text"
                  id="lastName"
                  name="lastName"
                  label={lastNameLabel}
                  placeholder={lastNamePlaceholder}
                  validate={lastNameRequired}
                />
              </div>
              <div className={css.sectionContainer}>
                <FieldCheckboxBool
                  className={css.b2b}
                  name="showPhone"
                  id={formId ? `${formId}.showPhone` : 'showPhone'}
                  label={showPhoneLabel}
                  defaultValue={true}
                />
                <Field
                  className={css.phone}
                  name="phoneNumber"
                  component={PhoneNumberInput}
                  placeholder={phonePlaceholder}
                  label={phoneLabel}
                  validate={phoneValid}
                  valid={phoneNumberSuccess}
                />
                <div className={css.phone}>{phoneNumberInfo}</div>
              </div>
              <div className={css.sectionContainer}>
                <div className={css.emaillabel}>{emailLabel}</div>
                <NamedLink className={css.profileLink} name="ContactDetailsPage">
                  <FormattedMessage id="ConfirmSignupForm.email" values={{ emailadr: email }} />
                </NamedLink>
                <div className={css.tip}>{emailInfo}</div>
              </div>
              <p className={css.Headline}>{jobTitleLabel}</p>
              <div className={css.jobTitle}>
                <FieldTextInput
                  type="textarea"
                  id="jobTitle"
                  name="jobTitle"
                  placeholder={jobTitlePlaceholder}
                  validate={jobTitleRequired}
                />
              </div>
              <p className={css.Headline}>
                <FormattedMessage id="ProfileSettingsForm.languageLabel" />
              </p>
              <div className={css.tip}>{languageInformationText}</div>
              <FieldCheckboxGroup
                setFocus={true}
                twoColumns={true}
                className={css.language}
                name={languageKey}
                id={formId ? `${formId}.language` : 'language'}
                options={languageOptions}
              />
              <div className={classNames(css.sectionContainer, css.lastSection)}>
                <div className={css.Headline}>
                  <FormattedMessage id="ProfileAddressForm.addressTitle" />
                </div>
                <FieldCheckboxBool
                  className={css.b2b}
                  name="showAddress"
                  id={formId ? `${formId}.showAddress` : 'showAddress'}
                  label={showAddressLabel}
                  initialValue={false}
                />
                <div className={css.tip}>{informationText}</div>
                <FieldTextInput
                  className={css.company}
                  type="text"
                  id={formId ? `${formId}.company` : 'company'}
                  name="company"
                  autoComplete="company-name"
                  label={companyLabel}
                  placeholder={companyPlaceholder}
                  validate={companyRequired}
                />
                <div className={css.streetContainer}>
                  <FieldTextInput
                    className={css.street}
                    type="text"
                    id={formId ? `${formId}.street` : 'street'}
                    name="street"
                    autoComplete="street-name"
                    label={streetLabel}
                    placeholder={streetPlaceholder}
                    validate={streetRequired}
                  />
                  <FieldTextInput
                    className={css.houseNumber}
                    type="text"
                    id={formId ? `${formId}.houseNumber` : 'houseNumber'}
                    name="houseNumber"
                    autoComplete="house-number"
                    label={houseNumberLabel}
                    placeholder={houseNumberPlaceholder}
                    validate={houseNumberRequired}
                  />
                </div>
                <div className={css.cityContainer}>
                  <FieldTextInput
                    className={css.postalCode}
                    type="text"
                    id={formId ? `${formId}.postalCode` : 'postalCode'}
                    name="postalCode"
                    autoComplete="postal-code"
                    label={postalCodeLabel}
                    placeholder={postalCodePlaceholder}
                    validate={postalCodeRequired}
                  />
                  <FieldTextInput
                    className={css.city}
                    type="text"
                    id={formId ? `${formId}.city` : 'city'}
                    name="city"
                    autoComplete="city"
                    label={cityLabel}
                    placeholder={cityPlaceholder}
                    validate={cityRequired}
                  />
                </div>
                <FieldSelect
                  className={css.country}
                  id={formId ? `${formId}.country` : 'country'}
                  name="country"
                  options={countryOptions}
                  validate={countryValidators}
                  label={countryLabel}
                >
                  <option value="">{countryPlaceholder}</option>
                  {countryOptions.map((o) => (
                    <FormattedMessage key={o.key} id={o.translationID}>
                      {(txt) => <option value={o.value}>{txt}</option>}
                    </FormattedMessage>
                  ))}
                </FieldSelect>
                {isSupportedVatCountry ? (
                  <div className={css.twoColumns}>
                    <FieldRadioButton
                      className={css.radioVat}
                      id={formId ? `${formId}.vatAvailable` : 'vatAvailable'}
                      name="vatAvailable"
                      label={vatAvailableLabel}
                      value="vat is available"
                      showAsRequired={showAsRequired}
                    />
                    <FieldRadioButton
                      className={css.radioVat}
                      id={formId ? `${formId}.noVatAvailable` : 'noVatAvailable'}
                      name="vatAvailable"
                      label={noVatAvailableLabel}
                      value="no vat available"
                      showAsRequired={showAsRequired}
                    />
                  </div>
                ) : null}
                {isVatAvailable && isSupportedVatCountry ? (
                  <div>
                    <FieldTextInput
                      className={css.company}
                      type="taxId"
                      id={formId ? `${formId}.taxId` : 'taxId'}
                      name="taxId"
                      label={taxIdLabel}
                      placeholder={taxIdPlaceholder}
                      validate={taxIdValidators}
                      infoText={taxInfoText}
                    />
                  </div>
                ) : null}
                <div className={css.company}>
                  <FieldCheckboxBool
                    className={css.b2b}
                    name="b2b"
                    id={formId ? `${formId}.b2b` : 'b2b'}
                    label={b2bLabel}
                    validate={validators.isNegBool(values.b2b)}
                  />
                </div>
              </div>
              <div className={css.stickyButton}>
                {submitError}
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={pristineSinceLastSubmit}
                >
                  <FormattedMessage id="ProfileSettingsForm.saveChanges" />
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
  filterConfig: config.custom.filters,
  selectedPlace: null,
  phoneNumber: null,
  savePhoneNumberError: null,
  showPhone: true,
  showAddress: true
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,
  selectedPlace: propTypes.place,

  // from injectIntl
  intl: intlShape.isRequired,
  filterConfig: propTypes.filterConfig,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
