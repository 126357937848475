import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconDx.module.css';

const IconDx = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="45px" height="45px" viewBox="0 0 45 45" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="DIALux">
        <g id="BG_1_">
          <g id="Group">
            <path d="M43 2L43 43L2 43L2 2L43 2M45 0L0 0L0 45L45 45L45 0L45 0L45 0Z" id="Shape" fill="#000000" fillRule="evenodd" stroke="none" />
          </g>
        </g>
        <g id="Layout_1_" transform="translate(7.8984375 13.400002)">
          <g id="Group">
            <path d="M0 0L4.5 0C11 0 13.6 3.8 13.6 9.1C13.6 15.7 9.2 18.2 3.2 18.2L0 18.2L0 0L0 0ZM1.80078 16.6L3.30078 16.6C8.50078 16.6 11.8008 14.5 11.8008 8.9C11.8008 3.3 8.50078 1.5 4.40078 1.5L1.80078 1.5L1.80078 16.6L1.80078 16.6L1.80078 16.6Z"
            id="Shape"
            fill="#000000"
            fillRule="evenodd"
            stroke="none" />
            <path d="M0.5 0L4.6 0L7.2 4.4L9.8 0L13.5 0L9.5 6.4L14 13.4L9.8 13.4L6.8 8.4L3.8 13.4L0 13.4L4.6 6.4L0.5 0L0.5 0Z"
            transform="translate(15.1015625 4.800003)"
            id="Shape"
            fill="#000000"
            fillRule="evenodd"
            stroke="none" />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconDx.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconDx.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconDx;
