import React, { Component } from 'react';
import { array, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { withViewport } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { LISTING_TYPES } from '../../util/constants';
import { getEditListingPageName } from '../../util/urlHelpers';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureCurrentUser, ensureListing } from '../../util/data';

import { Modal, NamedRedirect, Tabs, IconSpinner, ExpertCard, Button, IconPerson } from '../../components';

import EditListingWizardTab, {
  DESCRIPTION,
  CATEGORIES,
  LOCATION,
  LANGUAGE,
  PHOTOS,
  TENDER,
  EXPERTS,
  APPLICATION,
  SUPPORT_CATEGORIES,
  DETAILS,
  LOCATIONS,
  SUPPORT,
  LANGUAGES,
  LEAD_PHOTOS,
  LEAD_DESCRIPTION,
  LEAD_EVO,
} from './EditListingWizardTab';
import css from './EditListingWizard.module.css';
import { SecondaryButton } from '../Button/Button';

// Show availability calendar only if environment variable availabilityEnabled is true
//const availabilityMaybe = config.enableAvailability ? [AVAILABILITY] : [];

// You can reorder these panels.
// Note 1: You need to change save button translations for new listing flow
// Note 2: Ensure that draft listing is created after the first panel
// and listing publishing happens after last panel.
export const TABS = [
  CATEGORIES,
  APPLICATION,
  LOCATION,
  LANGUAGE,
  PHOTOS,
  TENDER,
  DESCRIPTION,
];

export const LEAD_TABS = [
  LEAD_EVO,
  APPLICATION,
  SUPPORT_CATEGORIES,
  DETAILS,
  LOCATIONS,
  SUPPORT,
  LANGUAGES,
  LEAD_PHOTOS,
  LEAD_DESCRIPTION,
]

// Tabs are horizontal in small screens
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

const tabLabel = (intl, tab, listingType) => {
  let key = null;
  if (tab === DESCRIPTION) {
    key = 'tabLabelDescription';
  } else if (tab === CATEGORIES) {
    key = 'tabLabelCategories';
  } else if (tab === LOCATION) {
    key = 'tabLabelLocation';
  } else if (tab === LANGUAGE) {
    key = 'tabLabelLanguage';
  } else if (tab === PHOTOS) {
    key = 'tabLabelPhotos';
  } else if (tab === TENDER) {
    key = 'tabLabelTender';
  } else if (tab === EXPERTS) {
    key = 'tabLabelExperts';
  } else if (tab === APPLICATION && listingType === LISTING_TYPES.LEAD) {
    key = 'tabLabelApplicationLead';
  } else if (tab === LEAD_EVO && listingType === LISTING_TYPES.LEAD) {
    key = 'tabLabelLandingFromEvoLead';
  } else if (tab === APPLICATION) {
    key = 'tabLabelApplication';
  } else if (tab === SUPPORT_CATEGORIES) {
    key = 'tabLabelSupportCategories';
  } else if (tab === DETAILS) {
    key = 'tabLabelDetails';
  } else if (tab === LOCATIONS) {
    key = 'tabLabelLocations';
  } else if (tab === SUPPORT) {
    key = 'tabLabelSupport';
  } else if (tab === LANGUAGES) {
    key = 'tabLabelLanguages';
  } else if (tab === LEAD_PHOTOS) {
    key = 'tabLabelLeadPhotos';
  } else if (tab === LEAD_DESCRIPTION) {
    key = 'tabLabelLeadDescription';
  }

  return intl.formatMessage({ id: `EditListingWizard.${key}` });
};

/**
 * Check if a wizard tab is completed.
 *
 * @param tab wizard's tab
 * @param listing is contains some specific data if tab is completed
 *
 * @return true if tab / step is completed.
 */

const tabCompleted = (tab, listing) => {
  const {
    //availabilityPlan,
    description,
    geolocation,
    title,
    publicData,
  } = listing.attributes;
  const images = listing.images;

  switch (tab) {
    case APPLICATION:
      return !!(publicData && (publicData.indoor || publicData.outdoor));
    case SUPPORT_CATEGORIES:
      return !!(publicData && (publicData?.categories?.length > 0));
    case DETAILS:
      return !!(
        publicData &&
        (publicData.measureType) &&
        ((publicData.length && publicData.width) || publicData.floorSpace) &&
        (
          publicData.dimensionsUnit &&
          (publicData.measureType === 'space' &&
            (publicData.dimensionsUnit === 'meterSquare' || publicData.dimensionsUnit === 'feetSquare')
          ) ||
          (publicData.measureType === 'dimensions' &&
            (publicData.dimensionsUnit === 'meter' || publicData.dimensionsUnit === 'feet')
          )
        ) &&
        (publicData.realisationStartDate) &&
        (publicData.applicationDeadline)
      )
    case LOCATIONS:
      return !!(geolocation && publicData && publicData.location && publicData.location.address);
    case SUPPORT:
      return !!(publicData && (publicData?.support?.length > 0));;
    case LANGUAGES:
      return !!(publicData && typeof publicData.language !== 'undefined');;
    case LEAD_PHOTOS:
      return !!(publicData && typeof publicData.language !== 'undefined');;
    case LEAD_DESCRIPTION:
      return !!(description && title);
    case DESCRIPTION:
      return !!(description && title);
    case CATEGORIES:
      return !!(publicData && (publicData.dialux || publicData.photoWork || publicData.lightingDesign || publicData.others || publicData.documentation));
    case LOCATION:
      if (publicData.location){
        if (publicData.locationGroup === 'remote'){
          return true;
        }
      }
      return !!(geolocation && publicData && publicData.location && publicData.location.address);
    case LANGUAGE:
      return !!(publicData && typeof publicData.language !== 'undefined');
    case PHOTOS:
      return !!(publicData && typeof publicData.language !== 'undefined');
    case TENDER:
      return !!(publicData && publicData.jobTypeGroup && publicData.paymentOption  && publicData.listingEndDateUTC && publicData.listingEndDateUNIX);
    case EXPERTS:
      return true;
    case LEAD_EVO:
      return true;
    default:
      return false;
  }
};

/**
 * Check which wizard tabs are active and which are not yet available. Tab is active if previous
 * tab is completed. In edit mode all tabs are active.
 *
 * @param isNew flag if a new listing is being created or an old one being edited
 * @param listing data to be checked
 *
 * @return object containing activity / editability of different tabs of this wizard
 */
const tabsActive = (isNew, listing, tabs) => {
  return tabs.reduce((acc, tab) => {
    const previousTabIndex = tabs.findIndex(t => t === tab) - 1;
    const isActive =
      previousTabIndex >= 0 ? !isNew || tabCompleted(tabs[previousTabIndex], listing) : true;
    return { ...acc, [tab]: isActive };
  }, {});
};

const scrollToTab = (tabPrefix, tabId) => {
  const el = document.querySelector(`#${tabPrefix}_${tabId}`);
  if (el) {
    el.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  }
};

// Create a new or edit listing through EditListingWizard
class EditListingWizard extends Component {
  constructor(props) {
    super(props);

    // Having this info in state would trigger unnecessary rerendering
    this.hasScrolledToTab = false;

    this.state = {
      draftId: null,
      isExpertsModalOpen: false,
      selectedExperts: [],
    };

    this.handleCreateFlowTabScrolling = this.handleCreateFlowTabScrolling.bind(this);
    this.handlePublishListing = this.handlePublishListing.bind(this);
    this.handleExpertsModal = this.handleExpertsModal.bind(this);
    this.handleSendListingToExperts = this.handleSendListingToExperts.bind(this);
    this.handleChangeExperts = this.handleChangeExperts.bind(this);
  }

  componentDidMount() {
    this.handleExpertsModal(false);
  }

  handleCreateFlowTabScrolling(shouldScroll) {
    this.hasScrolledToTab = shouldScroll;
    // feels dirty
    this.handleExpertsModal(false);
  }

  handlePublishListing(id) {
    const { onPublishListingDraft } = this.props;
    onPublishListingDraft(id);
  }

  handleExpertsModal(open, page=1){
    const currentListing = ensureListing(this.props.listing);
    const publicData = currentListing.attributes && currentListing.attributes.publicData;
    const privateData = currentListing.attributes && currentListing.attributes.privateData;
    const invitedExpertsIds = privateData && privateData.invitedExpertsIds;
    const isDirectConnect = privateData && privateData.isDirectConnect && invitedExpertsIds && invitedExpertsIds.length === 1 ;
    if (isDirectConnect){
      // Should not fetch experts incase of direct connect that will be fetched by loadData
      return false
    }
    const { dialux, lightingDesign, documentation, photoWork, others, language, country } = publicData;
    const dialuxFilterMayBe = dialux && dialux.length > 0 ? { pub_dialux: dialux.join(',') } : {}
    const lightingDesignFilterMayBe = lightingDesign && lightingDesign.length > 0 ? { pub_lightingDesign: lightingDesign.join(',') } : {}
    const documentationFilterMayBe = documentation && documentation.length > 0 ? { pub_documentation: documentation.join(',') } : {}
    const photoWorkFilterMayBe = photoWork && photoWork.length > 0 ? { pub_photoWork: photoWork.join(',') } : {}
    const othersFilterMayBe = others && others.length > 0 ? { pub_others: others.join(',') } : {}
    const languageFilterMayBe = language && language.length > 0 ? { pub_language: 'has_any:' + language.join(',') } : {}
    const params = {
      ...dialuxFilterMayBe ,
      ...lightingDesignFilterMayBe,
      ...documentationFilterMayBe,
      ...photoWorkFilterMayBe,
      ...othersFilterMayBe,
      ...languageFilterMayBe,
    }
    this.props.onFetchExperts(params, page)
    this.setState({isExpertsModalOpen: open})
  }

  handleSendListingToExperts(values){
    if(values && values.invitedExpertsIds){
      const updateValues = {
        privateData: { invitedExpertsIds: values.invitedExpertsIds}
      };
      this.props.onUpdateListing(null, {...updateValues, id: this.props.listing.id });
      this.setState({isExpertsModalOpen: false})
    }
  }

  handleChangeExperts(formState){
    this.setState({
      selectedExperts: formState.values.invitedExpertsIds
    })
  }

  render() {
    const {
      id,
      className,
      rootClassName,
      params,
      listing,
      viewport,
      intl,
      errors,
      onManageDisableScrolling,
      currentUser,
      experts,
      expertsPagination,
      allExpertsPagination,
      fetchExpertsInProgress,
      fetchExpertsError,
      listingType,
      ...rest
    } = this.props;

    const selectedTab = params.tab;
    const isNewListingFlow = [LISTING_PAGE_PARAM_TYPE_NEW, LISTING_PAGE_PARAM_TYPE_DRAFT].includes(
      params.type
    );
    const rootClasses = rootClassName || css.root;
    const classes = classNames(rootClasses, className);
    const currentListing = ensureListing(listing);
    const expertId = params.expertId;
    const privateData = currentListing.attributes &&
      currentListing.attributes.privateData && currentListing.attributes.privateData;
    const isDirectConnect = expertId || privateData && privateData.isDirectConnect;
    const invitedExpertsIds = privateData && privateData.invitedExpertsIds;
    const showExpertsTab = !(isDirectConnect || !isNewListingFlow);
    const tabs  = listingType === LISTING_TYPES.LEAD ?
      LEAD_TABS :
      showExpertsTab ?
        [...TABS, EXPERTS] : TABS;
    const tabsStatus = tabsActive(isNewListingFlow, currentListing, tabs);

    // If selectedTab is not active, redirect to the beginning of wizard
    if (!tabsStatus[selectedTab]) {
      const currentTabIndex = tabs.indexOf(selectedTab);
      const nearestActiveTab = tabs.slice(0, currentTabIndex)
        .reverse()
        .find(t => tabsStatus[t]);
      return <NamedRedirect name={getEditListingPageName(listingType)} params={{ ...params, tab: nearestActiveTab }} />;
    }

    const { width } = viewport;
    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasFontsLoaded =
      hasViewport && document.documentElement.classList.contains('fontsLoaded');

    // Check if scrollToTab call is needed (tab is not visible on mobile)
    if (hasVerticalTabLayout) {
      this.hasScrolledToTab = true;
    } else if (hasHorizontalTabLayout && !this.hasScrolledToTab && hasFontsLoaded) {
      const tabPrefix = id;
      scrollToTab(tabPrefix, selectedTab);
      this.hasScrolledToTab = true;
    }

    const tabLink = tab => {
      return { name: getEditListingPageName(listingType), params: { ...params, tab } };
    };

    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    const cardRenderSizes = [
      '(max-width: 767px) 100vw',
      `(max-width: 1023px) ${panelMediumWidth}vw`,
      `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
      `${panelLargeWidth / 3}vw`,
    ].join(', ');



    return (
      <div className={classes}>
        <Tabs
          rootClassName={css.tabsContainer}
          navRootClassName={css.nav}
          tabRootClassName={css.tab}
        >
          {tabs.map(tab => {
            return (
              <EditListingWizardTab
                {...rest}
                key={tab}
                tabId={`${id}_${tab}`}
                tabLabel={tabLabel(intl, tab, listingType)}
                tabLinkProps={tabLink(tab)}
                selected={selectedTab === tab}
                disabled={isNewListingFlow && !tabsStatus[tab]}
                tab={tab}
                intl={intl}
                params={params}
                listing={listing}
                marketplaceTabs={tabs}
                errors={errors}
                handleCreateFlowTabScrolling={this.handleCreateFlowTabScrolling}
                handlePublishListing={this.handlePublishListing}
                handleExpertsModal={this.handleExpertsModal}
                experts={experts}
                expertsPagination={expertsPagination}
                allExpertsPagination={allExpertsPagination}
                listingType={listingType}
                currentUser={currentUser}
              />
            );
          })}
        </Tabs>
        {
          showExpertsTab ?
          <Modal
            id="EditListingWizard.findExperts"
            isOpen={this.state.isExpertsModalOpen}
            onClose={() => this.setState({isExpertsModalOpen: false})}
            onManageDisableScrolling={onManageDisableScrolling}
            containerClassName={css.expertsModalContent}
            usePortal
            >
            <div className={css.expertsModalWrapper}>
              {
                fetchExpertsError ? <p></p> : null
              }
              {
                fetchExpertsInProgress ?
                <p className={css.spinner}>
                  <IconSpinner />
                </p> : experts && experts.length > 0 ?
                <div>
                  <div>
                    <IconPerson className={css.iconPerson}></IconPerson>
                  </div>
                  <h2 className={css.findExpertsHeading}>
                    <FormattedMessage id="EditListingWizard.sendListingHeading" values={{ expertsCount: expertsPagination.totalItems }} />
                  </h2>
                  <FinalForm
                    onSubmit={this.handleSendListingToExperts}
                    onChange={this.handleChangeExperts}
                    formId='send-listing-to-experts'
                    initialValues={{invitedExpertsIds}}
                    render={fieldRenderProps => {
                      const { form, onChange, handleSubmit, invalid, pristine, submitting, values } = fieldRenderProps;
                      const submitDisabled = invalid || pristine || submitting;
                      return (
                        <form
                          onSubmit={e => {
                            e.preventDefault();
                            handleSubmit(e);
                          }}
                        >
                          <div className={css.expertsWrapper}>
                          <FormSpy onChange={onChange} subscription={{ values: true, dirty: true }} />
                          {experts.map(l => (
                            <ExpertCard
                              className={css.expertCard}
                              key={l.id.uuid}
                              listing={l}
                              renderSizes={cardRenderSizes}
                              optionToSelect={true}
                              isSelected={this.state.selectedExperts && this.state.selectedExperts.includes(l.id.uuid)}
                            />
                          ))}
                          </div>
                          {
                            (experts.length < expertsPagination.totalItems) ?
                            <SecondaryButton
                              onClick={() => this.handleExpertsModal(this.state.isExpertsModalOpen, expertsPagination.page + 1)}
                              className={css.loadMore}
                            >
                              <FormattedMessage id="EditListingWizard.loadMore" />
                            </SecondaryButton> : null
                          }
                          <p>
                            <FormattedMessage id="EditListingWizard.sendListingInfo" />
                          </p>
                          <Button type="submit" disabled={submitDisabled}>
                            <FormattedMessage id="EditListingWizard.sendListing" />
                          </Button>
                        </form>
                      );
                    }}
                  />
                </div>
                : <FormattedMessage id="EditListingWizard.noMathcingExperts" />
              }
            </div>
          </Modal> : null
        }
      </div>
    );
  }
}

EditListingWizard.defaultProps = {
  className: null,
  currentUser: null,
  rootClassName: null,
  listing: null,
  updateInProgress: false,
};

EditListingWizard.propTypes = {
  id: string.isRequired,
  className: string,
  currentUser: propTypes.currentUser,
  rootClassName: string,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf([...TABS, EXPERTS, ...LEAD_TABS]).isRequired,
  }).isRequired,


  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  errors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,

  onManageDisableScrolling: func.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  listingType: oneOf([LISTING_TYPES.JOB, LISTING_TYPES.LEAD]).isRequired,
};

export default compose(
  withViewport,
  injectIntl
)(EditListingWizard);
