import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import { parse } from '../../util/urlHelpers';
import config from '../../config';

import moment from "moment-timezone";

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //
export const SEARCH_EXAMPLE_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_EXAMPLE_LISTINGS_REQUEST';
export const SEARCH_EXAMPLE_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_EXAMPLE_LISTINGS_SUCCESS';
export const SEARCH_EXAMPLE_LISTINGS_ERROR = 'app/SearchPage/SEARCH_EXAMPLE_LISTINGS_ERROR';

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  currentExampleResultIds:[],
  searchMapListingsError: null,
  searchExampleListingsError: null
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_EXAMPLE_LISTINGS_REQUEST:
      return {
        ...state,
        searchInProgress: true,
        searchListingsError: null,
      };
    case SEARCH_EXAMPLE_LISTINGS_SUCCESS:
      return {
        ...state,
        currentExampleResultIds: resultIds(payload.data),
        searchInProgress: false,
      };
    case SEARCH_EXAMPLE_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchExampleListingsError: payload
      };
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload
      };
    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //
export const searchExampleListingsRequest = searchParams => ({
  type: SEARCH_EXAMPLE_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchExampleListingsSuccess = response => ({
  type: SEARCH_EXAMPLE_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchExampleListingsError = e => ({
  type: SEARCH_EXAMPLE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  //Deadline-Params
  const listingEndDateUNIX = moment().unix();
  //Deadline-Params

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
        price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
      }
      : {};
  };

  const datesSearchParams = datesParam => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[0] : null;
    const isNightlyBooking = config.bookingUnitType === 'line-item/night';
    const endDate =
      hasValues && isNightlyBooking ? values[1] : hasValues ? getExclusiveEndDate(values[1]) : null;

    return hasValues
      ? {
        start: formatDateStringToUTC(startDate),
        end: formatDateStringToUTC(endDate),
        // Availability can be full or partial. Default value is full.
        availability: 'full',
      }
      : {};
  };

  //Deadline-Filter
  const deadlineSearchParams = (defaultParam, searchParam) => {
    const values = searchParam ? searchParam.split(',') : [];
    const hasValues = searchParam && values.length === 2;
    if (!hasValues) return {pub_listingEndDateUNIX: defaultParam + ','};
    const startDate = (moment(values[0]).unix());
    const endDate = (moment(values[1]).unix());

    return { pub_listingEndDateUNIX: startDate + ',' + endDate};
  }
  //Deadline-Filter

  const { perPage, price, dates, pub_listingEndDateUTC, ...rest } = searchParams;

  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const deadlineFilter = deadlineSearchParams(listingEndDateUNIX, pub_listingEndDateUTC);
  const listingCategoryFilter = {
    pub_listingCategory: 'job'
  };
  const privateJobFilter = {
    pub_jobTypeGroup: 'public'
  };

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...deadlineFilter,
    ...listingCategoryFilter,
    ...privateJobFilter,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .then(()=> {
      dispatch(searchExampleListings());
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const searchExampleListings = () => (dispatch, getState, sdk) => {
  dispatch(searchExampleListingsRequest());

  const exampleFilter = {
    meta_isExample: true
  };

  const params = {
    ...exampleFilter,
    per_page: '8',
    include: ['author','images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData.indoor', 'publicData.outdoor', 'publicData.categories', 'publicData.support', 'publicData.applicationDeadline', 'publicData.applicationDeadlineUNIX', 'publicData.realisationStartDate', 'publicData.realisationEndDate', 'publicData.location', 'publicData.country', 'publicData.postcode', 'publicData.listingCategory', 'metadata' ],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchExampleListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchExampleListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => {

  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': [
      'title',
      'geolocation',
      'price',

      "publicData.dialux",
      'publicData.lightingDesign',
      'publicData.photoWork',
      'publicData.documentation',
      'publicData.others',

      'publicData.listingEndDateUTC',
      'publicData.locationGroup',
      'metadata',
    ],

    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  })
};
