import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  NamedLink,
  SecondaryButton,
} from '../../components';
import { useTranslation } from '../../hooks';
import { IconInfo } from './IconInfo';
import { IconNo } from './IconNo';
import { IconYes } from './IconYes';
import { MoneyBackModal } from './MoneyBackModal';
import css from './Subscription.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { isAuthenticated } from 'passport/lib/http/request';

export const SubscriptionComponent = (props) => {
  const {
    subscription,
    onSaveSubscription,
    onManageSubscription,
    createStripeSubscriptionInProgress,
    createStripeSubscriptionError,
    addressMissing,
    showListingErrorMessage,
    isAuthenticated
  } = props;

  const setProfileTranslation = useTranslation('ProfileSkillsPage');
  const setTranslation = useTranslation('SubscriptionPage');
  const [isMoneyBackModalOpen, setIsMoneyBackModalOpen] = useState(false);
  const language = useSelector((rx) => rx.UI.lang);

  const benefits = [
    {
      title: setProfileTranslation('subscriptionDescription1'),
      tooltip: setProfileTranslation('subscriptionToolTip1'),
      bronze: <IconYes />,
      silver: <IconYes />,
      gold: <IconYes />,
    },
    {
      title: setProfileTranslation('subscriptionDescription2'),
      tooltip: setProfileTranslation('subscriptionToolTip2'),
      bronze: <IconYes />,
      silver: <IconYes />,
      gold: <IconYes />,
    },
    {
      title: setProfileTranslation('subscriptionDescription3'),
      tooltip: setProfileTranslation('subscriptionToolTip3'),
      bronze: <IconNo />,
      silver: <IconYes />,
      gold: <IconYes />,
    },
    {
      title: setProfileTranslation('subscriptionDescription4'),
      tooltip: setProfileTranslation('subscriptionToolTip4'),
      bronze: <IconNo />,
      silver: <IconYes />,
      gold: <IconYes />,
    },
    {
      title: setProfileTranslation('subscriptionDescription5'),
      tooltip: setProfileTranslation('subscriptionToolTip5'),
      bronze: <IconNo />,
      silver: <IconNo />,
      gold: <IconYes />,
    },
    {
      title: setProfileTranslation('subscriptionDescription6'),
      tooltip: setProfileTranslation('subscriptionToolTip6'),
      bronze: <IconNo />,
      silver: <IconNo />,
      gold: <IconYes />,
    },
  ];

  const profileAddressPageLink =
    <NamedLink name={'ProfileSettingsPage'}>
      {setTranslation('SubscriptionPage.gotoAddress')}
    </NamedLink>;

  const createUpgradeButton = (plan) => (
    !!onSaveSubscription && <div className={css.ctaContainer}>
      <SecondaryButton
        disabled={(addressMissing || subscription === plan) || !isAuthenticated}
        inProgress={createStripeSubscriptionInProgress === plan}
        className={classNames(css.ctaButton, css[plan]) }
        onClick={() => onSaveSubscription({ subscription: plan, language })}
      >
        {subscription === plan ? setTranslation('currentPlanStatus') : setTranslation(`${plan}Plan`)}
      </SecondaryButton>
    </div>
  )

  return (
    <>
    <section className={css.subscriptionSection}>

      {showListingErrorMessage && (
        <>
          <p>{setTranslation('descriptionLine1')}</p>
          <p>{setTranslation('descriptionLine2')}</p>
          <p>{setTranslation('descriptionLine3')}</p>
          <h2>{setTranslation('moneyBackHeader')}</h2>
          <p>
            {setTranslation('descriptionLine4', {
              moneyBackGuarantee: (
                <a onClick={() => setIsMoneyBackModalOpen(true)}>
                  {setTranslation('moneyBackGuaranteeLink')}
                </a>
              ),
            })}
          </p>
          <p>
            {setTranslation('manageSubscriptions', {
              here: (
                <a onClick={() => onManageSubscription({ language })}>
                  {setTranslation('manageSubscriptionsLink')}
                </a>
              ),
            })}
          </p>
        </>
      )}
    </section>
      <table className={css.subscriptionTable}>
          <thead className={css.nostyle}>
            <tr>
              <th colSpan={3}></th>
              <th>
                <span className={css.badge}>
                  {setProfileTranslation('subscriptionBadge')}
                </span>
              </th>
            </tr>
          </thead>

          <thead>
            <tr>
              <th></th>
              <th className={css.benefitMaybe}>
                {setTranslation('bronze')}
              </th>
              <th className={css.benefitMaybe}>
                {setTranslation('silver')}
              </th>
              <th className={css.benefitMaybe}>
                {setTranslation('gold')}
              </th>
            </tr>
          </thead>
          <tbody>
            {benefits.map((x, i) => (
              <tr key={i}>
                <td className={css.benefitCaption}>
                  <div className={css.titleWrapper}>
                    <span>{x.title}</span>

                    <div className={css.toolTip}>
                      <i className={css.icon}>
                        <IconInfo />
                      </i>
                      <div className={css.text}>{x.tooltip}</div>
                    </div>
                  </div>
                </td>
                <td className={css.benefitMaybe}>{x.bronze}</td>
                <td className={css.benefitMaybe}>{x.silver}</td>
                <td className={css.benefitMaybe}>{x.gold}</td>
              </tr>
            ))}
          </tbody>

          {!!onSaveSubscription &&
          <tfoot>
            <tr>
              <th></th>
              <th className={css.benefitMaybe}>
                {createUpgradeButton('bronze')}
              </th>
              <th className={css.benefitMaybe}>
                {createUpgradeButton('silver')}
              </th>
              <th className={css.benefitMaybe}>
                {createUpgradeButton('gold')}
              </th>
            </tr>
          </tfoot>
          }

          <tfoot>
            <tr>
              <td className={css.benefitCaption}>{setTranslation('perMonth')}</td>
              <td className={css.benefitMaybe}>{setTranslation('bronzePrice')}</td>
              <td className={css.benefitMaybe}>{setTranslation('silverPrice')}</td>
              <td className={css.benefitMaybe}>{setTranslation('goldPrice')}</td>
            </tr>
          </tfoot>
        </table>

      {createStripeSubscriptionError && (
        <div className={css.error}>
          <FormattedMessage
            id='SubscriptionPage.subscriptionError' values={{ link: profileAddressPageLink }}
          />
        </div>
      )}

      {addressMissing && (
        <div className={css.error}>
          {setTranslation('missingAddressText', {
            link: (
              <NamedLink name={'ProfileSettingsPage'}>{setTranslation('gotoAddress')}</NamedLink>
            ),
          })}
        </div>
      )}

      {isMoneyBackModalOpen && (
        <MoneyBackModal
          isOpen={isMoneyBackModalOpen}
          onClose={() => setIsMoneyBackModalOpen(false)}
        />
      )}

    </>
  );
};

SubscriptionComponent.defaultProps = {
  showListingErrorMessage: true,
};

SubscriptionComponent.propTypes = {
  showListingErrorMessage: PropTypes.bool,
  onSaveSubscription: PropTypes.func,
};

export default SubscriptionComponent;
