import React, { useState } from "react";
import DatePicker from 'react-datepicker';
//import 'react-datepicker/dist/react-datepicker.css';
//import 'react-datepicker/dist/react-datepicker-cssmodules.css';
//import { metaTagProps } from "../../util/seo";
import css from './DatePicker.module.css';

const DatePickerComponent = props => {
  const { input, isValidDate, utc, locale, label, displayTimezone, output} = props;

  return (
    <div>
      <label>{label}</label>
      <DatePicker
        onChange={value => {
          props.input.onChange(value)
        }}
        selected={props.input.value}
      />
      <label>{output}</label>
    </div>
  );
};

export default DatePickerComponent;
