import { FieldTextInput } from '../../components';
import { useTranslation } from '../../hooks';
import css from './ProfileSkillsForm.module.css';

export const SectionBio = () => {
  const setTranslation = useTranslation('ProfileSettingsForm');
  const placeholder = setTranslation('bioPlaceholder');

  return (
    <>
      <div className={css.headline}>
        {setTranslation('bioLabel')}
      </div>
      <div className={css.tip}>
        {setTranslation('informationTextBio')}
      </div>
      <div className={css.bio}>
        <FieldTextInput type='textarea' id='bio' name='bio' placeholder={placeholder}/>
      </div>
    </>
  );
};
