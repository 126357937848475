import React from 'react';

import css from './LocationAutocompleteInput.module.css';

const IconHourGlass = () => (
  <svg
    className={css.iconSvg}
    width="24"
    height="24"
    viewBox="-2 -2 26 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      className={css.iconSvgGroup}
      strokeWidth="1"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="black"
    >
      <path d="M24,22.8l-5.9-5.9c3.4-4,3.2-10.1-0.6-13.9c-2-2-4.6-3-7.3-3C7.7,0,5,1,3,3c-4,4-4,10.5,0,14.5
      c2,2,4.6,3,7.3,3c2.4,0,4.7-0.8,6.7-2.5l5.9,5.9L24,22.8z M4.2,16.4c-1.6-1.6-2.5-3.8-2.5-6.1s0.9-4.4,2.5-6.1
      C5.8,2.6,8,1.7,10.3,1.7c2.3,0,4.4,0.9,6.1,2.5c3.3,3.3,3.3,8.8,0,12.1c-1.6,1.6-3.8,2.5-6.1,2.5C8,18.9,5.8,18,4.2,16.4z" />
    </g>
  </svg>
);

export default IconHourGlass;
