import classNames from 'classnames';
import moment from 'moment-timezone';
import { func, string } from 'prop-types';
import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import { NamedLink, ResponsiveImage } from '../../components';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { ensureListing, ensureUser } from '../../util/data';
import { getStockImageForListing } from '../../util/getStockImageForListing';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { propTypes } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}

const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = (props) => {
  const { className, rootClassName, intl, listing, renderSizes, setActiveListing } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '' } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const publicData = currentListing.attributes.publicData;
  const listingEndDateUTC = publicData.listingEndDateUTC || '';

  const currentUser = useSelector((x) => x.user.currentUser);
  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
  //Timezone and format - listingEndDate
  const format = {
    month: 'short',
    day: 'numeric',
  };

  const LTZ = moment.tz.guess();
  const listingEndDateLTZ = moment.tz(listingEndDateUTC, LTZ).format();

  const formattedApplicationDeadline = intl.formatDate(listingEndDateLTZ, format);

  const applicationDeadlineString = intl.formatMessage(
    { id: 'ListingCard.applicationDeadline' },
    { dates: formattedApplicationDeadline }
  );

  const subscription = currentUser?.attributes?.profile?.metadata?.subscription ?? 'bronze';
  const isPremiumSubscriptionOrOwnListing = subscription === 'gold' || subscription === 'silver' || isOwnListing;
  const stockImageSrc = !isPremiumSubscriptionOrOwnListing && getStockImageForListing(currentListing);

  return (
    <div className={classes}>
      <NamedLink name="ListingPage" params={{ id, slug }}>
        <div
          className={css.imageContainer}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          {isPremiumSubscriptionOrOwnListing ? (
            <LazyImage
              imageContainerClassName={css.listingImage}
              alt={title}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
              sizes={renderSizes}
            />
          ) : (
            <div className={css.listingImage}>
              <img alt={title} src={stockImageSrc} />
            </div>
          )}
        </div>

        <div className={css.infoContainer}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>

          <div className={css.listingEndDate}>{applicationDeadlineString}</div>
        </div>
      </NamedLink>

      <div className={css.infoContainer}>
        <NamedLink
          className={css.contactUser}
          name="ListingPageContact"
          params={{ id, slug, shouldBeContacted: true }}
        >
          <FormattedMessage id="UserCard.contactUser" values={{ authorName }} />
        </NamedLink>
        {isPremiumSubscriptionOrOwnListing && (
          <NamedLink className={css.connectLink} name="ListingPage" params={{ id, slug }}>
            <FormattedMessage id="ListingCard.connectLink" values={{ authorName }} />
          </NamedLink>
        )}
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
