import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconHat.module.css';

const IconHat = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} viewBox="0 0 36 36">
      <path d="M30 13V2H6v11H0v7h36v-7h-6zM8 4h20v9H8V4zm26 14H2v-3h32v3zm-8.5 5c-2.5 0-4.6 1.7-5.3 4h-4.4c-.7-2.3-2.8-4-5.3-4-3 0-5.5 2.5-5.5 5.5S7.5 34 10.5 34c2.9 0 5.2-2.2 5.5-5h4c.2 2.8 2.6 5 5.5 5 3 0 5.5-2.5 5.5-5.5S28.5 23 25.5 23zm-15 9C8.6 32 7 30.4 7 28.5S8.6 25 10.5 25s3.5 1.6 3.5 3.5-1.5 3.5-3.5 3.5zm15 0c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5z"/>

    </svg>
  );
};

IconHat.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconHat.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconHat;
