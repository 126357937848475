import React from 'react';
import { bool, func, object, string, oneOfType } from 'prop-types';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink, ListingLinkUnnamed } from '../../components';
import { EditListingTenderForm } from '../../forms';
import moment from 'moment';

import css from './EditListingTenderPanel.module.css';
import { getDateOrDefault } from '../../util/getDateOrDefault';

const EditListingTenderPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    expertId,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const { publicData, privateData } = currentListing.attributes;
  const invitedExpertsIds = privateData && privateData.invitedExpertsIds;
  const isDirectConnect = expertId || privateData && privateData.isDirectConnect && invitedExpertsIds && invitedExpertsIds.length === 1 ;
  const jobTypeGroup = (publicData && publicData.jobTypeGroup) || (isDirectConnect ? 'private' : 'public');
  const paymentOption = (publicData && publicData.paymentOption) || 'withoutLightshift';

  const listingEndDate = getDateOrDefault(publicData?.listingEndDateUTC, d => d.add(30, 'd'));
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingTenderPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) :
  (
    <FormattedMessage id="EditListingTenderPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingTenderForm
        className={css.form}
        initialValues={{ listingEndDate: {date: listingEndDate}, jobTypeGroup, paymentOption }}
        isDirectConnect={isDirectConnect}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { listingEndDate, jobTypeGroup, paymentOption} = values;
          const listingEndDateUTC = moment(listingEndDate.date).endOf('day').utc().format();
          const listingEndDateUNIX = moment(listingEndDate.date).endOf('day').utc().unix();
          const updateValues = {
            publicData: { listingEndDateUTC, listingEndDateUNIX, jobTypeGroup, paymentOption },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}

      />
    </div>
  );
};

EditListingTenderPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingTenderPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: oneOfType([propTypes.listing, propTypes.ownListing, propTypes.draftListing]),

  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingTenderPanel;
