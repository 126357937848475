import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, NamedLink, FieldSelect, AvatarLarge } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';

import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 1000;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        form,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        isDirectConnect,
        pronounOptions,
        needOptions,
        categoryOptions,
        subCategoryOptions,
        placeOptions,
        setSelectedCategory,
        expertAuthor,
        directConnectInfo,
      } = formRenderProps;


      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      //
      const expertsProfileSettingsLink = (
        <NamedLink
        className={css.inboxLink}
        name="ProfileSkillsPage"
      >
          <FormattedMessage id="EditListingDescriptionForm.profileSkillsPageLink" />
      </NamedLink>
      );
      //

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength1000Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const pronounRequired = required(intl.formatMessage({
        id: 'EditListingDescriptionForm.pronounRequired',
      }))
      const pronounPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.pronounPlaceholder',
      });

      const needRequired = required(intl.formatMessage({
        id: 'EditListingDescriptionForm.needRequired',
      }))
      const needPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.needPlaceholder',
      });

      const titleCategoryRequired = required(intl.formatMessage({
        id: 'EditListingDescriptionForm.titleCategoryRequired',
      }))
      const titleCategoryPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleCategoryPlaceholder',
      });

      const placeRequired = required(intl.formatMessage({
        id: 'EditListingDescriptionForm.placeRequired',
      }))
      const placePlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.placePlaceholder',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      /*
                <div className={css.twoColumns}>
            <FieldRadioButton
              className={css.public}
              id="public"
              name="jobTypeGroup"
              label={publicLabel}
              value="public"
              showAsRequired={showAsRequired}
            />
            <FieldRadioButton
              className={css.private}
              id="private"
              name="jobTypeGroup"
              label={privateLabel}
              value="private"
              showAsRequired={showAsRequired}
              checked={isDirectConnect ? 'checked' : null}
            />
          </div>
      */
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <div className={css.tooltip}>
            <FormattedMessage id="EditListingDescriptionForm.tooltip" values={{ expertsProfileSettingsLink }}/>
          </div>
          {/* <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="textarea"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength1000Message)}
            autoFocus
          /> */}
          <div className={css.title}>
            <FieldSelect
              className={css.pronoun}
              name={'pronoun'}
              id={'pronoun'}
              validate={pronounRequired}
            >
              <option disabled value="">
                {pronounPlaceholder}
              </option>
              {pronounOptions.map(c => (
                <FormattedMessage id={c.label.props.id}>
                  {txt => <option value={c.key}>{txt}</option>}
                </FormattedMessage>
              ))}
            </FieldSelect>
            <FieldSelect
              className={css.need}
              name={'need'}
              id={'need'}
              validate={needRequired}
            >
              <option disabled value="">
                {needPlaceholder}
              </option>
              {needOptions.map(c => (
                <FormattedMessage id={c.label.props.id}>
                  {txt => <option value={c.key}>{txt}</option>}
                </FormattedMessage>
              ))}
            </FieldSelect>
            <FieldSelect
              className={css.titleCategory}
              name={'titleCategory'}
              id={'titleCategory'}
              validate={titleCategoryRequired}
            >
              <option disabled value="">
                {titleCategoryPlaceholder}
              </option>
              {categoryOptions.map(c =>
                <option value={c.key}>{c.label}</option>
              )}
            </FieldSelect>
            <div className={css.for}>
              <FormattedMessage id="EditListingDescriptionForm.for" />
            </div>
            <FieldSelect
              className={css.place}
              name={'place'}
              id={'place'}
              validate={placeRequired}
            >
              <option disabled value="">
                {placePlaceholder}
              </option>
              {placeOptions.map(c => (
                <option value={c.key}>{c.label}</option>
              ))}
            </FieldSelect>
          </div>
          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />
          {
            isDirectConnect && expertAuthor &&
            <div className={css.expert}>
              <AvatarLarge className={css.avatar} user={expertAuthor}/>
              {directConnectInfo}
            </div>
          }
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
