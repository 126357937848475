export class LoggingAnalyticsHandler {
  trackPageView(url) {
    console.debug('Analytics page view:', url);
  }
}

export class GoogleAnalyticsHandler {
  constructor() {
  }
  trackPageView(url) {
    if (window.gtag) {
      window.setTimeout(() => {
        window.gtag('event', 'page_view', {
          page_path: url,
        });
      }, 300);
    }
  }
}
