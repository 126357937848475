import classNames from 'classnames';
import { arrayOf, object, string } from 'prop-types';

//const { arrayOf, bool, node, object, string } = PropTypes;
import React from 'react';
import { NamedLink } from '../../components';

import css from './TabNav.module.css';

const Tab = (props) => {
  const { className, id, disabled, text, selected, hidden, linkProps, subTabs, lang } = props;
  const linkClasses = classNames(css.link, {
    [css.selectedLink]: selected,
    [css.disabled]: disabled,
    [css.hidden]: hidden,
  });

  if (!linkProps.lang) {
    linkProps.lang = lang;
  }

  return (
    <div id={id} className={classNames(className, css.tab)}>

        <NamedLink className={linkClasses} {...linkProps}>
          {text}
        </NamedLink>
        {subTabs?.map((tab) => (
          <SubTab key={tab.id} tab={tab} />
        ))}

    </div>
  );
};

const SubTab = ({ tab }) => {
  const tabClassNames = classNames(css.linkSubTab, {
    [css.selectedLink]: !!tab.selected,
    [css.disabled]: !!tab.disabled,
    [css.hidden]: !!tab.hidden,
  });

  return (
    <NamedLink id={tab.id} key={tab.id} className={tabClassNames} {...tab.linkProps}>
      {tab.text}
    </NamedLink>
  );
};

const TabNav = (props) => {
  const { className, rootClassName, tabRootClassName, tabs, lang } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;
  return (
    <nav className={classes}>
      {tabs.map((tab, index) => {
        const id = typeof tab.id === 'string' ? tab.id : `${index}`;
        return <Tab key={id} id={id} className={tabClasses} {...tab} lang={lang} />;
      })}
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
};

export default TabNav;
