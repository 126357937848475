import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPin.module.css';

const IconPin = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="31px" height="45px" viewBox="0 0 31 45" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <path d="M0 0L30.6 0L30.6 45L0 45L0 0Z" id="path_1" />
    <clipPath id="mask_1">
      <use href="#path_1" />
    </clipPath>
  </defs>
  <g id="Tag-3">
    <path d="M0 0L30.6 0L30.6 45L0 45L0 0Z" id="Background" fill="none" fillRule="evenodd" stroke="none" />
    <g clipPath="url(#mask_1)">
      <path d="M18.8 18.8C20.8 16.8 20.8 13.7 18.8 11.7C16.8 9.7 13.7 9.7 11.7 11.7C9.7 13.7 9.7 16.8 11.7 18.8C13.7 20.8 16.9 20.8 18.8 18.8L18.8 18.8ZM15.3 2C22.6 2 28.6 8 28.6 15.3C28.6 17.1 28.2 18.8 27.6 20.5C27.5 20.9 27.3 21.2 27.1 21.5L27.1 21.5L15.3 41.1L3.5 21.4C3.4 21.1 3.2 20.8 3.1 20.5C2.4 18.9 2.1 17.1 2.1 15.3C2 8 8 2 15.3 2M15.3 0C6.9 0 0 6.9 0 15.3C0 17.4 0.4 19.4 1.2 21.3C1.4 21.7 1.5 22.1 1.7 22.4L15.3 45L28.8 22.5L28.9 22.4C29.1 22 29.3 21.6 29.4 21.3C30.2 19.4 30.6 17.4 30.6 15.3C30.6 6.9 23.7 0 15.3 0L15.3 0L15.3 0Z"
      id="Shape"
      fill="#000000"
      fillRule="evenodd"
      stroke="none" />
    </g>
  </g>
</svg>
  );
};

IconPin.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconPin.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconPin;
