import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, Button, FieldCheckboxGroup } from '../../components';

import css from './EditListingSupportCategoriesForm.module.css';

const required = value => {
  // Intl Message checkbox required Message
  const errors = {}
  if (value?.categories?.length===0)
    {
      errors.emptyForm = 'EditListingSupportCategoriesForm.categoriesRequired'
    }
  return errors
}

export const EditListingSupportCategoriesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    validate={required}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        touched,
      } = formRenderProps;


      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingSupportCategoriesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingSupportCategoriesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || formRenderProps.errors.invalid;
      const isAnyTouched = Object.values(touched).includes(true);
      const messageEmptyForm = (formRenderProps.errors.emptyForm && isAnyTouched) ? intl.formatMessage({id: formRenderProps.errors.emptyForm}): <span>&nbsp;</span>;

      const categoriesOptions = findOptionsForSelectFilter('categories', filterConfig);

      const tip = intl.formatMessage({
        id: 'EditListingSupportCategoriesForm.tip',
      });

      return (
        <Form
          className={classes}
          onSubmit={handleSubmit}
          >
          {errorMessage}
          {errorMessageShowListing}
            <div>
              <p className={css.tooltip}>{tip}</p>
              <FieldCheckboxGroup
                setFocus={true}
                className={css.policy}
                id="categories"
                name='categories'
                options={categoriesOptions}
                label=''
              />
            </div>
            <div className={css.error}>
              {messageEmptyForm}
            </div>

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
        </Form>
      );
    }}
  />
);

EditListingSupportCategoriesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
  filterConfig: config.manufacturerConfig.servicePortfolio,
};

EditListingSupportCategoriesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingSupportCategoriesFormComponent);
