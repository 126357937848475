import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, IconEnquiry, IconQuestionmark, SecondaryButton, IconSpinner, IconClose } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import css from './EnquiryForm.module.css';
const ACCEPT_PDF = 'pdf/*';
const MAX_DOCUMENTS = 2;
const dateFormatOptions = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

const EnquiryFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        form,
        formId,
        handleSubmit,
        inProgress,
        intl,
        authorDisplayName,
        sendEnquiryError,
        enquireFormQA,
        isLeadEnquiry,
        informationText,
        tip,
        heading,
        canUploadAttachments,
        onFileUploadHandler,
        onFileRemove,
        uploadInProgress,
        documents,
      } = fieldRenderProps;

      const messageLabel = intl.formatMessage(
        {
          id: 'EnquiryForm.messageLabel',
        },
        { authorDisplayName }
      );
      const messagePlaceholder = intl.formatMessage(
        {
          id: 'EnquiryForm.messagePlaceholder',
        },
        { authorDisplayName }
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'EnquiryForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const documentsLabel =intl.formatMessage({
        id: 'OfferForm.documentsLabel',
      });

      const documentsTip =intl.formatMessage({
        id: 'OfferForm.documentsTip',
      });

      const chooseFile = intl.formatMessage({
        id: 'OfferForm.chooseFile',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          <IconQuestionmark className={css.icon} />
          <h2 className={css.heading}>
          { heading ? heading : <FormattedMessage id="EnquiryForm.heading" /> }
          </h2>
          <div className={css.tip}>
            { tip ? tip : <FormattedMessage id="EnquiryForm.tip" /> }
          </div>
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            label={messageLabel}
            placeholder={messagePlaceholder}
            validate={messageRequired}
          />
          {
            canUploadAttachments ?
            <div className={css.documentsWrapper}>
              <div className={css.documentLabel}>
                {documentsLabel}
              </div>
              <div className={css.fileUploadWrapper}>
                <Field
                  id="documents"
                  name="documents"
                  accept={ACCEPT_PDF}
                  label={chooseFile}
                  type="file"
                  form={null}
                  fieldDisabled={uploadInProgress}
                >
                  {fieldprops => {
                    const { accept, input, label, fieldDisabled } = fieldprops;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      onFileUploadHandler(e, form, file, 'documents');
                    };
                    const inputProps = { accept, id: name, name, onChange, type };
                    return (
                      <div className={css.uploadFieldWrapper}>
                        <div className={css.aspectRatioWrapper}>
                          {fieldDisabled ? null : (
                            <input {...inputProps} className={css.uploadInput} />
                          )}
                          <label htmlFor={name} className={fieldDisabled ? css.addFileDisabled : css.addFile}>
                            { uploadInProgress ? <IconSpinner/> : label}
                          </label>
                          <div className={css.documentTip}>
                          {documentsTip}
                        </div>
                        </div>
                      </div>
                    );
                  }}
                </Field>

              </div>

              {documents?.length > 0 ? (
                <div className={css.documentsContainer}>
                  <h2>
                    <FormattedMessage id='OfferForm.documents' />
                  </h2>
                  {
                    documents?.length > 0 ?
                      <div className={css.uploadedDocuments}>
                        {documents.map((t, index) => {
                          return (
                            <div>
                              <a
                                key={t.public_id}
                                target='_blank'
                                href={t?.secure_url}
                              >{t.original_filename}</a>
                              <div className={css.date}>{intl.formatDate(t.created_at, dateFormatOptions)}</div>
                              <div className={css.removeItem} onClick={onFileRemove('documents', t.public_id)}>
                                <IconClose size='normal' className={css.removeIcon} />
                              </div>
                            </div>
                          );
                        })}
                      </div> : null
                  }
                </div>
              ) : null}
            </div> : null
          }
          <div className={submitButtonWrapperClassName}>
            {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="EnquiryForm.sendEnquiryError" />
              </p>
            ) : null}
            <div className={css.informationText}>
            {
              informationText
                ? informationText :
                  !isLeadEnquiry
                    ? <FormattedMessage id="EnquiryForm.informationText" />
                    : null
            }
            </div>

            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled} className={css.sendMessage}>
              <FormattedMessage id="EnquiryForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

EnquiryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

EnquiryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,
  inProgress: bool,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EnquiryForm = compose(injectIntl)(EnquiryFormComponent);

EnquiryForm.displayName = 'EnquiryForm';

export default EnquiryForm;
