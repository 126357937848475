/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createReducer from './reducers';
import * as analytics from './analytics/analytics';
import config from './config';

/**
 * Create a new store with the given initial state. Adds Redux
 * middleware and enhancers.
 * Used for client-side store creation with persistance layer
 */
export default function configureStore(initialState = {}, sdk = null, analyticsHandlers = []) {
  const rootReducer = createReducer();
  const store = createCustomStore(rootReducer, initialState, sdk, analyticsHandlers);
  return { store};
}

export function createCustomStore(rootReducer, initialState = {}, sdk = null, analyticsHandlers = []) {
  const middlewares = [thunk.withExtraArgument(sdk), analytics.createMiddleware(analyticsHandlers)];

  // Enable Redux Devtools in client side dev mode.
  const composeEnhancers =
    config.dev && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  return createStore(rootReducer, initialState, enhancer);
}
