import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCircleNine.module.css';

const IconCircleNine = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.5 0C7.8 0 0 7.8 0 17.5S7.8 35 17.5 35 35 27.2 35 17.5 27.2 0 17.5 0zm0 32C9.5 32 3 25.5 3 17.5S9.5 3 17.5 3 32 9.5 32 17.5 25.5 32 17.5 32zm0-22.5c-1 0-1.9.2-2.7.7s-1.4 1.1-1.8 2c-.4.8-.6 1.8-.6 2.8 0 1.6.4 2.8 1.2 3.8s1.9 1.4 3.3 1.4c1.2 0 2.2-.5 3.1-1.4-.1 1.5-.6 2.6-1.4 3.3s-2 1.1-3.5 1.1h-.4v2.2h.3c2.5 0 4.4-.8 5.7-2.3s1.9-3.6 1.9-6.5V16c0-2-.5-3.5-1.4-4.7S19 9.5 17.5 9.5zm2.5 7c-.3.5-.6.9-1.1 1.2s-.9.5-1.5.5c-.8 0-1.4-.3-1.8-.9s-.6-1.4-.6-2.4c0-.9.2-1.7.7-2.3.4-.6 1-.9 1.8-.9s1.4.4 1.8 1.1.7 1.6.7 2.8v.9z"/>
    </svg>
  );
};

IconCircleNine.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCircleNine.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCircleNine;
