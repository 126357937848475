/*
  A component to format the Address saved in the profile data
*/

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import css from './Address.module.css';

const Address = props => {
    const { address, company, country, phonenumber, emailadr } = props;
    const hasStreet = !!address.street;
    const hasCity = !!address.city;
    const hasPhone = !!phonenumber;
    const hasEmail = !!emailadr;
    const streetWithoutNumber = hasStreet ? address.street : "";
    const houseNumber = address.houseNumber ? address.houseNumber : "";
    const postalCode = address.postalCode ? address.postalCode : "";
    const cityOnly = address.city ? address.city : "";

    if (hasStreet || hasCity || hasPhone || hasEmail){
        const street = hasStreet ? (streetWithoutNumber + " " + houseNumber) : null;
        const city = hasCity ? (postalCode + " " + cityOnly) : null;
        const phone = hasPhone ? <FormattedMessage id="Address.phone" values={{phonenumber: phonenumber}} /> : null;
        const email = hasEmail ? <FormattedMessage id="Address.email" values={{emailadr: emailadr}}/> : null;
        return(
            <div>
                <h2 className={css.sectionHeading}>
                    <FormattedMessage id="ListingPage.address" />
                </h2>
                <p className={css.address}>{company}</p>
                <p className={css.address}>{street}</p>
                <p className={css.address}>{city}</p>
                <p className={css.address}>{country}</p>
                <p className={css.address}>{phone}</p>
                <p className={css.address}>{email}</p>                
            </div>
        );
    }
    return null;
};

Address.defaultProps = {
    address: [],
    company: null,
    country: null,
    phonenumber: null,
    emailadr: null,
    hasStreet: false,
    hasCity: false,
    hasPhone:false,
    hasEmail:false,
    streetWithoutNumber: "",
    houseNumber: "",
    postalCode: "",
    cityOnly: "",
};

const { object, bool, string } = PropTypes;

Address.propTypes = {
    address: object,
    company: string,
    country: string,
    emailadr: string,
    hasStreet: bool,
    hasCity: bool,
    hasPhone:bool,
    hasEmail:bool,
    streetWithoutNumber: string,
    houseNumber: string,
    postalCode: string,
    cityOnly: string,
};

export default Address;