import React, { Component, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Form as FinalForm, FormSpy, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, Form } from '../../components';
import { saveListingDate } from '../../containers/EditListingPage/EditListingPage.duck';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker'
import DatePickerComponent from '../../components/DatePickerComponent/DatePickerComponent'

//import Datetime from 'react-datetime';
//import "react-datetime/css/react-datetime.css";
import moment from "moment-timezone";
//import moment from "moment";
import 'moment-timezone';
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/es";
import "moment/locale/it";

import css from './EditListingDeliveryDateForm.module.css';

class EditListingDeliveryDateFormComponent extends Component {
  constructor(props){
    super(props);

    this.submittedValues = {};

  }


render() {
    return (
  <FinalForm
    {...this.props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        intl,
        changeListingDate,
        lang,
        defaultTimezone,
        deliveryDate,
        timezone,
        values,
      } = formRenderProps;


      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const required = true;
      const showAsRequired = pristine && required;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDeliveryDateForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDeliveryDateForm.showListingFailed" />
        </p>
      ) : null;

      //const deliveryDateOptions = findOptionsForSelectFilter('deliveryDate', filterConfig);

      // Intl Message delivery date radibuttons
      const deliveryDateLabel = intl.formatMessage({
        id: 'EditListingDeliveryDateForm.deliveryDateLabel',
      });

      const [selectedTimezone, setSelectedTimezone] = useState(defaultTimezone);
      const [selectedDateTime, setSelectedDateTime] = useState('');

     /* const onChangeEndDate =
        endDate => {
          setSelectedDateTime(endDate);
          const endDateUTC = moment.utc(endDate).format();
          //const listingStartDate = new Date();
          //const listingStartDateTimezone = moment.tz(listingStartDate, selectedTimezone).format();
          //const listingStartDateUTC = moment.utc(listingStartDateTimezone).format();
          changeListingDate({endDateUTC});
          };
*/
      //const [selectedTimezone, setSelectedTimezone] = useState('Europe/Berlin')

      var tomorrow = moment().add(1, "day");
      function valid(current) {
        return current.isAfter(tomorrow);
      }
/*
      const DateTimePicker =
        <Datetime
        input={false}
        isValidDate={valid}
        utc={false}
        locale={lang}
        onChange={EndDateTime => onChangeListingEndDate(EndDateTime)}
        value={selectedDateTime}
        displayTimeZone={selectedTimezone}
      />;
      // displayTimeZone={selectedTimezone}
      //        utc={false}
      //        locale={lang}
*/
      /*
                <TimezoneSelect
          value={selectedTimezone}
          onChange={timezone => {
            setSelectedTimezone(timezone.value);
          }}

        />
      */


       /*const onChangeLocationGroup =
        formState => {
          setSelectedTimezone(formState.values.timezone);
          setSelectedDateTime(formState.values.endDate)
          changeListingDate({selectedTimezone, selectedDateTime});
          };*/

        const errors = {}
        const validateDateTime = value => {
        if (value === undefined){
          errors.endDate = 'Please select a date and time'
        }
        return errors
        }

        const validateTimezone = value => {
          // Intl Message checkbox required Message
            if(value.value===null){
              errors.timezone = 'Please select a timezone'
            }
          return errors
        }

      return (
        <Form
        className={classes}
        onSubmit={handleSubmit}
        >
          {errorMessage}
          {errorMessageShowListing}

          <Field
          name="endDate"
          output={deliveryDate}
          component={DateTimePicker}
          label='Beispiel Label'
          isValidDate={valid}
          utc={false}
          locale={lang}
          validate={validateDateTime}
          displayTimezone={selectedTimezone}
          />
          <Field
          name="Testdatum"
          component={DatePickerComponent}
          />
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
          {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);
}
}

/*<DateTimePicker
input={false}
isValidDate={valid}
utc={true}
locale={lang}
label='Beispiel Label'
value={selectedDateTime}
/>
*/
EditListingDeliveryDateFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
  deliveryDate: null,
};

EditListingDeliveryDateFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,

  // from injectIntl
  intl: intlShape.isRequired,
};


const mapStateToProps = state => {
  const {
    endDate,
  } = state.EditListingPage;
    const lang = state.UI.lang;
  return {
    endDate,
    lang,
  };
};

const mapDispatchToProps = dispatch => ({
  changeListingDate: e => dispatch(saveListingDate(e)),
});

const EditListingDeliveryDateForm = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl)
  (EditListingDeliveryDateFormComponent);

EditListingDeliveryDateForm.displayName = 'EditListingDeliveryDateForm';

export default EditListingDeliveryDateForm;
