import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCircleTwo.module.css';

const IconCircleTwo = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.5 0C7.8 0 0 7.8 0 17.5S7.8 35 17.5 35 35 27.2 35 17.5 27.2 0 17.5 0zm0 32C9.5 32 3 25.5 3 17.5S9.5 3 17.5 3 32 9.5 32 17.5 25.5 32 17.5 32zm2-12.7a20.14 20.14 0 0 0 2.2-2.9c.4-.9.7-1.7.7-2.5 0-1.3-.4-2.4-1.3-3.2S19 9.6 17.5 9.6c-1.1 0-2 .2-2.8.6s-1.4 1-1.9 1.8c-.4.8-.7 1.6-.7 2.6h2.6c0-.9.2-1.6.7-2.1s1.1-.8 2-.8c.7 0 1.3.2 1.7.7s.6 1.1.6 1.8c0 .6-.2 1.1-.5 1.7s-.9 1.3-1.6 2.1l-5.2 5.7v1.8h10.5v-2.1h-7.3l3.9-4.1z"/>
    </svg>
  );
};

IconCircleTwo.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCircleTwo.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCircleTwo;
