import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  TRANSITION_SEND_MESSAGE_BY_PROVIDER,
  TRANSITION_SEND_MESSAGE_BY_CUSTOMER,
  nextPossibleSendMessageTransition,
  TRANSITION_ENQUIRE,
  TRANSITION_DELETE_CUSTOMER_ENQUIRY,
  TRANSITION_DELETE_PROVIDER_ENQUIRY,
  transitionsToRequested,
  transitionsToRequestedFromClient,
} from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  sendMessage,
} from '../../util/api';
import _ from "lodash";

const INBOX_PAGE_SIZE = 10;
const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const CLEAR_INTERACTIONS = 'app/InboxPage/CLEAR_INTERACTIONS';
export const FETCH_TRANSACTIONS_REQUEST = 'app/MessagesPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/MessagesPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/MessagesPage/FETCH_TRANSACTIONS_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchTransactionsInProgress: false,
  fetchTransactionsError: null,
  transactionRefs: [],
  transactionsPagination: null,
};

export default function messagesPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_INTERACTIONS:
      return { ...state, transactionRefs: [], transactionsPagination: null  };

    case FETCH_TRANSACTIONS_REQUEST:
      return { ...state, fetchTransactionsInProgress: true, fetchTransactionsError: null};

    case FETCH_TRANSACTIONS_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data || payload.data);
      return {
        ...state,
        fetchTransactionsInProgress: false,
        transactionRefs: _.uniqBy(
          [ ...state.transactionRefs , ...entityRefs(transactions)],
          function (e) {
            return e.id.uuid;
          }
        ),
        transactionsPagination: payload.meta,
      };
    }
    case FETCH_TRANSACTIONS_ERROR:
      return { ...state, fetchTransactionsInProgress: false, fetchTransactionsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchTransactionsRequest = () => ({ type: FETCH_TRANSACTIONS_REQUEST });
const fetchTransactionsSuccess = (response) => ({ type: FETCH_TRANSACTIONS_SUCCESS, payload: response, });
const fetchTransactionsError = e => ({ type: FETCH_TRANSACTIONS_ERROR, error: true, payload: e });

export const clearInteractions = () => ({
  type: CLEAR_INTERACTIONS
});

// ================ Thunks ================ //

export const transitionTransaction = params => async (dispatch, getState, sdk) => {
  const { transactionId, transition} = params;
  const cancelReason = params.cancelReason;
  const transitionParams = cancelReason ? {protectedData: {cancelReason: cancelReason}} : {}
  try {
    const res = await sdk.transactions.transition(
      {
        id: transactionId,
        transition: transition,
        params: transitionParams,
      },
      {
        expand: true,
      }
    );

    dispatch(addMarketplaceEntities(res));
  } catch (e) {
    console.error(e);
  }
};
export const loadEnquiries = (params, search) => (dispatch, getState, sdk) => {
  //const lastTransitions = [TRANSITION_ENQUIRE, TRANSITION_SEND_MESSAGE_BY_CUSTOMER,
  //  TRANSITION_SEND_MESSAGE_BY_PROVIDER, TRANSITION_DELETE_CUSTOMER_ENQUIRY,
  //  TRANSITION_DELETE_PROVIDER_ENQUIRY]

  const lastTransitions = [...transitionsToRequested, ...transitionsToRequestedFromClient]

  const queryParams = parse(search);
  const page = queryParams.page || 1;
  dispatch(clearInteractions());
  dispatch(fetchTransactionsRequest());

  const apiQueryParams = {
    lastTransitions: lastTransitions,
    include: ['provider', 'customer', 'booking', 'messages', 'messages.sender', 'listing'],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    // 'limit.messages': 1,
    // 'sort.messages': 'createdAt',
    // 'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };
  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionsError(storableError(e)));
      throw e;
    });;
};

export const deleteMessage = (transactionId, transition, params, search) => async (dispatch, getState, sdk) => {
    try {
      const transaction = await dispatch(transitionTransaction({transactionId: transactionId, transition: transition}))
      return Promise.all([
        dispatch(loadEnquiries(params, search)),
        dispatch(fetchCurrentUser()),
      ])
    } catch (e) {
      console.error(e);
    }
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  return Promise.all([
    dispatch(loadEnquiries(params, search)),
    dispatch(fetchCurrentUser()),
  ])
};
