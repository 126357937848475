import { STOCK_IMAGES } from './constants';

export const getStockImageForListing = (listing) => {
  const { publicData } = listing.attributes || {};
  const seed = parseInt(listing?.id?.uuid?.split(/-/g)[3] ?? 0, 16);

  const getCategoriesImage = (category) => {
    const idx = seed % STOCK_IMAGES[category].length;
    return STOCK_IMAGES[category][idx];
  };

  if (publicData?.dialux) {
    return getCategoriesImage('dialux');
  } else if (publicData?.lightingDesign) {
    return getCategoriesImage('lightingDesign');
  } else if (publicData?.photoWork) {
    return getCategoriesImage('photoWork');
  } else if (publicData?.documentation) {
    return getCategoriesImage('documentation');
  } else if (publicData?.others) {
    return getCategoriesImage('others');
  } else {
    return getCategoriesImage('others');
  }
};
