import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconKeysSuccess.module.css';

const IconKeysSuccess = props => {
  const { className } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" >
    <path   fill="#2ecc71" d="M39.24 30.76l-5.65 5.65-2.83-2.82L29.34 35l2.83 2.83 1.42 1.41 7.07-7.07zM35 25c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18a8 8 0 1 1 0-16 8 8 0 1 1 0 16z" />
    <circle fill="#000" cx="29.41" cy="15.59" r="3.08"/>
    <path   fill="#000" d="M35.94 9.06c-5.42-5.41-14.21-5.4-19.62.03-3.26 3.27-4.68 7.95-3.78 12.48L0 34.11l1.09 9.8 9.8 1.09v-4.36l4.43.07-.07-4.42 4.4.05v-4.41h2a14.73 14.73 0 0 0 1.73.47c.15-.65.35-1.29.6-1.91a11.76 11.76 0 0 1-1.76-.49l-.32-.11h-4.32v2 2.36h-2.36-2.06v2.06 2.36h-2.37-2v4.16l-6-.67-.81-7.26L14 23l.75-.76-.2-1c-1.3-6.42 2.86-12.68 9.28-13.97s12.68 2.86 13.97 9.28c.45 2.24.25 4.57-.59 6.69.66.12 1.3.3 1.93.53 1.93-5.11.67-10.87-3.2-14.71z"/>
    </svg>
  );
};

IconKeysSuccess.defaultProps = { className: null };

const { string } = PropTypes;

IconKeysSuccess.propTypes = {
  className: string,
};

export default IconKeysSuccess;
