import React, { useEffect, useState } from 'react';
import { string, oneOfType, bool } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  ensureListing,
  listingDisplayNameAsString,
  listingAbbreviatedName, fetchFullName, ensureCurrentUser, ensureUser,
} from '../../util/data';
import { ResponsiveImage, IconBannedUser, NamedLink } from '../../components/';

import css from './ExpertAvatar.module.css';

// Responsive image sizes hint
const AVATAR_SIZES = '40px';
const AVATAR_SIZES_MEDIUM = '60px';
const AVATAR_SIZES_LARGE = '96px';

const AVATAR_IMAGE_VARIANTS = [

  // 40x40
  'square-xsmall',

  // 80x80
  'square-xsmall2x',

  // 240x240
  'square-small',

  // 480x480
  'square-small2x',
];

export const ExpertAvatarComponent = props => {
  const { rootClassName, className, author, renderSizes, fullName, disableProfileLink, intl } = props;

  const userIsCurrentUser = author && author.type === 'currentUser';
  const avatarUser = userIsCurrentUser ? ensureCurrentUser(author) : ensureUser(author);
  const displayName = avatarUser.attributes.profile.displayName;
  const defaultUserAbbreviatedName = displayName.match(/(^\S\S?|\s\S)?/g).map(v=>v.trim()).join("").match(/(^\S|\S$)?/g).join("").toLocaleUpperCase();
  const altText = fullName ? fullName : displayName;
  const classes = classNames(rootClassName || css.root, className);
  const rootProps = { className: classes, title: altText };

  const hasProfileImage = author.profileImage && author.profileImage.id ? true : false;

  if (hasProfileImage) {
    return (
      <div {...rootProps}>
        <ResponsiveImage
          rootClassName={css.avatarImage}
          alt={altText}
          image={avatarUser.profileImage}
          variants={AVATAR_IMAGE_VARIANTS}
          sizes={renderSizes}
        />
      </div>
    );
  } else {
    // Placeholder avatar (initials)
    return (
      <div {...rootProps}>
        <span className={css.initials}>{defaultUserAbbreviatedName}</span>
      </div>
    );
  }
};

ExpertAvatarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  user: null,
  renderSizes: AVATAR_SIZES,
  disableProfileLink: false,
};

ExpertAvatarComponent.propTypes = {
  rootClassName: string,
  className: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),

  renderSizes: string,
  disableProfileLink: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ExpertAvatar = injectIntl(ExpertAvatarComponent);

export default ExpertAvatar;

export const ExpertAvatarMedium = props => (
  <ExpertAvatar rootClassName={css.mediumAvatar} renderSizes={AVATAR_SIZES_MEDIUM} {...props} />
);
ExpertAvatarMedium.displayName = 'AvatarMedium';

export const ExpertAvatarLarge = props => (
  <ExpertAvatar rootClassName={css.largeAvatar} renderSizes={AVATAR_SIZES_LARGE} {...props} />
);
ExpertAvatarLarge.displayName = 'AvatarLarge';
