import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import {NamedLink, ExternalLink} from '../../components';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  const pricelistLink = (
    <NamedLink
    className={css.inboxLink}
    name="PriceListPage"
  >
      <FormattedMessage id="TermsOfService.pricelistLink" />
  </NamedLink>
  );

  const dataPrivacyPolicyLink = (
    <NamedLink
    className={css.inboxLink}
    name="PrivacyPolicyPage"
  >
      <FormattedMessage id="TermsOfService.dataPolicyLink" />
  </NamedLink>
  );

  const stripeConnectedAccountTermsLink = (
    <ExternalLink href="https://stripe.com/connect-account/legal" className={css.termsLink}>
      <FormattedMessage id=  "TermsOfService.StripeAGB"
      />
    </ExternalLink>
  );


  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}><FormattedMessage id="TermsOfService.lastUpdated" /></p>

      <h2><FormattedMessage id="TermsOfService.headline1" /></h2>
      <ol start="1" className={css.list}>
      <li className={css.listElement}>
        <FormattedMessage id="TermsOfService.GeneralRules_1" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="TermsOfService.GeneralRules_2" />
      </li>
      <li className={css.listElement}>
        <FormattedMessage id="TermsOfService.GeneralRules_3" />
      </li>
      </ol>

      <h2><FormattedMessage id="TermsOfService.headline2" /></h2>
      <ol start="1" className={css.list}>
      <li className={css.listElement}>
      <div>
        <FormattedMessage id="TermsOfService.Benefits_1" />
      </div>
      </li>
      <li className={css.listElement}>
      <div>
        <FormattedMessage id="TermsOfService.Benefits_2" />
      </div>
        <ol type="a" className={css.list}>
          <li className={css.listElement}>
            <div>
              <FormattedMessage id="TermsOfService.Benefits_2_1" />
            </div>
          </li>
          <li className={css.listElement}>
            <div>
              <FormattedMessage id="TermsOfService.Benefits_2_2" />
            </div>
          </li>
          <li className={css.listElement}>
            <div>
              <FormattedMessage id="TermsOfService.Benefits_2_3" />
            </div>
          </li>
        </ol>
      </li>
      <li className={css.listElement}>
        <div>
          <FormattedMessage id="TermsOfService.Benefits_3" />
        </div>
      </li>
      </ol>

      <h2><FormattedMessage id="TermsOfService.headline3" /></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Registration_1" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Registration_2"
            values={{ pricelistLink }} />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Registration_3"
            values={{ pricelistLink }} />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Registration_4" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Registration_5" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Registration_6" />
          </div>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfService.headline4" /></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Conclusion_1" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Conclusion_2" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Conclusion_3" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Conclusion_4" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Conclusion_5" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Conclusion_6" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Conclusion_7"
            values={{pricelistLink}} />
          </div>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfService.headline5" /></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.UserObligation_1" />
          </div>
          <ol type="a" className={css.list}>
            <li className={css.listElement}>
              <div>
                <FormattedMessage id="TermsOfService.UserObligation_1_1" />
              </div>
            </li>
            <li className={css.listElement}>
              <div>
                <FormattedMessage id="TermsOfService.UserObligation_1_2" />
              </div>
            </li>
          </ol>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.UserObligation_2" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.UserObligation_3" />
          </div>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfService.headline6" /></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.PlatformContracts_1" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.PlatformContracts_2" />
          </div>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfService.headline7" /></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.LiabilityLightshift_1" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.LiabilityLightshift_2" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.LiabilityLightshift_3" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.LiabilityLightshift_4" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.LiabilityLightshift_5" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.LiabilityLightshift_6" />
          </div>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfService.headline8" /></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.ExternalContent_1" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.ExternalContent_2" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.ExternalContent_3" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.ExternalContent_4" />
          </div>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfService.headline9" /></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.OtherObligationsUser_1" />
          </div>
          <ol type="a" className={css.list}>
            <li className={css.listElement}>
              <div>
                <FormattedMessage id="TermsOfService.OtherObligationsUser_1_1" />
              </div>
            </li>
            <li className={css.listElement}>
              <div>
                <FormattedMessage id="TermsOfService.OtherObligationsUser_1_2" />
              </div>
            </li>
            <li className={css.listElement}>
              <div>
                <FormattedMessage id="TermsOfService.OtherObligationsUser_1_3" />
              </div>
            </li>
            <li className={css.listElement}>
              <div>
                <FormattedMessage id="TermsOfService.OtherObligationsUser_1_4" />
              </div>
            </li>
          </ol>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.OtherObligationsUser_2" />
          </div>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfService.headline10" /></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.DataPprocessing_1" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.DataPprocessing_2" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.DataPprocessing_3" />
          </div>
          <ol type="a" className={css.list}>
            <li className={css.listElement}>
              <div>
                <FormattedMessage id="TermsOfService.DataPprocessing_3_1" />
              </div>
            </li>
            <li className={css.listElement}>
              <div>
                <FormattedMessage id="TermsOfService.DataPprocessing_3_2" />
              </div>
            </li>
            <li className={css.listElement}>
              <div>
                <FormattedMessage id="TermsOfService.DataPprocessing_3_3" />
              </div>
            </li>
          </ol>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.DataPprocessing_4"
            values={{ dataPrivacyPolicyLink }} />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.DataPprocessing_5"
            values={{ dataPrivacyPolicyLink }} />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.DataPprocessing_6" />
          </div>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfService.headline11" /></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Payment_1"
            values={{stripeConnectedAccountTermsLink}} />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Payment_2" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Payment_3"
            values={{pricelistLink}} />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Payment_4" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.Payment_5" />
          </div>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfService.headline12" /></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.AssignmentSetOff_1" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.AssignmentSetOff_2" />
          </div>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfService.headline13" /></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.ContractDuration_1" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.ContractDuration_2" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.ContractDuration_3" />
          </div>
          <ol type="a" className={css.list}>
            <li className={css.listElement}>
              <div>
                <FormattedMessage id="TermsOfService.ContractDuration_3_1" />
              </div>
            </li>
            <li className={css.listElement}>
              <div>
                <FormattedMessage id="TermsOfService.ContractDuration_3_2" />
              </div>
            </li>
            <li className={css.listElement}>
              <div>
                <FormattedMessage id="TermsOfService.ContractDuration_3_3" />
              </div>
            </li>
            <li className={css.listElement}>
              <div>
                <FormattedMessage id="TermsOfService.ContractDuration_3_4" />
              </div>
            </li>
            <li className={css.listElement}>
              <div>
                <FormattedMessage id="TermsOfService.ContractDuration_3_5" />
              </div>
            </li>
          </ol>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfService.headline14" /></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.FinalProvisions_1" />
          </div>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfService.FinalProvisions_2" />
          </div>
        </li>
      </ol>
    </div>


  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
