import React, { Component } from 'react';
import { string, func, object } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import {
  NamedLink,
  ResponsiveImage,
  PrimaryButton,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  InlineTextButton,
  IconFreelancerBadge,
  IconIndustryBadge,
  IconPrivateBadge
} from '../../components';
import MenuIcon from './MenuIcon';
import moment from "moment-timezone";

import css from './InteractionCard.module.css';

const MENU_CONTENT_OFFSET = -12;
const MIN_LENGTH_FOR_LONG_WORDS = 10;
class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const InteractionCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    handleViewInteractionDetails,
    notificationsCount,
    onToggleMenu,
    isMenuOpen,
    isClientInteractions,
    isExpertInteractions,
    isClientArchive,
    isExpertArchive,
    setArchiveListingId,
    setIsArchiveModalOpen
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '' } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const isArchive =  !!(isExpertArchive || isClientArchive);
  const publicData = currentListing.attributes.publicData;
  const listingCategory = publicData.listingCategory || '';
  const jobType = publicData.jobTypeGroup || '';
  const isJob = listingCategory === 'job';
  const isLead = listingCategory === 'lead';
  const hasClientPendingTransactions = isLead ?
  notificationsCount && notificationsCount.providerTransactions && notificationsCount.providerTransactions.length > 0 &&
    notificationsCount.providerTransactions.find(t => t.relationships.listing.data.id.uuid === id) :
    notificationsCount && notificationsCount.clientTransactions && notificationsCount.clientTransactions.length > 0 &&
    notificationsCount.clientTransactions.find(t => t.attributes.protectedData.jobListingId === id)
  const hasProviderPendingTransactions = isLead ?  notificationsCount && notificationsCount.clientTransactions && notificationsCount.clientTransactions.length > 0 &&
  notificationsCount.clientTransactions.find(t => t.relationships.listing.data.id.uuid === id) :
    notificationsCount && notificationsCount.providerTransactions && notificationsCount.providerTransactions.length > 0 &&
    notificationsCount.providerTransactions.find(t => t.attributes.protectedData.jobListingId === id)
  const hasPendingProposals = notificationsCount && notificationsCount.pendingProposalListings && notificationsCount.pendingProposalListings.length > 0 &&
    notificationsCount.pendingProposalListings.includes(id)
  const hasPendingInvitations = notificationsCount && notificationsCount.pendingInvitations && notificationsCount.pendingInvitations.find(i => i.listing_id === id)

  const isPrivateJob = isJob && jobType === "public" ? false : true;
  const hasPendingProviderQuestions = !!(isExpertInteractions || isExpertArchive) && notificationsCount && notificationsCount.providerUnseenQuestionsListings && notificationsCount.providerUnseenQuestionsListings.find(i => i === id)
  const hasPendingCustomerQuestions = !!(isClientInteractions || isClientArchive) && notificationsCount && notificationsCount.customerUnseenQuestionsListings && notificationsCount.customerUnseenQuestionsListings.find(i => i === id)
  const shouldShowNotificationDot = hasClientPendingTransactions || hasProviderPendingTransactions || hasPendingProposals || hasPendingInvitations || hasPendingProviderQuestions || hasPendingCustomerQuestions;
  const notificationDot = shouldShowNotificationDot ? <div className={css.notificationDot} /> : null;
  return (
  <div className={classes}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
          <div className={css.aspectWrapper}>
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
                sizes={renderSizes}
              />
          </div>

          <NamedLink className={ isArchive ? css.archive : css.cardLink } name="ListingPage" params={{ id, slug }}>
            <div className={classNames(css.menuOverlayWrapper, { [css.menuOverlayOpen]: isMenuOpen })}>
              <div className={classNames(css.menuOverlay)} />
              <div className={css.menuOverlayContent}>
                <FormattedMessage id="ManageListingCard.viewListing" />
              </div>
            </div>
          </NamedLink>
          <div className={css.menubarWrapper}>
            {
              isJob ? <div className={css.batch}>
              <IconFreelancerBadge className={css.icon} freelancerBadgeClassName={css.IconFreelancerBadge} />
              </div> : isLead ? <div className={css.batch}>
              <IconIndustryBadge className={css.icon} freelancerBadgeClassName={css.IconFreelancerBadge} />
              </div> : null
            }
            <div className={css.menubarGradient} />
            <div className={css.menubar}>
            <Menu
              className={classNames(css.menu, { [css.cardIsOpen]: !isArchive })}
              contentPlacementOffset={MENU_CONTENT_OFFSET}
              contentPosition="left"
              useArrow={false}
              onToggleActive={isOpen => {
                const listingOpen = isOpen ? currentListing : null;
                onToggleMenu(listingOpen);
              }}
              isOpen={isMenuOpen}
            >
              <MenuLabel className={css.menuLabel} isOpenClassName={css.listingMenuIsOpen}>
                <div className={css.iconWrapper}>
                  <MenuIcon className={css.menuIcon} isActive={isMenuOpen} />
                </div>
              </MenuLabel>
              <MenuContent rootClassName={css.menuContent}>
                <MenuItem key="archive-listing">
                  <InlineTextButton
                    className={css.link}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsArchiveModalOpen(true);
                      setArchiveListingId(currentListing.id.uuid);
                    }}
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="InteractionCard.archive" />
                  </InlineTextButton>
                </MenuItem>
              </MenuContent>
            </Menu>
          </div>
        </div>
      </div>
      <NamedLink className={ isArchive ? css.archive : css.cardLink } name="ListingPage" params={{ id, slug }}>
        <div className={css.info}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {notificationDot}
        </div>
      </NamedLink>
      <PrimaryButton className={css.viewDetails} onClick={() => handleViewInteractionDetails('all', id, slug)} >
        <FormattedMessage id="InteractionCard.viewDetails" />
      </PrimaryButton>
    </div>
  );
};

InteractionCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
  notificationsCount: {},
};

InteractionCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
  notificationsCount: object,
};

export default injectIntl(InteractionCardComponent);
