import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReviewStar.module.css';

const IconReviewStar = props => {
  const { className, rootClassName, isFilled } = props;
  const filledOrDefault = isFilled ? css.filled : css.root;
  const classes = classNames(rootClassName || filledOrDefault, className);

  return (
    <svg
      className={classes}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m11.968 4.1777c-0.07826-0.21493-0.28382-0.35996-0.5139-0.35996h-3.9511l-0.9772-3.4223c-0.067825-0.23371-0.28173-0.39544-0.52538-0.39544-0.24365 0-0.45912 0.16172-0.52486 0.39648l-0.97876 3.4207h-3.9505c-0.2306 0-0.4346 0.14503-0.51286 0.35996-0.078259 0.21598-0.013043 0.457 0.16382 0.60515l2.9739 2.478-1.501 4.0029c-0.083477 0.22432-0.010435 0.47786 0.18052 0.62394 0.18887 0.14607 0.4539 0.15129 0.64799 0.01043l3.5008-2.4989 3.5024 2.4989c0.19565 0.14086 0.45912 0.13564 0.64955-0.01043 0.19095-0.14607 0.26295-0.39909 0.17895-0.62394l-1.5-4.0013 2.9739-2.478c0.17739-0.14816 0.24156-0.38918 0.16434-0.60515"
        fillRule="evenodd"
      />
    </svg>
  );
};

IconReviewStar.defaultProps = { className: null, rootClassName: null, isFilled: false };

const { bool, string } = PropTypes;

IconReviewStar.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool,
};

export default IconReviewStar;
