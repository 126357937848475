import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities, ensureAvailabilityException } from '../../util/data';
import { isSameDate, monthIdStringInUTC } from '../../util/dates';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { inviteExperts, sendNotificationToExperts, canEditListingDetails } from '../../util/api';
import moment from "moment-timezone";

const { UUID, Money } = sdkTypes;
const PAGE_SIZE = 24;

// A helper function to filter away exception that matches start and end timestamps
const removeException = (exception, calendar) => {
  const availabilityException = ensureAvailabilityException(exception.availabilityException);
  const { start, end } = availabilityException.attributes;
  // When using time-based process, you might want to deal with local dates using monthIdString
  const monthId = monthIdStringInUTC(start);
  const monthData = calendar[monthId] || { exceptions: [] };

  const exceptions = monthData.exceptions.filter(e => {
    const anException = ensureAvailabilityException(e.availabilityException);
    const exceptionStart = anException.attributes.start;
    const exceptionEnd = anException.attributes.end;

    return !(isSameDate(exceptionStart, start) && isSameDate(exceptionEnd, end));
  });

  return {
    ...calendar,
    [monthId]: { ...monthData, exceptions },
  };
};

// A helper function to add a new exception and remove previous one if there's a matching exception
const addException = (exception, calendar) => {
  const { start } = ensureAvailabilityException(exception.availabilityException).attributes;
  // When using time-based process, you might want to deal with local dates using monthIdString
  const monthId = monthIdStringInUTC(start);

  // TODO: API doesn't support "availability_exceptions/update" yet
  // So, when user wants to create an exception we need to ensure
  // that possible existing exception is removed first.
  const cleanCalendar = removeException(exception, calendar);
  const monthData = cleanCalendar[monthId] || { exceptions: [] };

  return {
    ...cleanCalendar,
    [monthId]: { ...monthData, exceptions: [...monthData.exceptions, exception] },
  };
};

// A helper function to update exception that matches start and end timestamps
const updateException = (exception, calendar) => {
  const newAvailabilityException = ensureAvailabilityException(exception.availabilityException);
  const { start, end } = newAvailabilityException.attributes;
  // When using time-based process, you might want to deal with local dates using monthIdString
  const monthId = monthIdStringInUTC(start);
  const monthData = calendar[monthId] || { exceptions: [] };

  const exceptions = monthData.exceptions.map(e => {
    const availabilityException = ensureAvailabilityException(e.availabilityException);
    const exceptionStart = availabilityException.attributes.start;
    const exceptionEnd = availabilityException.attributes.end;

    return isSameDate(exceptionStart, start) && isSameDate(exceptionEnd, end) ? exception : e;
  });

  return {
    ...calendar,
    [monthId]: { ...monthData, exceptions },
  };
};

// Update calendar data of given month
const updateCalendarMonth = (state, monthId, data) => {
  // Ensure that every month has array for bookings and exceptions
  const defaultMonthData = { bookings: [], exceptions: [] };
  return {
    ...state,
    availabilityCalendar: {
      ...state.availabilityCalendar,
      [monthId]: {
        ...defaultMonthData,
        ...state.availabilityCalendar[monthId],
        ...data,
      },
    },
  };
};

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const SAVE_LISTING_DATE = 'app/EditListingPage/SAVE_LISTING_DATE';

export const MARK_TAB_UPDATED = 'app/EditListingPage/MARK_TAB_UPDATED';
export const CLEAR_UPDATED_TAB = 'app/EditListingPage/CLEAR_UPDATED_TAB';

export const CREATE_LISTING_DRAFT_REQUEST = 'app/EditListingPage/CREATE_LISTING_DRAFT_REQUEST';
export const CREATE_LISTING_DRAFT_SUCCESS = 'app/EditListingPage/CREATE_LISTING_DRAFT_SUCCESS';
export const CREATE_LISTING_DRAFT_ERROR = 'app/EditListingPage/CREATE_LISTING_DRAFT_ERROR';

export const PUBLISH_LISTING_REQUEST = 'app/EditListingPage/PUBLISH_LISTING_REQUEST';
export const PUBLISH_LISTING_SUCCESS = 'app/EditListingPage/PUBLISH_LISTING_SUCCESS';
export const PUBLISH_LISTING_ERROR = 'app/EditListingPage/PUBLISH_LISTING_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/EditListingPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/EditListingPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/EditListingPage/UPDATE_LISTING_ERROR';

export const SHOW_LISTINGS_REQUEST = 'app/EditListingPage/SHOW_LISTINGS_REQUEST';
export const SHOW_LISTINGS_SUCCESS = 'app/EditListingPage/SHOW_LISTINGS_SUCCESS';
export const SHOW_LISTINGS_ERROR = 'app/EditListingPage/SHOW_LISTINGS_ERROR';

export const FETCH_BOOKINGS_REQUEST = 'app/EditListingPage/FETCH_BOOKINGS_REQUEST';
export const FETCH_BOOKINGS_SUCCESS = 'app/EditListingPage/FETCH_BOOKINGS_SUCCESS';
export const FETCH_BOOKINGS_ERROR = 'app/EditListingPage/FETCH_BOOKINGS_ERROR';

export const FETCH_EXCEPTIONS_REQUEST = 'app/EditListingPage/FETCH_AVAILABILITY_EXCEPTIONS_REQUEST';
export const FETCH_EXCEPTIONS_SUCCESS = 'app/EditListingPage/FETCH_AVAILABILITY_EXCEPTIONS_SUCCESS';
export const FETCH_EXCEPTIONS_ERROR = 'app/EditListingPage/FETCH_AVAILABILITY_EXCEPTIONS_ERROR';

export const CREATE_EXCEPTION_REQUEST = 'app/EditListingPage/CREATE_AVAILABILITY_EXCEPTION_REQUEST';
export const CREATE_EXCEPTION_SUCCESS = 'app/EditListingPage/CREATE_AVAILABILITY_EXCEPTION_SUCCESS';
export const CREATE_EXCEPTION_ERROR = 'app/EditListingPage/CREATE_AVAILABILITY_EXCEPTION_ERROR';

export const DELETE_EXCEPTION_REQUEST = 'app/EditListingPage/DELETE_AVAILABILITY_EXCEPTION_REQUEST';
export const DELETE_EXCEPTION_SUCCESS = 'app/EditListingPage/DELETE_AVAILABILITY_EXCEPTION_SUCCESS';
export const DELETE_EXCEPTION_ERROR = 'app/EditListingPage/DELETE_AVAILABILITY_EXCEPTION_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/EditListingPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/EditListingPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/EditListingPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_IMAGE_ORDER = 'app/EditListingPage/UPDATE_IMAGE_ORDER';

export const REMOVE_LISTING_IMAGE = 'app/EditListingPage/REMOVE_LISTING_IMAGE';

export const INVITE_EXPERTS_REQUEST = 'app/EditListingPage/INVITE_EXPERTS_REQUEST';
export const INVITE_EXPERTS_SUCCESS = 'app/EditListingPage/INVITE_EXPERTS_SUCCESS';
export const INVITE_EXPERTS_ERROR = 'app/EditListingPage/INVITE_EXPERTS_ERROR';

export const FETCH_EXPERTS_REQUEST = 'app/EditListingPage/FETCH_EXPERTS_REQUEST';
export const FETCH_EXPERTS_SUCCESS = 'app/EditListingPage/FETCH_EXPERTS_SUCCESS';
export const FETCH_ALL_EXPERTS_SUCCESS = 'app/EditListingPage/FETCH_ALL_EXPERTS_SUCCESS';
export const FETCH_EXPERTS_ERROR = 'app/EditListingPage/FETCH_EXPERTS_ERROR';
export const CLEAR_EXPERTS = 'app/EditListingPage/CLEAR_EXPERTS';


export const NOTIFY_EXPERTS_REQUEST = 'app/EditListingPage/NOTIFY_EXPERTS_REQUEST';
export const NOTIFY_EXPERTS_SUCCESS = 'app/EditListingPage/NOTIFY_EXPERTS_SUCCESS';
export const NOTIFY_EXPERTS_ERROR = 'app/EditListingPage/NOTIFY_EXPERTS_ERROR';

export const CAN_EDIT_LISTING_REQUEST = 'app/EditListingPage/CAN_EDIT_LISTING_REQUEST';
export const CAN_EDIT_LISTING_REQUEST_SUCCESS = 'app/EditListingPage/CAN_EDIT_LISTING_REQUEST_SUCCESS';
export const CAN_EDIT_LISTING_REQUEST_ERROR = 'app/EditListingPage/CAN_EDIT_LISTING_REQUEST_ERROR';

// ================ Reducer ================ //

const initialState = {
  // Error instance placeholders for each endpoint
  createListingDraftError: null,
  publishingListing: null,
  publishListingError: null,
  updateListingError: null,
  showListingsError: null,
  uploadImageError: null,
  createListingDraftInProgress: false,
  submittedListingId: null,
  redirectToListing: false,
  //Radiobutton location group
  deliveryDate: null,
  listingTimeZone: null,
  availabilityCalendar: {
    // '2018-12': {
    //   bookings: [],
    //   exceptions: [],
    //   fetchExceptionsError: null,
    //   fetchExceptionsInProgress: false,
    //   fetchBookingsError: null,
    //   fetchBookingsInProgress: false,
    // },
  },
  images: {},
  imageOrder: [],
  removedImageIds: [],
  listingDraft: null,
  updatedTab: null,
  updateInProgress: false,
  inviteExpertsInProgress: false,
  inviteExpertsError: null,
  expertsIds: [],
  expertsPagination: null,
  allExpertsPagination: null,
  fetchExpertsInProgress: false,
  fetchExpertsError: null,
  notifyExpertsInProgress: false,
  notifyExpertsError: null,
  canEditListingRequestInProgress: false,
  canEditListingRequestError: null,
  canEditListing: true,
};

const resultIds = data => data.data.map(l => l.id);

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_LISTING_DATE:
      return {
        ...state,
        deliveryDate: payload.selectedDateTime,
        listingTimeZone: payload.selectedTimezone
       };
    case MARK_TAB_UPDATED:
      return { ...state, updatedTab: payload };
    case CLEAR_UPDATED_TAB:
      return { ...state, updatedTab: null, updateListingError: null };

    case CREATE_LISTING_DRAFT_REQUEST:
      return {
        ...state,
        createListingDraftInProgress: true,
        createListingDraftError: null,
        submittedListingId: null,
        listingDraft: null,
      };

    case CREATE_LISTING_DRAFT_SUCCESS:
      return {
        ...state,
        createListingDraftInProgress: false,
        submittedListingId: payload.data.id,
        listingDraft: payload.data,
      };
    case CREATE_LISTING_DRAFT_ERROR:
      return {
        ...state,
        createListingDraftInProgress: false,
        createListingDraftError: payload,
      };

    case PUBLISH_LISTING_REQUEST:
      return {
        ...state,
        publishingListing: payload.listingId,
        publishListingError: null,
      };
    case PUBLISH_LISTING_SUCCESS:
      return {
        ...state,
        redirectToListing: true,
        publishingListing: null,
        createListingDraftError: null,
        updateListingError: null,
        showListingsError: null,
        uploadImageError: null,
        createListingDraftInProgress: false,
        updateInProgress: false,
      };
    case PUBLISH_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        publishingListing: null,
        publishListingError: {
          listingId: state.publishingListing,
          error: payload,
        },
      };
    }

    case UPDATE_LISTING_REQUEST:
      return { ...state, updateInProgress: true, updateListingError: null };
    case UPDATE_LISTING_SUCCESS:
      return { ...state, updateInProgress: false };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateInProgress: false, updateListingError: payload };

    case SHOW_LISTINGS_REQUEST:
      return { ...state, showListingsError: null };
    case SHOW_LISTINGS_SUCCESS:
      return {
        ...initialState,
        availabilityCalendar: { ...state.availabilityCalendar },
        expertsIds: [...state.expertsIds],
        expertsPagination: state.expertsPagination,
        allExpertsPagination: state.allExpertsPagination
      };

    case SHOW_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, showListingsError: payload, redirectToListing: false };

    case FETCH_BOOKINGS_REQUEST:
      return updateCalendarMonth(state, payload.params.monthId, {
        fetchBookingsError: null,
        fetchBookingsInProgress: true,
      });
    case FETCH_BOOKINGS_SUCCESS:
      return updateCalendarMonth(state, payload.monthId, {
        bookings: payload.bookings,
        fetchBookingsInProgress: false,
      });
    case FETCH_BOOKINGS_ERROR:
      return updateCalendarMonth(state, payload.monthId, {
        fetchBookingsError: payload.error,
        fetchBookingsInProgress: false,
      });

    case FETCH_EXCEPTIONS_REQUEST:
      return updateCalendarMonth(state, payload.params.monthId, {
        fetchExceptionsError: null,
        fetchExceptionsInProgress: true,
      });
    case FETCH_EXCEPTIONS_SUCCESS:
      return updateCalendarMonth(state, payload.monthId, {
        exceptions: payload.exceptions,
        fetchExceptionsInProgress: false,
      });
    case FETCH_EXCEPTIONS_ERROR:
      return updateCalendarMonth(state, payload.monthId, {
        fetchExceptionsError: payload.error,
        fetchExceptionsInProgress: false,
      });

    case CREATE_EXCEPTION_REQUEST: {
      const { start, end, seats } = payload.params;
      const draft = ensureAvailabilityException({ attributes: { start, end, seats } });
      const exception = { availabilityException: draft, inProgress: true };
      const availabilityCalendar = addException(exception, state.availabilityCalendar);
      return { ...state, availabilityCalendar };
    }
    case CREATE_EXCEPTION_SUCCESS: {
      const availabilityCalendar = updateException(payload.exception, state.availabilityCalendar);
      return { ...state, availabilityCalendar };
    }
    case CREATE_EXCEPTION_ERROR: {
      const { availabilityException, error } = payload;
      const failedException = { availabilityException, error };
      const availabilityCalendar = updateException(failedException, state.availabilityCalendar);
      return { ...state, availabilityCalendar };
    }

    case DELETE_EXCEPTION_REQUEST: {
      const { id, seats, currentException } = payload.params;

      // We first create temporary exception with given 'seats' count (the default after deletion).
      // This makes it possible to show the UI element immediately with default color that matches
      // with the availability plan.
      const exception = {
        id,
        inProgress: true,
        availabilityException: {
          ...currentException.availabilityException,
          attributes: { ...currentException.availabilityException.attributes, seats },
        },
      };

      const availabilityCalendar = updateException(exception, state.availabilityCalendar);
      return { ...state, availabilityCalendar };
    }
    case DELETE_EXCEPTION_SUCCESS: {
      const availabilityCalendar = removeException(payload.exception, state.availabilityCalendar);
      return { ...state, availabilityCalendar };
    }
    case DELETE_EXCEPTION_ERROR: {
      const { availabilityException, error } = payload;
      const failedException = { availabilityException, error };
      const availabilityCalendar = updateException(failedException, state.availabilityCalendar);
      return { ...state, availabilityCalendar };
    }

    case UPLOAD_IMAGE_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const images = {
        ...state.images,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        images,
        imageOrder: state.imageOrder.concat([payload.params.id]),
        uploadImageError: null,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id'}
      const { id, imageId } = payload;
      const file = state.images[id].file;
      const images = { ...state.images, [id]: { id, imageId, file } };
      return { ...state, images };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const imageOrder = state.imageOrder.filter(i => i !== id);
      const images = omit(state.images, id);
      return { ...state, imageOrder, images, uploadImageError: error };
    }
    case UPDATE_IMAGE_ORDER:
      return { ...state, imageOrder: payload.imageOrder };

    case REMOVE_LISTING_IMAGE: {
      const id = payload.imageId;

      // Only mark the image removed if it hasn't been added to the
      // listing already
      const removedImageIds = state.images[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const images = omit(state.images, id);
      const imageOrder = state.imageOrder.filter(i => i !== id);

      return { ...state, images, imageOrder, removedImageIds };
    }

    case INVITE_EXPERTS_REQUEST:
      return { ...state, inviteExpertsInProgress: true, inviteExpertsError: null };
    case INVITE_EXPERTS_SUCCESS:
      return { ...state, inviteExpertsInProgress: false, inviteExpertsError: null };
    case INVITE_EXPERTS_ERROR:
      return { ...state, inviteExpertsInProgress: false, inviteExpertsError: payload };

    case FETCH_EXPERTS_REQUEST:
      return { ...state, fetchExpertsInProgress: true, fetchExpertsError: null };
    case FETCH_EXPERTS_SUCCESS:
      // For subscribed experts only, silver and gold subscription
      return { ...state, fetchExpertsInProgress: false, fetchExpertsError: null,
        expertsIds: uniqBy(
          [...state.expertsIds ,...resultIds(payload.data)],
          function (e) {
            return e.uuid;
          }
        ),
        expertsPagination: payload.data.meta,
      };
    case FETCH_ALL_EXPERTS_SUCCESS:
      // For all experts bronze, silver and gold subscription
      return { ...state, fetchExpertsInProgress: false, fetchExpertsError: null,
        allExpertsPagination: payload.data.meta,
      };
    case FETCH_EXPERTS_ERROR:
      return { ...state, fetchExpertsInProgress: false, fetchExpertsError: payload };
    case CLEAR_EXPERTS:
      return { ...state, expertsIds: [] };

    case NOTIFY_EXPERTS_REQUEST:
      return { ...state, notifyExpertsInProgress: true, notifyExpertsError: null };
    case NOTIFY_EXPERTS_SUCCESS:
      return { ...state, notifyExpertsInProgress: false, notifyExpertsError: null };
    case NOTIFY_EXPERTS_ERROR:
      return { ...state, notifyExpertsInProgress: false, notifyExpertsError: payload };

    case CAN_EDIT_LISTING_REQUEST:
      return { ...state, canEditListingRequestInProgress: true, canEditListingRequestError: null };
    case CAN_EDIT_LISTING_REQUEST_SUCCESS:
      return { ...state, canEditListingRequestInProgress: false, canEditListingRequestError: null, canEditListing: payload };
    case CAN_EDIT_LISTING_REQUEST_ERROR:
      return { ...state, canEditListingRequestInProgress: false, canEditListingRequestError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const saveListingDateRequest = payload => ({
  type: SAVE_LISTING_DATE,
  payload: payload,
});

export const markTabUpdated = tab => ({
  type: MARK_TAB_UPDATED,
  payload: tab,
});

export const clearUpdatedTab = () => ({
  type: CLEAR_UPDATED_TAB,
});

export const updateImageOrder = imageOrder => ({
  type: UPDATE_IMAGE_ORDER,
  payload: { imageOrder },
});

export const removeListingImage = imageId => ({
  type: REMOVE_LISTING_IMAGE,
  payload: { imageId },
});

export const inviteExpertsRequest = () => ({ type: INVITE_EXPERTS_REQUEST });
export const inviteExpertsSuccess = (message) => ({ type: INVITE_EXPERTS_SUCCESS, payload: message, });
export const inviteExpertsError = e => ({ type: INVITE_EXPERTS_ERROR, error: true, payload: e });

export const notifyExpertsRequest = () => ({ type: NOTIFY_EXPERTS_REQUEST });
export const notifyExpertsSuccess = (message) => ({ type: NOTIFY_EXPERTS_SUCCESS, payload: message, });
export const notifyExpertsError = e => ({ type: NOTIFY_EXPERTS_ERROR, error: true, payload: e });

export const fetchExpertsRequest = () => ({ type: FETCH_EXPERTS_REQUEST });
export const fetchExpertsSuccess = (response) => ({ type: FETCH_EXPERTS_SUCCESS, payload: { data: response.data }, });
export const fetchAllExpertsSuccess = (response) => ({ type: FETCH_ALL_EXPERTS_SUCCESS, payload: { data: response.data }, });
export const fetchExpertsError = e => ({ type: FETCH_EXPERTS_ERROR, error: true, payload: e });
export const clearExperts = () => ({
  type: CLEAR_EXPERTS,
});

export const canEditListingRequest = () => ({ type: CAN_EDIT_LISTING_REQUEST });
export const canEditListingRequestSuccess = (res) => ({ type: CAN_EDIT_LISTING_REQUEST_SUCCESS, payload: res, });
export const canEditListingRequestError = e => ({ type: CAN_EDIT_LISTING_REQUEST_ERROR, error: true, payload: e });

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.

// SDK method: ownListings.create
export const createListingDraft = requestAction(CREATE_LISTING_DRAFT_REQUEST);
export const createListingDraftSuccess = successAction(CREATE_LISTING_DRAFT_SUCCESS);
export const createListingDraftError = errorAction(CREATE_LISTING_DRAFT_ERROR);

// SDK method: ownListings.publish
export const publishListing = requestAction(PUBLISH_LISTING_REQUEST);
export const publishListingSuccess = successAction(PUBLISH_LISTING_SUCCESS);
export const publishListingError = errorAction(PUBLISH_LISTING_ERROR);

// SDK method: ownListings.update
export const updateListing = requestAction(UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction(UPDATE_LISTING_SUCCESS);
export const updateListingError = errorAction(UPDATE_LISTING_ERROR);

// SDK method: ownListings.show
export const showListings = requestAction(SHOW_LISTINGS_REQUEST);
export const showListingsSuccess = successAction(SHOW_LISTINGS_SUCCESS);
export const showListingsError = errorAction(SHOW_LISTINGS_ERROR);

// SDK method: images.upload
export const uploadImage = requestAction(UPLOAD_IMAGE_REQUEST);
export const uploadImageSuccess = successAction(UPLOAD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(UPLOAD_IMAGE_ERROR);

// SDK method: bookings.query
export const fetchBookingsRequest = requestAction(FETCH_BOOKINGS_REQUEST);
export const fetchBookingsSuccess = successAction(FETCH_BOOKINGS_SUCCESS);
export const fetchBookingsError = errorAction(FETCH_BOOKINGS_ERROR);

// SDK method: availabilityExceptions.query
export const fetchAvailabilityExceptionsRequest = requestAction(FETCH_EXCEPTIONS_REQUEST);
export const fetchAvailabilityExceptionsSuccess = successAction(FETCH_EXCEPTIONS_SUCCESS);
export const fetchAvailabilityExceptionsError = errorAction(FETCH_EXCEPTIONS_ERROR);

// SDK method: availabilityExceptions.create
export const createAvailabilityExceptionRequest = requestAction(CREATE_EXCEPTION_REQUEST);
export const createAvailabilityExceptionSuccess = successAction(CREATE_EXCEPTION_SUCCESS);
export const createAvailabilityExceptionError = errorAction(CREATE_EXCEPTION_ERROR);

// SDK method: availabilityExceptions.delete
export const deleteAvailabilityExceptionRequest = requestAction(DELETE_EXCEPTION_REQUEST);
export const deleteAvailabilityExceptionSuccess = successAction(DELETE_EXCEPTION_SUCCESS);
export const deleteAvailabilityExceptionError = errorAction(DELETE_EXCEPTION_ERROR);

// ================ Thunk ================ //

export function requestShowListing(actionPayload) {
  return (dispatch, getState, sdk) => {
    dispatch(showListings(actionPayload));
    return sdk.ownListings
      .show(actionPayload)
      .then(response => {
        // EditListingPage fetches new listing data, which also needs to be added to global data
        dispatch(addMarketplaceEntities(response));
        // In case of success, we'll clear state.EditListingPage (user will be redirected away)
        dispatch(showListingsSuccess(response));
        return response;
      })
      .catch(e => dispatch(showListingsError(storableError(e))));
  };
}

export const inviteExpertsToSendOffer = ({ listingId }) => (dispatch, getState, sdk) => {
  dispatch(inviteExpertsRequest());

  const handleSuccess = response => {
    dispatch(inviteExpertsSuccess(response.data.message));
    return response
  };

  const handleError = e => {
    dispatch(inviteExpertsError(e.error));
  };

  return inviteExperts({ listingId })
    .then(handleSuccess)
    .catch(handleError);
};

export const notifyExperts = ({ listingId }) => (dispatch, getState, sdk) => {
  dispatch(notifyExpertsRequest());

  const handleSuccess = response => {
    dispatch(notifyExpertsSuccess(response.data.message));
    return response
  };

  const handleError = e => {
    dispatch(notifyExpertsError(e.error));
  };

  return sendNotificationToExperts({ listingId })
    .then(handleSuccess)
    .catch(handleError);
};

export const fetchExperts = (searchParams, page = 1) => (dispatch, getState, sdk) => {
  dispatch(fetchExpertsRequest());

  const params = {
    ...searchParams,
    pub_listingCategory: 'expert',
    meta_subscription: 'silver,gold',
    perPage: PAGE_SIZE,
    page,
    include: ['author', 'images', 'author.profileImage'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': [
      'variants.landscape-crop', 'variants.landscape-crop2x',
      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    'limit.images': 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchAllExpertsSuccess(response));
      sdk.listings
      .query({
        ...params,
        pub_isIncognito: false,
        meta_subscription: 'silver,gold'
      })
      .then(subscribedExpertsResponse => {
        dispatch(fetchExpertsSuccess(subscribedExpertsResponse));
        return response;
      })

    })
    .catch(e => {
      dispatch(fetchExpertsError(storableError(e)));
    });
};

export function requestCreateListingDraft(data) {
  const {description, publicData, title, privateData} = data;
  const pricedraft = new Money(1500,"EUR");
  const defaultAvailabilityPlan = {
    type: 'availability-plan/day',
    entries: [
      { dayOfWeek: 'mon', seats: 1 },
      { dayOfWeek: 'tue', seats: 1 },
      { dayOfWeek: 'wed', seats: 1 },
      { dayOfWeek: 'thu', seats: 1 },
      { dayOfWeek: 'fri', seats: 1 },
      { dayOfWeek: 'sat', seats: 1 },
      { dayOfWeek: 'sun', seats: 1 },
    ],
  };

  const newData = {
    description: description,
    title: title || "draft-00000000-0000-0000-0000-000000000000",
    price: pricedraft,
    availabilityPlan: defaultAvailabilityPlan,
    publicData: {
      ...publicData
    },
    privateData: {...privateData},
  }

  return (dispatch, getState, sdk) => {
    //Create a UTC and UNIX timestamp for the listing-end-date
    dispatch(createListingDraft(newData));
    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    };

    return sdk.ownListings
      .createDraft(newData, queryParams)
      .then(response => {
        //const id = response.data.data.id.uuid;
        // Add the created listing to the marketplace data
        dispatch(addMarketplaceEntities(response));

        // Modify store to understand that we have created listing and can redirect away
        dispatch(createListingDraftSuccess(response));
        return response;
      })
      .catch(e => {
        log.error(e, 'create-listing-draft-failed', { listingData: newData });
        return dispatch(createListingDraftError(storableError(e)));
      });
  };
}

export const requestPublishListingDraft = listingId => (dispatch, getState, sdk) => {
  dispatch(publishListing(listingId));

  return sdk.ownListings
    .publishDraft({ id: listingId }, { expand: true })
    .then(response => {
      // Add the created listing to the marketplace data
      dispatch(addMarketplaceEntities(response));
      dispatch(publishListingSuccess(response));
      const shouldInviteExperts = response.data.data.attributes && response.data.data.attributes.privateData &&
      response.data.data.attributes.privateData.invitedExpertsIds && response.data.data.attributes.privateData.invitedExpertsIds.length > 0;
      if (shouldInviteExperts){
        dispatch(inviteExpertsToSendOffer({listingId: listingId.uuid}));
      }
      dispatch(notifyExperts({listingId: listingId.uuid}));
      return response;
    })
    .catch(e => {
      dispatch(publishListingError(storableError(e)));
    });
};

// Images return imageId which we need to map with previously generated temporary id
export function requestImageUpload(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImage(actionPayload));
    return sdk.images
      .upload({ image: actionPayload.file })
      .then(resp => dispatch(uploadImageSuccess({ data: { id, imageId: resp.data.data.id } })))
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export const requestFetchBookings = fetchParams => (dispatch, getState, sdk) => {
  const { listingId, start, end, state } = fetchParams;
  // When using time-based process, you might want to deal with local dates using monthIdString
  const monthId = monthIdStringInUTC(start);

  dispatch(fetchBookingsRequest({ ...fetchParams, monthId }));

  return sdk.bookings
    .query({ listingId, start, end, state }, { expand: true })
    .then(response => {
      const bookings = denormalisedResponseEntities(response);
      return dispatch(fetchBookingsSuccess({ data: { monthId, bookings } }));
    })
    .catch(e => {
      return dispatch(fetchBookingsError({ monthId, error: storableError(e) }));
    });
};

export const requestFetchAvailabilityExceptions = fetchParams => (dispatch, getState, sdk) => {
  const { listingId, start, end } = fetchParams;
  // When using time-based process, you might want to deal with local dates using monthIdString
  const monthId = monthIdStringInUTC(start);

  dispatch(fetchAvailabilityExceptionsRequest({ ...fetchParams, monthId }));

  return sdk.availabilityExceptions
    .query({ listingId, start, end }, { expand: true })
    .then(response => {
      const exceptions = denormalisedResponseEntities(response).map(availabilityException => ({
        availabilityException,
      }));
      return dispatch(fetchAvailabilityExceptionsSuccess({ data: { monthId, exceptions } }));
    })
    .catch(e => {
      return dispatch(fetchAvailabilityExceptionsError({ monthId, error: storableError(e) }));
    });
};

export const requestCreateAvailabilityException = params => (dispatch, getState, sdk) => {
  const { currentException, ...createParams } = params;

  dispatch(createAvailabilityExceptionRequest(createParams));

  return sdk.availabilityExceptions
    .create(createParams, { expand: true })
    .then(response => {
      dispatch(
        createAvailabilityExceptionSuccess({
          data: {
            exception: {
              availabilityException: response.data.data,
            },
          },
        })
      );
      return response;
    })
    .catch(error => {
      console.debug(error);
      console.debug(storableError(error));
      const availabilityException = currentException && currentException.availabilityException;
      return dispatch(
        createAvailabilityExceptionError({
          error: storableError(error),
          availabilityException,
        })
      );
    });
};

export const requestDeleteAvailabilityException = params => (dispatch, getState, sdk) => {
  const { currentException, seats, ...deleteParams } = params;

  dispatch(deleteAvailabilityExceptionRequest(params));

  return sdk.availabilityExceptions
    .delete(deleteParams, { expand: true })
    .then(response => {
      dispatch(
        deleteAvailabilityExceptionSuccess({
          data: {
            exception: currentException,
          },
        })
      );
      return response;
    })
    .catch(error => {
      const availabilityException = currentException && currentException.availabilityException;
      return dispatch(
        deleteAvailabilityExceptionError({
          error: storableError(error),
          availabilityException,
        })
      );
    });
};

// Update the given tab of the wizard with the given data. This saves
// the data to the listing, and marks the tab updated so the UI can
// display the state.
export function requestUpdateListing(tab, data) {
  return (dispatch, getState, sdk) => {

    const {officeDetails} = data?.privateData ?? {};

    dispatch(updateListing(data));
    const { id } = data;
    let updateResponse;
    return sdk.ownListings
      .update(data)
      .then(response => {
        updateResponse = response;
        const payload = {
          id,
          include: ['author', 'images'],
          'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        };

        if (!!officeDetails) {

          sdk.currentUser.updateProfile({
            publicData: {
              postalCode: officeDetails.address?.postalCode ?? '',
              company:officeDetails?.company ?? ''
            },
            protectedData: {
              address: {
                street: officeDetails.address?.street ?? '',
                houseNumber: officeDetails.address?.houseNumber ?? '',
                postalCode: officeDetails.address?.postalCode ?? '',
                city: officeDetails.address?.city ?? '',
              },
              phoneNumber: officeDetails.phoneNumber
            }
          });
        }


        return dispatch(requestShowListing(payload));
      })
      .then(() => {
        dispatch(markTabUpdated(tab));
        dispatch(updateListingSuccess(updateResponse));
        return updateResponse;
      })
      .catch(e => {
        log.error(e, 'update-listing-failed', { listingData: data });
        return dispatch(updateListingError(storableError(e)));
      });
  };
}

export const canEditListing = ({ listingId }) => (dispatch, getState, sdk) => {
  dispatch(canEditListingRequest());

  const handleSuccess = response => {
    dispatch(canEditListingRequestSuccess(response.data.data));
    return response
  };

  const handleError = e => {
    dispatch(canEditListingRequestError(e.error));
  };

  return canEditListingDetails({listingId})
    .then(handleSuccess)
    .catch(handleError);
};

// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export const loadData = params => (dispatch, getState, sdk) => {
  const { id, type } = params;
  //console.log("Params load data Edit Listing page:");
  //console.log(params);
  //console.log("Params load data Edit Listing page Language:");
  //console.log(params.lang);

  dispatch(clearUpdatedTab());

  if (type === 'new') {
    // No need to listing data when creating a new listing
    return Promise.all([dispatch(fetchCurrentUser())])
      .then(response => {
        //console.log("Fetch Current User for new type");
        const currentUser = getState().user.currentUser;
        if (currentUser && currentUser.stripeAccount) {
          //console.log("fetch Stripe Account for new type");
          dispatch(fetchStripeAccount());
        }
        return response;
      })
      .catch(e => {
        throw e;
      });
  }

  const payload = {
    id: new UUID(id),
    include: ['author', 'images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
  };

  return Promise.all([dispatch(requestShowListing(payload)), dispatch(fetchCurrentUser()), dispatch(canEditListing({ listingId: id }))])
    .then(response => {
      const currentUser = getState().user.currentUser;
      if (currentUser && currentUser.stripeAccount) {
        dispatch(fetchStripeAccount());
      }
      const attributes = response[0] && response[0].data.data && response[0].data.data.attributes;
      const privateData = attributes && attributes.privateData && attributes.privateData;
      const invitedExpertsIds = privateData && privateData.invitedExpertsIds;
      const hasInvitedExperts = invitedExpertsIds && invitedExpertsIds.length > 0;
      dispatch(clearExperts());
      if (hasInvitedExperts){
        dispatch(fetchExperts({ids: invitedExpertsIds}, 1));
      }

      return response;
    })
    .catch(e => {
      throw e;
    });
};

export const saveListingDate = params => (dispatch, getState, sdk) => {
  dispatch(saveListingDateRequest(params));
};
