import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUserMetaData } from '../../ducks/user.duck';
import { useStripeCountryDetection } from '../../hooks';
import { FormattedMessage } from '../../util/reactIntl';
import { LayoutWrapperSideNav } from '../index';

export const LayoutWrapperSettingsSideNav = ({ currentTab }) => {
  const [countryIsSupportedByStripe,country] = useStripeCountryDetection();
  const metaData = useSelector(selectCurrentUserMetaData);
  const isManufacturer = metaData?.isManufacturer ?? false;
  const hasSubscription = metaData?.subscription ?? false;

  const tabs = [
    {
      text: <FormattedMessage id="LayoutWrapperProfileSettingsSideNav.contactDetailsTabTitle" />,
      selected: ['ProfileSettingsPage', 'ContactDetailsPage', 'PasswordChangePage', 'PaymentMethodsPage'].includes(currentTab),
      id: 'ProfileSettingsPage',
      linkProps: {
        name: 'ProfileSettingsPage',
      },
      subTabs: [
        {
          text: (
            <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitle" />
          ),
          selected: currentTab === 'ContactDetailsPage',
          id: 'ContactDetailsPageTab',
          linkProps: {
            name: 'ContactDetailsPage',
          },
        },
        {
          text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.passwordTabTitle" />,
          selected: currentTab === 'PasswordChangePage',
          id: 'PasswordChangePageTab',
          linkProps: {
            name: 'PasswordChangePage',
          },
        },
        {
          text: (
            <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentMethodsTabTitle" />
          ),
          selected: currentTab === 'PaymentMethodsPage',
          id: 'PaymentMethodsPageTab',
          linkProps: {
            name: 'PaymentMethodsPage',
          },
        },
      ],
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.subscription" />,
      selected: ['SubscriptionPage','ProfileSkillsPage','StripePayoutPage'].includes(currentTab),
      id: 'SubscriptionPageTab',
      linkProps: {
        name: 'SubscriptionPage',
      },
      subTabs: [
        {
          text: <FormattedMessage id="LayoutWrapperProfileSettingsSideNav.SkillsTabTitle" />,
          selected: currentTab === 'ProfileSkillsPage',
          hidden: !hasSubscription,
          id: 'ProfileSkillsPage',
          linkProps: {
            name: 'ProfileSkillsPage',
          },
        },
        {
          text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentsTabTitle" />,
          selected: currentTab === 'StripePayoutPage',
          hidden: !countryIsSupportedByStripe || !hasSubscription,
          id: 'StripePayoutPageTab',
          linkProps: {
            name: 'StripePayoutPage',
          },
        },
      ],
    },
    {
      text: <FormattedMessage id="LayoutWrapperProfileSettingsSideNav.ManufacturerTabTitle" />,
      selected: currentTab === 'ProfileManufacturerPage',
      hidden: !isManufacturer,
      id: 'ProfileManufacturerPage',
      linkProps: {
        name: 'ProfileManufacturerPage',
      },
    },
  ];

  return <LayoutWrapperSideNav tabs={tabs} />;
};
