import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUserSubscription } from '../../ducks/user.duck';
import { useTranslation } from '../../hooks';
import css from './ProfileSkillsForm.module.css';

export const GoldSubscriptionDetails = () => {
  const subscription = useSelector(selectCurrentUserSubscription);
  const setTranslation = useTranslation('ProfileSkillsPage');

  if (subscription !== 'gold') {
    return null;
  }

  const details = [
    setTranslation('subscriptionDescription1'),
    setTranslation('subscriptionDescription2'),
    setTranslation('subscriptionDescription3'),
    setTranslation('subscriptionDescription4'),
    setTranslation('subscriptionDescription5'),
    setTranslation('subscriptionDescription6'),
  ];

  const statusClass = classNames(css.subscriptionStatusButton, css.bg_gold);

  return (
    <div className={css.subscriptionDetails}>
      <div className={css.subscriptionTitle}>
        {setTranslation('subscriptionTitle')}
      </div>
      <div className={statusClass}>
        {setTranslation('SubscriptionPage.gold')}
      </div>
      <div className={css.subscriptionDetailsListContainer}>
        <ul className={css.subscriptionDetailsList}>
          {details.map((detail, i) =>
            <li key={i} className={css.subscriptionDetailsListItem}>{detail}</li>,
          )}
        </ul>
      </div>
    </div>
  );
};
