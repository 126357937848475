import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCircleThreeIndustry.module.css';

const IconCircleThreeIndustry = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="45"
      height="45"
      viewBox="0 0 45 45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 2.5a19.93 19.93 0 0 0-20 20 19.93 19.93 0 0 0 20 20 19.93 19.93 0 0 0 20-20 19.93 19.93 0 0 0-20-20zm0 38c-9.9 0-18-8.1-18-18s8.1-18 18-18 18 8.1 18 18-8.1 18-18 18zm2.6-18.2c.9-.4 1.5-.9 2-1.6s.8-1.5.8-2.2c0-1.6-.5-2.8-1.4-3.7-1-.9-2.3-1.3-3.9-1.3s-2.9.5-3.9 1.4-1.5 2.1-1.5 3.5H17c0-.9.3-1.7.9-2.2.6-.6 1.4-.8 2.3-.8 2.1 0 3.1 1 3.1 3.1 0 .9-.3 1.7-.9 2.2s-1.4.8-2.5.8H18v1.8h1.7c1.2 0 2.1.3 2.7.9s1 1.3 1 2.4-.3 1.9-.9 2.5-1.4.9-2.5.9c-1 0-1.8-.3-2.5-.8-.6-.6-.9-1.3-.9-2.2h-2.3c0 1.5.5 2.7 1.6 3.6S18.3 32 20 32s3-.5 4.1-1.4c1-.9 1.6-2.2 1.6-3.8 0-1-.3-1.9-.8-2.6-.6-1.1-1.4-1.6-2.3-1.9z"/>
    </svg>
  );
};

IconCircleThreeIndustry.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCircleThreeIndustry.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCircleThreeIndustry;
