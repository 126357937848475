import React, { Component } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';
import config from '../../config';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { required, bookingDateRequired, composeValidators, maxLength } from '../../util/validators';
import { createSlug } from '../../util/urlHelpers';
import * as validators from '../../util/validators';
import { DELIVERY_DATE } from '../../util/dates';
import { propTypes } from '../../util/types';
import { Form, IconSpinner, PrimaryButton, NamedLink } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import { types as sdkTypes } from '../../util/sdkLoader';
import Breakdown from './Breakdown';

import css from './BookingDatesForm.module.css';

const identity = v => v;
const { Money } = sdkTypes;
const SERVICE_MAX_LENGTH = 500;

export class BookingDatesFormNotAuthorizedComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      step: 1 };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);

  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    const { date } = e.bookingDates || {};
    if (!date) {
      e.preventDefault();
      this.setState({ focusedInput: DELIVERY_DATE });
    } else {
      this.props.onSubmit(e);
    }
  }


  render() {
    const { rootClassName, className, price: unitPrice, listingId, listing, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    return (
      <FinalForm
        {...rest}
        //unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            handleSubmit,
            intl,
            hasStripeAccount,
            isAuthenticated,
            isExpert,
            isOwnListing,
            addressMissing,
            taxIdMissing,
            hasDeadlineDatePassed,
          } = fieldRenderProps;

          const { title = '' } = listing.attributes;
          const slug = createSlug(title);

          const infoTextNotRegistered = intl.formatMessage({
            id: 'BookingDatesFormNotAuthorized.infoTextNotRegistered',
            });

          const infoTextNotAuthorized =intl.formatMessage({
            id: 'BookingDatesFormNotAuthorized.infoTextNotAuthorized',
          });

          const infoTextNotExpert =intl.formatMessage({
            id: 'BookingDatesFormNotAuthorized.infoTextNotExpert',
          });

          const infoTextOwnListing =intl.formatMessage({
            id: 'BookingDatesFormNotAuthorized.infoTextOwnListing',
          });

          const missingInformation = addressMissing;

          const missingInformationLinkName = missingInformation ? "ProfileAddressPage" : null;

          const missingInformationButtonText = addressMissing ?
            <FormattedMessage id="ModalMissingInformation.gotoAddress" /> : null;

          const infoTextMissingInformation = addressMissing ?
            <FormattedMessage id="ModalMissingInformation.missingAddressText" /> : null;



          const signInContainer = (
            <div className={css.informationText}>{infoTextNotRegistered}
              <div className={css.buttonContainer}>
              <NamedLink
                name="LoginPage"
                to={{
                  state: {slug, listingId, state: 'redirect'}
                }}
                className={css.button1}
              >
                <FormattedMessage id="BookingDatesFormNotAuthorized.signInButton" />
              </NamedLink>
            </div>
          </div>
          );

          const stripeAccountContainer = (
            <div className={css.informationText}>{infoTextNotAuthorized}
            <div className={css.buttonContainer}>
              <NamedLink
                name="StripePayoutPage"
                to={{
                  search:
                    '',
                }}
                className={css.button1}
              >
                <FormattedMessage id="BookingDatesFormNotAuthorized.payoutButton" />
              </NamedLink>
          </div>
        </div>
          );

          const notExpertContainer = (
            <div className={css.informationText}>{infoTextNotExpert}
            <div className={css.buttonContainer}>
              <NamedLink
                name="ProfileSkillsPage"
                to={{
                  search:
                    '',
                }}
                className={css.button1}
              >
                <FormattedMessage id="BookingDatesFormNotAuthorized.experttButton" />
              </NamedLink>
          </div>
        </div>
          );

          const isOwnListingContainer = (
            <div className={css.informationText}>{infoTextOwnListing}
          </div>
          );

          const missingInformationContainer = (
            <div className={css.informationText}>
              {infoTextMissingInformation}
              <div className={css.buttonContainer}>
                <NamedLink
                  name={missingInformationLinkName}
                  to={{
                    search:
                      '',
                  }}
                  className={css.button1}
                >
                  {missingInformationButtonText}
                </NamedLink>
              </div>
            </div>);

        const deadlinePassedInformation = (
          <div className={css.informationText}>
            <FormattedMessage id="BookingDatesFormNotAuthorized.deadlinePassedInformation" />
          </div>
        );

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
            {
              hasDeadlineDatePassed ? deadlinePassedInformation :
              isAuthenticated && !hasStripeAccount && isExpert && !isOwnListing ? stripeAccountContainer :
              isAuthenticated && !hasStripeAccount && !isExpert && !isOwnListing ? notExpertContainer :
              isOwnListing ? isOwnListingContainer :
              isAuthenticated && hasStripeAccount && isExpert && missingInformation ?
              missingInformationContainer : !isAuthenticated ? signInContainer : null
            }
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormNotAuthorizedComponent.defaultProps = {
  rootClassName: null,
  className: null,
  isOwnListing: false,
};

BookingDatesFormNotAuthorizedComponent.propTypes = {
  rootClassName: string,
  className: string,

  isOwnListing: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingDatesFormNotAuthorized = compose(injectIntl)(BookingDatesFormNotAuthorizedComponent);
BookingDatesFormNotAuthorized.displayName = 'BookingDatesFormNotAuthorized';

export default BookingDatesFormNotAuthorized;
