import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCircleSix.module.css';

const IconCircleSix = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.5 0C7.8 0 0 7.8 0 17.5S7.8 35 17.5 35 35 27.2 35 17.5 27.2 0 17.5 0zm0 32C9.5 32 3 25.5 3 17.5S9.5 3 17.5 3 32 9.5 32 17.5 25.5 32 17.5 32zm.7-17.1c-1.3 0-2.4.4-3.2 1.3.2-1.4.7-2.5 1.6-3.3s2-1.2 3.5-1.2h.3V9.5h-.3c-2.5 0-4.4.8-5.8 2.3-1.3 1.6-2 3.7-2 6.4v.9c0 1.9.5 3.4 1.5 4.5 1 1.2 2.2 1.7 3.8 1.7 1.5 0 2.7-.5 3.7-1.5.9-1 1.4-2.3 1.4-3.9 0-1.5-.4-2.8-1.2-3.8-.8-.7-1.9-1.2-3.3-1.2zm1.2 7.6c-.5.6-1.1.9-1.8.9s-1.4-.3-1.9-1-.7-1.6-.7-2.8v-.8a3.25 3.25 0 0 1 1-1.3c.5-.3 1-.5 1.6-.5.8 0 1.4.3 1.8.9.5.6.7 1.4.7 2.3s-.2 1.7-.7 2.3z"/>
    </svg>
  );
};

IconCircleSix.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCircleSix.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCircleSix;
