import React from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';

// react-dates needs to be initialized before using any react-dates component
// https://github.com/airbnb/react-dates#initialize
// NOTE: Initializing it here will initialize it also for app.test.js
import 'react-dates/initialize';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import difference from 'lodash/difference';
import mapValues from 'lodash/mapValues';
import configureStore from './store';
import { IncludeAnalyticsScripts } from './util/includeScripts';
import routeConfiguration from './routeConfiguration';
import Routes from './Routes';
import config from './config';
import ConnectedIntlProvider from './components/ConnectedIntlProvider/ConnectedIntlProvider';

// Flex template application uses English translations as default.

// If you want to change the language, change the imports to match the wanted locale:
//   1) Change the language in the config.js file!
//   2) Import correct locale rules for Moment library
//   3) Use the `messagesInLocale` import to add the correct translation file.
//   4) To support older browsers we need add the correct locale for intl-relativetimeformat to `util/polyfills.js`

// Note that there is also translations in './translations/countryCodes.js' file
// This file contains ISO 3166-1 alpha-2 country codes, country names and their translations in our default languages
// This used to collect billing address in StripePaymentAddress on CheckoutPage

// Step 2:
// If you are using a non-english locale with moment library,
// you should also import time specific formatting rules for that locale
// e.g. for French: import 'moment/locale/fr';

// Step 3:
// If you are using a non-english locale, point `messagesInLocale` to correct .json file

// If translation key is missing from `messagesInLocale` (e.g. fr.json),
// corresponding key will be added to messages from `defaultMessages` (en.json)
// to prevent missing translation key errors.
const isTestEnv = process.env.NODE_ENV === 'test';

// Locale should not affect the tests. We ensure this by providing
// messages with the key as the value of each message.

export const ClientApp = props => {
  const { store } = props;
  return (
      <Provider store={store}>
        <ConnectedIntlProvider>
          <HelmetProvider>
            <IncludeAnalyticsScripts />
            <BrowserRouter>
              <Routes routes={routeConfiguration('en')} />
            </BrowserRouter>
          </HelmetProvider>
        </ConnectedIntlProvider>
      </Provider>
  );
};

const { any, string } = PropTypes;

ClientApp.propTypes = { store: any.isRequired };

export const ServerApp = props => {
  const { url, context, helmetContext, store } = props;
  HelmetProvider.canUseDOM = false;
  return (
      <Provider store={store}>
        <ConnectedIntlProvider>
          <HelmetProvider context={helmetContext}>
            <IncludeAnalyticsScripts />
            <StaticRouter location={url} context={context}>
              <Routes routes={routeConfiguration()} />
            </StaticRouter>
          </HelmetProvider>
        </ConnectedIntlProvider>
      </Provider>
  );
};

ServerApp.propTypes = { url: string.isRequired, context: any.isRequired, store: any.isRequired };

/**
 * Render the given route.
 *
 * @param {String} url Path to render
 * @param {Object} serverContext Server rendering context from react-router
 * @param {Object} store Serverside created store
 *
 * @returns {Object} Object with keys:
 *  - {String} body: Rendered application body of the given route
 *  - {Object} head: Application head metadata from react-helmet
 */
export const renderApp = (url, serverContext, preloadedState, collectChunks, store) => {
  const helmetContext = {};

  // When rendering the app on server, we wrap the app with webExtractor.collectChunks
  // This is needed to figure out correct chunks/scripts to be included to server-rendered page.
  // https://loadable-components.com/docs/server-side-rendering/#3-setup-chunkextractor-server-side
  const WithChunks = collectChunks(
    <ServerApp url={url} context={serverContext} helmetContext={helmetContext} store={store} />
  );
  const body = ReactDOMServer.renderToString(WithChunks);
  const { helmet: head } = helmetContext;
  return { head, body };
};
