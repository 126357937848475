import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  IconClose,
  IconSpinner,
} from '../../components';
import { selectCurrentUserSubscription } from '../../ducks/user.duck';
import { useTranslation } from '../../hooks';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ProfileSkillsForm.module.css';

export const SectionDocumentUpload = ({
  documents,
  documentType,
  uploadButtonId,
  uploadFileInProgress,
  onUploadHandler,
  uploadFileError,
  handleLink,
  handleDeleteFile,
  wrongFileTypeErrorMessage,
}) => {
  const subscription = useSelector(selectCurrentUserSubscription);
  const setTranslation = useTranslation('ProfileSkillsForm');
  const currentUser = useSelector(rx => rx.user.currentUser);
  const [allDocumentsVisible, setAllDocumentsVisible] = useState(false);

  const DOCUMENT_LIMIT_THRESHOLD = 10;

  const isGoldSubscription = subscription === 'gold';

  if (!isGoldSubscription) {
    return null;
  }

  const cld = new Cloudinary({
    cloud: {
      cloudName: 'lightshift',
    },
    secure: true,
  });

  const uploadErrorOccurred = uploadFileError === `filetype isn't correct: ${documentType}`;
  const specificFolder = `freelancer_${documentType}`;

  const allDocuments = documents
  ?.resources
  ?.filter(doc => doc.folder === specificFolder)
  ?.map(doc => ({
    id: doc.asset_id,
    publicId: doc.public_id,
    secureUrl: doc.secure_url,
    image: cld.image(doc.public_id)
              .format('jpg')
              .resize(fill(210, 297)),
  })) ?? [];

  const documentCount = allDocuments.length;

  const shownDocuments = allDocumentsVisible
    ? allDocuments
    : allDocuments.slice(0, 3);

  const uploadFile = e => {
    const file = e.target.files[0];
    onUploadHandler(e, null, file, documentType, currentUser.id.uuid);
  };

  return (
    <div className={css.cloudinaryContainer}>
      <div className={css.headline}>
        {setTranslation(`${documentType}Headline`)}
      </div>

      <div className={css.tip}>
        {documentCount >= DOCUMENT_LIMIT_THRESHOLD
          ? setTranslation('limitReachedMessage')
          : setTranslation('uploadInformationText')}
      </div>

      {documentCount < DOCUMENT_LIMIT_THRESHOLD &&
        <div className={css.uploadButton}>
          <input
            className={css.uploadInput}
            id={uploadButtonId}
            name={uploadButtonId}
            type='file'
            accept='image/jpg,application/pdf'
            onChange={uploadFile}
          />
          <label htmlFor={uploadButtonId} className={css.addFile}>
            {uploadFileInProgress?.params === documentType
              ? <IconSpinner/>
              : setTranslation('chooseFile')
            }
          </label>
        </div>
      }

      {uploadErrorOccurred &&
        <div className={css.uploadError}>
          {setTranslation(wrongFileTypeErrorMessage)}
        </div>
      }

      {documentCount > 0 &&
        <div className={css.documentsWrapper}>
          {shownDocuments.map((doc, idx) =>
            <div key={idx} className={css.imageCard}>
              <div
                className={css.removeButton}
                onClick={() => handleDeleteFile(doc.publicId, currentUser.id.uuid)}
              >
                <IconClose
                  size='normal' className={css.removeIcon}
                />
              </div>
              <AdvancedImage
                cldImg={doc.image} className={css.image} onClick={(e) => handleLink(doc.secureUrl)}
              />
            </div>,
          )}
        </div>
      }

      {documentCount > 3 &&
        <Button
          rootClassName={css.seeAllButton}
          onClick={() => setAllDocumentsVisible(!allDocumentsVisible)}
        >
          {!allDocumentsVisible
            ? <FormattedMessage
              id='ProfileSkillsForm.SeeAllButton' values={{ count: documentCount }}
            />
            : <FormattedMessage id='ProfileSkillsForm.SeeLessButton'/>
          }
        </Button>
      }
    </div>
  );
};
