import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCircleOne.module.css';

const IconCircleOne = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.5 0C7.8 0 0 7.8 0 17.5S7.8 35 17.5 35 35 27.2 35 17.5 27.2 0 17.5 0zm0 32C9.5 32 3 25.5 3 17.5S9.5 3 17.5 3 32 9.5 32 17.5 25.5 32 17.5 32zM13 11.9v2.2l3.8-1.3v12.5h2.7V9.7h-.3z"/>
    </svg>
  );
};

IconCircleOne.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCircleOne.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCircleOne;
