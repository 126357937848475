import React from 'react';
import { Helmet } from 'react-helmet-async';

const GOOGLE_ANALYTICS_SCRIPT_ID = 'GoogleAnalyticsScript';
const MATOMO_SCRIPT_ID = 'MatomoScript';
const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
const PUBLIC_URL = process.env.REACT_APP_CANONICAL_ROOT_URL;
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const isCookiesAccepted = () => {
  const cookies = typeof window !== 'undefined' && document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
  }, {});
  return cookies && cookies.euCookiesAccepted === '1';
}

export const IncludeAnalyticsScripts = () => {
  let scripts = [];

  if (typeof window !== 'undefined') {

    if(isCookiesAccepted()){

      scripts.push(
        <script key={'motomo'} type="text/javascript" id={MATOMO_SCRIPT_ID}>
          {`
          var _paq = window._paq || [];
          _paq.push(["setCookieDomain", "*.lightshift.co"]);
          _paq.push(['enableHeartBeatTimer']);
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);

          var u="https://stats.dial.de/";
          _paq.push(['setTrackerUrl', u+'matomo.php']);
          _paq.push(['setSiteId', '18']);
          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js';
          document.head.appendChild(g);
          `}
        </script>
      )

      scripts.push(<script key={'ga4'} async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}></script>);
      scripts.push(
        <script key={'ga4sn'}>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());

          gtag('config', '${googleAnalyticsId}');
          `}
        </script>
      )
    }

    if (window.mapboxgl && !window.mapboxgl.accessToken) {
      window.mapboxgl.accessToken = MAPBOX_TOKEN;
    }
  }

  const onScriptsLoaded = () => {
  }


  // React Helmet Async doesn't support onLoad prop for scripts.
  // However, it does have onChangeClientState functionality.
  // We can use that to start listen 'load' events when the library is added on client-side.
  const onChangeClientState = (newState, addedTags) => {
    if (addedTags && addedTags.scriptTags) {
      const foundScript = addedTags.scriptTags.find(s =>
        [GOOGLE_ANALYTICS_SCRIPT_ID].includes(s.id)
      );
      if (foundScript) {
        foundScript.addEventListener('load', onScriptsLoaded, { once: true });
      }
    }
  };

  return <Helmet onChangeClientState={onChangeClientState}>{scripts}</Helmet>;
};
