import React from 'react';
import PropTypes from 'prop-types';
import { bool, func, node, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { TopbarSearchForm } from '../../forms';
import { propTypes } from '../../util/types';
import { IconClose } from '../../components';
import { parseSelectFilterOptions, findOptionLabel } from '../../util/search';

import css from './SearchFiltersPrimary.module.css';

const SearchFiltersPrimaryComponent = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    isSecondaryFiltersOpen,
    toggleSecondaryFiltersOpen,
    selectedSecondaryFiltersCount,
    initialSearchFormValues,
	  clearAllButton,
    search,
    keywordSearch,
    searchPageType,
    urlQueryParams,
    getHandleChangedValueFn,
    filterConfig,
    expertCount,
    countryCount
  } = props;

  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(rootClassName || css.root, className);

  const toggleSecondaryFiltersOpenButtonClasses =
    isSecondaryFiltersOpen || selectedSecondaryFiltersCount > 0
      ? css.searchFiltersPanelOpen
      : css.searchFiltersPanelClosed;
  const toggleSecondaryFiltersOpenButton = toggleSecondaryFiltersOpen ? (
    <button
      className={toggleSecondaryFiltersOpenButtonClasses}
      onClick={() => {
        toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
      }}
    >
      <FormattedMessage
        id="SearchFiltersPrimary.moreFiltersButton"
        values={{ count: selectedSecondaryFiltersCount }}
      />
    </button>
  ) : null;


  let messageIDSearchType = "SearchFiltersPrimary.JobResults";
    if(searchPageType==='job'){
      messageIDSearchType = "SearchFiltersPrimary.JobResults";
    }
    else if(searchPageType==='expert'){
      messageIDSearchType = "SearchFiltersPrimary.ExpertResults";
    }
    else if(searchPageType==='lead'){
      messageIDSearchType = "SearchFiltersPrimary.LeadResults";
    }


  const selectedFiltersTags = urlQueryParams ?
  <div className={css.filterTags}>
    {
      urlQueryParams && Object.keys(urlQueryParams).map((key) => {
        return  urlQueryParams[key].split(',').map(filter => {
          const label = findOptionLabel( filterConfig, key, filter )
          return label && (
            <div className={css.filterTag} >
              <span>{ label }</span>
              <span onClick={() => {
                const selectFilterOptions = parseSelectFilterOptions(urlQueryParams[key])
                selectFilterOptions.splice(selectFilterOptions.indexOf(filter), 1)
                const updatedValue = selectFilterOptions?.length > 0 ? selectFilterOptions.join(',') : null;
                getHandleChangedValueFn(true)({ [key]: updatedValue })}
              }
              >
                <IconClose rootClassName={css.closeIcon} />
              </span>
            </div>
          )}
        )
      })
    }
  </div> : null

  return (
    <div className={classes}>
      <div className={css.filters}>
        {children}
        {toggleSecondaryFiltersOpenButton}
        {selectedFiltersTags}
        {clearAllButton}
      </div>
      {searchInProgress ? (
        <div className={css.loadingResults}>
          <FormattedMessage id="SearchFiltersPrimary.loadingResults" />
        </div>
      ) : null}
      <div className={css.searchOptions}>
      {listingsAreLoaded ? (
        <div className={css.searchResultSummary}>
          <span className={css.resultsFound}>
            <FormattedMessage
              id="SearchFiltersPrimary.foundResults"
            />
          </span>
          <span className={css.results}>
            <FormattedMessage
              id={messageIDSearchType}
              values={{ count: resultsCount, expertCount: expertCount, countryCount: countryCount  }}
            />
          </span>
        </div>
      ) : null}
      <div className={css.results}>
        {sortByComponent}
      </div>
    </div>
    {hasNoResult ? (
      <div className={css.noSearchResults}>
        <FormattedMessage id="SearchFiltersPrimary.noResults" />
      </div>
    ) : null}
    </div>
  );
};

const { object } = PropTypes;

SearchFiltersPrimaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
  sortByComponent: null,
  initialSearchFormValues: {},
};

SearchFiltersPrimaryComponent.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
  sortByComponent: node,
  initialSearchFormValues: object,
};

const  SearchFiltersPrimary = SearchFiltersPrimaryComponent;

export default SearchFiltersPrimary;
