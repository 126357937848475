import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  LanguageSwitch,
  IconWorldwide,
} from '../../components';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onLanguageSwitchDE,
    onLanguageSwitchFR,
    onLanguageSwitchEN,
    onLanguageSwitchES,
    onLanguageSwitchIT,
    onSearchSubmit,
    initialSearchFormValues,
    viewportWidth,
    isDistinguishPage
  } = props;
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const isManufacturer = currentUser ? currentUser.attributes.profile.metadata.isManufacturer : false;

  const classes = classNames(rootClassName || css.root, className);

  const MAX_MOBILE_SCREEN_WIDTH = 1120;
  const smallLogoView = viewportWidth < MAX_MOBILE_SCREEN_WIDTH;

  const notificationDot = notificationCount && notificationCount.total > 0 ? <div className={css.notificationDot} /> : null;
  const messagesNotificationDot = notificationCount && notificationCount.messagesTotal > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: 'client_interactions' }}
      activeClassName={css.active}
      matchingRoutes={[
        'InboxBasePage', 'InboxPage'
      ]}>

      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const messagesLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="MessagesPage"
      activeClassName={css.active}
      matchingRoutes={[
        'MessagesBasePage', 'MessagesPage'
      ]}>

      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.messages" />
        {messagesNotificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  //LanguageSwitch
  const languageSwitch =
    <LanguageSwitch>
      <MenuLabel className={css.languageLabel} isOpenClassName={css.languageSwitchIsOpen}>
      <IconWorldwide className={css.globe}>
      </IconWorldwide>
      </MenuLabel>
      <MenuContent className={css.languageSwitchContent}>
        <MenuItem key="English">
            <InlineTextButton rootClassName={css.languageSwitchButton} onClick={onLanguageSwitchEN}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.LanguageSwitchEnglish" />
            </InlineTextButton>
        </MenuItem>
        <MenuItem key="German">
            <InlineTextButton rootClassName={css.languageSwitchButton} onClick={onLanguageSwitchDE}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.LanguageSwitchGerman" />
            </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </LanguageSwitch>
    ;
  //LanguageSwitch

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
    { smallLogoView ?
      <div className={css.logoLink}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="mobile"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      </div>
       :
      <div className={css.logoLink}>
        <NamedLink className={css.logoLink} name="LandingPage">
          <Logo
            format="desktop"
            className={css.logo}
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          />
        </NamedLink>
      </div>
    }
      <div className={css.navItems}>
        <NamedLink className={css.createListingLink} name="ExpertsListingPage"
                   activeClassName={css.active}
                   matchingRoutes={[
                     'ProfilePage'
                   ]}>
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.showExpertListings" />
          </span>
        </NamedLink>
        <NamedLink className={css.createListingLink} name="SearchPage" to={{search: '' }}
                   activeClassName={css.active}
                   matchingRoutes={[
                     'ListingPage', 'ExamplePage'
                   ]}
          >
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.showJobListings" />
          </span>
        </NamedLink>
        <NamedLink className={css.createListingLink} name="RequestManufacturerPage"
                   activeClassName={css.active}
                   matchingRoutes={[
                     'ProjectPage'
                   ]}>
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.dialuxProjects" />
          </span>
        </NamedLink>

        {isAuthenticated && isManufacturer ?
          <NamedLink className={css.createListingLink} name="LeadsListingPage" to={{search: '' }}
                     activeClassName={css.active}
                     matchingRoutes={[
                       'LeadPage'
                     ]}>
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.showLeadListings" />
          </span>
          </NamedLink> : null
        }
        {inboxLink}
        {messagesLink}
        {profileMenu}
        {signupLink}
        {loginLink}
        {languageSwitch}
      </div>
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: {},
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: object,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
