/**
 * This component wraps React-Router's Redirect by providing name-based routing.
 * (Helps to narrow down the scope of possible format changes to routes.)
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import { connect } from 'react-redux';
import { compose } from 'redux';

const NamedRedirectComponent = props => {
  const { name, search, state, params, push } = props;
  params.lang = props.currentLang;
  const pathname = pathByRouteName(name, routeConfiguration(), params);
  return <Redirect to={{ pathname, search, state }} push={push} />;
};

const { bool, object, string } = PropTypes;

NamedRedirectComponent.defaultProps = { search: '', state: {}, push: false, params: {} };

NamedRedirectComponent.propTypes = {
  name: string.isRequired,
  search: string,
  state: object,
  push: bool,
  params: object,
};

function mapStateToProps(state, ownProps) {
  const { lang } = state.UI;
  const returnProps = {...ownProps}
  const hasOwnParamsLang = ownProps.params?.lang || false;
  if (hasOwnParamsLang) {
    returnProps.currentLang = ownProps.params.lang;
  }
  else if(!returnProps.currentLang) {
    returnProps.currentLang = lang;
  }
  return returnProps;
}

const NamedRedirect = connect(mapStateToProps)(NamedRedirectComponent)

export default NamedRedirect;
