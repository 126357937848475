import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';
import config from '../../config';
import moment from "moment-timezone";
// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 12;

// ================ Action types ================ //
export const SEARCH_EXAMPLE_LISTINGS_REQUEST = 'app/LeadsListingPage/SEARCH_EXAMPLE_LISTINGS_REQUEST';
export const SEARCH_EXAMPLE_LISTINGS_SUCCESS = 'app/LeadsListingPage/SEARCH_EXAMPLE_LISTINGS_SUCCESS';
export const SEARCH_EXAMPLE_LISTINGS_ERROR = 'app/LeadsListingPage/SEARCH_EXAMPLE_LISTINGS_ERROR';

export const LEADS_LISTINGS_REQUEST = 'app/LeadsListingPage/LEADS_LISTINGS_REQUEST';
export const LEADS_LISTINGS_SUCCESS = 'app/LeadsListingPage/LEADS_LISTINGS_SUCCESS';
export const LEADS_LISTINGS_ERROR = 'app/LeadsListingPage/LEADS_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/LeadsListingPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchLeadsListingsError: null,
  searchExampleListingsError: null,
  currentPageResultIds: [],
  currentExampleResultIds:[]
};


const resultIds = data => data.data.map(l => l.id);

const leadsListingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_EXAMPLE_LISTINGS_REQUEST:
      return {
        ...state,
        searchInProgress: true,
        searchListingsError: null,
      };
    case SEARCH_EXAMPLE_LISTINGS_SUCCESS:
      return {
        ...state,
        currentExampleResultIds: resultIds(payload.data),
        searchInProgress: false,
      };
    case SEARCH_EXAMPLE_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchExampleListingsError: payload };
    case LEADS_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchLeadsListingsError: null,
      };
    case LEADS_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case LEADS_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchLeadsListingsError: payload };
    case SEARCH_MAP_SET_ACTIVE_LISTING:
        return {
          ...state,
          activeListingId: payload,
        };
      default:
        return state;
  }
};

export default leadsListingPageReducer;

// ================ Action creators ================ //
export const searchExampleListingsRequest = () => ({
  type: SEARCH_EXAMPLE_LISTINGS_REQUEST,
});

export const searchExampleListingsSuccess = response => ({
  type: SEARCH_EXAMPLE_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchExampleListingsError = e => ({
  type: SEARCH_EXAMPLE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchLeadsListingsRequest = searchParams => ({
  type: LEADS_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchLeadsListingsSuccess = response => ({
  type: LEADS_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchLeadsListingsError = e => ({
  type: LEADS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchLeadsListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchLeadsListingsRequest(searchParams));

    //Deadline-Params
    const listingEndDateUNIX = moment().unix();
    //Deadline-Params

      //Application-Deadline-Filter
      const deadlineSearchParams = (defaultParam, searchParam, type) => {
        const values = searchParam ? searchParam.split(',') : [];
        const hasValues = searchParam && values.length === 2;
        if (!hasValues && type==='application') return {pub_applicationDeadlineUNIX: defaultParam + ','};
        if (!hasValues && type==='realisation') return {pub_realisationEndDateUNIX: defaultParam + ','};
        const startDate = (moment(values[0]).unix());
        const endDate = (moment(values[1]).unix());

        if(hasValues && type==='application'){
          return { pub_applicationDeadlineUNIX: startDate + ',' + endDate};
        }
        if(hasValues && type==='realisation'){
          return { pub_realisationEndDateUNIX: startDate + ',' + endDate};
        }
      }
      //Application-Deadline-Filter
  const { perPage, pub_applicationDeadline, pub_realisationDate, ...rest } = searchParams;
  const applicationDeadlineFilter = deadlineSearchParams(listingEndDateUNIX, pub_applicationDeadline, 'application');
  const realisationDateFilter = deadlineSearchParams(listingEndDateUNIX, pub_realisationDate, 'realisation');
  const listingCategoryFilter = {
    pub_listingCategory: 'lead'
  };

  const params = {
    ...listingCategoryFilter,
    ...realisationDateFilter,
    ...applicationDeadlineFilter,
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchLeadsListingsSuccess(response));
      return response;
    })
    .then(()=> {
      dispatch(searchExampleListings());
    })
    .catch(e => {
      dispatch(searchLeadsListingsError(storableError(e)));
      throw e;
    });
};

export const searchExampleListings = () => (dispatch, getState, sdk) => {
  dispatch(searchExampleListingsRequest());
  const listingCategoryFilter = {
    pub_listingCategory: 'lead'
  };
  const exampleFilter = {
    meta_isExample: true
  };

  const params = {
    ...listingCategoryFilter,
    ...exampleFilter,
    per_page: '8',
    include: ['author','images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData.indoor', 'publicData.outdoor', 'publicData.categories', 'publicData.support', 'publicData.applicationDeadline', 'publicData.applicationDeadlineUNIX', 'publicData.realisationStartDate', 'publicData.realisationEndDate', 'publicData.location', 'publicData.country', 'publicData.postcode', 'publicData.listingCategory', 'metadata' ],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchExampleListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchExampleListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});


export const loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchLeadsListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author','images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData.indoor', 'publicData.outdoor', 'publicData.categories', 'publicData.support', 'publicData.applicationDeadline', 'publicData.applicationDeadlineUNIX', 'publicData.realisationStartDate', 'publicData.realisationEndDate', 'publicData.location', 'publicData.country', 'publicData.postcode', 'metadata' ],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};
