import { denormalisedResponseEntities, ensureOwnListing } from '../util/data';
import { storableError } from '../util/errors';
import {
  transitionsToRequested,
  transitionsToRequestedFromClient,
  isPendingReplyFromCustomer,
  isPendingReplyFromProvider,
  TRANSITION_SEND_MESSAGE_BY_PROVIDER,
  TRANSITION_ENQUIRE,
  TRANSITION_ACCEPT_PROPOSAL_PENDING_REPLY,
  TRANSITION_SEND_MESSAGE_BY_CUSTOMER,
  TRANSITION_SEND_MESSAGE_BY_CUSTOMER_POST_PROPOSAL_ACCEPTED,
  TRANSITION_SEND_MESSAGE_BY_CUSTOMER_POST_PROPOSAL_REJECTED,
  TRANSITION_SEND_MESSAGE_BY_PROVIDER_PRE_PROPOSAL_RESPONSE,
  TRANSITION_SEND_MESSAGE_BY_PROVIDER_POST_PROPOSAL_ACCEPTED,
  TRANSITION_SEND_MESSAGE_BY_PROVIDER_POST_PROPOSAL_REJECTED,
} from '../util/transaction';
import { LISTING_STATE_DRAFT } from '../util/types';
import * as log from '../util/log';
import { authInfo } from './Auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import { util as sdkUtil } from '../util/sdkLoader';
import { types as sdkTypes } from '../util/sdkLoader';
import {changeUILanguage} from './UI.duck';
import { fetchInteractionNotificationsCount } from '../util/api';

const { Money } = sdkTypes;

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const UPDATE_PROFILE_SKILLS_REQUEST = 'app/user/UPDATE_PROFILE_SKILLS_REQUEST';
export const UPDATE_PROFILE_SKILLS_SUCCESS = 'app/user/UPDATE_PROFILE_SKILLS_SUCCESS';
export const UPDATE_PROFILE_SKILLS_ERROR = 'app/user/UPDATE_PROFILE_SKILLS_ERROR';
export const SET_CURRENT_USER_HAS_SKILLS_SUCCESS = 'app/user/SET_CURRENT_USER_HAS_SKILLS_SUCCESS';
export const SET_CURRENT_USER_HAS_SKILLS_ERROR = 'app/user/SET_CURRENT_USER_HAS_SKILLS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const UPDATE_PLATFORMLANGUAGE_REQUEST = 'app/user/UPDATE_PLATFORMLANGUAGE_REQUEST';
export const UPDATE_PLATFORMLANGUAGE_SUCCESS = 'app/user/UPDATE_PLATFORMLANGUAGE_SUCCESS';
export const UPDATE_PLATFORMLANGUAGE_ERROR = 'app/user/UPDATE_PLATFORMLANGUAGE_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/user/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/user/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/user/UPDATE_LISTING_ERROR';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  currentUserNotificationCount: { total: 0, clientTransactionsCount: 0, clientTransactions: [], providerTransactionsCount: 0, providerTransactions: [] , proposals: 0, pendingProposalListings: [] },
  currentUserNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  currentUserHasSkills: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  updateProfileSkillsError: null,
  profileSkillsChanged: false,

  updatePlatformLanguageError: null,
  updatePlatformLanguageInProgress: false,
  platformLanguageChanged: false,
  updateListingError: null,
  updateListingInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      return { ...state, currentUser: mergeCurrentUser(state.currentUser, payload) };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, currentUserShowError: payload };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserNotificationCount: {},
        currentUserNotificationCountError: null,
        currentUserHasSkills: null,
      };

    case UPDATE_PROFILE_SKILLS_REQUEST:
     return {
       ...state,
       currentUserHasSkillsError: null,
       updateProfileSkillsInProgress: true,
       profileSkillsChanged: false,
       currentUserHasSkills: payload
      };
    case UPDATE_PROFILE_SKILLS_SUCCESS:
     return {
       ...state,
      currentUserHasSkillsError: null,
      updateProfileSkillsInProgress: false,
      profileSkillsChanged: true,
    };
    case UPDATE_PROFILE_SKILLS_ERROR:
      return {
        ...state,
        updateProfileSkillsInProgress: false,
        updateProfileSkillsError: payload
    };

    case UPDATE_PLATFORMLANGUAGE_REQUEST:
     return {
       ...state,
       updatePlatformLanguageError: null,
       updatePlatformLanguageInProgress: true,
       platformLanguageChanged: false
      };
    case UPDATE_PLATFORMLANGUAGE_SUCCESS:
     return {
       ...state,
      updatePlatformLanguageError: null,
      updatePlatformLanguageInProgress: false,
      platformLanguageChanged: true,
    };
    case UPDATE_PLATFORMLANGUAGE_ERROR:
      return {
        ...state,
        updatePlatformLanguageInProgress: false,
        updatePlatformLanguageError: payload
    };


    case SET_CURRENT_USER_HAS_SKILLS_SUCCESS:
     return { ...state, currentUserHasSkills: payload };
    case SET_CURRENT_USER_HAS_SKILLS_ERROR:
     console.error(payload); // eslint-disable-line
     return { ...state, currentUserHasSkillsError: payload };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return { ...state, currentUserHasListings: payload.hasListings };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:

      const unreadTransactions = t => !(t?.attributes?.metadata?.lastMessageWasRead ?? false)
      const respondByProvider = t => t.attributes.lastTransition === TRANSITION_SEND_MESSAGE_BY_PROVIDER
                                  || t.attributes.lastTransition === TRANSITION_SEND_MESSAGE_BY_PROVIDER_POST_PROPOSAL_ACCEPTED
                                  || t.attributes.lastTransition === TRANSITION_SEND_MESSAGE_BY_PROVIDER_PRE_PROPOSAL_RESPONSE;
      const respondByClient = t => t.attributes.lastTransition === TRANSITION_ENQUIRE
                                || t.attributes.lastTransition === TRANSITION_SEND_MESSAGE_BY_CUSTOMER;


      const pendingInvitations = payload.listingInvitations;
      const clientMessagesNotificationCount = payload
        .clientNotificationTransactions
        .filter(unreadTransactions)
        .filter(respondByProvider)
        ?.length;

      const providerMessagesNotificationCount = payload
        .providerNotificationTransactions
        .filter(unreadTransactions)
        .filter(respondByClient)
        ?.length;

      const clientTransactionNotificationsCount = payload.clientTransactionNotificationsCount - clientMessagesNotificationCount;
      const providerTransactionNotificationsCount = payload.providerTransactionNotificationsCount - providerMessagesNotificationCount;

      return {
        ...state,
        currentUserNotificationCount: {
          total: clientTransactionNotificationsCount + providerTransactionNotificationsCount + payload.proposalsCount + pendingInvitations.length + payload.providerUnseenQuestionsListings.length + payload.customerUnseenQuestionsListings.length,
          messagesTotal: clientMessagesNotificationCount + providerMessagesNotificationCount,
          clientTransactionsCount: payload.clientTransactionNotificationsCount,
          clientTransactions: payload.clientNotificationTransactions,
          providerTransactionsCount: payload.providerTransactionNotificationsCount,
          providerTransactions: payload.providerNotificationTransactions,
          proposals: payload.proposalsCount,
          pendingProposalListings: payload.pendingProposalListings,
          pendingInvitations: pendingInvitations,
          pendingInvitationsCount: pendingInvitations.length,
          providerUnseenQuestionsCount: payload.providerUnseenQuestionsListings.length,
          customerUnseenQuestionsCount: payload.customerUnseenQuestionsListings.length,
          providerUnseenQuestionsListings: payload.providerUnseenQuestionsListings,
          customerUnseenQuestionsListings: payload.customerUnseenQuestionsListings,
        }
      };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserNotificationCountError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };
    case UPDATE_LISTING_REQUEST:
      return {
        ...state,
        updateListingInProgress: true,
        updateListingError: null,
        submittedListingId: null,
        listing: null,
      };
    case UPDATE_LISTING_SUCCESS:
      return {
        ...state,
        updateListingInProgress: false,
        submittedListingId: payload.data.id,
        listing: payload.data,
      };
    case UPDATE_LISTING_ERROR:
      return {
        ...state,
        updateListingInProgress: false,
        updateListingError: payload,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError ||
    user.currentUserHasSkillsError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

//Update profile skills
export const updateProfileSkillsRequest = profileSkills => ({
  type: UPDATE_PROFILE_SKILLS_REQUEST,
  payload: {profileSkills},
});
export const updateProfileSkillsSuccess = () => ({ type: UPDATE_PROFILE_SKILLS_SUCCESS });
export const updateProfileSkillsError = error => ({
  type: UPDATE_PROFILE_SKILLS_ERROR,
  payload: error,
  error: true,
});
//

//
export const updatePlatformLanguageRequest = () => ({ type: UPDATE_PLATFORMLANGUAGE_REQUEST });
export const updatePlatformLanguageSuccess = () => ({ type: UPDATE_PLATFORMLANGUAGE_SUCCESS });
export const updatePlatformLanguageError = error => ({
  type: UPDATE_PLATFORMLANGUAGE_ERROR,
  payload: error,
  error: true,
});

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

//
const setCurrentUserHasSkillsSuccess = profileSkills => ({
  type: SET_CURRENT_USER_HAS_SKILLS_SUCCESS,
  payload: {profileSkills},
});
//

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = hasListings => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings },
});

const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = payload => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: payload,
});

const fetchCurrentUserNotificationsError = e => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = hasOrders => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

export const updateListing = requestAction (UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction (UPDATE_LISTING_SUCCESS);
export const updateListingError = error => ({
  type: UPDATE_LISTING_ERROR,
  payload: error,
  error: true,
});
// ================ Thunks ================ //
export function requestUpdateListing(data) {

  const title = data.data.data.attributes.profile.displayName;
  const profileListingID = data.data.data.attributes.profile.privateData.listingID;
  const country = data.data.data.attributes.profile.publicData.country;
  const hourlyRate = data.data.data.attributes.profile.publicData.hourlyRate;
  const homepage = data.data.data.attributes.profile.publicData.homepage;
  const jobTitle = data.data.data.attributes.profile.publicData.jobTitle;
  const language = data.data.data.attributes.profile.publicData.language;
  const profileSkills = data.data.data.attributes.profile.publicData.profileSkills;
  const setService = data.data.data.attributes.profile.publicData.setService;
  const description = data.data.data.attributes.profile.bio;
  const pricedraft = new Money(1500,"EUR");
  const listingCategory = 'expert';
  const expertOption = data.data.data.attributes.profile.publicData.expertOption;
  const defaultAvailabilityPlan = {
    type: 'availability-plan/day',
    entries: [
      { dayOfWeek: 'mon', seats: 1 },
      { dayOfWeek: 'tue', seats: 1 },
      { dayOfWeek: 'wed', seats: 1 },
      { dayOfWeek: 'thu', seats: 1 },
      { dayOfWeek: 'fri', seats: 1 },
      { dayOfWeek: 'sat', seats: 1 },
      { dayOfWeek: 'sun', seats: 1 },
    ],
  };

  const newData = {
    id: profileListingID,
    description: description,
    title: title,
    price: pricedraft,
    availabilityPlan: defaultAvailabilityPlan,
    publicData: {
      listingCategory: listingCategory,
      country: country,
      hourlyRate: hourlyRate,
      homepage: homepage,
      jobTitle: jobTitle,
      language: language,
      profileSkills: profileSkills,
      setService: setService,
      expertOption: expertOption,
    }
  }

  return (dispatch, getState, sdk) => {
    //Create a UTC and UNIX timestamp for the listing-end-date
    dispatch(updateListing(newData));

    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    };

    return sdk.ownListings
      .update(newData)
      .then(response => {

        // Modify store to understand that we have created listing and can redirect away
        dispatch(updateListingSuccess(response));
        return response;
      })
      .catch(e => {
        log.error(e, 'create-listing-failed', { listingData: newData });
        return dispatch(updateListingError(storableError(e)));
      });
  };
}

//Update profile skills
const requestUpdateProfileSkills = params => (dispatch, getState, sdk) => {
  const dialux = params.dialux;
  const lightingDesign = params.lightingDesign;
  const documentation = params.documentation;
  const photoWork = params.photoWork;
  const others = params.others;


  return sdk.currentUser
    .updateProfile(
      { publicData: {
        dialux: dialux,
        lightingDesign: lightingDesign,
        documentation: documentation,
        photoWork: photoWork,
        others: others
       } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      dispatch(queryCurrentUser());
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(updateProfileSkillsError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};
//

export const updatePlatformLanguage = params => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  if(currentUser) {
    dispatch(updatePlatformLanguageRequest());
    if(currentUser.platformLanguage === params.lang) return dispatch(updatePlatformLanguageSuccess());
    return (
      dispatch(requestUpdatePlatformLanguage(params))
        .then(user => {
          dispatch(currentUserShowSuccess(user));
          dispatch(updatePlatformLanguageSuccess());
        })
        // error action dispatched in requestSaveTechnologySkills
        .catch(e => null)
    );
  }
  dispatch(updatePlatformLanguageRequest());
  return dispatch(updatePlatformLanguageSuccess());
};

const requestUpdatePlatformLanguage = params => (dispatch, getState, sdk) => {
  return sdk.currentUser
    .updateProfile(
      { privateData: { platformLanguage: params.lang } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(updatePlatformLanguageError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    page: 1,
    per_page: 1,
  };

  return sdk.ownListings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 0;

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(fetchCurrentUserHasListingsSuccess(!!hasPublishedListings));
    })
    .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

export const fetchCurrentUserNotifications = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserNotificationsRequest());

  const apiQueryParams = {
    only: 'sale',
    include: [
      'listing',
    ],
    last_transitions: transitionsToRequested,
    page: 1,
    per_page: NOTIFICATION_PAGE_SIZE,
  };

  const apiOrderQueryParams = {
    only: 'order',
    include: [
      'listing',
    ],
    last_transitions: transitionsToRequestedFromClient,
    page: 1,
    per_page: NOTIFICATION_PAGE_SIZE,
  };

  const currentUser = getState().user.currentUser;
  const userListingId = currentUser && currentUser.attributes && currentUser.attributes.profile.privateData.listingID;
  sdk.transactions
    .query(apiQueryParams)
    .then(saleTransactionsResponse => {
      sdk.transactions
        .query(apiOrderQueryParams)
        .then(orderTransactionsResponse => {
          const clientNotificationTransactions = orderTransactionsResponse.data.data;
          const providerNotificationTransactions = saleTransactionsResponse.data.data;

          //console.log({
          //  clientNotificationTransactions,
          //  providerNotificationTransactions
          //});

          fetchInteractionNotificationsCount({userId: currentUser.id.uuid, userListingId: userListingId})
          .then(res => {
            const providerTransactionNotificationsCount = providerNotificationTransactions.length;
            const clientTransactionNotificationsCount = clientNotificationTransactions.length;
            const proposalsCount = res.data.data.proposals_notifications_count;
            const pendingProposalListings = res.data.data.pending_proposal_listings;
            const listingInvitations = res.data.data.invitations;
            const providerUnseenQuestionsCount = res.data.data.provider_unseen_questions_count;
            const customerUnseenQuestionsCount = res.data.data.customer_unseen_questions_count;
            const providerUnseenQuestionsListings = res.data.data.provider_unseen_questions_listings;
            const customerUnseenQuestionsListings = res.data.data.customer_unseen_questions_listings;
            dispatch(fetchCurrentUserNotificationsSuccess({providerNotificationTransactions, providerTransactionNotificationsCount, clientNotificationTransactions, clientTransactionNotificationsCount, proposalsCount, pendingProposalListings, listingInvitations, providerUnseenQuestionsCount, customerUnseenQuestionsCount, providerUnseenQuestionsListings, customerUnseenQuestionsListings }));
          })

        })
    })
    .catch(e => dispatch(fetchCurrentUserNotificationsError(storableError(e))));
};

export const fetchCurrentUser = (params = null) => (dispatch, getState, sdk) => {
  dispatch(currentUserShowRequest());
  const { isAuthenticated } = getState().Auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const parameters = params || {
    include: ['profileImage', 'stripeAccount'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.square-xsmall',
      'variants.square-xsmall2x',
    ],
    'imageVariant.square-xsmall': sdkUtil.objectQueryString({
      w: 40,
      h: 40,
      fit: 'crop',
    }),
    'imageVariant.square-xsmall2x': sdkUtil.objectQueryString({
      w: 80,
      h: 80,
      fit: 'crop',
    }),
  };

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      const attributes = currentUser.attributes;
      const profile = attributes.profile;
      const publicData = profile.publicData;
      const privateData = profile.privateData;

      const profileSkills = publicData.profileSkills || '';

      if (profileSkills) {
        dispatch(setCurrentUserHasSkillsSuccess(profileSkills));
      }

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);
      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(currentUser => {
      const platformLanguage = currentUser.attributes.profile.privateData.platformLanguage;
      const uiLanguage = getState().UI.lang;
      if (platformLanguage != uiLanguage) {
        dispatch(updatePlatformLanguage({lang: uiLanguage}));
      }
      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchCurrentUserNotifications());
      if (!currentUser.attributes.emailVerified) {
        dispatch(fetchCurrentUserHasOrders());
      }

      // Make sure auth info is up to date
      dispatch(authInfo());
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(currentUserShowError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};


//Update ProfileSkills

const updateSkills = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestUpdateProfileSkills(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(updateProfileSkillsSuccess());
      })
      // error action dispatched in requestSaveTechnologySkills
      .catch(e => null)
  );
};

 export const updateProfileSkills = params => (dispatch, getState, sdk) => {
  let {
    dialux,
    lightingDesign,
    documentation,
    photoWork,
    others
  } = params.publicData;

  if (dialux === ''){
    dialux = false;
  };
  if (lightingDesign === ''){
    lightingDesign = false;
  };
  if (documentation === ''){
    documentation = false;
  };
  if (photoWork === ''){
    photoWork = false;
  };
  if (others === ''){
    others = false;
  };

    const profileSkills = {
      dialux,
      lightingDesign,
      documentation,
      photoWork,
      others
    };

  dispatch(updateProfileSkillsRequest(profileSkills));
    return dispatch(updateSkills({
      dialux,
      lightingDesign,
      documentation,
      photoWork,
      others
    }));

};
const queryCurrentUser = params => (dispatch, getState, sdk) => {
  return sdk.currentUser
  .show()
  .then(response => {
    const profileListingID = response.data.data.attributes.profile.privateData.listingID;
    if(profileListingID){
      dispatch(requestUpdateListing(response));
    }

  })
  .catch(e => {
    dispatch(updateListingError(storableError(e)));
    // pass the same error so that the SAVE_BCONS_SUCCESS
    // action will not be fired
    throw e;
  });
};

export const selectCurrentUserCountryCode = rx => rx.user?.currentUser?.attributes?.profile?.privateData?.countryCode ?? ''
export const selectCurrentUserCountry = rx => rx.user?.currentUser?.attributes?.profile?.publicData?.country ?? ''
export const selectCurrentUserSubscription = rx => rx.user?.currentUser?.attributes?.profile?.metadata?.subscription || '';
export const selectHasGoldSubscription = rx => rx.user?.currentUser?.attributes?.profile?.metadata?.subscription === 'gold';
export const selectHasSilverSubscription = rx => rx.user?.currentUser?.attributes?.profile?.metadata?.subscription === 'silver';
export const selectPayedSubscription = rx => selectHasGoldSubscription(rx) || selectHasSilverSubscription(rx);
export const selectIsExpertOption = rx => rx.user?.currentUser?.attributes?.profile?.publicData?.expertOption || false;
export const selectCurrentUserAddress = rx => rx.user?.currentUser?.attributes?.profile?.protectedData?.address;

export const selectCurrentUserPublicData = rx =>  rx?.user?.currentUser?.attributes?.profile?.publicData ?? {};
export const selectCurrentUserMetaData = rx =>  rx?.user?.currentUser?.attributes?.profile?.metadata ?? {};
