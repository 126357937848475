import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReviewUser.module.css';

const IconReviewUser = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="45"
      height="45"
      viewBox="0 0 45 45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.5 6.3L27 16.8l.5 1.1 1.2.1 11.5 1-8.7 7.5-.9.8.3 1.2 2.6 11.2-9.8-5.9-1-.6-1 .6-9.8 5.9 2.6-11.2.3-1.2-.9-.8-9-7.5 11.5-1 1.2-.1.5-1.1 4.4-10.5m0-5.2L16.2 16 0 17.4 12.3 28 8.6 43.9l13.9-8.4 13.9 8.4-3.7-15.8L45 17.4 28.8 16 22.5 1.1h0z"/>
    </svg>
  );
};

IconReviewUser.defaultProps = { className: null, rootClassName: null };

const { string } = PropTypes;

IconReviewUser.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconReviewUser;
