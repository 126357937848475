import React, {useState} from 'react';
import { bool, func, object, string, oneOfType } from 'prop-types';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter, findOptionLabel } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink, ListingLinkUnnamed } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';

import css from './EditListingDescriptionPanel.module.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    intl,
    expertId,
    experts,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData, privateData } = currentListing.attributes;
  const dialux = publicData.dialux || [];
  const lightingDesign = publicData.lightingDesign || [];
  const photoWork = publicData.photoWork || [];
  const documentation = publicData.documentation || [];
  const others = publicData.others || [];
  const categories = {dialux, lightingDesign, photoWork, documentation, others}
  const indoor = publicData.indoor || [];
  const outdoor = publicData.outdoor || [];
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const invitedExpertsIds = privateData && privateData.invitedExpertsIds;
  const isDirectConnect = expertId || privateData && privateData.isDirectConnect && invitedExpertsIds && invitedExpertsIds.length === 1 ;
  const expertAuthor = isDirectConnect && experts[0] && experts[0].author;
  const expertName = expertAuthor && expertAuthor.attributes && expertAuthor.attributes.profile && expertAuthor.attributes.profile.displayName;
  const directConnectInfo = !isPublished && isDirectConnect  ?
    <p className={css.connectInfo}>
      <FormattedMessage id="EditListingPhotosForm.directConnectInfo" values={{name: expertName}} />
    </p> : null;
  const titleAttributes = publicData?.titleAttributes || {};
  const { pronoun, need, titleCategory, subCategory, place } = titleAttributes;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );
  const pronounOptions = findOptionsForSelectFilter('pronoun', config.custom.titleConfig);
  const needOptions = findOptionsForSelectFilter('need', config.custom.titleConfig);
  let catOptions = [];
  _.each( categories, ( options, cat ) => {
    if(options?.length > 0) {
      const categoryOption = config.custom.filters.find(co => co.id === cat)
      _.each( options, ( option ) => {
        const optionLabel = findOptionLabel( config.custom.filters, "pub_" + cat, option)
        catOptions.push({
          key: categoryOption.id +'-'+ option,
          label: intl.formatMessage({id: categoryOption.label.props.id}) + ' - ' + intl.formatMessage({id: optionLabel.props.id}) ,
        })
      })
    }
  });

  const indoorOptions = findOptionsForSelectFilter('indoor', config.manufacturerConfig.productPortfolio);
  const outdoorOptions = findOptionsForSelectFilter('outdoor', config.manufacturerConfig.productPortfolio);
  const selectedApplicationOptions = indoor.concat(outdoor);
  const indoorLabel = intl.formatMessage({
    id: 'ManufacturerProductPortfolio.IndoorLabel',
  });
  const outdoorLabel = intl.formatMessage({
    id: 'ManufacturerProductPortfolio.OutdoorLabel',
  });

  let placeOptions = [];
  _.each( selectedApplicationOptions, ( option ) => {
    const indoorOption = indoorOptions.find(o => o.key === option)
    const outdoorOption = outdoorOptions.find(o => o.key === option)
    if (indoorOption) {
      placeOptions.push({
        key: indoorOption.key,
        label: indoorLabel  + ' - ' + intl.formatMessage({id: indoorOption.label.props.id}) ,
      })
    }
    else if (outdoorOption){
      placeOptions.push({
        key: outdoorOption.key,
        label: outdoorLabel  + ' - ' + intl.formatMessage({id: outdoorOption.label.props.id}) ,
      })
    }
  })

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDescriptionForm
        className={css.form}
        initialValues={{ title, description, pronoun, need, titleCategory, place }}
        isDirectConnect={isDirectConnect}
        expertAuthor={expertAuthor}
        directConnectInfo={directConnectInfo}
        pronounOptions={pronounOptions}
        needOptions={needOptions}
        categoryOptions={catOptions}
        placeOptions={placeOptions}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { description, pronoun, need, titleCategory, subCategory, place } = values;
          const pronounLabelId = pronounOptions.find(o => o.key === pronoun)?.label.props.id
          const pronounLabel = intl.formatMessage({id: pronounLabelId});
          const needLabelId = needOptions.find(o => o.key === need)?.label.props.id
          const needLabel = intl.formatMessage({id: needLabelId});
          const titleCategoryLabel = catOptions.find(o => o.key === titleCategory)?.label
          const forLabel = intl.formatMessage({id:"EditListingDescriptionForm.for"});
          const placeLabel = placeOptions.find(o => o.key === place)?.label
          const title = pronounLabel + " " + needLabel + " " + titleCategoryLabel + " " + forLabel + " " + placeLabel;
          const updateValues = {
            title: title.trim(),
            description,
            publicData: { titleAttributes: {pronoun, need, titleCategory, place} },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: oneOfType([propTypes.listing, propTypes.ownListing, propTypes.draftListing]),

  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default compose(injectIntl)(EditListingDescriptionPanel);
