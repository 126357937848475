import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import {NamedLink, ExternalLink} from '..';

import css from './TermsOfServiceLead.module.css';

const TermsOfServiceLead = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  const termsOfServiceLink = (
    <NamedLink
    className={css.inboxLink}
    name="TermsOfServicePage"
  >
      <FormattedMessage id="TermsOfServiceLead.TermsOfServicePage" />
  </NamedLink>
  );

  const dataPrivacyPolicyLink = (
    <NamedLink
    className={css.inboxLink}
    name="PrivacyPolicyPage"
  >
      <FormattedMessage id="TermsOfServiceLead.dataPolicyLink" />
  </NamedLink>
  );

  const stripeConnectedAccountTermsLink = (
    <ExternalLink href="https://stripe.com/connect-account/legal" className={css.termsLink}>
      <FormattedMessage id="TermsOfServiceLead.StripeAGB"
      />
    </ExternalLink>
  );


  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}><FormattedMessage id="TermsOfServiceLead.lastUpdated" /></p>

      <h2><FormattedMessage id="TermsOfServiceLead.headline1" /></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.generalRules_1" values={{termsOfServiceLink}}/>
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.generalRules_2" values={{termsOfServiceLink}}/>
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.generalRules_3" values={{termsOfServiceLink}}/>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfServiceLead.headline2"/></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.service_1"/>
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.service_2"/>
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.service_3"/>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfServiceLead.headline3"/></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.contract_1"/>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfServiceLead.headline4"/></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.payment_1" values={{stripeConnectedAccountTermsLink}}/>
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.payment_2"/>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfServiceLead.headline5"/></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.liability_1"/>
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.liability_2"/>
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.liability_3"/>
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.liability_4"/>
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.liability_5"/>
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.liability_6"/>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfServiceLead.headline6"/></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.dataManagement_1"/>
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.dataManagement_2"/>
        </li>
        <li className={css.listElement}>
          <div>
            <FormattedMessage id="TermsOfServiceLead.dataManagement_3" values={{br: <br/>}}/>
          </div>
          <ol type="a" className={css.list}>
          <li className={css.listElement}>
            <div>
              <FormattedMessage id="TermsOfServiceLead.dataManagement_3_1" />
            </div>
          </li>
          <li className={css.listElement}>
            <div>
              <FormattedMessage id="TermsOfServiceLead.dataManagement_3_2" />
            </div>
          </li>
          <li className={css.listElement}>
            <div>
              <FormattedMessage id="TermsOfServiceLead.dataManagement_3_3" />
            </div>
          </li>
        </ol>
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.dataManagement_4" values={{dataPrivacyPolicyLink}}/>
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.dataManagement_5" values={{dataPrivacyPolicyLink}}/>
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.dataManagement_6"/>
        </li>
      </ol>

      <h2><FormattedMessage id="TermsOfServiceLead.headline7"/></h2>
      <ol start="1" className={css.list}>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.closingProvisions_1"/>
        </li>
        <li className={css.listElement}>
          <FormattedMessage id="TermsOfServiceLead.closingProvisions_2"/>
        </li>
      </ol>
    </div>
  );
};

TermsOfServiceLead.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfServiceLead.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfServiceLead;
