import { useSelector } from 'react-redux';
import { FieldCheckboxBool } from '../../components';
import { selectCurrentUserSubscription } from '../../ducks/user.duck';
import { useTranslation } from '../../hooks';
import css from './ProfileSkillsForm.module.css';
import NamedLink from '../../components/NamedLink/NamedLink';

export const SectionIncognitoMode = () => {
  const setTranslation = useTranslation('ProfileBConsForm');
  const subscription = useSelector(selectCurrentUserSubscription);

  const isGoldSubscription = subscription === 'gold';
  const isSilverSubscription = subscription === 'silver';
  const isPayedSubscription = isGoldSubscription || isSilverSubscription;

  if (!isPayedSubscription) {
    return null;
  }

  const incognitoModeLabel = isGoldSubscription
    ? setTranslation('isIncognitoGold')
    : setTranslation('isIncognitoSilver');

  const membershipLink =(
    <NamedLink name="SubscriptionPage" className={css.link}>
      {setTranslation('membershipLinkLabel')}
    </NamedLink>);


  return (
    <>
      <br/>
      <FieldCheckboxBool
        id={'isIncognito'} name='isIncognito' label={incognitoModeLabel}
      />
    </>
  );
};
