import React from 'react';
import PropTypes from 'prop-types';

import css from './IconKeys.module.css';

const IconKeys = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="45px"
      height="45px"
      viewBox="0 0 45 45"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#000" d="M38.44 9.06c-5.4-5.44-14.18-5.47-19.62-.07-3.3 3.28-4.74 8.01-3.82 12.58L2.5 34.11l1.09 9.8 9.8 1.09v-4.36l4.43.07-.07-4.42 4.4.05v-4.41h2a13.85 13.85 0 0 0 4.6.78c7.65-.05 13.81-6.3 13.75-13.95-.02-3.64-1.48-7.13-4.06-9.7zM37 27.25c-3.23 3.19-8 4.26-12.28 2.75l-.32-.11h-4.32v2 2.36h-2.36-2.06v2.06 2.36h-2.37-2v4.16l-6-.67-.81-7.26L16.46 23l.75-.76-.2-1c-1.29-6.42 2.88-12.67 9.3-13.96s12.67 2.88 13.96 9.3c.78 3.9-.44 7.93-3.26 10.73l-.01-.06z"/>
      <circle fill="#000" cx="31.91" cy="15.59" r="3.08"/>
    </svg>
  );
};

IconKeys.defaultProps = { className: null };

const { string } = PropTypes;

IconKeys.propTypes = {
  className: string,
};

export default IconKeys;
