import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { AvatarLarge } from '../Avatar/Avatar';
import css from './TransactionPanel.module.css';

export const SectionOthersProfile = ({ dataReader }) => {
  const profile = dataReader.getOthersProfileData();
  const otherUser = dataReader.getOtherUser();

  let isClient = dataReader.isCustomer();

  if (dataReader.isExpert()) {
    isClient = !isClient;
  }

  const isAccepted = dataReader.isAccepted();
  const areContactDetailsVisible = !isClient || isAccepted;

  return (
    <section className={css.userProfile}>
      <h2>
        {!isClient ? (
          <FormattedMessage id="FreelancerDetails.heading" />
        ) : (
          <FormattedMessage id="ListingPage.leadClientTitle" />
        )}
      </h2>

      {!isClient && (
        <div className={css.freelancerAvatar}>
          <AvatarLarge user={otherUser} className={css.avatarDesktop} />
        </div>
      )}

      <div className={css.metaData}>
        {!isClient && <ProfileDataLine labelId={'FreelancerDetails.name'} value={profile.name} />}

        <ProfileDataLine labelId={'FreelancerDetails.jobTitle'} value={profile.jobTitle} />
        <ProfileDataLine labelId={'FreelancerDetails.memberSince'} value={profile.joined} />

        {isClient && isAccepted && (
          <ProfileDataLine labelId={'ListingPage.totalTendersLabel'} value={profile.tenderCount} />
        )}

        {areContactDetailsVisible ? (
          <ProfileAddress profile={profile} />
        ) : (
          <>
            <ProfileDataLine labelId={'ListingPage.locatedAtLabel'} value={profile.country} />
            <ProfileDataLine
              labelId={'ListingPage.postalcodeLabel'}
              value={profile.postalCodeAnon}
            />
          </>
        )}

        {isClient && !isAccepted && (
          <ProfileDataLine labelId={'ListingPage.totalTendersLabel'} value={profile.tenderCount} />
        )}

        {isAccepted && (
          <>
            <ProfileDataLine labelId={'TransactionPanel.email'} value={profile.email} />
            <ProfileDataLine labelId={'TransactionPanel.phone'} value={profile.phone} />
          </>
        )}
      </div>
    </section>
  );
};

const ProfileDataLine = ({ labelId, value }) => (
  <>
    <div>
      <FormattedMessage id={labelId} />
    </div>
    <div>{value}</div>
  </>
);
const ProfileAddress = ({ profile }) => (
  <>
    <h3>
      <FormattedMessage id="TransactionPanel.contactTitle" />
    </h3>

    <div>
      <FormattedMessage id="FreelancerDetails.address" />
    </div>
    <div>
      <span>{profile.company}</span>
      <span>
        {profile.street} {profile.houseNumber}
      </span>
      <span>
        {profile.postalCode} {profile.city}
      </span>
      <span>{profile.country}</span>
    </div>
  </>
);
