import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button, PrimaryButton } from '../../components';
import { BookingDatesForm, BookingDatesFormNotAuthorized, OfferForm, OfferFormNotAuthorized } from '../../forms';
import moment from 'moment';

import css from './BookingPanel.module.css';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 9999999;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    title2,
    authorDisplayName,
    onManageDisableScrolling,
    timeSlots,
    fetchTimeSlotsError,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendProposalInProgress,
    sendProposalError,
    sendProposalMessage,
    currentUser,
    onOpenDataSecurityPolicy,
    onOpenTermsOfService,
    onOpenLeadService,
    isAuthenticated,
    isLead,
    showContactUser,
    onContactUser,
    isExample,
  } = props;

  let hasStripeAccount = false;
  let hasStripeAccountExpert = false;
  let hasStripeAccountManufacturer = false;
  const isExpert = true;
  let isManufacturer = false;

  if(!!currentUser?.stripeAccount) {
    hasStripeAccountExpert = true;
  }

  if(currentUser){
    if(currentUser.attributes.profile.metadata.isManufacturer){
      if(currentUser.attributes.profile.metadata.isManufacturer === true){
        isManufacturer = true;
        if(currentUser.stripeAccount){
          hasStripeAccountManufacturer = true;
        }
      }
    }
    else{
      hasStripeAccountManufacturer = false;
      isManufacturer = false;
    }
  }

  hasStripeAccount = hasStripeAccountExpert || hasStripeAccountManufacturer ? true : false;

  const price = listing.attributes.price;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;

  const profile = currentUser && currentUser.attributes && currentUser.attributes.profile;
  const privateData = profile && profile.privateData;
  const protectedData = profile && profile.protectedData;
  const publicData = listing && listing.attributes && listing.attributes.publicData;
  const metaData = listing && listing.attributes && listing.attributes.metadata;
  const addressMissing = !!protectedData && (!protectedData.address ||
    (!!protectedData.address && (!protectedData.address.city || !protectedData.address.houseNumber || !protectedData.address.postalCode ||
    !protectedData.address.street)));
  const taxIdMissing = !!privateData && !privateData.taxId;

  const listingEndDateUTC = publicData && publicData.listingEndDateUTC;
  const hasDeadlineDatePassed = listingEndDateUTC ?
    moment.utc(moment()).isAfter(moment.utc(listingEndDateUTC)) : false;

  const countryCode = privateData?.countryCode ?? ''

  const isCountrySupported = !!config.stripe.supportedCountries.find(c => c.code === countryCode);

  const showBookingDatesForm = !isLead && hasListingState && !isClosed && isAuthenticated && isCountrySupported && hasStripeAccount &&
    !isOwnListing && !addressMissing && !hasDeadlineDatePassed && !isExample;

  const showOfferForm = isLead && hasListingState && !isClosed && isAuthenticated && !isOwnListing && !addressMissing && isManufacturer && !isExample;



  const showLeadOfferFormNotAuthorized = isLead && hasListingState && !isClosed && isAuthenticated && !isOwnListing && addressMissing || (isLead && hasListingState && !isClosed && isAuthenticated && !isOwnListing && addressMissing && !isManufacturer) || (isExample);
  const showBookingDatesFormNotAuthorized = !showBookingDatesForm && !showOfferForm && !showLeadOfferFormNotAuthorized
  const showClosedListingHelpText = listing.id && isClosed;
  const isBook = !!parse(location.search).book;

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const showSubtitle = subTitleText && !isExample ? true : false;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  //<div className={css.applicationDeadline}>{applicationDeadline}</div>

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
          <div className={css.author}>
            <FormattedMessage id="BookingPanel.hostedBy" values={{ name: authorDisplayName }} />
          </div>
        </div>
        {
          (showBookingDatesForm || showOfferForm) ?
          <div className={css.bookingHeading}>
            <span className={titleClasses}>{title}</span>
            {showSubtitle ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
          </div> : null
        }
        {showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            currentUser={currentUser}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            title={title2}
            sendProposalInProgress= {sendProposalInProgress}
            sendProposalError= {sendProposalError}
            sendProposalMessage= {sendProposalMessage}
            onOpenTermsOfService={onOpenTermsOfService}
            onOpenDataSecurityPolicy={onOpenDataSecurityPolicy}
          />
        ) : showOfferForm ? (
          <OfferForm
            className={css.bookingForm}
            currentUser={currentUser}
            formId="OfferForm"
            submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            title={title2}
            sendProposalInProgress= {sendProposalInProgress}
            sendProposalError= {sendProposalError}
            sendProposalMessage= {sendProposalMessage}
            onOpenTermsOfService={onOpenTermsOfService}
            onOpenDataSecurityPolicy={onOpenDataSecurityPolicy}
            onOpenLeadService={onOpenLeadService}
            addressMissing={addressMissing}
          />
        ) : null }
      </ModalInMobile>
      {isAuthenticated && showContactUser ? (
        <span className={css.contactWrapper}>
          <PrimaryButton
            rootClassName={css.contactLink}
            onClick={onContactUser}
            enforcePagePreloadFor="SignupPage"
          >
            <FormattedMessage id="ListingPage.contactUser" />
          </PrimaryButton>
        </span>
      ) : null}
      <div className={css.openBookingForm}>
        {isAuthenticated && (showBookingDatesForm || showOfferForm) ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            {isLead
              ? <FormattedMessage id="BookingPanel.ctaButtonMessageLead" />
              : <FormattedMessage id="BookingPanel.ctaButtonMessage" />}

          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
      <div className={css.notAuthorizedContainer}>
        {
          showLeadOfferFormNotAuthorized ?
            <OfferFormNotAuthorized
              className={css.bookingForm}
              currentUser={currentUser}
              formId="BookingPanel"
              isAuthenticated={isAuthenticated}
              hasStripeAccount={hasStripeAccount}
              isManufacturer={isManufacturer}
              submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
              unitType={unitType}
              onSubmit={onSubmit}
              price={price}
              listingId={listing.id}
              isOwnListing={isOwnListing}
              listing={listing}
              timeSlots={timeSlots}
              fetchTimeSlotsError={fetchTimeSlotsError}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              title={title2}
              sendProposalInProgress= {sendProposalInProgress}
              sendProposalError= {sendProposalError}
              sendProposalMessage= {sendProposalMessage}
              onOpenTermsOfService={onOpenTermsOfService}
              onOpenDataSecurityPolicy={onOpenDataSecurityPolicy}
              addressMissing={addressMissing}
              taxIdMissing={taxIdMissing}
              hasDeadlineDatePassed={hasDeadlineDatePassed}
              isExample={isExample}
            /> : showBookingDatesFormNotAuthorized ?
            <BookingDatesFormNotAuthorized
              className={css.bookingForm}
              currentUser={currentUser}
              formId="BookingPanel"
              isAuthenticated={isAuthenticated}
              hasStripeAccount={hasStripeAccount}
              isExpert={isExpert}
              submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
              unitType={unitType}
              onSubmit={onSubmit}
              price={price}
              listingId={listing.id}
              isOwnListing={isOwnListing}
              listing={listing}
              timeSlots={timeSlots}
              fetchTimeSlotsError={fetchTimeSlotsError}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              title={title2}
              sendProposalInProgress= {sendProposalInProgress}
              sendProposalError= {sendProposalError}
              sendProposalMessage= {sendProposalMessage}
              onOpenTermsOfService={onOpenTermsOfService}
              onOpenDataSecurityPolicy={onOpenDataSecurityPolicy}
              addressMissing={addressMissing}
              taxIdMissing={taxIdMissing}
              hasDeadlineDatePassed={hasDeadlineDatePassed}
              isCountrySupported={isCountrySupported}
            /> : null
          }
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
