import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconArrowTurn.module.css';

const IconArrowTurn = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="55px" height="230px" viewBox="0 0 55 230" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.5 227.2l6.7-6.7c28.1-28.1 43.6-65.4 43.6-105.1S39.3 38.3 11.2 10.2L1.7.6.2 2.1l9.6 9.6c27.7 27.7 43 64.5 43 103.7s-15.3 76-43 103.7l-6.7 6.7.3-8.4-2-.1-.4 12 12-.4-.1-2-8.4.3z"
        fillRule="evenodd"
      />
    </svg>
  );
};

IconArrowTurn.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconArrowTurn.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconArrowTurn;
