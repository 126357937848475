import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCircleTwoIndustry.module.css';

const IconCircleTwoIndustry = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="45"
      height="45"
      viewBox="0 0 45 45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 2.5a19.93 19.93 0 0 0-20 20 19.93 19.93 0 0 0 20 20 19.93 19.93 0 0 0 20-20 19.93 19.93 0 0 0-20-20zm0 38c-9.9 0-18-8.1-18-18s8.1-18 18-18 18 8.1 18 18-8.1 18-18 18zm1.8-16c2.2-2.4 3.3-4.5 3.3-6.2 0-1.4-.5-2.6-1.4-3.5-1-.9-2.3-1.3-3.9-1.3-1.8 0-3.2.5-4.2 1.5-1.1 1-1.6 2.3-1.6 4h2.3c0-1.1.3-2 .9-2.7.6-.6 1.5-1 2.6-1 .9 0 1.7.3 2.2.9.6.6.8 1.4.8 2.3 0 .7-.2 1.4-.5 2s-1 1.5-1.9 2.5l-6.2 6.8v1.6H26v-1.8h-8.9l4.7-5.1z"/>
    </svg>
  );
};

IconCircleTwoIndustry.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCircleTwoIndustry.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCircleTwoIndustry;
