import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCircleTen.module.css';

const IconCircleTen = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.7 11.9v2.2l3.9-1.3v12.5h2.6V9.6h-.4zm14.4-2.4c-1.7 0-3 .6-3.9 1.7-.7 1.1-1.2 2.8-1.2 5v2.7c0 2.2.5 3.8 1.3 4.9.9 1.1 2.1 1.7 3.8 1.7s3-.6 3.8-1.7 1.3-2.8 1.3-5.1V16c0-2.2-.5-3.8-1.3-4.9s-2-1.6-3.8-1.6zm2.6 9.8c0 1.4-.2 2.4-.6 3.1s-1 1-1.9 1-1.5-.4-1.9-1.1-.6-1.8-.6-3.3v-3.5c0-1.4.2-2.4.6-3s1-1 1.9-1 1.5.3 1.9 1 .6 1.8.6 3.2v3.6zM17.5 0C7.8 0 0 7.8 0 17.5S7.8 35 17.5 35 35 27.2 35 17.5 27.2 0 17.5 0zm0 32C9.5 32 3 25.5 3 17.5S9.5 3 17.5 3 32 9.5 32 17.5 25.5 32 17.5 32z"/>
    </svg>
  );
};

IconCircleTen.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCircleTen.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCircleTen;
