import React from 'react';
import { bool, func, object, string, oneOfType } from 'prop-types';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink, ListingLinkUnnamed } from '../../components';
import { EditListingLeadDescriptionForm } from '../../forms';

import css from './EditListingLeadDescriptionPanel.module.css';

const EditListingLeadDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title } = currentListing.attributes;
  // Description tab is moved to last which has the title field backend api doesn't create listing without
  // title so when creating a new listing we need to pass a dummy title which is 'draft-00000000-0000-0000-0000-000000000000' here and then
  // this will be replaced by actual input from user on last tab. Here we are replacing draft-00000000-0000-0000-0000-000000000000 with empty
  // string so users don't see Draft in title field.
  const titleValue = title === 'draft-00000000-0000-0000-0000-000000000000' ? '' : title;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingLeadDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) :
  (
    <FormattedMessage id="EditListingLeadDescriptionPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingLeadDescriptionForm
        className={css.form}
        initialValues={{ title: titleValue, description }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { title, description } = values;
          const updateValues = {
            title: title.trim(),
            description,
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingLeadDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingLeadDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: oneOfType([propTypes.listing, propTypes.ownListing, propTypes.draftListing]),

  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingLeadDescriptionPanel;
