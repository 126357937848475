import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';
import moment from "moment-timezone";

import css from './OfferCard.module.css';
import { PrimaryButton } from '../Button/Button';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

class OfferImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(OfferImage, { loadAfterInitialRendering: 3000 });

export const OfferCardComponent = props => {
  const { renderSizes, invitation, invitationListings } = props;
  const classes = classNames(rootClassName || css.root, className);
  const listing = invitationListings.filter(invLis => invLis.id.uuid === invitation.listing_id)[0];
  const title = listing && listing.attributes.title
  const otherUser = listing && listing.author;
  // const otherUserDisplayName = otherUser && <UserDisplayName user={otherUser} intl={intl} />;
  const isOtherUserBanned = otherUser && otherUser.attributes.banned;
  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });
  const invitedAt = formatDate(intl, invitation.created_at);
   //Timezone and format - listingEndDate
  const format = {
    month: 'short',
    day: 'numeric',
  };

  const listingEndDateUTC = publicData.listingEndDateUTC || '';

  const LTZ = moment.tz.guess();
  const listingEndDateLTZ = moment.tz(listingEndDateUTC, LTZ).format();

  const formattedApplicationDeadline = intl.formatDate(listingEndDateLTZ, format);

  const applicationDeadlineString = intl.formatMessage(
    { id: 'ListingCard.applicationDeadline' },
    {
      dates: `${formattedApplicationDeadline}`,
    }
  )
  // End

  return (
  <div className={classes}>
    <div className={css.threeToTwoWrapper}>
      <div className={css.aspectWrapper}>
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={['landscape-crop', 'landscape-crop2x']}
          sizes={renderSizes}
        />
      </div>
    </div>
    <div className={css.gridContainer}>
      <div className={css.title}>
      </div>
      <div className={css.status}>
      </div>     
      <div className={css.listingEndDate}>
        {applicationDeadlineString}
      </div>
      <PrimaryButton className={css.modalOfferButton}></PrimaryButton>
    </div>
  </div>
  );
};

OfferCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

OfferCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(OfferCardComponent);
