import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput } from '../../components';

import css from './ProfileReferencesForm.module.css';

class ProfileReferencesFormComponent extends Component {
  constructor(props) {
    super(props);

   // this.uploadDelayTimeoutId = null;
    //this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  /*componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }*/

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            //onImageUpload,
            pristine,
            //profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            //uploadInProgress,
            //form,
            values,
          } = fieldRenderProps;


          const user = ensureCurrentUser(currentUser);

          //Job
          const jobPlaceholder = intl.formatMessage({
            id: 'ProfileReferencesForm.jobPlaceholder',
          });
          const jobLabel = intl.formatMessage({
            id: 'ProfileReferencesForm.jobLabel',
          });

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileReferencesForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || submitInProgress;
            //|| uploadInProgress

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={classNames(css.sectionContainer, css.lastSection)}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileReferencesForm.yourJobReferences" />
                </h3>
                <FieldTextInput
                  type="text"
                  name="jobTitle"
                  id="jobTitle"
                  label={jobLabel}
                  placeholder={jobPlaceholder}
                />
              </div>
              {submitError}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                <FormattedMessage id="ProfileReferencesForm.saveChanges" />
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileReferencesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  updateProfileError: null,
  updateProfileReady: false,
  jobTitle: null,
};

ProfileReferencesFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  //uploadInProgress: bool.isRequired,
  //updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileReferencesForm = compose(injectIntl)(ProfileReferencesFormComponent);

ProfileReferencesForm.displayName = 'ProfileReferencesForm';

export default ProfileReferencesForm;
