import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, IconQuestionmark } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import css from './QuestionForm.module.css';

const QuestionFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        intl,
        listingTitle,
        authorDisplayName,
        sendQuestionInProgress,
        sendQuestionError,
      } = fieldRenderProps;

      const questionLabel = intl.formatMessage(
        {
          id: 'QuestionForm.messageLabel',
        },
        { authorDisplayName }
      );
      const questionPlaceholder = intl.formatMessage(
        {
          id: 'QuestionForm.messagePlaceholder',
        },
        { authorDisplayName }
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'QuestionForm.messageRequired',
      });
      const questionRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = sendQuestionInProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <IconQuestionmark className={css.icon} />
          <h2 className={css.heading}>
            <FormattedMessage id="QuestionForm.heading" values={{ listingTitle: listingTitle }}/>
          </h2>
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="question"
            id={formId ? `${formId}.question` : 'question'}
            label={questionLabel}
            placeholder={questionPlaceholder}
            validate={questionRequired}
          />
          <div className={submitButtonWrapperClassName}>
            {sendQuestionError ? (
              <p className={css.error}>
                <FormattedMessage id="QuestionForm.sendQuestionError"/>
              </p>
            ) : null}
              <div className={css.informationText}>
              <FormattedMessage id="QuestionForm.informationText" />
              </div>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="QuestionForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

QuestionFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendQuestionError: null,
};

QuestionFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string,
  authorDisplayName: string,
  sendQuestionError: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const QuestionForm = compose(injectIntl)(QuestionFormComponent);

QuestionForm.displayName = 'QuestionForm';

export default QuestionForm;
