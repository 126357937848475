import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, Modal } from '../../components';
import classNames from 'classnames';

import css from './CookieConsent.module.css';
import { PrimaryButton, SecondaryButton } from '../Button/Button';
import { manageDisplayConsent } from '../../ducks/UI.duck';

const GOOGLE_ANALYTICS_SCRIPT_ID = 'GoogleAnalyticsScript';
const MATOMO_SCRIPT_ID = 'MatomoScript';
const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
};

const euCookiesAcceptedCookie = () => {
  const cookies = typeof window !== 'undefined' && document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});
  return cookies && cookies.euCookiesAccepted;
}

class CookieConsentComponent extends Component {
  constructor(props) {
    super(props);
    this.onAcceptCookies = this.onAcceptCookies.bind(this);
    this.saveCookieConsent = this.saveCookieConsent.bind(this);
  }

  componentDidMount() {
    const euCookiesAccepted = euCookiesAcceptedCookie();

    if (!(euCookiesAccepted === '1' || euCookiesAccepted === '0')) {
      this.props.onManageDisplayConsent(true)
    }
  }

  onAcceptCookies(option) {
    this.saveCookieConsent(option);
    this.props.onManageDisplayConsent(false)
    if (typeof window !== 'undefined') {
      const euCookiesAccepted = euCookiesAcceptedCookie();
      const hasAcceptedCookies = euCookiesAccepted === '1';
      const gaElem = document.getElementById(GOOGLE_ANALYTICS_SCRIPT_ID);
      const matomoElem = document.getElementById(MATOMO_SCRIPT_ID);
      if(hasAcceptedCookies && !!googleAnalyticsId && !gaElem){
        let googleAnalyticsScript = document.createElement("script");
        googleAnalyticsScript.id = GOOGLE_ANALYTICS_SCRIPT_ID;
        googleAnalyticsScript.type = "text/javascript";
        googleAnalyticsScript.async = true;
        googleAnalyticsScript.src = "https://www.google-analytics.com/analytics.js";
        googleAnalyticsScript.onload = function() {
          window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
          ga('create', googleAnalyticsId, 'auto');
        };
        document.head.appendChild(googleAnalyticsScript);
      }
      else if(!hasAcceptedCookies && !!googleAnalyticsId && !!gaElem){
        gaElem.parentNode.removeChild(gaElem);
      }
      if(hasAcceptedCookies && !matomoElem){
        var _paq = window._paq || [];
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(["setCookieDomain", "*.lightshift.co"]);
        _paq.push(['enableHeartBeatTimer']);
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);

        var u="https://stats.dial.de/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '18']);
        var d=document, matomoScript=d.createElement('script');
        matomoScript.id = MATOMO_SCRIPT_ID;
        matomoScript.type='text/javascript'; matomoScript.async=true; matomoScript.defer=true; matomoScript.src=u+'matomo.js';;
        document.head.appendChild(matomoScript);
      }
      else if (!hasAcceptedCookies && !!matomoElem){
        matomoElem.parentNode.removeChild(matomoElem);
      }
    }
  }

  saveCookieConsent(option) {
    // We create date object and modify it to show date 10 years into the future.
    let expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    // Save the cookie with expiration date
    document.cookie = 'euCookiesAccepted=' + option + '; path=/; expires=' + expirationDate.toGMTString();
  }

  render() {
    const { className, rootClassName, displayConsent } = this.props;
    const isServer = typeof window === 'undefined';
    // Server side doesn't know about cookie consent
    if (isServer) {
      return null;
    } else {
      const dataProtectionLink = (
        <NamedLink name="PrivacyPolicyPage" className={css.cookieLink}>
          <FormattedMessage id="CookieConsent.dataProtection" />
        </NamedLink>
      );
      const imprintLink = (
        <NamedLink name="ImpressumPage" className={css.cookieLink}>
          <FormattedMessage id="CookieConsent.imprint" />
        </NamedLink>
      );
      const classes = classNames(rootClassName || css.root, className);

      return (
        <div className={classes}>
          <Modal
            id="consent-modal"
            isOpen={displayConsent}
            onClose={() => {}}
            onManageDisableScrolling={onManageDisableScrolling}
            containerClassName={css.consentModalContent}
            hideCloseBtn={true}
            usePortal
            >
            <div className={css.expertsModalWrapper}>
              <h2 className={css.title}>
                <FormattedMessage id="CookieConsent.title" />
              </h2>
              <div className={css.message}>
                <FormattedMessage id="CookieConsent.message" values={{ dataProtectionLink, imprintLink }} />
              </div>
              <div className={css.actions}>
                <div className={css.actionBtns}>
                  <SecondaryButton className={css.acceptRequiredCookies} onClick={() => this.onAcceptCookies('0')}>
                    <FormattedMessage id="CookieConsent.acceptRequiredCookies" />
                  </SecondaryButton>
                  <PrimaryButton className={css.acceptAllCookies} onClick={() => this.onAcceptCookies('1')}>
                    <FormattedMessage id="CookieConsent.acceptAllCookies" />
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  }
}

const { string } = PropTypes;

CookieConsentComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

CookieConsentComponent.propTypes = {
  className: string,
  rootClassName: string,
};

const mapStateToProps = state => {
  const { displayConsent } = state.UI
  return {
    displayConsent
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisplayConsent: (show) => dispatch(manageDisplayConsent(show)),
});

const CookieConsent = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CookieConsentComponent);

export default CookieConsent;
