import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { EditListingFromEvoForm } from '../../forms';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingApplicationForm } from '../../forms';
import { Button, ListingLink, ListingLinkUnnamed } from '..';

import css from './EditListingEvoLeadPanel.module.css';

const CATEGORIES_NAME = 'amenities';

const EditListingEvoLeadPanel = props => {
  const {
    rootClassName,
    className,
    disabled,
    ready,
    onSubmit,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    currentUser
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const submitInProgress = updateInProgress;
  const submitDisabled = disabled || submitInProgress;
  const submitReady = panelUpdated  || ready;
  const panelTitle = (<FormattedMessage id="EditListingEvoLeadPanel.evoLeadPanelTitle" />);


  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingFromEvoForm
        className={css.form}
        name='EditListingFromEvoForm'
        onSubmit={() => {
          const fromEvo = true;
          const updatedValues = {
            privateData: { fromEvo: fromEvo },
          };
          onSubmit(updatedValues);
        }}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        currentUser={currentUser}
      />
    </div>
  );
};

EditListingEvoLeadPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingEvoLeadPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.

  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

export default EditListingEvoLeadPanel;
