import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const SearchIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootSearchIcon, className);

  return (
    <svg 
      width="24" 
      height="24" 
      version="1.1" 
      viewBox="0 0 6.35 6.35" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m6.35 6.0577-1.561-1.5676c0.89958-1.0628 0.84666-2.6835-0.15875-3.6931-0.52917-0.53138-1.2171-0.79707-1.9315-0.79707-0.66146 0-1.3758 0.26569-1.905 0.79707-1.0583 1.0628-1.0583 2.7898 0 3.8525 0.52916 0.53138 1.2171 0.79707 1.9315 0.79707 0.635 0 1.2435-0.21255 1.7727-0.66423l1.561 1.5676zm-5.2388-1.7004c-0.42334-0.42511-0.66146-1.0096-0.66146-1.6207 0-0.61109 0.23812-1.169 0.66146-1.6207 0.42333-0.42511 1.0054-0.66423 1.614-0.66423 0.60854 0 1.1642 0.23912 1.614 0.66423 0.87313 0.87677 0.87313 2.3381 0 3.2148-0.42333 0.4251-1.0054 0.66423-1.614 0.66423-0.60855 0.0266-1.1906-0.21256-1.614-0.63766z" fill="#000000" strokeWidth=".26514"/>
    </svg>
  );
};

const { string } = PropTypes;

SearchIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default SearchIcon;
