import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import arrayMutators from 'final-form-arrays';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {supportedVatCountry} from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput, FieldSelect, FieldCheckboxBool, FieldRadioButton} from '../../components';


import css from './ProfileAddressForm.module.css';

class ProfileAddressFormComponent extends Component {
  constructor(props) {
    super(props);
    this.submittedValues = {};
  }




  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
          const {
            className,
            formId,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            pristine,
            rootClassName,
            updateInProgress,
            updateProfileError,
            values,
            form,
          } = fieldRenderProps;

          // country
          const countryLabel = intl.formatMessage({
            id: 'ProfileAddressForm.countryLabel',
          });
          const countryPlaceholder = intl.formatMessage({
          id: 'ProfileAddressForm.countryPlaceholder',
          });
          const countryRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.countryRequired',
          });
          const invalidCountryMessage = intl.formatMessage({
            id: 'ProfileAddressForm.invalidCountryMessage',
          });
          const countryRequired = validators.required(countryRequiredMessage);

          const validCountry =  validators.validTaxId(invalidCountryMessage);
          const countryValidators = validators.composeValidators(countryRequired, validCountry)

          const supportedCountries = validators.supportedCountries;
          const countryNotSupportedMessage =
            <div className={css.countryNotSupportedMessage}>
              <FormattedMessage id="ProfileAddressForm.countryNotSupportedMessage" />
            </div>
          // company
          const companyLabel = intl.formatMessage({
            id: 'ProfileAddressForm.companyLabel',
          });
          const companyPlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.companyPlaceholder',
          });
          const companyRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.companyRequired',
          });
          const companyRequired = validators.required(companyRequiredMessage);

          // firstName
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileAddressForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.firstNamePlaceholder',
          });
          /*const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);
          */
          // lastName
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileAddressForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.lastNamePlaceholder',
          });
          /*const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);
          */
          // street
          const streetLabel = intl.formatMessage({
            id: 'ProfileAddressForm.streetLabel',
          });
          const streetPlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.streetPlaceholder',
          });
          /*const streetRequiredMessage = intl.formatMessage({
            id: 'ProfileAddress.streetRequired',
          });
          const streetRequired = validators.required(streetRequiredMessage);
          */
          // house number
          const houseNumberLabel = intl.formatMessage({
            id: 'ProfileAddressForm.houseNumberLabel',
          });
          const houseNumberPlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.houseNumberPlaceholder',
          });
          /*const houseNumberRequiredMessage = intl.formatMessage({
            id: 'ProfileAddress.houseNumberRequired',
          });
          const houseNumberRequired = validators.required(houseNumberRequiredMessage);
          */
          // postal code
          const postalCodeLabel = intl.formatMessage({
            id: 'ProfileAddressForm.postalCodeLabel',
          });
          const postalCodePlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.postalCodePlaceholder',
          });
          /*const postalCodeRequiredMessage = intl.formatMessage({
            id: 'ProfileAddress.postalCodeRequired',
          });
          const postalCodeRequired = validators.required(postalCodeRequiredMessage);
          */
          // city
          const cityLabel = intl.formatMessage({
            id: 'ProfileAddressForm.cityLabel',
          });
          const cityPlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.cityPlaceholder',
          });

          const taxIdLabel = intl.formatMessage({
            id: 'ProfileAddressForm.taxIdLabel',
          });

          const taxIdPlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.taxIdPlaceholder',
          });

          const taxIdRequiredMessage =intl.formatMessage({
            id: 'ProfileAddressForm.taxIdRequiredMessage',
          });

          const invalidTaxIdMessage = intl.formatMessage({
            id: 'ProfileAddressForm.invalidtaxIdMessage',
          });

          const b2bRequiredMessage =intl.formatMessage({
            id: 'ProfileAddressForm.b2bRequiredMessage',
          });

          const addressInformationText = intl.formatMessage({
            id: 'ProfileAddressForm.informationText',
          });

          const b2bRequired = validators.required(b2bRequiredMessage);
          const taxIdRequired =  validators.required(taxIdRequiredMessage);
          const validTaxId =  validators.validTaxId(invalidTaxIdMessage);
          const taxIdValidators = validators.composeValidators(taxIdRequired, validTaxId)
          const showAsRequired = pristine;

          /*const cityRequiredMessage = intl.formatMessage({
            id: 'ProfileAddress.cityRequired',
          });
          const cityRequired = validators.required(cityRequiredMessage);
          */

          const b2bLabel = intl.formatMessage({
            id: 'ProfileAddressForm.b2bLabel',
          });

          const vatAvailableLabel = intl.formatMessage({
            id: 'ProfileAddressForm.vatAvailableLabel',
          })

          const noVatAvailableLabel = intl.formatMessage({
            id: 'ProfileAddressForm.noVatAvailableLabel',
          })

          const user = ensureCurrentUser(currentUser);
          const isVatAvailable = values.vatAvailable === 'vat is available' ? true : false;
          const countryKey = 'country';
          const countryOptions = findOptionsForSelectFilter(countryKey, config.profileConfig.filters);
          const countryCode = countryOptions.filter(countryOption => countryOption.key === values.country)[0]?.code;
          const isSupportedVatCountry = supportedVatCountry(countryCode);
          const taxInfoText = supportedCountries.includes(countryCode) ? null : countryNotSupportedMessage
          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileAddressForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || submitInProgress || values.b2b === false;
            //|| uploadInProgress

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
              onChange={() => {
                const state = form.getState();
                if (state.active === 'country' && state.dirty === true){
                  form.change('taxId', '');
                }
              }}
              >
              <div className={classNames(css.sectionContainer, css.lastSection)}>
                <div className={css.Headline}>
                  <FormattedMessage id="ProfileAddressForm.addressTitle" />
                </div>
                <div className={css.tip}>
                  {addressInformationText}
                </div>
                <FieldSelect
                  className={css.country}
                  id={formId ? `${formId}.country` : 'country'}
                  name="country"
                  options={countryOptions}
                  validate={countryValidators}
                  label={countryLabel}
                  >
                    <option value="">{countryPlaceholder}</option>
                    {countryOptions.map(o => (
                      <FormattedMessage key={o.key} id={o.translationID}>
                        {txt => <option value={o.value}>{txt}</option>}
                      </FormattedMessage>
                    ))}
                </FieldSelect>
                <FieldTextInput
                className={css.company}
                type="text"
                id={formId ? `${formId}.company` : 'company'}
                name="company"
                autoComplete="company-name"
                label={companyLabel}
                placeholder={companyPlaceholder}
                validate={companyRequired}
                />
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.firstName}
                    type="text"
                    id={formId ? `${formId}.fname` : 'fname'}
                    name="fname"
                    autoComplete="given-name"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                  />
                  <FieldTextInput
                    className={css.lastName}
                    type="text"
                    id={formId ? `${formId}.lname` : 'lname'}
                    name="lname"
                    autoComplete="family-name"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                  />
                </div>
              <div className={css.streetContainer}>
                <FieldTextInput
                  className={css.street}
                  type="text"
                  id={formId ? `${formId}.street` : 'street'}
                  name="street"
                  autoComplete="street-name"
                  label={streetLabel}
                  placeholder={streetPlaceholder}
                />
                <FieldTextInput
                  className={css.houseNumber}
                  type="text"
                  id={formId ? `${formId}.houseNumber` : 'houseNumber'}
                  name="houseNumber"
                  autoComplete="house-number"
                  label={houseNumberLabel}
                  placeholder={houseNumberPlaceholder}
                />
              </div>
              <div className={css.cityContainer}>
                <FieldTextInput
                  className={css.postalCode}
                  type="text"
                  id={formId ? `${formId}.postalCode` : 'postalCode'}
                  name="postalCode"
                  autoComplete="postal-code"
                  label={postalCodeLabel}
                  placeholder={postalCodePlaceholder}
                />
                <FieldTextInput
                  className={css.city}
                  type="text"
                  id={formId ? `${formId}.city` : 'city'}
                  name="city"
                  autoComplete="city"
                  label={cityLabel}
                  placeholder={cityPlaceholder}
                />
              </div>
              {
                isSupportedVatCountry ?
                <div className={css.twoColumns}>
                  <FieldRadioButton
                    className={css.radioVat}
                    id={formId ? `${formId}.vatAvailable` : 'vatAvailable'}
                    name="vatAvailable"
                    label={vatAvailableLabel}
                    value="vat is available"
                    showAsRequired={showAsRequired}
                  />
                  <FieldRadioButton
                    className={css.radioVat}
                    id={formId ? `${formId}.noVatAvailable` : 'noVatAvailable'}
                    name="vatAvailable"
                    label={noVatAvailableLabel}
                    value="no vat available"
                    showAsRequired={showAsRequired}
                  />
                </div> : null
              }
              {
                isVatAvailable && isSupportedVatCountry?
                <div>
                <FieldTextInput
                  className={css.company}
                  type="taxId"
                  id={formId ? `${formId}.taxId` : 'taxId'}
                  name="taxId"
                  label={taxIdLabel}
                  placeholder={taxIdPlaceholder}
                  validate={taxIdValidators}
                  infoText={taxInfoText}
                />
              </div> : null
              }
              <div className={css.company}>
                <FieldCheckboxBool
                  className={css.b2b}
                  name="b2b"
                  id={formId ? `${formId}.b2b` : 'b2b'}
                  label={b2bLabel}
                  validate={b2bRequired}
                />
              </div>
            </div>
              {submitError}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                <FormattedMessage id="ProfileAddressForm.saveChanges" />
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileAddressFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  updateProfileError: null,
  updateProfileReady: false,
  jobTitle: null,
  filterConfig: config.custom.filters,
};

ProfileAddressFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  //uploadInProgress: bool.isRequired,
  //updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
  filterConfig: propTypes.filterConfig
};

const  ProfileAddressForm = compose(injectIntl)(ProfileAddressFormComponent);

ProfileAddressForm.displayName = 'ProfileAddressForm';

export default ProfileAddressForm;
