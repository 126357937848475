import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEmailSuccess.module.css';

const IconEmailSuccess = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="45"
      height="45"
      viewBox="0 0 45 45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#000" d="M23.18 33H2V8l20.5 14.66L43 8v18.08c.76.68 1.43 1.45 2 2.3V5H0v30h23c0-.67.06-1.34.18-2zM40.83 7L22.5 20.19 4.17 7h36.66z"/>
      <path fill="#2ecc71" d="M39.24 30.77l-5.66 5.65-2.82-2.83-1.42 1.42 2.83 2.83 1.41 1.41 7.08-7.07zM35 25c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18a8 8 0 1 1 0-16 8 8 0 1 1 0 16z"/>
    </svg>
  );
};

IconEmailSuccess.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconEmailSuccess.propTypes = { rootClassName: string, className: string };

export default IconEmailSuccess;
