import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, NamedLink, FieldDateInput, FieldDateRangeInput, FieldRadioButton, FieldCurrencyInput } from '../../components';
import { FormSpy } from 'react-final-form'
import config from '../../config';
import css from './EditListingDetailsForm.module.css';
import moment from 'moment';
import { LINE_ITEM_DAY } from '../../util/types';
import { setInitialValues } from '../../ducks/paymentMethods.duck';

const identity = v => v;
const dateFormatOptions = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
};
const EditListingDetailsFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        initialValues,
        form
      } = formRenderProps;

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const spaceLabel = intl.formatMessage({ id: 'EditListingDetailsForm.spaceLabel' });
      const dimensionsLabel = intl.formatMessage({ id: 'EditListingDetailsForm.dimensionsLabel' });
      const meterSquareLabel = intl.formatMessage({ id: 'EditListingDetailsForm.meterSquareLabel' });
      const feetSquareLabel = intl.formatMessage({ id: 'EditListingDetailsForm.feetSquareLabel' });
      const meterLabel = intl.formatMessage({ id: 'EditListingDetailsForm.meterLabel' });
      const feetLabel = intl.formatMessage({ id: 'EditListingDetailsForm.feetLabel' });

      const lengthMessage = intl.formatMessage({ id: 'EditListingDetailsForm.lengthMessage' });
      const lengthPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.lengthPlaceholder',
      });
      const lengthRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.lengthRequired',
      });

      const widthMessage = intl.formatMessage({ id: 'EditListingDetailsForm.widthMessage' });
      const widthPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.widthPlaceholder',
      });
      const widthRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.widthRequired',
      });

      const floorSpaceMessage = intl.formatMessage({ id: 'EditListingDetailsForm.floorSpaceMessage' });
      const floorSpacePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.floorSpacePlaceholder',
      });
      const floorSpaceRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.floorSpaceRequired',
      });

      const heightMessage = intl.formatMessage({ id: 'EditListingDetailsForm.heightMessage' });
      const heightPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.heightPlaceholder',
      });

      const floorsMessage = intl.formatMessage({ id: 'EditListingDetailsForm.floorsMessage' });
      const floorsPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.floorsPlaceholder',
      });

      const luminairesMessage = intl.formatMessage({ id: 'EditListingDetailsForm.luminairesMessage' });
      const luminairesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.luminairesPlaceholder',
      });

      const budgetMessage = intl.formatMessage({ id: 'EditListingDetailsForm.budgetMessage' });
      const budgetPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.budgetPlaceholder',
      });

      const today = moment().startOf('day').toDate();
      const datePlaceholder = intl.formatDate(today, dateFormatOptions);

      const realisationDateLabel = intl.formatMessage({ id: 'EditListingDetailsForm.realisationDateMessage' });
      const realisationDateRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.realisationDateRequired',
      });

      const applicationDeadlineLabel = intl.formatMessage({ id: 'EditListingDetailsForm.applicationDeadlineMessage' });
      const applicationDeadlineRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.applicationDeadlineRequired',
      });

      return (
        <Form
          className={classes}
          onSubmit={handleSubmit}
          onChange={() => {
            const state = form.getState();
            if(state.active === 'measureType' && state.dirty === true && state.values.measureType === 'dimensions'){
              form.change('dimensionsUnit', 'meter')
            }
            else if(state.active === 'measureType' && state.dirty === true && state.values.measureType === 'space'){
              form.change('dimensionsUnit', 'meterSquare')
            }
          }}>
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <div className={css.heading}>
            <FormattedMessage id="EditListingDetailsForm.frameworkDetails"/>
          </div>

          <div className={css.measureType}>
            <FieldRadioButton
              className={css.space}
              id="space"
              name="measureType"
              label={spaceLabel}
              value="space"
              showAsRequired={true}
            />
            <FieldRadioButton
              className={css.dimensions}
              id="dimensions"
              name="measureType"
              label={dimensionsLabel}
              value="dimensions"
              showAsRequired={true}
            />
          </div>
          <div className={css.dimensionsWrapper}>
            <div className={css.dimensions}>
              {values?.measureType === 'dimensions' ?
                (
                  <div className={css.dimensionValuesWrapper}>
                    <div className={css.dimensionValues}>
                      <FieldTextInput
                        id="length"
                        name="length"
                        className={css.length}
                        type="number"
                        min={0}
                        step="0.01"
                        label={lengthMessage}
                        placeholder={lengthPlaceholderMessage}
                        validate={required(lengthRequiredMessage)}
                      />
                      <FieldTextInput
                        id="width"
                        name="width"
                        className={css.width}
                        type="number"
                        min={0}
                        step="0.01"
                        label={widthMessage}
                        placeholder={widthPlaceholderMessage}
                        validate={required(widthRequiredMessage)}
                      />
                    </div>
                    <div className={css.dimensionsUnit}>
                      <FieldRadioButton
                        className={css.meter}
                        id="meter"
                        name="dimensionsUnit"
                        label={meterLabel}
                        value="meter"
                        showAsRequired={true}
                      />
                      <FieldRadioButton
                        className={css.feet}
                        id="feet"
                        name="dimensionsUnit"
                        label={feetLabel}
                        value="feet"
                        showAsRequired={true}
                      />
                    </div>
                  </div>
                ) :
                (
                  <div className={css.floorSpaceWrapper}>
                    <FieldTextInput
                      id="floorSpace"
                      name="floorSpace"
                      className={css.floorSpace}
                      type="number"
                      min={0}
                      step="0.01"
                      label={floorSpaceMessage}
                      placeholder={floorSpacePlaceholderMessage}
                      validate={required(floorSpaceRequiredMessage)}
                    />
                    <div className={css.dimensionsUnit}>
                      <FieldRadioButton
                        className={css.meterSquare}
                        id="meterSquare"
                        name="dimensionsUnit"
                        label={meterSquareLabel}
                        value="meterSquare"
                        showAsRequired={true}
                      />
                      <FieldRadioButton
                        className={css.feetSquare}
                        id="feetSquare"
                        name="dimensionsUnit"
                        label={feetSquareLabel}
                        value="feetSquare"
                        showAsRequired={true}
                      />
                    </div>
                  </div>
                )
              }
            </div>
          </div>

          <div className={css.heightWrapper}>
            <FieldTextInput
              id="height"
              name="height"
              className={css.height}
              type="number"
              min={0}
              step="0.01"
              label={heightMessage}
              placeholder={heightPlaceholderMessage}
            />
            <div className={css.heightUnit}>
              <FieldRadioButton
                className={css.meter}
                id="heightMeter"
                name="heightUnit"
                label={meterLabel}
                value="meter"
                showAsRequired={true}
              />
              <FieldRadioButton
                className={css.feetSquare}
                id="heightFeet"
                name="heightUnit"
                label={feetLabel}
                value="feet"
                showAsRequired={true}
              />
            </div>
          </div>

          <FieldTextInput
            id="floors"
            name="floors"
            className={css.floors}
            type="number"
            min={0}
            step="1"
            label={floorsMessage}
            placeholder={floorsPlaceholderMessage}
          />

          <FieldTextInput
            id="luminaires"
            name="luminaires"
            className={css.luminaires}
            type="number"
            min={0}
            step="1"
            label={luminairesMessage}
            placeholder={luminairesPlaceholderMessage}
          />

          <FieldCurrencyInput
            id="budget"
            name="budget"
            className={css.budget}
            label={budgetMessage}
            placeholder={budgetPlaceholderMessage}
            currencyConfig={config.currencyConfig}
          />

          <div className={css.applicationDeadlineWrapper}>
            <div className={css.heading}>{applicationDeadlineLabel}</div>
            <FieldDateInput
              initialDate={formRenderProps?.values?.applicationDeadline?.date || today}
              className={css.applicationDeadline}
              name="applicationDeadline"
              id='applicationDeadline'
              placeholderText={datePlaceholder}
              format={identity}
              useMobileMargins
              validate={required(applicationDeadlineRequiredMessage)}
              disabled={false}
            />
          </div>
          <div className={css.realisationDateWrapper}>
            <div className={css.heading}>{realisationDateLabel}</div>
            <FieldDateRangeInput
              className={css.realisationDate}
              name="realisationDate"
              startDateId='startRealisationDate'
              endDateId='endRealisationDate'
              format={identity}
              useMobileMargins
              validate={required(realisationDateRequiredMessage)}
              unitType={LINE_ITEM_DAY}
            />
          </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDetailsFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDetailsFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingDetailsFormComponent);
