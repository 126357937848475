import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconExpertKnowledge.module.css';

const IconExpertKnowledge = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg enableBackground="new 0 0 36 36" version="1.1" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
      <path d="m13.7 2c6.4 0 11.7 5.2 11.7 11.7 0 4.3-2.4 8.2-6.2 10.3h-11c-3.8-2-6.2-6-6.2-10.3 0-6.5 5.3-11.7 11.7-11.7m0-2c-7.5 0-13.7 6.1-13.7 13.7 0 5.1 2.8 9.8 7.4 12.1l0.3 0.1h12l0.3-0.1c4.6-2.4 7.4-7 7.4-12.1 0-7.6-6.2-13.7-13.7-13.7zm6 29h-12v2h12v-2zm-2 5h-8v2h8v-2z"/>
    </svg>
  );
};

IconExpertKnowledge.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconExpertKnowledge.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconExpertKnowledge;
