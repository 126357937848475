import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import arrayMutators from 'final-form-arrays';
import { Form as FinalForm, Field } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Form,
  Button,
  FieldCheckboxBool,
  IconClose,
  AccordionComponent,
  IconSpinner,
} from '../../components';

import css from './ProfileManufacturerForm.module.css';

const ACCEPT_PDF = 'image/jpg,application/pdf';

class ProfileManufacturerFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      manufacturerTermsAndConditions: null,
      manufacturerPrivacyPolicies: null,
      isUploading: null
    };

    this.onFileUploadHandler = this.onFileUploadHandler.bind(this);
    this.onFileRemove = this.onFileRemove.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);

    this.submittedValues = {};
    this.selectedValues = {};
  }

  onFileUploadHandler(e, form, file, field){
    this.setState({
      isUploading: field
    })

    const url = 'https://api.cloudinary.com/v1_1/lightshift/upload';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'pt06jnpu');

    fetch(url, { method: 'POST', body: formData })
      .then((response) => response.text())
      .then((data) => {
        const res = JSON.parse(data)
        if (res && res.secure_url){
          this.setState({
            [field]: res,
          });
          form.change(field, res);
          this.setState({
            isUploading: null
          })
          e.target.value = null;
        }
      });
  }

  onFileRemove = (id) => (e) => {
    e.stopPropagation();
    const stateValues = {
      [id]: null,
    }
    this.setState(stateValues);
  };

  handleSelectAll = (e, key, options, form) => {
      const name = key;
      const values = options.map(o => o.key);
      form.change(name, values);
    };

  handleClearAll = (e, key, form) => {
    const name = key;
    form.change(name, '');
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
          const {
            form,
            className,
            formId,
            handleSubmit,
            invalid,
            pristine,
            rootClassName,
            updateInProgress,
            updateProfileError,
            values,
            intl,
            manufacturerCountriesConfig,
            manufacturerProductPortfolioConfig,
            manufacturerServicePortfolioConfig,
            ready,
            userTermsAndConditions,
            userPrivacyPolicies,
          } = fieldRenderProps;
          const {
            manufacturerTermsAndConditions,
            manufacturerPrivacyPolicies,
          } = this.state;
          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileManufacturerForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues) && ready;
          const submitDisabled =
            (invalid || pristine || pristineSinceLastSubmit || submitInProgress) && !(manufacturerPrivacyPolicies || manufacturerTermsAndConditions);
            //|| uploadInProgress

          const informationText = <FormattedMessage id="ProfileManufacturerForm.informationText"/>;
          const informationTextCountries = <FormattedMessage id="ProfileManufacturerForm.informationTextCountries"/>;
          const informationTextProductPortfolio = <FormattedMessage id="ProfileManufacturerForm.informationTextProductPortfolio"/>;
          const informationTextServicePortfolio = <FormattedMessage id="ProfileManufacturerForm.informationTextServicePortfolio"/>;

          const ownTcAndPpLabel = intl.formatMessage({
            id: 'ProfileSkillsForm.ownTcAndPpLabel',
          });

          const ownTermsAndConditionsLabel = intl.formatMessage({
            id: 'ProfileSkillsForm.ownTermsAndConditionsLabel',
          });
          const ownPrivacyPolicyLabel = intl.formatMessage({
            id: 'ProfileSkillsForm.ownPrivacyPolicyLabel',
          });

          const chooseFile = intl.formatMessage({
            id: 'ProfileSkillsForm.chooseFile',
          });

          const dateFormatOptions = {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          };

          const manufacturerConfigWithCurrentCount = manufacturerCountriesConfig.map(config => {
            return {...config, currentCount: values[config.id].length}});

          const manufacturerProductPortfolioConfigWithCurrentCount = manufacturerProductPortfolioConfig.map(config => {
            return {...config, currentCount: values[config.id].length}});

          const manufacturerServicePortfolioConfigWithCurrentCount = manufacturerServicePortfolioConfig.map(config => {
            return {...config, currentCount: values[config.id].length}});

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                this.setState({
                  manufacturerPrivacyPolicies: null,
                  manufacturerTermsAndConditions: null,
                });
                handleSubmit(e);
              }}
              >
              <div className={css.tip}>
                {informationText}
              </div>
              <div className={css.sectionContainer}>
                <FieldCheckboxBool
                  className={css.ownTcAndPp}
                  name="manufacturerOwnTcAndPp"
                  id={formId ? `${formId}.ownTcAndPp` : 'ownTcAndPp'}
                  label={ownTcAndPpLabel}
                />
              </div>
              {
                values.manufacturerOwnTcAndPp ? (
                <div className={css.tcAndPpWrapper}>
                  <div className={css.ownTermsAndConditionsWrapper}>
                    <FieldCheckboxBool
                      className={css.ownTermsAndConditions}
                      name="manufacturerOwnTermsAndConditions"
                      id={formId ? `${formId}.ownTermsAndConditions` : 'ownTermsAndConditions'}
                      label={ownTermsAndConditionsLabel}
                    />
                    <Field
                      id="ownTermsAndConditionsFile"
                      name="ownTermsAndConditionsFile"
                      accept={ACCEPT_PDF}
                      label={chooseFile}
                      type="file"
                      form={null}
                    >
                      {fieldprops => {
                        const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          this.onFileUploadHandler(e, form, file, 'manufacturerTermsAndConditions');
                        };
                        const inputProps = { accept, id: name, name, onChange, type };
                        return (
                          <div className={css.uploadFieldWrapper}>
                            <div className={css.aspectRatioWrapper}>
                              {fieldDisabled ? null : (
                                <input {...inputProps} className={css.uploadInput} />
                              )}
                              <label htmlFor={name} className={css.addFile}>
                                {this.state.isUploading ===  'manufacturerTermsAndConditions'
                                  ? <IconSpinner />
                                  : label}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <div className={css.ownPrivacyPolicyWrapper}>
                    <FieldCheckboxBool
                      className={css.ownPrivacyPolicy}
                      name="manufacturerOwnPrivacyPolicy"
                      id={formId ? `${formId}.ownPrivacyPolicy` : 'ownPrivacyPolicy'}
                      label={ownPrivacyPolicyLabel}
                    />
                    <Field
                      id="ownPrivacyPolicyFile"
                      name="ownPrivacyPolicyFile"
                      accept={ACCEPT_PDF}
                      label={chooseFile}
                      type="file"
                      form={null}
                    >
                      {fieldprops => {
                        const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          this.onFileUploadHandler(e, form, file, 'manufacturerPrivacyPolicies');
                        };
                        const inputProps = { accept, id: name, name, onChange, type };
                        return (
                          <div className={css.uploadFieldWrapper}>
                            <div className={css.aspectRatioWrapper}>
                              {fieldDisabled ? null : (
                                <input {...inputProps} className={css.uploadInput} />
                              )}
                              <label htmlFor={name} className={css.addFile}>
                                {this.state.isUploading ===  'manufacturerPrivacyPolicies'
                                  ? <IconSpinner />
                                  : label}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                </div>) : null }
                {userTermsAndConditions?.length > 0 || manufacturerTermsAndConditions ? (
                  <div className={css.userTermsAndConditionsContainer}>
                    <h2>
                      <FormattedMessage id='ProfileSkillsForm.userTermsAndConditions' />
                    </h2>
                    {
                      manufacturerTermsAndConditions ?
                      <div className={css.newItem}>
                        <div>
                          <a
                            key={manufacturerTermsAndConditions.public_id}
                            target='_blank'
                            href={manufacturerTermsAndConditions?.secure_url}
                          >{manufacturerTermsAndConditions.original_filename}</a>
                          <div className={css.date}>{intl.formatDate(manufacturerTermsAndConditions.created_at, dateFormatOptions)}</div>
                          <div className={css.removeItem} onClick={this.onFileRemove('manufacturerTermsAndConditions')}>
                            <IconClose size='normal' className={css.removeIcon} />
                          </div>
                        </div>
                      </div> : null
                    }
                    {
                      userTermsAndConditions?.length > 0 ?
                        <div className={css.userTcPp}>
                          {userTermsAndConditions.map((t, index) => {
                            return (
                              <div key={`${index}.tac`}>
                                <a
                                  key={t.public_id}
                                  target='_blank'
                                  href={t?.secure_url}
                                >{t.original_filename}</a>
                                <div className={css.date}>{intl.formatDate(t.created_at, dateFormatOptions)}</div>
                              </div>
                            );
                          })}
                        </div> : null
                    }
                  </div>
                ) : null}

                {userPrivacyPolicies?.length > 0 || manufacturerPrivacyPolicies ? (
                  <div className={css.userPrivacyPoliciesContainer}>
                    <h2>
                      <FormattedMessage id='ProfileSkillsForm.privacyPolicies' />
                    </h2>
                    {
                      manufacturerPrivacyPolicies ?
                      <div className={css.newItem}>
                        <div>
                          <a
                            key={manufacturerPrivacyPolicies.public_id}
                            target='_blank'
                            href={manufacturerPrivacyPolicies?.secure_url}
                          >{manufacturerPrivacyPolicies.original_filename}</a>
                          <div className={css.date}>{intl.formatDate(manufacturerPrivacyPolicies.created_at, dateFormatOptions)}</div>
                          <div className={css.removeItem} onClick={this.onFileRemove('manufacturerPrivacyPolicies')}>
                            <IconClose size='normal' className={css.removeIcon} />
                          </div>
                        </div>
                      </div> : null
                    }
                    {
                      userPrivacyPolicies?.length > 0 ?
                        <div className={css.userTcPp}>
                          {userPrivacyPolicies.map((pp, index) => {
                            return (
                              <div key={`${index}.pp`}>
                                <a
                                  key={pp.public_id}
                                  target='_blank'
                                  href={pp?.secure_url}
                                >{pp.original_filename}</a>
                                <div className={css.date}>{intl.formatDate(pp.created_at, dateFormatOptions)}</div>
                              </div>
                            );
                          })}
                        </div> : null
                    }
                  </div>
                ) : null}
              <div className={css.headline}>
                <FormattedMessage id="ProfileManufacturerForm.countriesTitle" />
              </div>
              <div className={css.tip}>{informationTextCountries}</div>
              <div>
                <AccordionComponent
                  key={`${config.id}.countries`}
                  items={manufacturerConfigWithCurrentCount}
                  setFocus = {true}
                  setHighlight = {true}
                  sortByChecked = {false}
                  clearAll = {this.handleClearAll}
                  selectAll = {this.handleSelectAll}
                  clearSelectButtons = {true}
                  form = {form}
                  >
                </AccordionComponent>
              </div>
              <div className={css.headline}>
                <FormattedMessage id="ProfileManufacturerForm.productPortfolioTitle" />
              </div>
              <div className={css.tip}>{informationTextProductPortfolio}</div>
              <div>
                <AccordionComponent
                  key={`${config.id}.productPortfolio`}
                  items={manufacturerProductPortfolioConfigWithCurrentCount}
                  setFocus = {true}
                  setHighlight = {true}
                  sortByChecked = {false}
                  clearAll = {this.handleClearAll}
                  selectAll = {this.handleSelectAll}
                  clearSelectButtons = {true}
                  form = {form}>
                </AccordionComponent>
              </div>
              <div className={css.headline}>
                <FormattedMessage id="ProfileManufacturerForm.servicePortfolioTitle" />
              </div>
              <div className={css.tip}>{informationTextServicePortfolio}</div>
              <div>
                <AccordionComponent
                  key={`${config.id}.servicePortfolio`}
                  items={manufacturerServicePortfolioConfigWithCurrentCount}
                  setFocus = {true}
                  setHighlight = {true}
                  sortByChecked = {false}
                  clearAll = {this.handleClearAll}
                  selectAll = {this.handleSelectAll}
                  clearSelectButtons = {true}
                  form = {form}
                  >
                </AccordionComponent>
              </div>
              <div className={css.stickyButton}>
                {submitError}
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={pristineSinceLastSubmit}
                >
                <FormattedMessage id="ProfileManufacturerForm.saveChanges" />
              </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileManufacturerFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  updateProfileError: null,
  updateProfileReady: false,
  jobTitle: null,
  manufacturerCountriesConfig: config.manufacturerConfig.countries,
  manufacturerProductPortfolioConfig: config.manufacturerConfig.productPortfolio,
  manufacturerServicePortfolioConfig: config.manufacturerConfig.servicePortfolio,
};

ProfileManufacturerFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  //uploadInProgress: bool.isRequired,
  //updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
  manufacturerCountriesConfig: propTypes.filterConfig,
  manufacturerProductPortfolioConfig: propTypes.filterConfig,
  manufacturerServicePortfolioConfig: propTypes.filterConfig
};

const ProfileManufacturerForm = compose(injectIntl)(ProfileManufacturerFormComponent);

ProfileManufacturerForm.displayName = 'ProfileManufacturerForm';

export default ProfileManufacturerForm;
