import _ from 'lodash';
import config from '../config';
import { findOptionLabel } from './search';

export const getExpertSkillLabels = (expert) => {

  const publicData = expert?.attributes?.publicData;

  if (!publicData) return [];

  const skills = _.pick(publicData, ['dialux', 'lightingDesign', 'documentation', 'photoWork', 'others']);
  const validSkills = _.omitBy(skills, (v) => _.isUndefined(v) || _.isNull(v) || v === '');
  const validSkillsKeys = Object.keys(validSkills);
  const labels =  validSkillsKeys && validSkillsKeys.length > 0 && validSkillsKeys.map(s => validSkills[s].map(so => findOptionLabel( config.profileConfig.filters, "pub_" + s, so)))
  return  _.flatten(labels)
}
