import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';
import config from '../../config';
// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 12;

// ================ Action types ================ //

export const EXPERTS_LISTINGS_REQUEST = 'app/ExpertsListingPage/EXPERTS_LISTINGS_REQUEST';
export const EXPERTS_LISTINGS_SUCCESS = 'app/ExpertsListingPage/EXPERTS_LISTINGS_SUCCESS';
export const EXPERTS_LISTINGS_ERROR = 'app/ExpertsListingPage/EXPERTS_LISTINGS_ERROR';
export const EXPERTS_LISTINGS_COUNT = 'app/ExpertsListingPage/EXPERTS_LISTINGS_COUNT';
export const EXPERTS_COUNTRIES_COUNT = 'app/ExpertsListingPage/EXPERTS_COUNTRIES_COUNT';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchExpertsListingsError: null,
  currentPageResultIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const expertsListingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case EXPERTS_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchExpertsListingsError: null,
      };
    case EXPERTS_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case EXPERTS_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchExpertsListingsError: payload };
    case EXPERTS_LISTINGS_COUNT:
      return {
        ...state,
        totalAmountOfFreelancers: payload
      }
    case EXPERTS_COUNTRIES_COUNT:
      return {
        ...state,
        totalAmountOfFreelancerCountries: payload
      }
      default:
        return state;
  }
};

export default expertsListingPageReducer;

// ================ Action creators ================ //

export const searchExpertsListingsRequest = searchParams => ({
  type: EXPERTS_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchExpertsListingsSuccess = response => ({
  type: EXPERTS_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchExpertsListingsError = e => ({
  type: EXPERTS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const storeTotalAmountOfFreelancers = response => ({
  type: EXPERTS_LISTINGS_COUNT,
  payload: response?.data?.meta?.totalItems ?? 0
})

export const storeTotalAmountOfFreelancerCountries = response => ({
  type: EXPERTS_COUNTRIES_COUNT,
  payload: response?.data?.data
                   ?.map(x => x.attributes?.publicData?.country ?? '')  // select country
                   ?.filter(x => !!x) // remove empty values
                   ?.filter((x,i,a) => a.indexOf(x) === i) // remove duplicates
                   ?.length
})

export const searchExpertsListings = searchParams => (dispatch, getState, sdk) => {

  dispatch(searchExpertsListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const listingCategoryFilter = {
    //pub_expertOption: true,
    pub_listingCategory: 'expert',
    pub_isIncognito: false,
    meta_subscription: 'gold'
  }

  const params = {
    ...listingCategoryFilter,
    ...rest,
    per_page: perPage,
  };

  return sdk.listings.query(params)
    .then(response => {
      sdk.listings.query({
        per_page: 100,
        pub_expertOption: true,
        
        //pub_listingCategory: 'expert',
        //meta_subscription: 'gold,silver,bronze',
        ...rest
      }).then(response => {
        dispatch(storeTotalAmountOfFreelancers(response))
        dispatch(storeTotalAmountOfFreelancerCountries(response))
      })

      return response;
    }).then(response => {

      dispatch(addMarketplaceEntities(response));
      dispatch(searchExpertsListingsSuccess(response));
      return response;
    }).catch(e => {
      dispatch(searchExpertsListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchExpertsListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author.profileImage'],
    'fields.user': ['email', 'profile.displayName', 'profile.bio', 'profile.publicData'],
    'fields.image': ['variants.square-xsmall', 'variants.square-xsmall2x', 'variants.square-small', 'variants.square-small2x'],
    'limit.images': 1,
  });
};
