import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCircleThree.module.css';

const IconCircleThree = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.5 0C7.8 0 0 7.8 0 17.5S7.8 35 17.5 35 35 27.2 35 17.5 27.2 0 17.5 0zm0 32C9.5 32 3 25.5 3 17.5S9.5 3 17.5 3 32 9.5 32 17.5 25.5 32 17.5 32zm2.6-14.7c.7-.3 1.3-.8 1.7-1.4s.7-1.3.7-2c0-1.4-.4-2.5-1.3-3.3s-2.1-1.2-3.7-1.2c-.9 0-1.8.2-2.6.6s-1.4.9-1.8 1.5c-.4.7-.6 1.4-.6 2.2h2.6c0-.7.2-1.2.7-1.6a2.52 2.52 0 0 1 1.7-.6c.8 0 1.4.2 1.8.6s.6 1 .6 1.7c0 .8-.2 1.4-.7 1.8s-1.1.6-1.9.6h-1.6v2.1h1.5c1 0 1.7.2 2.2.7.5.4.7 1.1.7 1.9s-.2 1.4-.7 1.9-1.1.6-1.9.6-1.4-.2-1.9-.6-.7-1-.7-1.7h-2.6c0 1.3.5 2.4 1.4 3.2 1 .8 2.2 1.2 3.7 1.2 1.6 0 2.8-.4 3.8-1.2s1.4-1.9 1.4-3.4c0-.9-.2-1.6-.7-2.3-.3-.5-1-1-1.8-1.3z"/>
    </svg>
  );
};

IconCircleThree.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCircleThree.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCircleThree;
