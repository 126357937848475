const SET_INITIAL_VALUES = 'app/WorkingExperience/SET_INITIAL_VALUES';
const SET_MODAL_VISIBILITY = 'app/WorkingExperience/SET_MODAL_VISIBILITY';
const UPDATE_WORKING_EXPERIENCE = 'app/WorkingExperience/UPDATE_WORKING_EXPERIENCE';
const ADD_WORKING_EXPERIENCE = 'app/WorkingExperience/ADD_WORKING_EXPERIENCE';
const DELETE_WORKING_EXPERIENCE = 'app/WorkingExperience/DELETE_WORKING_EXPERIENCE';

const initialState = {
  isModalOpen: false,
  items: [],
  selectedItem: {},
  isPristine:true
};

export default function reducer(state = initialState, { payload, type }) {

  switch (type) {
    case SET_INITIAL_VALUES:

      const items = payload.sort((a,b)=>{
        if (+a?.startYear < +b?.startYear) {
          return +1;
        } else if (+a?.startYear > +b?.startYear) {
          return -1;
        } else {
          if (+a?.startMonth < +b?.startMonth) {
            return +1;
          } else if (+a?.startMonth > +b?.startMonth) {
            return -1;
          } else {
            return 0;
          }
        }
      });

      return {
        ...initialState,
        ...state, items,
        isPristine: true
      };
    case SET_MODAL_VISIBILITY:
      const { isOpen, item } = payload;
      return { ...state, isModalOpen: isOpen, selectedItem: item };

    case ADD_WORKING_EXPERIENCE:
      return {
        ...state,
        items: [
          { ...payload, id: state.items.length + 1 },
          ...state.items,
        ],
        isPristine: false
      };

    case UPDATE_WORKING_EXPERIENCE:
      return  {
        ...state,
        items: state.items.map(x =>
          x.id === payload.id ? { ...payload, id: x.id } : x,
        ),
        isPristine: false
      };

    case DELETE_WORKING_EXPERIENCE:
      const { id } = payload;
      return {
        ...state,
        items: state.items.filter(x => x.id !== id),
        isPristine: false,
        isModalOpen: false
      };

    default:
      return state;
  }
}

export const initializeWorkingExperience = (items = []) => ({
  type: SET_INITIAL_VALUES,
  payload: items
});

export const openWorkingExperienceModal = (item = {}) => ({
  type: SET_MODAL_VISIBILITY,
  payload: { isOpen: true, item },
});

export const closeWorkingExperienceModal = () => ({
  type: SET_MODAL_VISIBILITY,
  payload: { isOpen: false, item: {} },
});

export const upsertWorkingExperience = (item) => {
  const isNewItem = !item.id;

  return {
    type: isNewItem ? ADD_WORKING_EXPERIENCE : UPDATE_WORKING_EXPERIENCE,
    payload: item,
  };
};

export const deleteWorkingExperience = (item) => {
  return {
    type: DELETE_WORKING_EXPERIENCE,
    payload: item,
  };
};
