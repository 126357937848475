import React from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import {
  connect,
  useSelector,
} from 'react-redux';
import { selectPayedSubscription } from '../../ducks/user.duck';
import { propTypes } from '../../util/types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { logout, authenticationInProgress } from '../../ducks/Auth.duck';
import { manageDisplayConsent } from '../../ducks/UI.duck';
import {
  Logo,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';

const FooterComponent = props => {
  const { rootClassName, className, intl, isAuthenticated, currentUser, onManageDisplayConsent} = props;
  const hasPayedSubscription = useSelector(selectPayedSubscription);

  const classes = classNames(rootClassName || css.root, className);

  const expertLink = !hasPayedSubscription && (
    <li className={css.listItem}>
      <NamedLink name="ProfileSkillsPage" className={css.link}>
        <FormattedMessage id="Footer.expert" />
      </NamedLink>
    </li>
  );

  const signUpLink = !isAuthenticated && (
    <li className={css.listItem}>
      <NamedLink name="SignupPage" className={css.link}>
        <FormattedMessage id="Footer.signup" />
      </NamedLink>
    </li>
  );

  const aboutUsLink = intl.formatMessage({id:'ContactPage.aboutUsLink'});

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo format="desktop" className={css.logo} />
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                {expertLink}
                {signUpLink}
                <li className={css.listItem}>
                  <NamedLink name="NewListingPage" className={css.link}>
                    <FormattedMessage id="Footer.toNewListingPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="NewLeadPage" className={css.link}>
                    <FormattedMessage id="Footer.toIndustrySupport" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ExpertsListingPage" className={css.link}>
                    <FormattedMessage id="Footer.toExpertsListingPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <FormattedMessage id="Footer.toSearchPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <a href={aboutUsLink} className={css.link} target='_blank'>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="FaqPage" className={css.link}>
                    <FormattedMessage id="Footer.toFAQPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ContactPage" to={{ hash: '#contact' }} className={css.link}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <NamedLink name="LandingPage" className={css.companyInfo}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            {" | "}
            <NamedLink name="ImpressumPage" className={css.companyInfo}>
              <FormattedMessage id="Footer.toImpressumPage" />
            </NamedLink>
            {" | "}
            <NamedLink name="TermsOfServicePage" className={css.companyInfo}>
              <FormattedMessage id="Footer.termsOfUse" />
            </NamedLink>
            {" | "}
            <NamedLink name="PrivacyPolicyPage" className={css.companyInfo}>
              <FormattedMessage id="Footer.privacyPolicy" />
            </NamedLink>
            {" | "}
            <NamedLink name="PriceListPage" className={css.companyInfo}>
              <FormattedMessage id="Footer.priceList" />
            </NamedLink>
            {" | "}
            <span className={css.companyInfo} onClick={() => onManageDisplayConsent(true)}>
              <FormattedMessage id="Footer.cookie" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

FooterComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
};

FooterComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
};

const mapStateToProps = state => {
  // Footer needs isAuthenticated
  const { isAuthenticated } = state.Auth;
  // Footer needs user info.
  const { currentUser } = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) => dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onManageDisplayConsent: (show) => dispatch(manageDisplayConsent(show)),
});

const Footer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FooterComponent);

export default injectIntl(Footer);
