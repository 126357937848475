import React from 'react';
import css from './PriceList.module.css';
import { PriceListCard } from './PriceListCard';

export function PriceListBenefitGroup({ benefits }) {
  return (
    <section  className={css.benefits}>
      <h2 className={css.capture}>{benefits.header}</h2>

      <div className={css.cardFeatureList}>
        {benefits.cards.map(card =>
          <PriceListCard key={card.idx} card={card} />
        )}
      </div>
    </section>
  );
}

PriceListBenefitGroup.defaultProps = {};

PriceListBenefitGroup.propTypes = {};
