import React from 'react';
import config from '../../config';
import { FormattedDate, FormattedMessage } from '../../util/reactIntl';
import css from './TransactionPanel.module.css';

export const SectionListingDescriptionMaybe = ({ dataReader, isManufacturer }) => {
  const isLead = dataReader.isLeadListing();

  if (dataReader.isExpert()) {
    return null;
  }

  const listingTitle = dataReader.getListingTitle();
  const [startDate, endDate] = dataReader.getRealisationDate();
  const [totalLabel, totalPrice] = dataReader.getLeadTotalPrice();

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };

  const shouldDisplayPrice = isLead && isManufacturer && !config.isFreeLeadPeriod;

  return (
    <div className={css.detailsContainerDesktop}>
      <div className={css.bookingPeriod}>
        <div className={css.detailsHeadings}>
          <h2 className={css.detailsTitle}>{listingTitle}</h2>
        </div>
        {isLead && (
          <>
            <div className={css.bookingPeriodSectionRigth}>
              <div className={css.dayLabel}>
                <FormattedMessage id="ListingPage.realisationTime" />
              </div>
              <span className={css.dayInfo}>
                <FormattedDate value={startDate} {...dateFormatOptions} />
                <span> - </span>
                <FormattedDate value={endDate} {...dateFormatOptions} />
              </span>
            </div>
            <hr className={css.totalDivider} />
          </>
        )}
      </div>
      {shouldDisplayPrice && (
        <div className={css.lineItemTotal}>
          <div className={css.totalLabel}>{totalLabel}</div>
          <div className={css.totalPrice}>{totalPrice}</div>
        </div>
      )}
    </div>
  );
};
