import React from "react";
import classNames from 'classnames';
import PhoneInput from 'react-phone-number-input/input';
import css from './PhoneNumberInput.module.css';
//import 'react-phone-number-input/style.css'

const PhoneNumberInput = props => {
  const { placeholder, label, defaultCountry, valid, hasError} = props;

 const inputClasses =
  classNames(css.input, {
    [css.inputSuccess]: valid,
    [css.inputError]: hasError,
  });


  return (
    <div>
      <label>{label}</label>
      <PhoneInput
        className={inputClasses}
        placeholder={placeholder}
        defaultCountry={defaultCountry}
        onChange={value => {
          props.input.onChange(value)
        }}
        value={props.input.value}
      />
    </div>
  );
};

export default PhoneNumberInput;
