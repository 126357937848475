import css from './IconAboveText.module.css';
import React from 'react';
import PropTypes from 'prop-types';

const IconAboveText = ({ icon, text }) => {
  return (
    <div className={css.column}>
      <div className={css.icon}>{icon}</div>
      <div className={css.text}>{text}</div>
    </div>
  );
};

IconAboveText.defaultProps = {
  icon: null,
  text: null
};

const { string } = PropTypes;

IconAboveText.propTypes = {
  text: string
};
export default IconAboveText;
