import { useSelector } from 'react-redux';
import { selectCurrentUserCountry } from '../ducks/user.duck';
import { stripeCountryDetails } from '../stripe-config';

export const useStripeCountryDetection = (country) => {
  const currentCountry = country || useSelector(selectCurrentUserCountry);
  const isStripeCountry = !!stripeCountryDetails.find(x => x.name === currentCountry);

  return [isStripeCountry, currentCountry];
};
