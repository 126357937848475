import { FieldCheckboxBool } from '../../components';
import { useTranslation } from '../../hooks';
import css from './ProfileSkillsForm.module.css';

export const SectionEmailNotification = () => {

  const setTranslation = useTranslation('ProfileBConsForm');

  const selectExpertLabel = setTranslation('selectExpertLabel');

  return (
    <div className={css.sectionContainer}>
      <FieldCheckboxBool
        className={css.expertOption}
        name="expertOption"
        id={'expertOption'}
        label={selectExpertLabel}
      />
    </div>
  );
};
