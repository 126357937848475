import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { useStripeCountryDetection } from '../../hooks';
import { getExpertSkillLabels } from '../../util/getExpertSkillLabels';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, fetchFullName } from '../../util/data';
import config from '../../config';
import { NamedLink, ExpertAvatarLarge, FieldCheckbox } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

import css from './ExpertCard.module.css';

export const ExpertCardComponent = props => {
  const { className, rootClassName, intl, listing, optionToSelect=false, currentUser } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const attributes = currentListing.attributes;
  const publicData = currentListing.attributes.publicData;
  const id = publicData && publicData.userID ? publicData.userID : null;

  const jobTitle = attributes && attributes.publicData && attributes.publicData.jobTitle ? attributes.publicData.jobTitle : null;
  const author = currentListing ? currentListing.author : '';
  const expertName = attributes && attributes.title ? attributes.title : null;

  const [fullName, setFullName] = useState(expertName);

  useEffect(() => {
    let ignore = false;
    setFullName(expertName);
    fetchFullName(listing, expertName).then(result => {
      if(!ignore) {
        setFullName(result);
      }
    });
    return () => {
      ignore = true;
    };
  },[id]);

  const expertLink = <NamedLink name="ProfilePage" params={{ id }} target="_blank">
                      {fullName}
                    </NamedLink>
  const price = attributes && attributes.price ? attributes.price : null;

  const allSkillsLabels = getExpertSkillLabels(currentListing)
  const skillsLabels = allSkillsLabels.slice(0, 6);
  const skillsLabelsAll = allSkillsLabels.slice(0, 50);

  const moreSkillsLink =  skillsLabelsAll.length > 6 ?
                          <NamedLink name="ProfilePage" params={{ id }} className={css.moreSkillsButtonLink}>
                            <FormattedMessage id="ExpertsListingCard.seeAllSkills"/>
                          </NamedLink> : <div className={css.moreSkillsButtonLink}>&nbsp;</div>

  const connectLink = currentUser &&
    <div className={css.button}>
      <NamedLink className={css.connectLink} name="ProfilePage" params={{id}} to={{search: '?contact=true'}} >
        <FormattedMessage className={css.connectText} id="ExpertsListingCard.contact"/>
      </NamedLink>
    </div>


  const skillTags = skillsLabels && skillsLabels.length > 0 ?
    <div className={css.skillTags}>
      {
        skillsLabels.map(s => {return <span key={s.props.id} className={css.skillTag}>{s}</span>})
      }
    </div> : <div className={css.skillTags}></div>;

  const bio = currentListing.attributes.description;

  const county = publicData && publicData.country ? publicData.country : null;
  const [isStripeUser] = useStripeCountryDetection(county);

  const currentHourlyRateAmount = price?.amount ?? 0;
  const currentHourlyRateCurrency = price?.currency ?? '';

  const hourlyRateAsMoney =
    new Money(currentHourlyRateAmount, currentHourlyRateCurrency);

  const hourlyRateData = (hourlyRateAsMoney, intl) => {
    if (hourlyRateAsMoney && hourlyRateAsMoney.currency === config.currency) {
      const formattedPrice = formatMoney(intl, hourlyRateAsMoney);

      return { formattedPrice, priceTitle: formattedPrice };
    } else if (hourlyRateAsMoney === null) {
      return {
        formattedPrice: intl.formatMessage(
          { id: 'ListingCard.unsupportedPrice' },
          { currency: hourlyRateAsMoney.currency }
        ),
        priceTitle: intl.formatMessage(
          { id: 'ListingCard.unsupportedPriceTitle' },
          { currency: hourlyRateAsMoney.currency }
        ),
      };
    }
    return {formattedPrice: '', priceTitle: ''};
  };
  const { formattedPrice, priceTitle } = hourlyRateData(hourlyRateAsMoney, intl);


  return (
  <div className={classes}>
    {
      optionToSelect ?
      <>
        <div className={css.gridContainer}>
          <div>
            <NamedLink name="ProfilePage" params={{ id }} target="_blank">
              <ExpertAvatarLarge author={author} fullName={fullName} />
            </NamedLink>
          </div>
          <div>
            <div className={css.authorInfo}>{expertLink}</div>
            <div className={css.jobDescription}>{jobTitle}</div>
          </div>
          <div className={css.price}>
            {currentHourlyRateAmount > 0
              ? <FormattedMessage id="ExpertsListingCard.ratePerHour" values={{ formattedPrice }}/>
              : null}
          </div>
          <div className={css.country}>
            {county}
          </div>
        </div>

        <div className={css.bio}>
          {bio}
        </div>
        {skillTags}
        <div className={css.select}>
          <FieldCheckbox id={`select-expert-${currentListing.id.uuid}`} name="invitedExpertsIds" label="" value={currentListing.id.uuid} large={true} />
        </div>
      </> :
      <>
        <div>
        <NamedLink className={css.link} name="ProfilePage" params={{ id }}>
          <div className={css.gridContainer}>
            <div>
              <ExpertAvatarLarge author={author} fullName={fullName} />
            </div>

            <div>
              <div className={css.authorInfo}>{fullName}</div>
              <div className={css.jobDescription}>{jobTitle}</div>
            </div>

            <div className={css.price}>
              {currentHourlyRateAmount > 0
                ? <FormattedMessage id="ExpertsListingCard.ratePerHour" values={{ formattedPrice }}/>
                : null}
            </div>

            <div className={css.country}>
              {county}
            </div>
          </div>

          <div className={css.bio}>
            {bio}
          </div>
          {skillTags}
        </NamedLink>
        {moreSkillsLink}
        </div>
        {connectLink}
    </>
    }
  </div>

  );
};

ExpertCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
};

ExpertCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,
};

export default injectIntl(ExpertCardComponent);
