import React, { useState } from "react";
import Datetime from 'react-datetime';
//import "react-datetime/css/react-datetime.css";
//import { metaTagProps } from "../../util/seo";
import css from './DateTimePicker.module.css';


const DateTimePicker = props => {
  const { input, isValidDate, utc, locale, label, displayTimezone, output} = props;

  return (
    <div className={css.DateTime}>
      <label>{label}</label>
      <Datetime
        className={css.rdt}
        displayTimezone={displayTimezone}
        input={false}
        isValidDate={isValidDate}
        utc={utc}
        locale={locale}
        onChange={value => {
          props.input.onChange(value)
        }}
        value={props.input.value}
      />
      <label>{output}</label>
    </div>
  );
};

export default DateTimePicker;
