import React, { Component } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy, Field } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';
import config from '../../config';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { required, composeValidators, maxLength } from '../../util/validators';
import { DELIVERY_DATE } from '../../util/dates';
import { propTypes } from '../../util/types';
import { Form, PrimaryButton, FieldTextInput, GenericMessage, FieldCheckboxBool, IconClose, IconSpinner } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import LeadBreakdown from './LeadBreakdown';

import css from './BookingDatesForm.module.css';

const { Money } = sdkTypes;
const DETAILS_MAX_LENGTH = 5000;
const ACCEPT_PDF = 'pdf/*';
const LIGHTSHIFT_FEE = 5900;
const MAX_DOCUMENTS = 2;
const dateFormatOptions = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export class OfferFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      documents: [],
      uploadInProgress: false,
    };
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.onFileUploadHandler = this.onFileUploadHandler.bind(this);
    this.onFileRemove = this.onFileRemove.bind(this);

  }

  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  onFileUploadHandler(e, form, file, field){
    if (this.state[field].length >= MAX_DOCUMENTS)
      return false
    const url = 'https://api.cloudinary.com/v1_1/lightshift/upload';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'vbh822gi');
    formData.append('resource_type', 'pdf');
    this.setState({
      uploadInProgress: true,
    });
    fetch(url, { method: 'POST', body: formData })
      .then((response) => response.text())
      .then((data) => {
        const res = JSON.parse(data)
        if (res && res.secure_url){
          form.change(field, [...this.state[field], res]);
          this.setState({
            [field]: [...this.state[field], res],
            uploadInProgress: false
          });
          e.target.value = null;
        }
      });
  }

  onFileRemove = (id, documentId) => (e) => {
    e.stopPropagation();
    const stateValues = {
      [id]: this.state[id].filter(document => document.public_id !== documentId),
    }
    this.setState(stateValues);
  };


  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    return (
      <FinalForm
        {...rest}
        onSubmit={this.props.onSubmit}
        render={fieldRenderProps => {
          const {
            form,
            formId,
            handleSubmit,
            intl,
            submitButtonWrapperClassName,
            values,
            pristine,
            sendProposalInProgress,
            sendProposalError,
            sendProposalMessage,
            currentUser,
            onOpenTermsOfService,
            onOpenDataSecurityPolicy,
            onOpenLeadService,
            hasValidationErrors,
            isOwnListing,
          } = fieldRenderProps;

          const {
            documents,
            uploadInProgress,
          } = this.state;

          const detailsLabel = intl.formatMessage({
            id: 'OfferForm.detailsLabel',
          });
          const detailsPlaceholder = intl.formatMessage({
            id: 'OfferForm.detailsPlaceholder',
          });
          const detailsRequiredMessage = intl.formatMessage({
            id: 'OfferForm.detailsRequiredMessage',
          });

          const detailsRequired = required(detailsRequiredMessage)

          const maxLengthMessage = intl.formatMessage(
            {
              id: 'OfferForm.maxLengthDetails',
            },
            {
              maxLength: DETAILS_MAX_LENGTH,
            });
          const maxLength5000Message = maxLength(maxLengthMessage, DETAILS_MAX_LENGTH);

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );
          const offerSubmitted = sendProposalMessage ? (sendProposalMessage === "offer_submitted") : false;
          const alreadyOffered = sendProposalMessage ? (sendProposalMessage === "already_offered") : false;
          const isDeadlinePassed = sendProposalMessage ? (sendProposalMessage === "deadline_passed") : false;
          const submitDisabled = hasValidationErrors || sendProposalInProgress || offerSubmitted || alreadyOffered || uploadInProgress;
          const sendOfferRequestMessage = sendProposalError ?
          intl.formatMessage({
            id: 'OfferForm.sendProposalErrorMessage',
          }) :
          alreadyOffered ? intl.formatMessage({
            id: 'OfferForm.alreadyOfferedMessage',
          }) :
          offerSubmitted ? intl.formatMessage({
            id: 'OfferForm.offerSubmittedMessage',
          }) :
          isDeadlinePassed ? intl.formatMessage({
            id: 'OfferForm.deadlinePassedMessage',
          }) : null;

          const lightshift = intl.formatMessage({
            id: 'OfferForm.lightshift',
          });

          const priceLabel = intl.formatMessage({
            id: 'OfferForm.offerPriceMessage',
          });

          const lightshiftFee = formatMoney(intl, new Money(LIGHTSHIFT_FEE, config.currency))

          const documentsLabel =intl.formatMessage({
            id: 'OfferForm.documentsLabel',
          });

          const documentsTip =intl.formatMessage({
            id: 'OfferForm.documentsTip',
          });

          const chooseFile = intl.formatMessage({
            id: 'OfferForm.chooseFile',
          });

          const showAsRequired = pristine

          const agbCheckedRequired = value => (value ? false : true)
          const dseCheckedRequired = value => (value ? false : true)

          const handleTermsKeyUp = e => {
            // Allow click action with keyboard like with normal links
            if (e.keyCode === KEY_CODE_ENTER) {
              onOpenTermsOfService();
            }
          };

          const handleDseKeyUp = e => {
            // Allow click action with keyboard like with normal links
            if (e.keyCode === KEY_CODE_ENTER) {
              onOpenDataSecurityPolicy();
            }
          };

          const handleLeadKeyUp = e => {
            // Allow click action with keyboard like with normal links
            if (e.keyCode === KEY_CODE_ENTER) {
              onOpenLeadService();
            }
          };

          const termsLink = (
            <span
              className={css.link}
              onClick={onOpenTermsOfService}
              role="button"
              tabIndex="0"
              onKeyUp={handleTermsKeyUp}
            >
              <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
            </span>
          );
          const dseLink = (
            <span
              className={css.link}
              onClick={onOpenDataSecurityPolicy}
              role="button"
              tabIndex="0"
              onKeyUp={handleDseKeyUp}
            >
              <FormattedMessage id="SignupForm.dataSecurityLinkText" />
            </span>
          );
          const leadLink = (
            <span
              className={css.link}
              onClick={onOpenLeadService}
              role="button"
              tabIndex="0"
              onKeyUp={handleLeadKeyUp}
            >
              <FormattedMessage id="SignupForm.leadTermsLinkText" />
            </span>
          );

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              <FieldTextInput
                className={css.fieldCurrencyInput}
                name="service"
                id={`${formId}.service`}
                label={detailsLabel}
                placeholder={detailsPlaceholder}
                type="textarea"
                maxLength={DETAILS_MAX_LENGTH}
                validate={composeValidators(detailsRequired, maxLength5000Message)}
              />
                <div className={css.documentsWrapper}>
                  <div className={css.documentLabel}>
                    {documentsLabel}
                  </div>
                  <div className={css.fileUploadWrapper}>
                    <Field
                      id="documents"
                      name="documents"
                      accept={ACCEPT_PDF}
                      label={chooseFile}
                      type="file"
                      form={null}
                      fieldDisabled={uploadInProgress}
                    >
                      {fieldprops => {
                        const { accept, input, label, fieldDisabled } = fieldprops;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          this.onFileUploadHandler(e, form, file, 'documents');
                        };
                        const inputProps = { accept, id: name, name, onChange, type };
                        return (
                          <div className={css.uploadFieldWrapper}>
                            <div className={css.aspectRatioWrapper}>
                              {fieldDisabled ? null : (
                                <input {...inputProps} className={css.uploadInput} />
                              )}
                              <label htmlFor={name} className={fieldDisabled ? css.addFileDisabled : css.addFile}>
                                { uploadInProgress ? <IconSpinner/> : label}
                              </label>
                              <div className={css.documentTip}>
                              {documentsTip}
                            </div>
                            </div>
                          </div>
                        );
                      }}
                    </Field>

                  </div>
                </div>

                {documents?.length > 0 ? (
                  <div className={css.documentsContainer}>
                    <h2>
                      <FormattedMessage id='OfferForm.documents' />
                    </h2>
                    {
                      documents?.length > 0 ?
                        <div className={css.uploadedDocuments}>
                          {documents.map((t, index) => {
                            return (
                              <div>
                                <a
                                  key={t.public_id}
                                  target='_blank'
                                  href={t?.secure_url}
                                >{t.original_filename}</a>
                                <div className={css.date}>{intl.formatDate(t.created_at, dateFormatOptions)}</div>
                                <div className={css.removeItem} onClick={this.onFileRemove('documents', t.public_id)}>
                                  <IconClose size='normal' className={css.removeIcon} />
                                </div>
                              </div>
                            );
                          })}
                        </div> : null
                    }
                  </div>
                ) : null}

              <div className={css.lightshiftPriceWrapper}>
                <h4>{lightshift}</h4>
                <ul className={css.ServiceInformation}>
                  <li className={css.ServiceInformationListElement}><FormattedMessage id="OfferForm.bulletpoint1" /></li>
                  <li className={css.ServiceInformationListElement}><FormattedMessage id="OfferForm.bulletpoint2" /></li>
                  <li className={css.ServiceInformationListElement}><FormattedMessage id="OfferForm.bulletpoint3" /></li>
                </ul>
                <LeadBreakdown
                currentUser={currentUser}
                fee={LIGHTSHIFT_FEE}
                intl={intl}
                />
              </div>
              <div className={css.agb}>
                <FieldCheckboxBool
                  className={css.agb}
                  id={formId ? `${formId}.agbCheckbox` : 'agbCheckbox'}
                  name="agbCheckbox"
                  label={<FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptTextLead"
                  values={{ termsLink, dseLink, leadLink }}/>}
                  validate = {agbCheckedRequired}
                />
              </div>
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit" disabled={submitDisabled} inProgress={sendProposalInProgress}>
                  <FormattedMessage id="OfferForm.buy" />
                </PrimaryButton>
              </div>
              <GenericMessage show={sendOfferRequestMessage} message={sendOfferRequestMessage} />
            </Form>
          );
        }}
      />
    );
  }
}

OfferFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
};

OfferFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,
  price: propTypes.money,
  isOwnListing: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const OfferForm = compose(injectIntl)(OfferFormComponent);
OfferForm.displayName = 'OfferForm';

export default OfferForm;
