import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form } from '../../components';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import css from './EditListingCategoriesForm.module.css';

const required = value => {
  // Intl Message checkbox required Message
  const errors = {}
  if (value.dialux.length===0
    && value.lightingDesign.length===0
    && value.documentation.length===0
    && value.photoWork.length===0
    && value.others.length===0
    )
    {
      errors.emptyForm = 'EditListingCategoriesForm.checkboxRequired'
    }
  return errors
}

const EditListingCategoriesFormComponent = props => (

  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    validate={required}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        intl,
        touched,
        values,
        accordionID,
      } = formRenderProps;

      const isAnyTouched = Object.values(touched).includes(true);
      const messageEmptyForm = (formRenderProps.errors.emptyForm && isAnyTouched) ? intl.formatMessage({id: formRenderProps.errors.emptyForm}): <span>&nbsp;</span>;


      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress || formRenderProps.invalid;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCategoriesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCategoriesForm.showListingFailed" />
        </p>
      ) : null;

      const dialuxOptions = findOptionsForSelectFilter('dialux', filterConfig);
      const lightingDesignOptions = findOptionsForSelectFilter('lightingDesign', filterConfig);
      const photoWorkOptions = findOptionsForSelectFilter('photoWork', filterConfig);
      const documentationOptions = findOptionsForSelectFilter('documentation', filterConfig);
      const othersOptions = findOptionsForSelectFilter('others', filterConfig);

      const dialux = values.dialux;
      let countDialux = ' ';
      let countClassDialux = css.countUnVisible
      if(dialux.length > 0){
        countDialux = dialux.length;
        countClassDialux = css.countVisible
      }
      else{
          null
      }

      const lightingDesign = values.lightingDesign;
      let countLightingDesign = ' ';
      let countClassLightingDesign = css.countUnVisible
      if(lightingDesign.length > 0){
        countLightingDesign = lightingDesign.length;
        countClassLightingDesign = css.countVisible
      }
      else{
          null
      }

      const documentation = values.documentation;
      let countDocumentation = ' ';
      let countClassDocumentation = css.countUnVisible
      if(documentation.length > 0){
        countDocumentation = documentation.length;
        countClassDocumentation = css.countVisible
      }
      else{
          null
      }

      const photoWork = values.photoWork;
      let countPhotoWork = ' ';
      let countClassPhotoWork = css.countUnVisible
      if(photoWork.length > 0){
        countPhotoWork = photoWork.length;
        countClassPhotoWork = css.countVisible
      }
      else{
          null
      }

      const others = values.others;
      let countOthers = ' ';
      let countClassOthers = css.countUnVisible
      if(others.length > 0){
        countOthers = others.length;
        countClassOthers = css.countVisible
      }
      else{
          null
      }

      // Intl Message DIALux checkbox
      const dialuxLabel = intl.formatMessage({
        id: 'EditListingCategoriesForm.dialuxLabel',
      });

      // Intl Message photo work checkbox
      const photoWorkLabel = intl.formatMessage({
        id: 'EditListingCategoriesForm.photoWorkLabel',
      });

      // Intl Message artificial lighting checkbox
      const lightingDesignLabel = intl.formatMessage({
        id: 'EditListingCategoriesForm.lightingDesignLabel',
      });

      // Intl Message expert report checkbox
      const documentationLabel = intl.formatMessage({
        id: 'EditListingCategoriesForm.documentationLabel',
      });

      // Intl Message emergency light checkbox
      const othersLabel = intl.formatMessage({
        id: 'EditListingCategoriesForm.othersLabel',
      });

      //Accordion Headlines
      const headlineDIALux = intl.formatMessage({
        id: 'ProfileSkillsForm.dialuxLabel',
      });
      const headlinePictures = intl.formatMessage({
        id: 'ProfileSkillsForm.picturesLabel',
      });
      const headlineOther = intl.formatMessage({
        id: 'ProfileSkillsForm.otherLabel',
      });
      const headlineLightingDesign = intl.formatMessage({
        id: 'ProfileSkillsForm.lightingDesignLabel',
      });
      const headlineDocumentation = intl.formatMessage({
        id: 'ProfileSkillsForm.documentationLabel',
      });
      const languageLabel = intl.formatMessage({
        id: 'EditListingLanguageForm.languageLabel',
      });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <p className={css.tooltip}>{languageLabel}</p>
          <Accordion
          className = {css.accordion}
          allowZeroExpanded= {false}
          preExpanded= {accordionID}
          >
          <AccordionItem
            className = {css.accordion__item}
            uuid='1'
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={css.accordion__button}
              >
                <div className={countClassDialux}>
                <span>{countDialux}</span>
                </div>
                <div className={css.headline}>
                {headlineDIALux}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel
              className={css.accordion__panel}
            >
              <FieldCheckboxGroup
                setFocus={true}
                setHighlight={true}
                setAccordion={true}
                sortByChecked={false}
                className={css.checkbox}
                id="dialux"
                name='dialux'
                options={dialuxOptions}
                label=''
              />
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className = {css.accordion__item}
            uuid='2'
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={css.accordion__button}
              >
              <div className={countClassLightingDesign}>
                <span>{countLightingDesign}</span>
              </div>
              <div className={css.headline}>
              {headlineLightingDesign}
              </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel
              className={css.accordion__panel}
            >
            <FieldCheckboxGroup
                setFocus={false}
                setHighlight={true}
                setAccordion={true}
                sortByChecked={false}
                className={css.checkbox}
                id="lightingDesign"
                name='lightingDesign'
                options={lightingDesignOptions}
                label=''
              />
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className = {css.accordion__item}
            uuid='3'
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={css.accordion__button}
              >
              <div className={countClassDocumentation}>
                <span>{countDocumentation}</span>
              </div>
              <div className={css.headline}>
                {headlineDocumentation}
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel
            className={css.accordion__panel}
          >
              <FieldCheckboxGroup
                setFocus={false}
                setHighlight={true}
                setAccordion={true}
                sortByChecked={false}
                className={css.checkbox}
                id="documentation"
                name='documentation'
                options={documentationOptions}
                label=''
              />
          </AccordionItemPanel>
         </AccordionItem>
         <AccordionItem
          className = {css.accordion__item}
          uuid='4'
        >
          <AccordionItemHeading>
            <AccordionItemButton
              className={css.accordion__button}
            >
            <div className={countClassPhotoWork}>
              <span>{countPhotoWork}</span>
            </div>
            <div className={css.headline}>
              {headlinePictures}
            </div>
            </AccordionItemButton>
             </AccordionItemHeading>
            <AccordionItemPanel
          className={css.accordion__panel}
             >
           <FieldCheckboxGroup
                setFocus={false}
                setHighlight={true}
                setAccordion={true}
                sortByChecked={false}
                className={css.checkbox}
                id="photoWork"
                name='photoWork'
                options={photoWorkOptions}
                label=''
              />
        </AccordionItemPanel>
    </AccordionItem>
    <AccordionItem
        className = {css.accordion__item}
        uuid='5'
      >
        <AccordionItemHeading>
          <AccordionItemButton
            className={css.accordion__button}
          >
          <div className={countClassOthers}>
            <span>{countOthers}</span>
          </div>
          <div className={css.headline}>
            {headlineOther}
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel
        className={css.accordion__panel}
      >
        <FieldCheckboxGroup
          setFocus={false}
          setHighlight={true}
          setAccordion={true}
          sortByChecked={false}
          className={css.checkbox}
          id="others"
          name='others'
          options={othersOptions}
          label=''
      />
      </AccordionItemPanel>
    </AccordionItem>
  </Accordion>
    <div className={css.error}>
     {messageEmptyForm}
    </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingCategoriesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingCategoriesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,

  // from injectIntl
  intl: intlShape.isRequired,
};


const EditListingCategoriesForm = compose(injectIntl)(EditListingCategoriesFormComponent);


export default EditListingCategoriesForm;
