import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  searchFiles,
  getOfficeDetails,
  getUserListingId, getFullName,
} from '../../util/api';
import { deleteFilesFromCloudinaryError } from '../ProfileSkillsPage/ProfileSkillsPage.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_LISTING_REQUEST = 'app/ProfilePage/QUERY_LISTING_REQUEST';
export const QUERY_LISTING_SUCCESS = 'app/ProfilePage/QUERY_LISTING_SUCCESS';
export const QUERY_LISTING_ERROR = 'app/ProfilePage/QUERY_LISTING_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const FETCH_FILE_FROM_CLOUDINARY_REQUEST = 'app/ProfileBConsPage/FETCH_FILE_FROM_CLOUDINARY_REQUEST';
export const FETCH_FILE_FROM_CLOUDINARY_SUCCESS = 'app/ProfileBConsPage/FETCH_FILE_FROM_CLOUDINARY_SUCCESS';
export const FETCH_FILE_FROM_CLOUDINARY_ERROR = 'app/ProfileBConsPage/FETCH_FILE_FROM_CLOUDINARY_ERROR';

export const QUERY_OFFICE_ADDRESS_REQUEST = 'app/ProfilePage/QUERY_OFFICE_ADDRESS_REQUEST';
export const QUERY_OFFICE_ADDRESS_SUCCESS = 'app/ProfilePage/QUERY_OFFICE_ADDRESS_SUCCESS';
export const QUERY_OFFICE_ADDRESS_ERROR = 'app/ProfilePage/QUERY_OFFICE_ADDRESS_ERROR';

export const QUERY_USER_LISTING_ID_REQUEST = 'app/ProfilePage/QUERY_USER_LISTING_ID_REQUEST';
export const QUERY_USER_LISTING_ID_SUCCESS = 'app/ProfilePage/QUERY_USER_LISTING_ID_SUCCESS';
export const QUERY_USER_LISTING_ID_ERROR = 'app/ProfilePage/QUERY_USER_LISTING_ID_ERROR';

export const QUERY_FULL_NAME_REQUEST = 'app/ProfilePage/QUERY_FULL_NAME_REQUEST';
export const QUERY_FULL_NAME_SUCCESS = 'app/ProfilePage/QUERY_FULL_NAME_SUCCESS';
export const QUERY_FULL_NAME_ERROR = 'app/ProfilePage/QUERY_FULL_NAME_ERROR';
// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  userListingRef: null,
  queryListingError: null,
  fetchFilesFromCloudinaryInProgress: false,
  fetchFilesFromCloudinaryError: false,
  documents: {},
  officeAddress: {},
  officeAddressError: null,
  queryOfficeAddressInProgress: false,
  userListingId: '',
  userListingIdError: null,
  queryUserListingIdInProgress: false,
  nameObject: {},
  nameObjectError: null,
  queryFullNameInProgress: false
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };
    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };
    case QUERY_LISTING_REQUEST:
      return {
        ...state,
        queryListingError: null,
      };
    case QUERY_LISTING_SUCCESS:
      return { ...state, userListingRef: payload.listingRef };
    case QUERY_LISTING_ERROR:
      return { ...state, userListingRef: null, queryListingError: payload };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };
    case FETCH_FILE_FROM_CLOUDINARY_REQUEST:
      return {
        ...state,
        fetchFilesFromCloudinaryInProgress: true,
        fetchFilesFromCloudinaryError: null
      };
    case FETCH_FILE_FROM_CLOUDINARY_SUCCESS:
      return {
        ...state,
        fetchFilesFromCloudinaryInProgress: false,
        fetchFilesFromCloudinaryError: null,
        documents: payload
      };
    case FETCH_FILE_FROM_CLOUDINARY_ERROR:
      return {
        ...state,
        fetchFilesFromCloudinaryInProgress: false,
        fetchFilesFromCloudinaryError: payload
      };
    case QUERY_OFFICE_ADDRESS_REQUEST:
      return { ...state,queryOfficeAddressInProgress: true, officeAddressError: null };
    case QUERY_OFFICE_ADDRESS_SUCCESS:
      return {...state, queryOfficeAddressInProgress: false, officeAddressError: null, officeAddress: payload};
    case QUERY_OFFICE_ADDRESS_ERROR:
      return { ...state, queryOfficeAddressInProgress: false, officeAddressError: payload };
    case QUERY_USER_LISTING_ID_REQUEST:
      return { ...state,queryUserListingIdInProgress: true, userListingIdError: null };
    case QUERY_USER_LISTING_ID_SUCCESS:
      return {...state, queryUserListingIdInProgress: false, userListingIdError: null, userListingId: payload};
    case QUERY_USER_LISTING_ID_ERROR:
      return { ...state, queryUserListingIdInProgress: false, userListingIdError: payload };
    case QUERY_FULL_NAME_REQUEST:
      return { ...state,queryFullNameInProgress: true, nameObjectError: null };
    case QUERY_FULL_NAME_SUCCESS:
      return {...state, queryFullNameInProgress: false, nameObjectError: null, nameObject: payload};
    case QUERY_FULL_NAME_ERROR:
      return { ...state, queryFullNameInProgress: false, nameObjectError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryListingRequest = () => ({
  type: QUERY_LISTING_REQUEST,
});

export const queryListingSuccess = listingRef => ({
  type: QUERY_LISTING_SUCCESS,
  payload: { listingRef },
});

export const queryListingError = e => ({
  type: QUERY_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const fetchFilesFromCloudinary = () => ({
  type: FETCH_FILE_FROM_CLOUDINARY_REQUEST,
})

export const fetchFilesFromCloudinarySuccess = data => ({
  type: FETCH_FILE_FROM_CLOUDINARY_SUCCESS,
  payload: data
});

export const fetchFilesFromCloudinaryError = (e) => ({
  type: FETCH_FILE_FROM_CLOUDINARY_ERROR,
  error: true,
  payload: e
});

export const queryOfficeAddressRequest = userId => ({
  type: QUERY_OFFICE_ADDRESS_REQUEST,
  payload: { userId },
});

export const queryOfficeAddressSuccess = data => ({
  type: QUERY_OFFICE_ADDRESS_SUCCESS,
  payload: data,
});

export const queryOfficeAddressError = e => ({
  type: QUERY_OFFICE_ADDRESS_ERROR,
  error: true,
  payload: e,
});

export const queryUserListingIdRequest = userId => ({
  type: QUERY_USER_LISTING_ID_REQUEST,
  payload: { userId },
});
export const queryUserListingIdSuccess = data => ({
  type: QUERY_USER_LISTING_ID_SUCCESS,
  payload: data,
});

export const queryUserListingIdError = e => ({
  type: QUERY_USER_LISTING_ID_ERROR,
  error: true,
  payload: e,
});

export const queryFullNameRequest = user => ({
  type: QUERY_FULL_NAME_REQUEST,
  payload: { user },
});

export const queryFullNameSuccess = data => ({
  type: QUERY_FULL_NAME_SUCCESS,
  payload: data,
});

export const queryFullNameError = e => ({
  type: QUERY_FULL_NAME_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryUserListings = userId => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));
  return sdk.listings
    .query({
      author_id: userId,
      pub_listingCategory: 'job',
      pub_jobTypeGroup: 'public',
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(listingRefs));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

// Fetch user expert listing
export const queryUserListing = userId => (dispatch, getState, sdk) => {
  dispatch(queryListingRequest());
  return sdk.listings
    .query({
      author_id: userId,
      pub_listingCategory: 'expert',
      pub_userID: userId,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRef = response.data.data.map(({ id, type }) => ({ id, type }))[0];
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingSuccess(listingRef));
      return response;
    })
    .catch(e => dispatch(queryListingError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const queryOfficeDetails = (userId) => (dispatch, getState, sdk) => {
  dispatch(queryOfficeAddressRequest());

  const handleSuccess = response => {
    dispatch(queryOfficeAddressSuccess(response.data));
    return response
  };

  const handleError = e => {
    dispatch(queryOfficeAddressError(e.error));
  };

  return getOfficeDetails({userId: userId.uuid})
    .then(handleSuccess)
    .catch(handleError);
};

export const queryUserFullName = (user) => (dispatch, getState, sdk) => {
  dispatch(queryFullNameRequest(user));

  const handleSuccess = response => {
    dispatch(queryFullNameSuccess(response.data));
    return response
  };

  const handleError = e => {
    dispatch(queryFullNameError(e.error));
  };

  return getFullName({userId: user.id})
    .then(handleSuccess)
    .catch(handleError);
};

export const queryUserListingId = (userId) => (dispatch, getState, sdk) => {
  dispatch(queryUserListingIdRequest());

  const handleSuccess = response => {
    dispatch(queryUserListingIdSuccess(response.data));
    return response
  };

  const handleError = e => {
    dispatch(queryUserListingIdError(e.error));
  };

  return getUserListingId({userId: userId.uuid})
    .then(handleSuccess)
    .catch(handleError);
};

export const loadData = params => (dispatch, getState, sdk) => {
  const userId = new UUID(params.id);
  const user = params;
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return new Promise((resolve, reject) => {
    dispatch(fetchCurrentUser())
    dispatch(showUser(userId))
    dispatch(queryUserListing(userId))
    dispatch(queryUserListings(userId))
    dispatch(queryUserReviews(userId))
    dispatch(getFreelancerDocuments(userId))
    dispatch(queryUserFullName(user));
    resolve()
  }).then(()=>{
    dispatch(queryOfficeDetails(userId));
  }).then(()=>{
    dispatch(queryUserListingId(userId));
  }).then(()=>{
    dispatch(getFreelancerDocuments(userId));
  });
};

export const getFreelancerDocuments = (userId) => (dispatch, getState, sdk) => {
  dispatch(fetchFilesFromCloudinary());
  searchFiles({userId: userId.uuid})
  .then(response => {
    dispatch(fetchFilesFromCloudinarySuccess(response.data))
  })
  .catch(error => {
    console.debug('error delete file');
    dispatch(deleteFilesFromCloudinaryError(error));
  });
}
