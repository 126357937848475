import classNames from 'classnames';
import moment from 'moment';
import {
  bool,
  func,
  shape,
  string,
} from 'prop-types';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import {
  Button,
  FieldDateInput,
  FieldRadioButton,
  Form,
} from '../../components';
import { useTranslation } from '../../hooks';
import {
  FormattedMessage,
  injectIntl,
  intlShape,
} from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';

import css from './EditListingTenderForm.module.css';

const identity = v => v;
const dateFormatOptions = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
};
const EditListingTenderFormComponent = props => {
  const setTranslation = useTranslation('EditListingTenderForm');
  const jobTypeLabel = setTranslation('jobTypeLabel');

  return <FinalForm
    {...props} render={formRenderProps => {
    const {
      form,
      formId,
      className,
      disabled,
      ready,
      handleSubmit,
      intl,
      invalid,
      pristine,
      saveActionMsg,
      updated,
      updateInProgress,
      fetchErrors,
      values,
    } = formRenderProps;

    const privateLabel = setTranslation('privateLabel');
    const publicLabel = setTranslation('publicLabel');
    const offerDeadlineLabel = setTranslation('offerDeadlineLabel');

    const offerDeadlineRequiredMessage = setTranslation('offerDeadlineRequiredMessage');

    const showAsRequired = pristine;
    const { updateListingError, showListingsError } = fetchErrors || {};
    const errorMessageUpdateListing = updateListingError &&
      <p className={css.error}>
        <FormattedMessage id='EditListingTenderForm.updateFailed'/>
      </p>;

    const errorMessageShowListing = showListingsError &&
      <p className={css.error}>
        <FormattedMessage id='EditListingTenderForm.showListingFailed'/>
      </p>;

    const classes = classNames(css.root, className);
    const submitReady = (updated && pristine) || ready;
    const submitInProgress = updateInProgress;
    const submitDisabled = invalid || disabled || submitInProgress;

    const today = moment().startOf('day').toDate();
    const offerDeadlinePlaceholder = intl.formatDate(today, dateFormatOptions);

    return (
      <Form className={classes} onSubmit={handleSubmit}>
        {errorMessageUpdateListing}
        {errorMessageShowListing}

        <div className={css.offerDeadlineWrapper}>
          <h2>{offerDeadlineLabel}</h2>

          <FieldDateInput
            initialDate={formRenderProps?.values?.listingEndDate?.date || today}
            className={css.bookingDates}
            name='listingEndDate'
            id={`tenderForm.listingEndDate`}
            placeholderText={offerDeadlinePlaceholder}
            format={identity}
            useMobileMargins
            validate={required(offerDeadlineRequiredMessage)}
            disabled={false}
          />
        </div>
        <div>
          <h2>{jobTypeLabel}</h2>
          <div className={css.twoColumns}>
            <FieldRadioButton
              className={css.public}
              id='public'
              name='jobTypeGroup'
              label={publicLabel}
              value='public'
              showAsRequired={showAsRequired}
            />
            <FieldRadioButton
              className={css.private}
              id='private'
              name='jobTypeGroup'
              label={privateLabel}
              value='private'
              showAsRequired={showAsRequired}
            />
          </div>
        </div>
        <Button
          className={css.submitButton}
          type='submit'
          inProgress={submitInProgress}
          disabled={submitDisabled}
          ready={submitReady}
        >
          {saveActionMsg}
        </Button>
      </Form>
    );
  }}
  />;
};

EditListingTenderFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingTenderFormComponent.propTypes = {
  className: propTypes.string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingTenderFormComponent);
