import {
  FieldCheckboxGroup,
  NamedLink,
} from '../../components';
import config from '../../config';
import {
  useStripeCountryDetection,
  useTranslation,
} from '../../hooks';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import css from './ProfileSkillsForm.module.css';

export const SectionPaymentMethods = () => {
  const setTranslation = useTranslation('ProfileSkillsForm');
  const [isStripeConnected] = useStripeCountryDetection();

  const paymentKey = 'payment';
  const paymentOptions = findOptionsForSelectFilter(paymentKey, config.profileConfig.filters)
  .filter(x => !isStripeConnected && x.key === 'lightshift' ? null : x);

  const availabilityLink =
    <NamedLink name='AvailabilityPage' className={css.link}>
      {setTranslation('AboutPage.availabilityLinkLabel')}
    </NamedLink>;

  return (
    <>
      <div className={css.headline}>
        <FormattedMessage id='ProfileSettingsForm.yourPaymentOptions'/>
      </div>


      <div className={css.tip}>
        {isStripeConnected
          ? <FormattedMessage id='ProfileSkillsForm.informationText'/>
          : <FormattedMessage
            id='ProfileSkillsForm.informationTextExpertSettingsNotAvailable'
            values={{ availabilityLink }}
          />
        }
      </div>

      <FieldCheckboxGroup
        setFocus={true}
        twoColumns={false}
        className={css.payment}
        name={paymentKey}
        id={'payment'}
        options={paymentOptions}
      />
    </>
  );
};
