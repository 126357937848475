import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCircleFour.module.css';

const IconCircleFour = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.5 0C7.8 0 0 7.8 0 17.5S7.8 35 17.5 35 35 27.2 35 17.5 27.2 0 17.5 0zm0 32C9.5 32 3 25.5 3 17.5S9.5 3 17.5 3 32 9.5 32 17.5 25.5 32 17.5 32zm2.8-22.3h-2.7l-6.7 10.6.1 1.6h6.8v3.5h2.6v-3.5h1.9v-2.1h-1.9V9.7zm-2.6 10h-4.1l3.9-6.2.2-.3v6.5z"/>
    </svg>
  );
};

IconCircleFour.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCircleFour.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCircleFour;
