import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconJobNonPublic.module.css';

const IconJobNonPublic = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg enableBackground="new 0 0 36 36" version="1.1" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
      <path d="m5.4 24.9 1.4-1.4c-1.8-1.5-3.4-3.4-4.6-5.5 3.1-5.7 9.2-9.4 15.8-9.4 1.2 0 2.3 0.1 3.4 0.3l1.7-1.7c-1.6-0.4-3.3-0.7-5.1-0.7-8 0.1-14.8 4.8-18 11.5 1.3 2.7 3.2 5 5.4 6.9z"/>
      <path d="m18.8 22.8-2 2c0.4 0.1 0.7 0.1 1.1 0.1 3.8 0 6.9-3.1 6.9-6.9 0-0.4 0-0.8-0.1-1.1l-2 2c-0.2 2-1.8 3.6-3.9 3.9z"/>
      <path d="m17.2 13.2 2-2c-0.4-0.1-0.7-0.1-1.1-0.1-3.8 0-6.9 3.1-6.9 6.9 0 0.4 0 0.8 0.1 1.1l2-2c0.2-2 1.8-3.6 3.9-3.9z"/>
      <path d="m30.6 11.1-1.4 1.4c1.9 1.5 3.4 3.3 4.6 5.5-3.1 5.7-9.2 9.4-15.8 9.4-1.2 0-2.3-0.1-3.4-0.3l-1.7 1.7c1.6 0.4 3.3 0.7 5.1 0.7 8 0 14.8-4.7 18-11.4-1.3-2.8-3.2-5.1-5.4-7z"/>
      <polygon points="36 0 34.6 0 0 34.6 0 36 1.4 36 36 1.4"/>
    </svg>
  );
};

IconJobNonPublic.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconJobNonPublic.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconJobNonPublic;
