import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReviewStarMedium.module.css';

const IconReviewStarMedium = props => {
  const { className, rootClassName, isFilled } = props;
  const filledOrDefault = isFilled ? css.filled : css.root;
  const classes = classNames(rootClassName || filledOrDefault, className);

  return (
    <svg
      className={classes}
      width="23"
      height="23"
      viewBox="0 0 23 23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m22.937 8.0072c-0.15-0.41195-0.54398-0.68992-0.98496-0.68992h-7.5729l-1.873-6.5594c-0.13-0.44794-0.53998-0.75792-1.007-0.75792-0.46699 0-0.87997 0.30996-1.006 0.75991l-1.8759 6.5563h-7.5717c-0.44198 0-0.83298 0.27797-0.98297 0.68992-0.15 0.41396-0.024999 0.87591 0.31399 1.1599l5.6999 4.7495-2.8769 7.6722c-0.16 0.42994-0.020001 0.91589 0.34599 1.1959 0.362 0.27996 0.86997 0.28997 1.242 0.02001l6.7098-4.7895 6.7129 4.7895c0.37499 0.26998 0.87997 0.25998 1.245-0.02001 0.36599-0.27997 0.50398-0.76492 0.34299-1.1959l-2.875-7.6691 5.6999-4.7495c0.33999-0.28397 0.46299-0.74592 0.31498-1.1599" fillRule="evenodd"/>
    </svg>
  );
};

IconReviewStarMedium.defaultProps = { className: null, rootClassName: null, isFilled: false };

const { bool, string } = PropTypes;

IconReviewStarMedium.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool,
};

export default IconReviewStarMedium;
