import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';
import { Form, Button, FieldCheckboxGroup, FieldCurrencyInput, FieldCheckboxBool, FieldTextInput } from '../../components';

import css from './ProfileBConsForm.module.css';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';


class ProfileBconsFormComponent extends Component {
  constructor(props) {
    super(props);

   // this.uploadDelayTimeoutId = null;
    //this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(nextProps.saveBconsError !== this.props.saveBconsError) return true;
    //if(nextProps.ready !== this.props.ready) return true;
    return false;
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
          const {
            className,
            formId,
            handleSubmit,
            intl,
            invalid,
            pristine,
            rootClassName,
            updateInProgress,
            updateProfileError,
            values,
            filterConfig
          } = fieldRenderProps;



          //Set Expert
          const selectExpertLabel = intl.formatMessage({
            id: 'ProfileBConsForm.selectExpertLabel',
          });

          const taxIdLabel = intl.formatMessage({
            id: 'ProfileBConsForm.taxIdLabel',
          });

          const taxIdPlaceholder = intl.formatMessage({
            id: 'ProfileBConsForm.taxIdPlaceholder',
          });

          //Set hourly Rate
          const hourlyRateMessage = intl.formatMessage({
          id: 'ProfileBConsForm.hourlyRateLabel',
          });

          const hourlyRatePlaceholderMessage =intl.formatMessage({
            id: 'ProfileBConsForm.hourlyRateLabel',
          });

          const hourlyRateRequiredMessage =intl.formatMessage({
            id: 'ProfileBConsForm.hourlyRateError',
          });

          const taxIdRequiredMessage =intl.formatMessage({
            id: 'ProfileBConsForm.taxIdRequiredMessage',
          });

          const hourlyRateRequired = validators.required(hourlyRateRequiredMessage);
          const taxIdRequired = validators.required(taxIdRequiredMessage);

          //Set Expert

          const selectServiceKey = 'setService'
          const selectServiceOptions = findOptionsForSelectFilter(selectServiceKey, filterConfig)
          let serviceField = null;
          let taxId = null;

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileReferencesForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || submitInProgress;

          if(values.expertOption){
            serviceField =
            <FieldCheckboxGroup
              setFocus={true}
              className={css.serviceField}
              id="setService"
              name="setService"
              options={selectServiceOptions}

            />;
            taxId =
              <FieldTextInput
              className={css.taxId}
              type="taxId"
              id={formId ? `${formId}.taxId` : 'taxId'}
              name="taxId"
              label={taxIdLabel}
              placeholder={taxIdPlaceholder}
              validate={taxIdRequired}
            />
          }
          else {
            serviceField = null;
            taxId = null;
          }

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
              >
              <div className={css.sectionContainer}>
                <FieldCurrencyInput
                  id="hourlyRate"
                  name="hourlyRate"
                  className={css.sectionContainer}
                  label={hourlyRateMessage}
                  placeholder={hourlyRatePlaceholderMessage}
                  currencyConfig={config.currencyConfig}
                  validate={hourlyRateRequired}
                />
              </div>
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileBConsForm.ExpertTitle" />
                </h3>
                <FieldCheckboxBool
                  className={css.expertOption}
                  name="expertOption"
                  id={formId ? `${formId}.expertOption` : 'expertOption'}
                  label={selectExpertLabel}
                />
                <div>
                  {serviceField}
                  {taxId}
                </div>
              </div>
              {submitError}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                <FormattedMessage id="ProfileSettingsForm.saveChanges" />
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileBconsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  filterConfig: config.profileConfig.filters,
};

ProfileBconsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  //uploadInProgress: bool.isRequired,
  //updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
  filterConfig: propTypes.filterConfig,
};

const ProfileBConsForm = compose(injectIntl)(ProfileBconsFormComponent);

ProfileBConsForm.displayName = 'ProfileBusinessConditionsForm';

export default ProfileBConsForm;
