import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { ensureListing, ensureUser } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { NamedLink, ExpertAvatarLarge } from '..';
import {AvatarSmall, InlineTextButton} from '../../components';
import QaDiv from './QaDiv';
import _ from 'lodash';


import css from './QA_Card.module.css';
import { connect } from 'react-redux';
/*
      <div className={css.skills} >
        <CarouselComponent skills={skills}/>
      </div>
*/
export const QA_CardComponent = props => {
  const { className, rootClassName, intl, q, currentUser, onAnswer, user1, user2} = props;
  const {question, answer, created_at: qDate, answer_datetime: aDate} = q;
  const isAuthor = currentUser && currentUser.id.uuid === q.author_id;
  const isUser = currentUser && currentUser.id.uuid === q.user_id;
  const classes = classNames(rootClassName || css.root, className);

  const questionLabel = <FormattedMessage id="QA_Card.questionLabel" />
  const answerLabel = <FormattedMessage id="QA_Card.answerLabel" />

  const format = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const dateQuestion = intl.formatDate(qDate, format);
  const dateAnswer = intl.formatDate(aDate, format);

  const userName = user1 && user1.attributes && user1.attributes.profile.displayName
  const authorName = user2 && user2.attributes && user2.attributes.profile.displayName

  const answerButton = (
    <InlineTextButton
      rootClassName={css.answerButton}
      onClick={() => onAnswer(q)}
    >
      <FormattedMessage id="QaCard.answer" />
    </InlineTextButton>
  );

   return (
    <div className={classes}>
      <div className={css.container}>
        <div className={css.label}>{questionLabel}</div>
        <div className={css.avatar}><AvatarSmall user={isUser ? currentUser : user1}/></div>
        <QaDiv
          className={css.content}
          qa={question}
        />
        <div className={css.name}>{userName}<span className={css.separator}>•</span>{dateQuestion}</div>
      </div>
      {
        answer ?
        <div className={css.container}>
          <div className={css.label}>{answerLabel}</div>
          <div className={css.avatar}></div>
          <QaDiv
            className={css.content}
            qa={answer}
          />
          <div className={css.name}>{dateAnswer}</div>

        </div> : null
      }
      {
        isAuthor && !answer ? answerButton : null
      }
    <hr className={css.hr}/>
  </div>
  );
};

QA_CardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
};

QA_CardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,

  renderSizes: string,
};

export default injectIntl(QA_CardComponent);
