import React from 'react';
import * as validators from '../../util/validators';
import { Form as FinalForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import {
  Button,
  FieldSelect,
  FieldTextInput,
  Form,
  PrimaryButton,
  SecondaryButton,
} from '../../components';

import css from './WorkingExperienceForm.module.css';

export function WorkingExperienceForm(props) {

  const requiredVal = validators.required('required');

  return <FinalForm {...props} render={({ handleSubmit, onCancelButtonClicked, values, invalid }) => {

    const intl = useIntl();
    const setTranslation = (key) =>
      intl.formatMessage({ id: `WorkingExperience.${key}` });

    const currentItem = values;
    const isNewItem = !currentItem.id;

    const modalHeadline = isNewItem
      ? setTranslation('addPosition')
      : setTranslation('editPosition');

    const cancelButtonText = isNewItem
      ? setTranslation('cancelButtonText')
      : setTranslation('deleteButtonText');

    const NUMBER_OF_YEARS_IN_PAST = 40;
    const currentYear = new Date().getFullYear();
    const startingYear = currentYear - NUMBER_OF_YEARS_IN_PAST + 1;
    const lastFewYears = Array.from({ length: NUMBER_OF_YEARS_IN_PAST }, (_, i) => i + startingYear)
                              .reverse();

    const months = [setTranslation('monthDefault')];
    for (let i = 1; i <= 12; i++) {
      months.push(setTranslation(`monthNo${i}`));
    }

    return <Form onSubmit={handleSubmit}>
      <h2>{modalHeadline}</h2>

      <FieldTextInput
        type='text'
        label={setTranslation('titleText')}
        name='jobTitle'
        id='jobTitle'
        placeholder={setTranslation('titlePlaceholder')}
        validate={requiredVal}
      />

      <FieldTextInput
        type='text'
        label={setTranslation('companyNameText')}
        name='companyName'
        id='companyName'
        placeholder={setTranslation('companyNamePlaceholder')}
        validate={requiredVal}
      />

      <div className={css.twoColumnGrid}>
        <FieldSelect label={setTranslation('startDateText')} name='startMonth' id='startMonth'>
          {months.map((month, idx) => (
            <option key={month} value={idx}>{month}</option>
          ))}
        </FieldSelect>
        <FieldSelect name='startYear' id='startYear' validate={requiredVal}>
          <option>{setTranslation('yearDefault')}</option>
          {lastFewYears.map(year => (
            <option key={year}>{year}</option>
          ))}
        </FieldSelect>
      </div>

      <div className={css.twoColumnGrid}>
        <FieldSelect label={setTranslation('endDateText')} name='endMonth' id='endMonth'>
          {months.map((month, idx) => (
            <option key={month} value={idx}>{month}</option>
          ))}
        </FieldSelect>
        <FieldSelect name='endYear' id='endYear'>
          <option>{setTranslation('yearDefault')}</option>
          {lastFewYears.map(year => (
            <option key={year}>{year}</option>
          ))}
        </FieldSelect>
      </div>
      <div className={css.twoColumnGrid}>
        <button className={css.cancelButton} onClick={() => onCancelButtonClicked(currentItem)}>
          {cancelButtonText}
        </button>
        <Button type='submit' disabled={invalid}>
          {setTranslation('saveButtonText')}
        </Button>
      </div>
    </Form>;
  }}
  />;
}

WorkingExperienceForm.defaultProps = {};

WorkingExperienceForm.propTypes = {};
