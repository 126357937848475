import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPrivateBadge.module.css';

const IconPrivateBadge = props => {
  const { rootClassName, className, freelancerBadgeClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="35" height="35" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
      <g
        className={freelancerBadgeClassName || css.badge}
        stroke="none"
        fill="ffffff"
        fillRule="evenodd"
      >
      <g id="UniversalIconsForgotPassword">
        <g id="ForgotPassword">
          <path d="M0 0L35 0L35 35L0 35L0 0Z" id="Background" fill="none" fill-rule="evenodd" stroke="none" />
            <g clip-path="url(#mask_1)">
              <g id="Icons" transform="translate(1.9444444 3.8577476)">
                <path d="M27.9533 3.18896C23.7533 -1.04216 16.9244 -1.06549 12.6933 3.13451C10.1267 5.68562 9.00667 9.36451 9.72222 12.919L0 22.6723L0.847778 30.2945L8.47 31.1423L8.47 27.7512L11.9156 27.8056L11.8611 24.3678L15.2833 24.4067L15.2833 20.9767L16.8389 20.9767C17.99 21.3812 19.1956 21.5834 20.4167 21.5834C26.3667 21.5445 31.1578 16.6834 31.1111 10.7334C31.0956 7.90229 29.96 5.18784 27.9533 3.18896L27.9533 3.18896ZM26.8333 17.3367C24.3211 19.8178 20.6111 20.6501 17.2822 19.4756L17.0333 19.3901L13.6733 19.3901L13.6733 20.9456L13.6733 22.7812L11.8378 22.7812L10.2356 22.7812L10.2356 24.3834L10.2356 26.219L8.39222 26.219L6.83667 26.219L6.83667 29.4545L2.17 28.9334L1.54 23.2867L10.8578 14.0312L11.4411 13.4401L11.2856 12.6623C10.2822 7.66895 13.5256 2.80784 18.5189 1.80451C23.5122 0.801178 28.3733 4.04451 29.3767 9.03784C29.9833 12.0712 29.0344 15.2056 26.8411 17.3834L26.8333 17.3367L26.8333 17.3367Z" id="Shape" fill="#000000" fill-rule="evenodd" stroke="none" />
                <path d="M0 2.39556C0 1.07253 1.07253 0 2.39556 0C3.71858 0 4.79111 1.07253 4.79111 2.39556C4.79111 3.71858 3.71858 4.79111 2.39556 4.79111C1.07253 4.79111 0 3.71858 0 2.39556Z" transform="translate(20.478888 5.8722887)" id="Circle" fill="#000000" fill-rule="evenodd" stroke="none" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconPrivateBadge.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconPrivateBadge.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconPrivateBadge;
