import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSpecialHardware.module.css';

const IconSpecialHardware = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg enableBackground="new 0 0 36 36" version="1.1" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
      <path d="m31 0c-2.8 0-5 2.2-5 5v29h-3v-34h-23v36h28v-27c0.8 0.6 1.9 1 3 1 2.8 0 5-2.2 5-5s-2.2-5-5-5zm-29 34v-32h19v32h-19zm29-26c-1.6 0-3-1.3-3-3 0-1.6 1.3-3 3-3s3 1.3 3 3c0 1.6-1.4 3-3 3zm-26 10h2v2h-2v-2zm11 0h2v2h-2v-2zm-5.5 0h2v2h-2v-2zm-5.5-3h13v-10h-13v10zm2-8h9v6h-9v-6z"/>
    </svg>
  );
};

IconSpecialHardware.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSpecialHardware.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSpecialHardware;
