import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import {
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  LISTING_STATE_PUBLISHED,
  propTypes,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureOwnListing } from '../../util/data';
import {
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { VALID_STATES_TO_CLOSE_LISTING } from '../../util/constants';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import config from '../../config';
import {
  InlineTextButton,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconSpinner,
  ResponsiveImage,
  PrimaryButton,
  IconFreelancerBadge,
  IconIndustryBadge,
  IconPrivateBadge
} from '../../components';
import moment from "moment-timezone";

import MenuIcon from './MenuIcon';
import Overlay from './Overlay';
import css from './ManageListingCard.module.css';

// Menu content needs the same padding
const MENU_CONTENT_OFFSET = -12;
const MAX_LENGTH_FOR_WORDS_IN_TITLE = 3;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const createListingURL = (routes, listing, lang) => {
  const id = listing.id.uuid;
  const slug = createSlug(listing.attributes.title);
  const isPendingApproval = listing.attributes.state === LISTING_STATE_PENDING_APPROVAL;
  const isDraft = listing.attributes.state === LISTING_STATE_DRAFT;

  const variant = isDraft
    ? LISTING_PAGE_DRAFT_VARIANT
    : isPendingApproval
    ? LISTING_PAGE_PENDING_APPROVAL_VARIANT
    : null;

  const linkProps =
    isPendingApproval || isDraft
      ? {
          name: 'ListingPageVariant',
          params: {
            id,
            slug,
            variant,
            lang
          },
        }
      : {
          name: 'ListingPage',
          params: { id, slug, lang },
        };

  return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};

const createEditListingURL = (routes, listing, lang) => {
  const isLead = listing && listing.attributes.publicData.listingCategory === "lead" ? true : false;
  const name = isLead ? "EditLeadPage" : "EditListingPage";
  const id = listing.id.uuid;
  const slug = createSlug(listing.attributes.title);

  const linkProps =
      {
        name: name,
        params: { id, slug, lang, type: LISTING_PAGE_PARAM_TYPE_EDIT, tab: "description" },
      }

  return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};

// Cards are not fixed sizes - So, long words in title make flexboxed items to grow too big.
// 1. We split title to an array of words and spaces.
//    "foo bar".split(/([^\s]+)/gi) => ["", "foo", " ", "bar", ""]
// 2. Then we break long words by adding a '<span>' with word-break: 'break-all';
const formatTitle = (title, maxLength) => {
  const nonWhiteSpaceSequence = /([^\s]+)/gi;
  return title.split(nonWhiteSpaceSequence).map((word, index) => {
    return word.length > maxLength ? (
      <span key={index} style={{ wordBreak: 'break-all' }}>
        {word}
      </span>
    ) : (
      word
    );
  });
};

export const ManageListingCardComponent = props => {
  const {
    className,
    rootClassName,
    hasClosingError,
    hasOpeningError,
    history,
    intl,
    isMenuOpen,
    actionsInProgressListingId,
    listing,
    onCloseListing,
    onDeleteListing,
    onDeleteDraft,
    onOpenListing,
    onToggleMenu,
    renderSizes,
    onOpenDeleteModal,
    availabilityEnabled,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const lang = intl.locale;

  const privateData = currentListing.attributes.privateData || '';
  const publicData = currentListing.attributes.publicData || '';
  const id = currentListing.id.uuid;
  const { title = '', price, state } = currentListing.attributes;
  const slug = createSlug(title);
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const offerDeadline = currentListing.attributes.publicData.listingEndDateUTC;
  const jobType = publicData.jobTypeGroup || '';
  const listingCategory = publicData.listingCategory || '';
  const isJob = listingCategory === 'job';
  const isLead = listingCategory === 'lead';
  const isPrivateJob = isJob && jobType === "public" ? false : true;
  const isDeactivated = currentListing.attributes.state === 'closed' ? true : false;


  const jobStatus = (jobType) =>{
    if (jobType === "public") {
      return {
        currentJobStatus: intl.formatMessage(
          { id: 'ManageListingCard.statusPublic' }
        ),
      };
    } else if (jobType === "private") {
      return {
        currentJobStatus: intl.formatMessage(
          { id: 'ManageListingCard.statusPrivate' },
        ),
      };
    }
    return {
      currentJobStatus: ''
    };
  };
  const {currentJobStatus} = jobStatus(jobType);

  //Timezone and format - listingEndDate
  const format = {
    month: 'short',
    day: 'numeric',
  };

  const LTZ = moment.tz.guess();
  const listingEndDateLTZ = moment.tz(offerDeadline, LTZ).format();

  const formattedApplicationDeadline = intl.formatDate(listingEndDateLTZ, format);

  const applicationDeadlineString = intl.formatMessage(
    { id: 'ManageListingCard.jobDeadline' },
    {
      dates: `${formattedApplicationDeadline}`,
    }
  )
  // End

  let isDeleted = false;
  if (privateData && privateData.deleted){
    isDeleted = true;
  }

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const menuItemClasses = classNames(css.menuItem, {
    [css.menuItemDisabled]: !!actionsInProgressListingId,
  });

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const hasError = hasOpeningError || hasClosingError;
  const thisListingInProgress =
    actionsInProgressListingId && actionsInProgressListingId.uuid === id;

  const onOverListingLink = () => {
    // Enforce preloading of ListingPage (loadable component)
    const { component: Page } = findRouteByRouteName('ListingPage', routeConfiguration());
    // Loadable Component has a "preload" function.
    if (Page.preload) {
      Page.preload();
    }
  };

  const titleClasses = classNames(css.title, {
    [css.titlePending]: isPendingApproval,
    [css.titleDraft]: isDraft,
  });

  const editListingLinkType = isDraft
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ManageListingCard.perNight'
    : isDaily
    ? 'ManageListingCard.perDay'
    : 'ManageListingCard.perUnit';

  const canClose = VALID_STATES_TO_CLOSE_LISTING.includes(state);

  return (
    <div className={classes}>
      <div
        className={css.threeToTwoWrapper}
        tabIndex={0}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();

          // ManageListingCard contains links, buttons and elements that are working with routing.
          // This card doesn't work if <a> or <button> is used to wrap events that are card 'clicks'.
          //
          // NOTE: It might be better to absolute-position those buttons over a card-links.
          // (So, that they have no parent-child relationship - like '<a>bla<a>blaa</a></a>')
          history.push(createListingURL(routeConfiguration(), listing, lang));
        }}
        onMouseOver={onOverListingLink}
        onTouchStart={onOverListingLink}
      >
        <div className={css.aspectWrapper}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
        <div className={classNames(css.menuOverlayWrapper, { [css.menuOverlayOpen]: isMenuOpen })}>
          <div className={classNames(css.menuOverlay)} />
          <div className={css.menuOverlayContent}>
            <FormattedMessage id="ManageListingCard.viewListing" />
          </div>
        </div>
        <div className={css.menubarWrapper}>
          {
            isJob ? <div className={css.batch}>
            <IconFreelancerBadge className={css.icon} freelancerBadgeClassName={css.IconFreelancerBadge} />
            </div> : isLead ? <div className={css.batch}>
              <IconIndustryBadge className={css.icon} freelancerBadgeClassName={css.IconFreelancerBadge} />
            </div> : null
          }

          <div className={css.menubarGradient} />
          <div className={css.menubar}>
            <Menu
              className={classNames(css.menu, { [css.cardIsOpen]: !isClosed })}
              contentPlacementOffset={MENU_CONTENT_OFFSET}
              contentPosition="left"
              useArrow={false}
              onToggleActive={isOpen => {
                const listingOpen = isOpen ? currentListing : null;
                onToggleMenu(listingOpen);
              }}
              isOpen={isMenuOpen}
            >
              <MenuLabel className={css.menuLabel} isOpenClassName={css.listingMenuIsOpen}>
                <div className={css.iconWrapper}>
                  <MenuIcon className={css.menuIcon} isActive={isMenuOpen} />
                </div>
              </MenuLabel>
              <MenuContent rootClassName={css.menuContent}>
                <MenuItem key="edit-listing">
                  <div className={css.editListing}>
                  <InlineTextButton
                    className={css.link}
                    onClick={event => {
                      event.preventDefault();
                      event.stopPropagation();
                      history.push(createEditListingURL(routeConfiguration(), listing, lang));}}
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="ManageListingCard.editListing" />
                  </InlineTextButton>
                  </div>
                </MenuItem>
                {
                  canClose ?
                  <MenuItem key="close-listing">
                    <InlineTextButton
                      className={css.link}
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (!actionsInProgressListingId) {
                          onToggleMenu(null);
                          onCloseListing(currentListing.id);
                        }
                      }}
                    >
                      <span className={css.menuItemBorder} />
                      <FormattedMessage id="ManageListingCard.closeListing" />
                    </InlineTextButton>
                  </MenuItem> : <MenuItem key="close-listing"></MenuItem>
                }
                <MenuItem key="delete-listing">
                  <InlineTextButton
                    className={css.link}
                    onClick={event => {
                      event.preventDefault();
                      event.stopPropagation();
                      if (!actionsInProgressListingId) {
                        onToggleMenu(null);
                        onOpenDeleteModal(currentListing.id);
                      }
                    }}
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="ManageListingCard.deleteListing" />
                  </InlineTextButton>
                </MenuItem>
              </MenuContent>
            </Menu>
          </div>
        </div>
        {isDraft ? (
          <React.Fragment>
            <div className={classNames({ [css.draftNoImage]: !firstImage })} />
            <Overlay
              message={intl.formatMessage(
                { id: 'ManageListingCard.draftOverlayText' },
                { listingTitle: title }
              )}
            >
              <NamedLink
                className={css.finishListingDraftLink}
                name={isLead ? "EditLeadPage" : "EditListingPage"}
                params={{ id, slug, type: LISTING_PAGE_PARAM_TYPE_DRAFT, tab: 'photos' }}
              >
                <FormattedMessage id="ManageListingCard.finishListingDraft" />
              </NamedLink>
              <button
              className={css.deleteDraftButton}
              disabled={!!actionsInProgressListingId}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                if (!actionsInProgressListingId) {
                  onDeleteDraft(currentListing.id);
                }
              }}
            >
              <FormattedMessage id="ManageListingCard.deleteDraft" />
            </button>
            </Overlay>
          </React.Fragment>
        ) : null}
        {isClosed &&! isDeleted ? (
          <Overlay
            message={intl.formatMessage(
              { id: 'ManageListingCard.closedListing' },
              { listingTitle: title }
            )}
          >
            <button
              className={css.openListingButton}
              disabled={!!actionsInProgressListingId}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                if (!actionsInProgressListingId) {
                  onOpenListing(currentListing.id);
                }
              }}
            >
              <FormattedMessage id="ManageListingCard.openListing" />
            </button>
            <button
            className={css.deleteDraftButton}
            disabled={!!actionsInProgressListingId}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              if (!actionsInProgressListingId) {
                onDeleteListing(currentListing.id);
              }
            }}
          >
            <FormattedMessage id="ManageListingCard.deleteListing" />
          </button>
          </Overlay>
        ) : null}
        {isDeleted ? (
          <Overlay
            message={intl.formatMessage(
              { id: 'ManageListingCard.deletedListing' },
              { listingTitle: title }
            )}
          >
          </Overlay>
        ) : null}
        {isPendingApproval ? (
          <Overlay
            message={intl.formatMessage(
              { id: 'ManageListingCard.pendingApproval' },
              { listingTitle: title }
            )}
          />
        ) : null}
        {thisListingInProgress ? (
          <Overlay>
            <IconSpinner />
          </Overlay>
        ) : hasError ? (
          <Overlay errorMessage={intl.formatMessage({ id: 'ManageListingCard.actionFailed' })} />
        ) : null}
      </div>
      <div
        className={css.infowrapper}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();

          // ManageListingCard contains links, buttons and elements that are working with routing.
          // This card doesn't work if <a> or <button> is used to wrap events that are card 'clicks'.
          //
          // NOTE: It might be better to absolute-position those buttons over a card-links.
          // (So, that they have no parent-child relationship - like '<a>bla<a>blaa</a></a>')
          history.push(createListingURL(routeConfiguration(), listing, lang));
        }}
        onMouseOver={onOverListingLink}
        onTouchStart={onOverListingLink}
      >
        {isDeactivated ? (
          <div className={css.headline}>
            <span className={css.deactivated}>
              <FormattedMessage id="ManageListingCard.deactivated" />
            </span>
            <span className={css.title}>
              {formatTitle(title, MAX_LENGTH_FOR_WORDS_IN_TITLE)}
            </span>
          </div>
          ) : (
            <div className={css.headline}>
              <span className={css.title}>
                {formatTitle(title, MAX_LENGTH_FOR_WORDS_IN_TITLE)}
              </span>
            </div>
          )
        }
        <div className={css.info}>
        <div className={css.status}>
          {currentJobStatus}
        </div>
        <div className={css.deadlines}>
          {applicationDeadlineString}
        </div>
      </div>
        {/*<div className={css.buttonLeft}>
          <PrimaryButton
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              if (!actionsInProgressListingId) {
                onToggleMenu(null);
                onOpenOfferModal(currentListing.id);
              }
            }}>
              Status
          </PrimaryButton>
        </div>

        <div className={css.buttonRight}>
          <PrimaryButton
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              if (!actionsInProgressListingId) {
                onToggleMenu(null);
                onOpenOfferModal(currentListing.id);
              }
            }}>
              X Angebote öffnen
          </PrimaryButton>
          </div>*/}

        {/*<div className={css.manageLinks}>
          <NamedLink
            className={css.manageLink}
            name="EditListingPage"
            params={{ id, slug, type: editListingLinkType, tab: 'description' }}
          >
            <FormattedMessage id="ManageListingCard.editListing" />
          </NamedLink>

          {availabilityEnabled ? (
            <React.Fragment>
              <span className={css.manageLinksSeparator}>{' • '}</span>

              <NamedLink
                className={css.manageLink}
                name="EditListingPage"
                params={{ id, slug, type: editListingLinkType, tab: 'availability' }}
              >
                <FormattedMessage id="ManageListingCard.manageAvailability" />
              </NamedLink>
            </React.Fragment>
          ) : null}
        </div>*/}
      </div>
    </div>
  );
};

ManageListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  actionsInProgressListingId: null,
  renderSizes: null,
  availabilityEnabled: config.enableAvailability,
};

const { bool, func, shape, string } = PropTypes;

ManageListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  hasClosingError: bool.isRequired,
  hasOpeningError: bool.isRequired,
  intl: intlShape.isRequired,
  listing: propTypes.ownListing.isRequired,
  isMenuOpen: bool.isRequired,
  actionsInProgressListingId: shape({ uuid: string.isRequired }),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  onToggleMenu: func.isRequired,
  availabilityEnabled: bool,
  onOpenDeleteModal: func.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(ManageListingCardComponent);
