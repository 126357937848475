import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '..';
import moment from "moment-timezone";
import { types as sdkTypes } from '../../util/sdkLoader';
import IconDoneJob from '../IconDoneJob/IconDoneJob';
import Overlay from '../Overlay/Overlay';
import css from './ExampleCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const { Money } = sdkTypes;
const LIGHTSHIFT_FEE = 5900;

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ExampleCardComponent = props => {
  const { className, rootClassName, intl, listing, renderSizes, setActiveListing } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '' } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
  currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const publicData = currentListing.attributes.publicData;
  const wasLead = publicData.listingCategory === 'lead';

  return (
    <div className={classes}>
      <NamedLink className={css.link} name="ExamplePage" params={{ id, slug }}>
        <div
          className={css.imageContainer}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          <Overlay
            icon={<IconDoneJob/>}
            message={intl.formatMessage(
              { id: 'ExampleCard.overlayText' }
            )}
          >
            <LazyImage
              imageContainerClassName={css.listingImage}
              alt={title}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
              sizes={renderSizes}
            />
          </Overlay>
        </div>
        <div className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </div>
      </NamedLink>
      <div className={css.twoColumns}>
        <NamedLink className={css.connectLink} name="ExamplePage" params={{ id, slug }}>
          <FormattedMessage id="ExampleCard.details"/>
        </NamedLink>
      </div>
    </div>
  );
};

ExampleCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ExampleCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ExampleCardComponent);
