import React, { useState } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { Subscription } from '../index';
import NamedLink from '../NamedLink/NamedLink';
import { MoneyBackModal } from '../Subscription/MoneyBackModal';
import css from './PriceList.module.css';
import { PriceListBenefitGroup } from './PriceListBenefitGroup';
import {
  IconFree,
  IconLimited,
  IconRiskFree,
  IconSecure,
} from './PriceListIcons';

export const PriceList = () => {
  const setTranslation = useTranslation('PriceList');
  const [isMoneyBackModalOpen, setIsMoneyBackModalOpen] = useState(false);

  const benefits = [
    {
      idx: 1,
      header: setTranslation('benefitsForClientTitle'),
      cards: [
        {
          idx: 1,
          picture: <IconFree/>,
          title: setTranslation('sectionFreeCaption'),
          content: setTranslation('sectionFreeContent'),
        }, {
          idx: 2,
          picture: <IconSecure/>,
          title: setTranslation('sectionSecureCaption'),
          content: setTranslation('sectionSecureContent'),
        }],
    }, {
      idx: 2,
      header: setTranslation('benefitsForFreelancerTitle'),
      cards: [
        {
          idx: 1,
          picture: <IconLimited/>,
          title: setTranslation('sectionLimitedFreeUseCaption'),
          content: setTranslation('sectionLimitedFreeUseContent'),
        }, {
          idx: 2,
          picture: <IconRiskFree/>,
          title: setTranslation('sectionRiskFreeCaption'),
          content: setTranslation('sectionRiskFreeContent', {
            moneyBackGuarantee: <a onClick={()=>setIsMoneyBackModalOpen(true)}>{setTranslation('SubscriptionPage.moneyBackGuaranteeLink')}</a>
          }),
        }],
    },
  ];

  return (
    <div>
      <p>{setTranslation('lastUpdated')}</p>

      {benefits.map(x =>
        <PriceListBenefitGroup key={x.idx} benefits={x}/>,
      )}

      <div className={css.countryNotice}>
        <span>{setTranslation('paymentAvailableOnlyInTheseCountries')}</span>
        <NamedLink name='AvailabilityPage'>
          {setTranslation('countryList')}
        </NamedLink>
      </div>

      <p className={css.transactionCost}>{setTranslation('headline')}</p>
      <ul className={css.list}>
        <li>{setTranslation('TransactionCostsExperts_1')}</li>
        <li>{setTranslation('TransactionCostsExperts_2')}</li>
      </ul>

      <Subscription showListingErrorMessage={false}  />

      {isMoneyBackModalOpen && (
        <MoneyBackModal
          isOpen={isMoneyBackModalOpen}
          onClose={() => setIsMoneyBackModalOpen(false)}
        />
      )}
    </div>
  );
};
