import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { findOptionsForSelectFilter } from '../../util/search';
import { supportedVatCountry } from '../../util/validators';
import config from '../../config';
import {updateStripeCustomer} from '../../util/api';


const { Money } = sdkTypes;

const requestAction = (actionType) => (params) => ({ type: actionType, payload: { params } });

const successAction = (actionType) => (result) => ({ type: actionType, payload: result.data });

const errorAction = (actionType) => (error) => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/ProfileSettingsPage/CLEAR_UPDATED_FORM';

export const UPLOAD_IMAGE_REQUEST = 'app/ProfileSettingsPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/ProfileSettingsPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'app/ProfileSettingsPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/ProfileSettingsPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/ProfileSettingsPage/UPDATE_PROFILE_ERROR';

export const SAVE_PROFILE_SKILLS_REQUEST = 'app/ProfileSettingsPage/SAVE_PROFILE_SKILLS_REQUEST';
export const SAVE_PROFILE_SKILLS_SUCCESS = 'app/ProfileSettingsPage/SAVE_PROFILE_SKILLS_SUCCESS';
export const SAVE_PROFILE_SKILLS_ERROR = 'app/ProfileSettingsPage/SAVE_PROFILE_SKILLS_ERROR';

export const SAVE_LANGUAGE_ERROR = 'app/ProfileSettingsPage/SAVE_LANGUAGE_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/ProfileSettingsPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/ProfileSettingsPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/ProfileSettingsPage/UPDATE_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  image: null,
  uploadImageError: null,
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
  saveProfileSkillsInProgress: false,
  profileSkillsChanged: false,
  updateListingError: null,
  updateListingInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_PROFILE_SKILLS_REQUEST:
      return {
        ...state,
        saveProfileSkillsInProgress: true,
        saveProfileSkillsError: null,
        profileSkillsChanged: false,
      };
    case SAVE_PROFILE_SKILLS_SUCCESS:
      return { ...state, saveProfileSkillsInProgress: false, profileSkillsChanged: true };
    case SAVE_PROFILE_SKILLS_ERROR:
      return { ...state, saveProfileSkillsInProgress: false, saveProfileSkillsError: payload };

    case UPLOAD_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state,
        image: { ...payload.params },
        uploadInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedImage }
      const { id, uploadedImage } = payload;
      const { file } = state.image || {};
      const image = { id, imageId: uploadedImage.id, file, uploadedImage };
      return { ...state, image, uploadInProgress: false };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    }

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        image: null,
        updateInProgress: false,
        updateProfileError: payload,
      };

    case CLEAR_UPDATED_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };
    case UPDATE_LISTING_REQUEST:
      return {
        ...state,
        updateListingInProgress: true,
        updateListingError: null,
        submittedListingId: null,
        listing: null,
      };
    case UPDATE_LISTING_SUCCESS:
      return {
        ...state,
        updateListingInProgress: false,
        submittedListingId: payload.data.id,
        listing: payload.data,
      };
    case UPDATE_LISTING_ERROR:
      return {
        ...state,
        updateListingInProgress: false,
        updateListingError: payload,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const saveProfileSkillsClear = () => ({ type: CLEAR_UPDATED_FORM });
export const saveProfileSkillsRequest = () => ({ type: SAVE_PROFILE_SKILLS_REQUEST });
export const saveProfileSkillsSuccess = () => ({ type: SAVE_PROFILE_SKILLS_SUCCESS });
export const saveProfileSkillsError = (error) => ({
  type: SAVE_LANGUAGE_ERROR,
  payload: error,
  error: true,
});

export const updateListing = requestAction(UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction(UPDATE_LISTING_SUCCESS);
export const updateListingError = (error) => ({
  type: UPDATE_LISTING_ERROR,
  payload: error,
  error: true,
});

export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});

// SDK method: images.upload
export const uploadImageRequest = (params) => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = (result) => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload: result.data,
});
export const uploadImageError = (error) => ({
  type: UPLOAD_IMAGE_ERROR,
  payload: error,
  error: true,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = (params) => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: { params },
});
export const updateProfileSuccess = (result) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: result.data,
});
export const updateProfileError = (error) => ({
  type: UPDATE_PROFILE_ERROR,
  payload: error,
  error: true,
});

// ================ Thunk ================ //
export function requestUpdateListing(data) {
  const profile = data.data.data.attributes.profile;
  const privateData = data.data.data.attributes.profile.privateData;
  const publicData = data.data.data.attributes.profile.publicData;
  const profileListingID = privateData.listingID;

  const title = profile.displayName;
  const jobTitle = publicData.jobTitle;
  const language = publicData.language;
  const country = publicData.country;

  const defaultAvailabilityPlan = {
    type: 'availability-plan/day',
    entries: [
      { dayOfWeek: 'mon', seats: 1 },
      { dayOfWeek: 'tue', seats: 1 },
      { dayOfWeek: 'wed', seats: 1 },
      { dayOfWeek: 'thu', seats: 1 },
      { dayOfWeek: 'fri', seats: 1 },
      { dayOfWeek: 'sat', seats: 1 },
      { dayOfWeek: 'sun', seats: 1 },
    ],
  };

  const newData = {
    id: profileListingID,
    title: title,
    availabilityPlan: defaultAvailabilityPlan,
    publicData: {
      language: language,
      jobTitle: jobTitle,
      country: country,
    },
  };

  return (dispatch, getState, sdk) => {
    //Create a UTC and UNIX timestamp for the listing-end-date
    dispatch(updateListing(newData));
    return sdk.ownListings
      .update(newData)
      .then((response) => {
        // Modify store to understand that we have created listing and can redirect away
        dispatch(updateListingSuccess(response));
        return response;
      })
      .catch((e) => {
        log.error(e, 'create-listing-failed', { listingData: newData });
        return dispatch(updateListingError(storableError(e)));
      });
  };
}

//Update Profile Skills
const requestSaveSkills = (params) => (dispatch, getState, sdk) => {
  const phoneNumber = params.phoneNumber;
  const firstName = params.firstName;
  const lastName = params.lastName;
  const language = params.language;
  const street = params.street;
  const city = params.city;
  const postalCode = params.postalCode;
  const houseNumber = params.houseNumber;
  const country = params.country;
  const company = params.company;
  const b2b = params.b2b;
  const vatAvailable = params.vatAvailable;
  const jobTitle = params.jobTitle;
  const showPhone = params.showPhone;
  const showAddress = params.showAddress;
  const countryOptions = findOptionsForSelectFilter('country', config.profileConfig.filters);
  const countryFiltered = countryOptions.filter((countryOption) => countryOption.key === country);
  const countryCode = countryFiltered[0].code;
  const isVatAvailable = vatAvailable === 'vat is available' ? true : false;
  const taxId = isVatAvailable && supportedVatCountry(countryCode) ? params.taxId : '';

  const address = {
    city: city,
    houseNumber: houseNumber,
    postalCode: postalCode,
    street: street,
  };

  const queryParams = {
    expand: true,
    include: ['profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };

  return sdk.currentUser
    .updateProfile(
      {
        publicData: { language, jobTitle, company, country, b2b, postalCode, showPhone, showAddress },
        privateData: { countryCode, vatAvailable, taxId },
        protectedData: { firstName, lastName, address, phoneNumber },
      },
      queryParams
    )

    .then((response) => {
      const entities = denormalisedResponseEntities(response);
      dispatch(queryCurrentUser());
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      updateStripeCustomer()
      .then(response => {
      })
      .catch((e) => {
        dispatch(saveProfileSkillsError(storableError(e)));
        throw e;
      });
      return currentUser;
    })
    .catch((e) => {
      dispatch(saveProfileSkillsError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

// Images return imageId which we need to map with previously generated temporary id
export function uploadImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImageRequest(actionPayload));

    const bodyParams = {
      image: actionPayload.file,
    };
    const queryParams = {
      expand: true,
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then((resp) => {
        const uploadedImage = resp.data.data;
        dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
      })
      .catch((e) => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export const updateProfile = (actionPayload) => {
  return (dispatch, getState, sdk) => {
    dispatch(updateProfileRequest());
    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then((response) => {
        dispatch(updateProfileSuccess(response));
        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
        dispatch(queryCurrentUser());
      })
      .catch((e) => dispatch(updateProfileError(storableError(e))));
  };
};

/** Save Profile Skills and update the current user.
 */
const saveSkills = (params) => (dispatch, getState, sdk) =>
  dispatch(requestSaveSkills(params))
    .then((user) => {
      dispatch(currentUserShowSuccess(user));
      dispatch(saveProfileSkillsSuccess());
    })
    // error action dispatched in requestSaveSkills
    .catch((e) => null);

export const saveProfileSkills = (params) => (dispatch, getState, sdk) => {
  dispatch(saveProfileSkillsRequest());

  const {
    firstName,
    lastName,
    language,
    jobTitle,
    street,
    city,
    postalCode,
    houseNumber,
    country,
    company,
    b2b,
    vatAvailable,
    taxId,
    phoneNumber,
    showPhone,
    showAddress,
    currentLanguage,
    currentjobTitle,
    currentStreet,
    currentCity,
    currentPostalCode,
    currentHouseNumber,
    currentCountry,
    currentCompany,
    currentB2b,
    currentVatAvailable,
    currentTaxId,
    currentPhoneNumber,
    currentShowPhone,
    currentShowAddress,
  } = params;

  const languageChanged = language !== currentLanguage;
  const streetChanged = street !== currentStreet;
  const cityChanged = city !== currentCity;
  const postalCodeChanged = postalCode !== currentPostalCode;
  const houseNumberChanged = houseNumber !== currentHouseNumber;
  const countryChanged = country !== currentCountry;
  const companyChanged = company !== currentCompany;
  const b2bChanged = b2b !== currentB2b;
  const vatAvailableChanged = vatAvailable !== currentVatAvailable;
  const taxIdChanged = taxId !== currentTaxId;
  const jobTitleChanged = jobTitle !== currentjobTitle;
  const phoneNumberChanged = currentPhoneNumber !== phoneNumber;
  const showPhoneChanged = currentShowPhone !== showPhone;
  const showAddressChanged = currentShowAddress !== showAddress;

  if (
    streetChanged ||
    languageChanged ||
    cityChanged ||
    postalCodeChanged ||
    jobTitleChanged ||
    houseNumberChanged ||
    countryChanged ||
    companyChanged ||
    b2bChanged ||
    vatAvailableChanged ||
    taxIdChanged ||
    phoneNumberChanged ||
    showPhoneChanged ||
    showAddressChanged
  ) {
    return dispatch(
      saveSkills({
        firstName,
        lastName,
        language,
        jobTitle,
        street,
        city,
        postalCode,
        houseNumber,
        country,
        company,
        b2b,
        vatAvailable,
        taxId,
        phoneNumber,
        showPhone,
        showAddress
      })
    );
  }
};
const queryCurrentUser = (params) => (dispatch, getState, sdk) => {
  return sdk.currentUser
    .show()
    .then((response) => {
      const profileListingID = response.data.data.attributes.profile.privateData.listingID;
      if (profileListingID) {
        dispatch(requestUpdateListing(response));
      }
    })
    .catch((e) => {
      dispatch(updateListingError(storableError(e)));
      // pass the same error so that the SAVE_BCONS_SUCCESS
      // action will not be fired
      throw e;
    });
};
