import moment from 'moment/moment';

export const getDateOrDefault = (currentDate, fallbackManipulationCallback) => {
  let output;

  if (!!currentDate) {
    output= moment.tz(currentDate, moment.tz.guess())
  } else {
    output = moment().startOf('day')
    if (fallbackManipulationCallback) {
      output = fallbackManipulationCallback(output)
    }
  }

  return output.toDate();
}
