import React from 'react';
import loadable from '@loadable/component';
import config from './config';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import { NotFoundPage } from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';
import { getLanguageFromNavigatorOrDefault, getAsLanguage } from './ducks/UI.duck';
import { LISTING_TYPES } from './util/constants';
import {
  APPLICATION,
  CATEGORIES,
  DESCRIPTION, LEAD_EVO,
} from './components/EditListingWizard/EditListingWizardTab';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage'));
const AvailabilityPage = loadable(() => import(/* webpackChunkName: "AvailabilityPage" */ './containers/AvailabilityPage/AvailabilityPage'));
const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ './containers/AuthenticationPage/AuthenticationPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage'));
const LeadCheckoutPage = loadable(() => import(/* webpackChunkName: "LeadCheckoutPage" */ './containers/LeadCheckoutPage/LeadCheckoutPage'));
const FakeLeadCheckoutPage = loadable(() => import(/* webpackChunkName: "FakeLeadCheckoutPage" */ './containers/FakeLeadCheckoutPage/LeadCheckoutPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'));
const ContactPage = loadable(() => import(/* webpackChunkName: "ContactPage" */ './containers/ContactPage/ContactPage'));
const ExpertsListingPage = loadable(() => import(/* webpackChunkName: "ExpertsListingPage" */ './containers/ExpertsListingPage/ExpertsListingPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'));
const FaqPage = loadable(() => import(/* webpackChunkName: "FaqPage" */ './containers/FaqPage/FaqPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ './containers/InboxPage/InboxPage'));
const MessagesPage = loadable(() => import(/* webpackChunkName: "MessagesPage" */ './containers/MessagesPage/MessagesPage'));
const ImpressumPage = loadable(() => import(/* webpackChunkName: "ImpressumPage" */ './containers/ImpressumPage/ImpressumPage'));
const IndustrypartnerPage = loadable(() => import(/* webpackChunkName: "IndustrypartnerPage" */ './containers/IndustrypartnerPage/IndustrypartnerPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ './containers/LandingPage/LandingPage'));
const LeadsListingPage = loadable(() => import(/* webpackChunkName: "LeadsListingPage" */ './containers/LeadsListingPage/LeadsListingPage'));
const ListingPage = loadable(() => import(/* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/ListingPage/ListingPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ './containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ './containers/PaymentMethodsPage/PaymentMethodsPage'));
const PostJobPage = loadable(() => import(/* webpackChunkName: "PostJobPage" */ './containers/PostJobPage/PostJobPage'));
const PriceListPage = loadable(() => import(/* webpackChunkName: "PriceListPage" */ './containers/PriceListPage/PriceListPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'));
const ProfileSkillsPage = loadable(() => import(/* webpackChunkName: "ProfileSkillsPage" */ './containers/ProfileSkillsPage/ProfileSkillsPage'));
const RequestManufacturerPage = loadable(() => import(/* webpackChunkName: "RequestManufacturerPage" */ './containers/RequestManufacturerPage/RequestManufacturerPage'));
const ProfileManufacturerPage = loadable(() => import(/* webpackChunkName: "ProfileManufacturerPage" */ './containers/ProfileManufacturerPage/ProfileManufacturerPage'));
const SearchPage = loadable(() => import(/* webpackChunkName: "SearchPage" */ /* webpackPrefetch: true */  './containers/SearchPage/SearchPage'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'));
const SubscriptionPage = loadable(() => import(/* webpackChunkName: "SubscriptionPage" */ './containers/SubscriptionPage/SubscriptionPage'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'));
const TermsOfServiceLeadPage = loadable(() => import(/* webpackChunkName: "TermsOfServiceLeadPage" */ './containers/TermsOfServiceLeadPage/TermsOfServiceLeadPage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage'));

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
  'SubscriptionPage',
];


// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" currentLang={ getLanguageFromNavigatorOrDefault() } />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'UnlocalizedPasswordResetPage',
      component: () => <NamedRedirect name="PasswordResetPage" currentLang={ getLanguageFromNavigatorOrDefault() } />,
    },
    {
      path: '/:lang/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage ,
    },
    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'UnlocalizedEmailVerificationPage',
      component: () => <NamedRedirect name="EmailVerificationPage" currentLang={ getLanguageFromNavigatorOrDefault() } />,
    },
    {
      path: '/:lang/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    {
      path: '/:lang/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/:lang/industry-partner',
      name: 'IndustrypartnerPage',
      component: IndustrypartnerPage,
    },
    {
      path: '/:lang/post-a-job',
      name: 'PostJobPage',
      component: PostJobPage,
      loadData: pageDataLoadingAPI.PostJobPage.loadData,
    },
    {
      path: '/:lang/availability',
      name: 'AvailabilityPage',
      component: AvailabilityPage,
    },
    {
      path: '/:lang/contact',
      name: 'ContactPage',
      component: ContactPage,
    },
    {
      path: '/:lang/faq',
      name: 'FaqPage',
      component: FaqPage,
    },
    {
      path: '/:lang/legal-notice',
      name: 'ImpressumPage',
      component: ImpressumPage,
    },
    {
      path: '/find-lighting-jobs',
      name: 'UnlocalizedSearchPage',
      component: () => <NamedRedirect name="SearchPage" currentLang={ getLanguageFromNavigatorOrDefault() } />,
    },
    {
      path: '/:lang/find-lighting-jobs',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/find-lighting-experts',
      name: 'UnlocalizedExpertsListingPage',
      component: () => <NamedRedirect name="ExpertsListingPage" currentLang={ getLanguageFromNavigatorOrDefault() } />,
    },
    {
      path: '/:lang/find-lighting-experts',
      name: 'ExpertsListingPage',
      component: ExpertsListingPage,
      loadData: pageDataLoadingAPI.ExpertsListingPage.loadData,
    },
    {
      path: '/:lang/job',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/:lang/freelancer/new/connect/:expertId',
      name: 'ConnectExpert',
      auth: true,
      component: (props) => (
        <EditListingPage
          params={{ ...props.params, slug: draftSlug, id: draftId, type: 'new', tab: 'categories', expertId: props.params.expertId }}
        />
        ),
        loadData: params => pageDataLoadingAPI.EditListingPage.loadData({ ...params, slug: draftSlug, id: draftId, type: 'new', tab: 'categories', expertId: params.expertId  }),
    },
    {
      path: '/le',
      name: 'UnlocalizedLeadsListingPage',
      component: props => <NamedRedirect name="LeadsListingPage" currentLang={ getLanguageFromNavigatorOrDefault() } params={{ ...props.params }}/>,
    },
    {
      path: '/profile-skills',
      name: 'UnlocalizedProfileSkillsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="ProfileSkillsPage" currentLang={ getLanguageFromNavigatorOrDefault() } params={{ ...props.params }}/>
    },
    {
      path: '/:lang/leads',
      name: 'LeadsListingPage',
      component: LeadsListingPage,
      loadData: pageDataLoadingAPI.LeadsListingPage.loadData,
    },
    {
      path: '/:lang/lead/:slug/:id',
      name: 'LeadPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
      extraProps: { isLead: true },
    },
    {
      path: '/:lang/example/:slug/:id',
      name: 'ExamplePage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
      extraProps: { isExample: true },
    },
    {
      path: '/:lang/job/:slug/:id',
      name: 'ListingPage',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },

    {
      path: '/:lang/job/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/:lang/lead/:slug/:id/checkout',
      name: 'LeadCheckoutPage',
      auth: true,
      component: config.isFreeLeadPeriod
        ? FakeLeadCheckoutPage
        : LeadCheckoutPage,
      setInitialValues: pageDataLoadingAPI.LeadCheckoutPage.setInitialValues,
    },
    {
      path: '/:lang/job/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'UnlocalizedNewListingPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: DESCRIPTION }}
        />
      ),
    },
    {
      path: '/:lang/job/new',
      name: 'NewListingPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => (
        <NamedRedirect
          name='EditListingPage'
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: CATEGORIES }}
        />
      ),
    },
    {
      path: '/lead/new',
      name: 'UnlocalizedNewLeadPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => (
        <NamedRedirect
          name="EditLeadPage"
          currentLang={ getLanguageFromNavigatorOrDefault() }
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: APPLICATION }}
        />
      ),
    },
    {
      path: '/:lang/lead/:slug/:id/:type/:tab',
      name: 'EditLeadPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => (
        <EditListingPage listingType={LISTING_TYPES.LEAD} {...props} />
      ),
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/:lang/job/:slug/:id/:type/:tab/:accordion',
      name: 'EditListingPage2',
      auth: true,
      authPage: 'LoginPage',
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData
    },
    {
      path: '/:lang/job/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      authPage: 'LoginPage',
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/:lang/profile-skills/:returnURLType',
      name: 'ProfileSkillsStripeOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSkillsPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/job/:id',
      name: 'UnlocalizedListingPageCanonical',
      component: props => <NamedRedirect name="ListingPageCanonical" currentLang={ getLanguageFromNavigatorOrDefault() } params={{ ...props.params }} />,
    },
    {
      path: '/:lang/job/:id',
      name: 'ListingPageCanonical',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/:lang/freelancer',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/:lang/freelancer/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/:lang/account/settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },
    {
      path: '/:lang/profile-address',
      name: 'ProfileAddressPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name={'ProfileSkillsPage'}/>,
    },
    {
      path: '/:lang/account/lighting-professional',
      name: 'ProfileSkillsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSkillsPage,
    },
    {
      path: '/:lang/profile-skills',
      name: 'ProfileSkillsPageLegacy',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name={'ProfileSkillsPage'}/>,
    },
    {
      path: '/:lang/return-after-subscription',
      name: 'ProfileSkillsPageAfterSubscription',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSkillsPage,
      extraProps: { isSubscribed: true }
    },
    {
      path: '/:lang/account/manufacturer',
      name: 'ProfileManufacturerPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileManufacturerPage,
    },
    {
      path: '/:lang/profile-manufacturer',
      name: 'ProfileManufacturerPageLegacy',
      component: () => <NamedRedirect name={'ProfileSkillsPage'}/>,
    },
    {
      path: '/:lang/profile-references',
      name: 'ProfileReferencesPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name={'ProfileSkillsPage'}/>,
    },
    {
      path: '/:lang/profile-business-conditions',
      name: 'ProfileBConsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name={'ProfileSkillsPage'}/>,
    },
    {
      path: '/:lang/evo/landingpage',
      name: 'DxServicesLandingpage',
      component: LandingPage,
    },
    {
      path: '/:lang/lead/evo',
      name: 'NewEvoLeadPage',
      component: props => (
        <NamedRedirect
          name="EditLeadPage"
          currentLang={props.params.lang}
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: APPLICATION, ...props.params }}
        />
      ),
    },
    {
      path: '/:lang/lead/new',
      name: 'NewLeadPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => (
        <NamedRedirect
          name="EditLeadPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: APPLICATION }}
        />
      ),
    },
    {
      path: '/lead/:id',
      name: 'UnlocalizedLeadPageCanonical',
      component: props => <NamedRedirect name="LeadPageCanonical" currentLang={ getLanguageFromNavigatorOrDefault() } params={{ ...props.params }} />,
    },
    {
      path: '/:lang/lead/:id',
      name: 'LeadPageCanonical',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
      extraProps: { isLead: true },
    },
    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/:lang/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/:lang/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: '/:lang/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/:lang/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/:lang/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'listings' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'UnlocalizedInboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="InboxPage" currentLang={ getLanguageFromNavigatorOrDefault() } params={{ ...props.params }} />,
    },
    {
      path: '/:lang/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/messages',
      name: 'UnlocalizedMessagesPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="MessagesPage" currentLang={ getLanguageFromNavigatorOrDefault() } params={{ ...props.params }} />,
    },
    {
      path: '/:lang/messages',
      name: 'MessagesPage',
      auth: true,
      authPage: 'LoginPage',
      component: MessagesPage,
      loadData: pageDataLoadingAPI.MessagesPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'UnlocalizedOrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" currentLang={ getLanguageFromNavigatorOrDefault() } params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'UnlocalizedOrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" currentLang={ getLanguageFromNavigatorOrDefault() } params={{ ...props.params }} />,
    },
    {
      path: '/:lang/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/:lang/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id/details',
      name: 'UnlocalizedSaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" currentLang={ getLanguageFromNavigatorOrDefault() } params={{ ...props.params }} />,
    },
    {
      path: '/:lang/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/:lang/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/:lang/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ProfileSettingsPage" />,
    },
    {
      path: '/:lang/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/:lang/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/:lang/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/subscription',
      name: 'UnlocalizedSubscriptionPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SubscriptionPage" currentLang={ getLanguageFromNavigatorOrDefault() } params={{ ...props.params }} />,
      loadData: pageDataLoadingAPI.SubscriptionPage.loadData,
    },
    {
      path: '/:lang/account/subscription',
      name: 'SubscriptionPage',
      component: SubscriptionPage,
      loadData: pageDataLoadingAPI.SubscriptionPage.loadData,
    },
    {
      path: '/:lang/subscription',
      name: 'SubscriptionPageLegacy',
      component: () => <NamedRedirect name="SubscriptionPage" />,
    },
    {
      path: '/:lang/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/:lang/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'UnlocalizedTermsOfServicePage',
      component: props => <NamedRedirect name="TermsOfServicePage" currentLang={ getLanguageFromNavigatorOrDefault() } params={{ ...props.params }}/>,
    },
    {
      path: '/terms-of-service-lead',
      name: 'UnlocalizedTermsOfServiceLeadPage',
      component: props => <NamedRedirect name="TermsOfServiceLeadPage" currentLang={ getLanguageFromNavigatorOrDefault() } params={{ ...props.params }}/>,
    },
    {
      path: '/:lang/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
    },
    {
      path: '/:lang/terms-of-service-lead',
      name: 'TermsOfServiceLeadPage',
      component: TermsOfServiceLeadPage,
    },
    {
      path: '/pricing',
      name: 'UnlocalizedPriceListPage',
      component: props => <NamedRedirect name="PriceListPage" currentLang={ getLanguageFromNavigatorOrDefault() } params={{ ...props.params }}/>,
    },
    {
      path: '/:lang/pricing',
      name: 'PriceListPage',
      component: PriceListPage,
    },
    {
      path: '/request-manufacturer',
      name: 'UnlocalizedRequestManufacturerPage',
      component: props => <NamedRedirect name="ProjectPage" currentLang={ getLanguageFromNavigatorOrDefault() } params={{ ...props.params }}/>,
    },
    {
      path: '/:lang/request-manufacturer',
      name: 'RequestManufacturerPage',
      component: RequestManufacturerPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData
    },
    {
      path: '/data-protection-policy',
      name: 'UnlocalizedPrivacyPolicyPage',
      component: props => <NamedRedirect name="PrivacyPolicyPage" currentLang={ getLanguageFromNavigatorOrDefault() } params={{ ...props.params }}/>,
    },
    {
      path: '/:lang/data-protection-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: '/notfound',
      name: 'UnlocalizedNotFoundPage',
      component: props => <NamedRedirect name="NotFoundPage" currentLang={ getLanguageFromNavigatorOrDefault() } params={{ ...props.params }} />,
    },
    {
      path: '/:lang/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },
    {
      path: '/:lang/jobc/:slug/:id',
      name: 'ListingPageContact',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
      extraProps: { shouldBeContacted: true },
    },
    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/',
      name: 'UnlocalizedLandingPage',
      component: () => <NamedRedirect name="LandingPage" currentLang={ getLanguageFromNavigatorOrDefault() } />,
    },
    {
      path: '/:lang',
      name: 'LandingPage',
      component: LandingPage,
    },
  ];
};

export default routeConfiguration;
