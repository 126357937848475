import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button } from '../../components';

import css from './EditListingFromEvoForm.module.css';
import { ensureUser } from '../../util/data';

const EditListingFromEvoFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        form,
        className,
        disabled,
        ready,
        handleSubmit,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        currentUser
      } = formRenderProps;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const user = ensureUser(currentUser);
      const name = user.attributes.profile?.displayName?.slice(0,-2) || "User";

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.text}>
            <FormattedMessage id="EditListingEvoLeadPanel.evoLeadPanelText" values={{name: name, br: <br/>}}/>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFromEvoFormComponent.defaultProps = { className: null };

EditListingFromEvoFormComponent.propTypes = {
  className: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(EditListingFromEvoFormComponent);
