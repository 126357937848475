import {
  createStripeAccount,
  updateStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { deleteFiles, searchFiles } from '../../util/api';

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });


// ================ Action types ================ //

export const SAVE_BCONS_REQUEST = 'app/ProfileBConsPage/SAVE_BCONS_REQUEST';
export const SAVE_BCONS_SUCCESS = 'app/ProfileBConsPage/SAVE_BCONS_SUCCESS';
export const SAVE_BCONS_ERROR = 'app/ProfileBConsPage/SAVE_BCONS_ERROR';
export const SAVE_BCONS_CLEAR = 'app/ProfileBConsPage/SAVE_BCONS_CLEAR';

export const CREATE_LISTING_REQUEST = 'app/ProfileBConsPage/CREATE_LISTING_REQUEST';
export const CREATE_LISTING_SUCCESS = 'app/ProfileBConsPage/CREATE_LISTING_SUCCESS';
export const CREATE_LISTING_ERROR = 'app/ProfileBConsPage/CREATE_LISTING_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/ProfileBConsPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/ProfileBConsPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/ProfileBConsPage/UPDATE_LISTING_ERROR';

export const UPLOAD_FILE_ON_CLOUDINARY_REQUEST = 'app/ProfileBConsPage/UPLOAD_FILE_ON_CLOUDINARY_REQUEST';
export const UPLOAD_FILE_ON_CLOUDINARY_SUCCESS = 'app/ProfileBConsPage/UPLOAD_FILE_ON_CLOUDINARY_SUCCESS';
export const UPLOAD_FILE_ON_CLOUDINARY_ERROR = 'app/ProfileBConsPage/UPLOAD_FILE_ON_CLOUDINARY_ERROR';

export const FETCH_FILE_FROM_CLOUDINARY_REQUEST = 'app/ProfileBConsPage/FETCH_FILE_FROM_CLOUDINARY_REQUEST';
export const FETCH_FILE_FROM_CLOUDINARY_SUCCESS = 'app/ProfileBConsPage/FETCH_FILE_FROM_CLOUDINARY_SUCCESS';
export const FETCH_FILE_FROM_CLOUDINARY_ERROR = 'app/ProfileBConsPage/FETCH_FILE_FROM_CLOUDINARY_ERROR';

export const DELETE_FILE_FROM_CLOUDINARY_REQUEST = 'app/ProfileBConsPage/DELETE_FILE_FROM_CLOUDINARY_REQUEST';
export const DELETE_FILE_FROM_CLOUDINARY_SUCCESS = 'app/ProfileBConsPage/DELETE_FILE_FROM_CLOUDINARY_SUCCESS';
export const DELETE_FILE_FROM_CLOUDINARY_ERROR = 'app/ProfileBConsPage/DELETE_FILE_FROM_CLOUDINARY_ERROR';

export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/ProfileBConsPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/ProfileBConsPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/ProfileBConsPage/SAVE_PAYOUT_DETAILS_ERROR';
// ================ Reducer ================ //

const initialState = {
  saveProfileBConsInProgress: false,
  saveBConsError: null,
  profileBConsChanged: false,
  createListingError: null,
  createListingInProgress: false,
  updateListingError: null,
  updateListingInProgress: false,
  listing: null,
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  uploadFileError: null,
  uploadFileInProgress: false,
  fetchFilesFromCloudinaryInProgress: false,
  fetchFilesFromCloudinaryError: false,
  documents: {},
  deleteFilesFromCloudinaryInProgress: false,
  deleteFilesFromCloudinaryError: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_BCONS_REQUEST:
      return {
        ...state,
        saveProfileBConsInProgress: true,
        saveBConsError: null,
        profileBConsChanged: false,
      };
    case SAVE_BCONS_SUCCESS:
      return { ...state, saveProfileBConsInProgress: false, profileBConsChanged: true };
    case SAVE_BCONS_ERROR:
      return { ...state, saveProfileBConsInProgress: false, saveBConsError: payload };
    case SAVE_BCONS_CLEAR:
      return {
        ...state,
        saveProfileBConsInProgress: false,
        saveBConsError: null,
        profileBConsChanged: false,
      };
    case CREATE_LISTING_REQUEST:
      return {
        ...state,
        createListingInProgress: true,
        createListingError: null,
        submittedListingId: null,
        listing: null,
      };
    case CREATE_LISTING_SUCCESS:
      return {
        ...state,
        createListingInProgress: false,
        submittedListingId: payload.data.id,
        listing: payload.data,
      };
    case CREATE_LISTING_ERROR:
      return {
        ...state,
        createListingInProgress: false,
        createListingError: payload,
      };
    case UPLOAD_FILE_ON_CLOUDINARY_REQUEST:
      return {
        ...state,
        uploadFileError: null,
        uploadFileInProgress: payload,
      };
    case UPLOAD_FILE_ON_CLOUDINARY_SUCCESS:
      return {
        ...state,
        uploadFileError: null,
        uploadFileInProgress: false,
      };
    case UPLOAD_FILE_ON_CLOUDINARY_ERROR:
      return {
        ...state,
        uploadFileError: payload,
        uploadFileInProgress: false,
      };

    case UPDATE_LISTING_REQUEST:
      return {
        ...state,
        createListingInProgress: true,
        createListingError: null,
        submittedListingId: null,
        listing: null,
      };
    case UPDATE_LISTING_SUCCESS:
      return {
        ...state,
        createListingInProgress: false,
        submittedListingId: payload.data.id,
        listing: payload.data,
      };
    case UPDATE_LISTING_ERROR:
      return {
        ...state,
        createListingInProgress: false,
        createListingError: payload,
      };
    case FETCH_FILE_FROM_CLOUDINARY_REQUEST:
      return {
        ...state,
        fetchFilesFromCloudinaryInProgress: true,
        fetchFilesFromCloudinaryError: null,
      };
    case FETCH_FILE_FROM_CLOUDINARY_SUCCESS:
      return {
        ...state,
        fetchFilesFromCloudinaryInProgress: false,
        fetchFilesFromCloudinaryError: null,
        documents: payload,
      };
    case FETCH_FILE_FROM_CLOUDINARY_ERROR:
      return {
        ...state,
        fetchFilesFromCloudinaryInProgress: false,
        fetchFilesFromCloudinaryError: payload,
      };

    case DELETE_FILE_FROM_CLOUDINARY_REQUEST:
      return {
        ...state,
        deleteFilesFromCloudinaryInProgress: true,
        deleteFilesFromCloudinaryError: null,
      };
    case DELETE_FILE_FROM_CLOUDINARY_SUCCESS:
      return {
        ...state,
        deleteFilesFromCloudinaryInProgress: false,
        deleteFilesFromCloudinaryError: null,
      };
    case DELETE_FILE_FROM_CLOUDINARY_ERROR:
      return {
        ...state,
        deleteFilesFromCloudinaryInProgress: false,
        deleteFilesFromCloudinaryError: payload,
      };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveBConsRequest = () => ({ type: SAVE_BCONS_REQUEST });
export const saveBConsSuccess = () => ({ type: SAVE_BCONS_SUCCESS });
export const saveBConsError = error => ({
  type: SAVE_BCONS_ERROR,
  payload: error,
  error: true,
});

// SDK method: ownListings.create
export const updateListing = requestAction(UPDATE_LISTING_REQUEST);

export const createListing = requestAction(CREATE_LISTING_REQUEST);
export const createListingSuccess = successAction(CREATE_LISTING_SUCCESS);
export const createListingError = errorAction(CREATE_LISTING_ERROR);

export const uploadFile = requestAction(UPLOAD_FILE_ON_CLOUDINARY_REQUEST);
export const uploadFileSuccess = successAction(UPLOAD_FILE_ON_CLOUDINARY_SUCCESS);
export const uploadFileError = errorAction(UPLOAD_FILE_ON_CLOUDINARY_ERROR);

export const fetchFilesFromCloudinary = requestAction(FETCH_FILE_FROM_CLOUDINARY_REQUEST);
export const fetchFilesFromCloudinarySuccess = data => ({
  type: FETCH_FILE_FROM_CLOUDINARY_SUCCESS,
  payload: data,
});

export const deleteFilesFromCloudinaryRequest = requestAction(DELETE_FILE_FROM_CLOUDINARY_REQUEST);
export const deleteFilesFromCloudinarySuccess = successAction(DELETE_FILE_FROM_CLOUDINARY_SUCCESS);
export const deleteFilesFromCloudinaryError = errorAction(DELETE_FILE_FROM_CLOUDINARY_ERROR);

export const savePayoutDetailsRequest = requestAction(SAVE_PAYOUT_DETAILS_REQUEST);
export const savePayoutDetailsSuccess = successAction(SAVE_PAYOUT_DETAILS_SUCCESS);
export const savePayoutDetailsError = errorAction(SAVE_PAYOUT_DETAILS_ERROR);

// ================ Thunks ================ //
export function requestFetchUploadedFiles(userId, type) {

  return (dispatch, _, __) => {
    setTimeout(() => {
      searchFiles({ userId: userId })
        .then(result => {
          dispatch(fetchFilesFromCloudinarySuccess(result.data));
          if (type === 'upload') {
            dispatch(uploadFileSuccess(result.data));
          }
          if (type === 'destroy') {
            dispatch(deleteFilesFromCloudinarySuccess(result.data));
          }
        });
    }, 3000);
  };
}

export function requestUpdateListing(data) {

  const userProfile = data.data.data.attributes.profile;
  const userPublicData = userProfile.publicData;
  const profileListingID = userProfile.privateData.listingID;

  const newData = {
    description: userProfile.bio,
    id: profileListingID,
    price: userPublicData.hourlyRate,
    publicData: {
      listingCategory: 'expert',
      country: userPublicData.country,
      homepage: userPublicData.homepage,
      jobTitle: userPublicData.jobTitle,
      language: userPublicData.language,
      setService: userPublicData.setService,
      expertOption: userPublicData.expertOption,
      dialux: userPublicData.dialux,
      documentation: userPublicData.documentation,
      lightingDesign: userPublicData.lightingDesign,
      others: userPublicData.others,
      photoWork: userPublicData.photoWork,
      isIncognito: userPublicData.isIncognito,
    },
  };

  return (dispatch, getState, sdk) => {
    //Create a UTC and UNIX timestamp for the listing-end-date
    dispatch(updateListing(newData));

    return sdk.ownListings
      .update(newData)
      .then(response => {

        // Modify store to understand that we have created listing and can redirect away
        dispatch(createListingSuccess(response));
        return response;
      })
      .catch(e => {
        log.error(e, 'create-listing-failed', { listingData: newData });
        return dispatch(createListingError(storableError(e)));
      });
  };
}

export function requestCreateListing(data, isExpert = true) {

  const userProfile = data.data.data.attributes.profile;
  const userPublicData = userProfile.publicData;
  const userID = data.data.data.id.uuid;
  const hourlyRate = userPublicData.hourlyRate || {amount:0, currency: 'EUR'};

  const defaultAvailabilityPlan = {
    type: 'availability-plan/day',
    entries: [
      { dayOfWeek: 'mon', seats: 1 },
      { dayOfWeek: 'tue', seats: 1 },
      { dayOfWeek: 'wed', seats: 1 },
      { dayOfWeek: 'thu', seats: 1 },
      { dayOfWeek: 'fri', seats: 1 },
      { dayOfWeek: 'sat', seats: 1 },
      { dayOfWeek: 'sun', seats: 1 },
    ],
  };

  const newData = {
    description: userProfile.bio,
    title: userProfile.displayName,
    price: hourlyRate,
    availabilityPlan: defaultAvailabilityPlan,
    publicData: {
      listingCategory: 'expert',
      country: userPublicData.country,
      homepage: userPublicData.homepage,
      jobTitle: userPublicData.jobTitle,
      language: userPublicData.language,
      setService: userPublicData.setService,
      expertOption: isExpert,
      userID: userID,
      dialux: userPublicData.dialux,
      documentation: userPublicData.documentation,
      lightingDesign: userPublicData.lightingDesign,
      others: userPublicData.others,
      photoWork: userPublicData.photoWork,
      isIncognito: userPublicData.isIncognito,
    },
  };

  return (dispatch, getState, sdk) => {
    //Create a UTC and UNIX timestamp for the listing-end-date
    dispatch(createListing(newData));

    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    };

    return sdk.ownListings
      .create(newData, queryParams)
      .then(response => {

        const id = response.data.data.id.uuid;
        // Add the created listing to the marketplace data
        dispatch(addMarketplaceEntities(response));
        dispatch(saveListingIDRequest({listingId:id, isExpert}));

        // Modify store to understand that we have created listing and can redirect away
        dispatch(createListingSuccess(response));
        return response;
      })
      .catch(e => {
        log.error(e, 'create-listing-failed', { listingData: newData });
        return dispatch(createListingError(storableError(e)));
      });
  };
}

// Save expert-listing-id to profile
const saveListingIDRequest = params => (dispatch, getState, sdk) => {
  const { listingId, isExpert } = params;

  return sdk.currentUser
    .updateProfile(
      {
        publicData: {
          expertOption: isExpert
        },
        privateData: {
          listingID: listingId
        },
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      },
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveBConsError(storableError(e)));
      // pass the same error so that the SAVE_BCONS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Make a update request to the API and return the current user.
 */
const saveProfileBConsRequest = params => (dispatch, getState, sdk) => {

  const hourlyRate = params.formattedHourlyRate;
  const expertOption = params.expertOption;
  const setService = params.setService;
  const dialux = params.dialux;
  const documentation = params.documentation;
  const lightingDesign = params.lightingDesign;
  const others = params.others;
  const photoWork = params.photoWork;
  const payment = params.payment;
  const homepage = params.homepage;
  const linkedIn = params.linkedIn;
  const facebook = params.facebook;
  const xing = params.xing;
  const twitter = params.twitter;
  const bio = params.bio;

  const {
    termsAndConditions,
    privacyPolicies,
    ownTcAndPp,
    ownTermsAndConditions,
    ownPrivacyPolicy,
    isIncognito,
    workingExperience

  } = params;

  return sdk.currentUser
    .updateProfile(
      {
        bio,
        publicData: {
          hourlyRate,
          expertOption,
          setService,
          dialux,
          documentation,
          lightingDesign,
          others,
          photoWork,
          payment,
          homepage,
          linkedIn,
          facebook,
          xing,
          twitter,
          isIncognito,
          workingExperience
        },
        privateData: {
          termsAndConditions,
          privacyPolicies,
          ownTcAndPp,
          ownTermsAndConditions,
          ownPrivacyPolicy,
        },
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      },
    )
    .then(response => {
      dispatch(queryCurrentUser());
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveBConsError(storableError(e)));
      // pass the same error so that the SAVE_BCONS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/** Save HourlyRate, Expert Option and Services, update the current user.
 */
const saveBCons = params => (dispatch, getState, sdk) => {
  return (
    dispatch(saveProfileBConsRequest(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveBConsSuccess());
      })
      // error action dispatched in requestBConsRequest
      .catch(e => null)
  );
};

export const saveProfileBCons = params => (dispatch, getState, sdk) => {
  dispatch(saveBConsRequest());
  const {
    hourlyRate,
    expertOption,
    setService,
    dialux,
    documentation,
    lightingDesign,
    others,
    photoWork,
    payment,
    homepage,
    linkedIn,
    facebook,
    xing,
    twitter,
  } = params.publicData;
  const {
    termsAndConditions,
    privacyPolicies,
    ownTcAndPp,
    ownTermsAndConditions,
    ownPrivacyPolicy,
  } = params.privateData;

  const formattedHourlyRate =
    hourlyRate === undefined || hourlyRate === null
      ? undefined
      : { amount: hourlyRate.amount, currency: hourlyRate.currency };

  const bio = params.profile.bio;
  const isIncognito = params?.publicData?.isIncognito ?? false;

  const workingExperience = getState()?.WorkingExperience?.items;

  return dispatch(saveBCons({
    formattedHourlyRate,
    expertOption,
    setService,
    dialux,
    documentation,
    lightingDesign,
    others,
    photoWork,
    termsAndConditions,
    privacyPolicies,
    ownTcAndPp,
    ownTermsAndConditions,
    ownPrivacyPolicy,
    payment,
    homepage,
    linkedIn,
    facebook,
    xing,
    twitter,
    bio,
    isIncognito,
    workingExperience
  }));
};

const queryCurrentUser = params => (dispatch, getState, sdk) => {
  return sdk.currentUser
    .show()
    .then(response => {
      const profileListingID = response.data.data.attributes.profile.privateData.listingID;

      if (!profileListingID) {
        dispatch(requestCreateListing(response));
      } else {
        dispatch(requestUpdateListing(response));
      }
    })
    .catch(e => {
      dispatch(saveBConsError(storableError(e)));
      throw e;
    });
};

export const createExpertListingIfNotExists = () => (dispatch, getState, sdk) =>
  sdk.currentUser.show().then((response) => {
    const listingId = response.data.data.attributes.profile.privateData.listingID;
    if (!listingId) {
      dispatch(requestCreateListing(response, true))
    }
  });

export const savePayoutDetails = (values, isUpdateCall) => (dispatch, getState, sdk) => {
  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(savePayoutDetailsRequest());

  return dispatch(upsertThunk(values, { expand: true }))
    .then((response) => {
      dispatch(savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(savePayoutDetailsError()));
};

export const uploadFileOnCloudinary = (e, form, file, area, userId) => (dispatch, getState, sdk) => {
  dispatch(uploadFile(area));

  const allowedFileTypes = [
    'application/pdf',
    'image/jpeg',
    'image/tiff',
    'application/postscript',
  ];

  const correctFileType = allowedFileTypes.includes(file.type);

  if (correctFileType) {
    const url = 'https://api.cloudinary.com/v1_1/lightshift/upload';
    const uploadPresets = area === 'qualifications' ? 'uyvoyxqa' : 'uu3uold8';
    const tags = [userId];

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPresets);
    formData.append('tags', tags);
    //formData.append('use_filename', true);
    //formData.append('unique_filename', true);

    return fetch(url, { method: 'POST', body: formData })
      .then((response) => {
        console.debug(response);
        dispatch(requestFetchUploadedFiles(userId, 'upload'));
      })
      .catch((error) => dispatch(uploadFileError(error)));

  } else {
    return dispatch(uploadFileError('filetype isn´t correct: ' + area));
  }

};

export const getFreelancerDocuments = (userId) => (dispatch, getState, sdk) => {
  dispatch(fetchFilesFromCloudinary());
  searchFiles({ userId: userId })
    .then(response => {
      dispatch(fetchFilesFromCloudinarySuccess(response.data));
    })
    .catch(error => {
      dispatch(deleteFilesFromCloudinaryError(error));
    });
};

export const destroyFile = (publicId, userId) => (dispatch, getState, sdk) => {
  dispatch(deleteFilesFromCloudinaryRequest());

  deleteFiles({ publicId: publicId })
    .then((response) => {
      if (response.data.result === 'ok') {
        dispatch(requestFetchUploadedFiles(userId, 'destroy'));
      } else dispatch(deleteFilesFromCloudinaryError(error));
    });
//  deleteFilesFromCloudinarySuccess
//  deleteFilesFromCloudinaryError
};




