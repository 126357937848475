import React from 'react';
import PropTypes from 'prop-types';

const ButtonNext = props => {
  const { className } = props;
  return (
<svg version="1.1" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
 <circle cx="16" cy="16" r="16" fill="#4a4a4a"/>
 <path d="m11.8 10.3 5.6 5.7-5.6 5.7 1.4 1.4 5.6-5.7 1.4-1.4-1.4-1.4-5.6-5.7z" fill="#fff"/>
</svg>

  );
};

ButtonNext.defaultProps = { className: null };

const { string } = PropTypes;

ButtonNext.propTypes = { className: string };

export default ButtonNext;
