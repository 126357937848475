import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconIndustryBadge.module.css';

const IconIndustryBadge = props => {
  const { rootClassName, className, freelancerBadgeClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <g
        className={freelancerBadgeClassName || css.badge}
        stroke="none"
        fill="ffffff"
        fillRule="evenodd"
      >
      <path d="m23.143 13.389v-4.8171l-6.8571 4.8171v-4.8171l-6.8571 4.8171v-13.389h-9.4286v30h30v-21.429zm5.1429 14.897h-26.571v-26.571h6v14.983l2.7-1.9029 4.1571-2.9229v4.8171l2.7-1.9029 4.1571-2.9229v4.8171l2.7-1.9029 4.1571-2.9229v16.414z" />
      </g>
    </svg>
  );
};

IconIndustryBadge.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconIndustryBadge.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconIndustryBadge;
