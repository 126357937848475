import { createStripeSubscription, createStripeCustomerPortalSession } from '../../util/api';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { createExpertListingIfNotExists } from '../ProfileSkillsPage/ProfileSkillsPage.duck';

// ================ Action types ================ //

export const CREATE_STRIPE_SUBSCRIPTION_REQUEST = 'app/ProfileBConsPage/CREATE_STRIPE_SUBSCRIPTION_REQUEST';
export const CREATE_STRIPE_SUBSCRIPTION_SUCCESS = 'app/ProfileBConsPage/CREATE_STRIPE_SUBSCRIPTION_SUCCESS';
export const CREATE_STRIPE_SUBSCRIPTION_ERROR = 'app/ProfileBConsPage/CREATE_STRIPE_SUBSCRIPTION_ERROR';

export const MANAGE_STRIPE_SUBSCRIPTION_REQUEST = 'app/ProfileBConsPage/MANAGE_STRIPE_SUBSCRIPTION_REQUEST';
export const MANAGE_STRIPE_SUBSCRIPTION_SUCCESS = 'app/ProfileBConsPage/MANAGE_STRIPE_SUBSCRIPTION_SUCCESS';
export const MANAGE_STRIPE_SUBSCRIPTION_ERROR = 'app/ProfileBConsPage/MANAGE_STRIPE_SUBSCRIPTION_ERROR';
// ================ Reducer ================ //

const initialState = {
  createStripeSubscriptionInProgress: null,
  createStripeSubscriptionError: null,
  manageStripeSubscriptionInProgress: false,
  manageStripeSubscriptionError: null,

};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_STRIPE_SUBSCRIPTION_REQUEST:
      return { ...state, createStripeSubscriptionError: null, createStripeSubscriptionInProgress: payload };
    case CREATE_STRIPE_SUBSCRIPTION_SUCCESS:
      return { ...state, createStripeSubscriptionInProgress: null };
    case CREATE_STRIPE_SUBSCRIPTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, createStripeSubscriptionError: payload, createStripeSubscriptionInProgress: null };
    case MANAGE_STRIPE_SUBSCRIPTION_REQUEST:
      return { ...state, manageStripeSubscriptionError: null, manageStripeSubscriptionInProgress: true };
    case MANAGE_STRIPE_SUBSCRIPTION_SUCCESS:
      return { ...state, manageStripeSubscriptionInProgress: false };
    case MANAGE_STRIPE_SUBSCRIPTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, manageStripeSubscriptionError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const createStripeSubscriptionRequest = (e) => ({ type: CREATE_STRIPE_SUBSCRIPTION_REQUEST, payload: e });
export const createStripeSubscriptionSuccess = () => ({ type: CREATE_STRIPE_SUBSCRIPTION_SUCCESS });
export const createStripeSubscriptionError = e => ({
  type: CREATE_STRIPE_SUBSCRIPTION_ERROR,
  error: true,
  payload: e,
});

export const manageStripeSubscriptionRequest = () => ({ type: MANAGE_STRIPE_SUBSCRIPTION_REQUEST });
export const manageStripeSubscriptionSuccess = () => ({ type: MANAGE_STRIPE_SUBSCRIPTION_SUCCESS });
export const manageStripeSubscriptionError = e => ({
  type: MANAGE_STRIPE_SUBSCRIPTION_ERROR,
  error: true,
  payload: e,
});
// ================ Thunks ================ //

export const createSubscription = (params) => (dispatch, getState, sdk) => {
  dispatch(createStripeSubscriptionRequest(params));
  dispatch(createExpertListingIfNotExists())

  return createStripeSubscription(params)
    .then(response => {
      if (!!response?.url){
        window.location.href = response.url
      }
      else {
        dispatch(fetchCurrentUser({}))
        .then(response => {
          dispatch(createStripeSubscriptionSuccess(response));
        })
        .catch(e => {
          console.error(e)
        });
      }
      return response;
    })
    .catch((e) => dispatch(createStripeSubscriptionError(e)));
};

export const manageSubscription = (params) => (dispatch, getState, sdk) => {
  dispatch(manageStripeSubscriptionRequest());
  dispatch(createExpertListingIfNotExists())

  return createStripeCustomerPortalSession(params)
    .then(response => {
      if (response !== 'Failed') {
        dispatch(manageStripeSubscriptionSuccess(response));

        window.location.href = response.url;
      } else {
        dispatch(manageStripeSubscriptionError(response))
      }

      return response;
    })
};

export const loadData = (params, search) => dispatch => {
  return Promise.all([
    dispatch(fetchCurrentUser({}))
  ])
};
