import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, string } from 'prop-types';
import { updateAttachments } from '../../util/api';
import { FormattedMessage, injectIntl, intlShape, FormattedDate } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import classNames from 'classnames';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsDeletedEnquiry,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
  isPendingReplyFromCustomer,
  isPendingReplyFromProvider,
  // Lead
  TRANSITION_INITIATE_ORDER,
  TRANSITION_CONFIRM_LEAD_PAYMENT,
  TRANSITION_ACCEPT_PROPOSAL,
  TRANSITION_REJECT_PROPOSAL,
  TRANSITION_ACCEPT_PROPOSAL_PENDING_REPLY,
  TRANSITION_REJECT_PROPOSAL_PENDING_REPLY,
  TRANSITION_DELIVER,
  TRANSITION_SEND_MESSAGE_BY_CUSTOMER,
  TRANSITION_SEND_MESSAGE_BY_PROVIDER,
  TRANSITION_ENQUIRE,
  TRANSITION_VIEW_ACCEPTED_PROPOSAL,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
  ReasonsModal,
  IconClose,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import { SectionOthersProfile } from './SectionOthersProfile';
import { SectionListingDescriptionMaybe } from './SectionListingDescriptionMaybe';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_DELETE_CUSTOMER_ENQUIRY,
  HEADING_DELETE_PROVIDER_ENQUIRY,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
  HEADING_INITIATE_ORDER,
  HEADING_CONFIRM_LEAD_PAYMENT,
  HEADING_ACCEPT_PROPOSAL,
  HEADING_REJECT_PROPOSAL,
} from './PanelHeading';
import { TransactionDataReader } from './TransactionDataReader';

import css from './TransactionPanel.module.css';

const { Money } = sdkTypes;
const LIGHTSHIFT_FEE = 5900;
const MAX_DOCUMENTS = 2;
// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    const currentTransaction = ensureTransaction(props.transaction);
    const attachedDocs = currentTransaction?.attributes?.metadata?.documents || [];
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      isDeclineReasonsModalOpen: false,
      documents: [...attachedDocs],
      uploadInProgress: false,
      currentTransaction,
      currentUser: this.props.currentUser,
      messages:this.props.messages,
      onTransitionTransaction:this.props.onTransitionTransaction,
      transactionRole:this.props.transactionRole
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.handleDeclineSale = this.handleDeclineSale.bind(this);
    this.onFileUploadHandler = this.onFileUploadHandler.bind(this);
    this.onFileRemove = this.onFileRemove.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();

    const {currentTransaction,messages,currentUser, onTransitionTransaction,transactionRole} = this.state;

    const lastTransition = currentTransaction.attributes.transitions.pop();
    const alreadySent = lastTransition?.by === transactionRole;
    const shouldMarkAsRead = messages[0]?.sender?.id?.uuid !== currentUser?.id?.uuid;
    const currentMetaData = currentTransaction.attributes.metadata;

    if (shouldMarkAsRead && !alreadySent) {

      updateAttachments(
        {
          transactionId: currentTransaction.id,
          metadata: {
            ...currentMetaData,
            lastMessageWasRead: true
          }
        }
      )
    }
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);
    const metadata = {
      documents: [...this.state.documents],
    }
    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message, metadata)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  handleDeclineSale(values){
    this.props.onDeclineSale(this.props.transaction.id, values.reason)
    this.setState({
      isDeclineReasonsModalOpen: false
    })
  }

  onFileUploadHandler(e, form, file, field) {
    if (this.state[field].length >= MAX_DOCUMENTS)
      return false;
    const url = 'https://api.cloudinary.com/v1_1/lightshift/upload';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'vbh822gi');
    formData.append('resource_type', 'pdf');
    this.setState({
      uploadInProgress: true,
    });
    fetch(url, { method: 'POST', body: formData })
      .then((response) => response.text())
      .then((data) => {
        const res = JSON.parse(data);
        if (res && res.secure_url) {
          form.change(field, [...this.state[field], res]);
          this.setState({
            [field]: [...this.state[field], res],
            uploadInProgress: false,
          });
          e.target.value = null;
        }
      });
  }

  onFileRemove = (id, documentId) => (e) => {
    e.stopPropagation();
    const stateValues = {
      [id]: this.state[id].filter(document => document.public_id !== documentId),
    };
    this.setState(stateValues);
  };

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      timeSlots,
      fetchTimeSlotsError,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      jobListing,
      fetchProviderOfficeDetailsInProgress,
      fetchProviderOfficeDetailsError,
      providerOfficeDetails,
      totalListingsCount,
      postalCode
    } = this.props;

    const transactionReader = new TransactionDataReader(transaction, intl);
    transactionReader.setTransactionRole(transactionRole);
    transactionReader.setTotalListingCount(totalListingsCount);
    transactionReader.setOfficeDetails(providerOfficeDetails, null, postalCode);

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';
    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const isLead = currentListing?.attributes?.publicData?.listingCategory === 'lead';
    const dateFormatOptions = {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    };

    const attachedDocs = currentTransaction?.attributes?.metadata?.documents || [];

    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (tx.attributes.lastTransition === TRANSITION_INITIATE_ORDER) {
        return {
          headingState: HEADING_INITIATE_ORDER,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (tx.attributes.lastTransition === TRANSITION_CONFIRM_LEAD_PAYMENT) {
        return {
          headingState: HEADING_CONFIRM_LEAD_PAYMENT,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (tx.attributes.lastTransition === TRANSITION_ACCEPT_PROPOSAL || tx.attributes.lastTransition === TRANSITION_ACCEPT_PROPOSAL_PENDING_REPLY) {
        return {
          headingState: HEADING_ACCEPT_PROPOSAL,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (tx.attributes.lastTransition === TRANSITION_REJECT_PROPOSAL || tx.attributes.lastTransition === TRANSITION_REJECT_PROPOSAL_PENDING_REPLY) {
        return {
          headingState: HEADING_REJECT_PROPOSAL,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsDeletedEnquiry(tx)) {
        return {
          headingState: HEADING_ENQUIRED,
          showDetailCardHeadings: isCustomer,
        }
      } else if (isPendingReplyFromCustomer(tx.attributes.lastTransition)) {
        return {
          headingState: HEADING_ENQUIRED,
          showDetailCardHeadings: isCustomer,
        }
      } else if (isPendingReplyFromProvider(tx.attributes.lastTransition)) {
        return {
          headingState: HEADING_ENQUIRED,
          showDetailCardHeadings: isCustomer,
        }
      }
       else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);
    let isAccepted = stateData.headingState === 'accepted' || stateData.headingState === HEADING_ACCEPT_PROPOSAL;
    const isDeletedTransaction = txIsDeletedEnquiry(currentTransaction);
    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    isAccepted |= currentTransaction.attributes.transitions.some(tx => {
      return tx.transition === TRANSITION_ACCEPT_PROPOSAL;
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    // const providerPublicData = currentProvider && currentProvider.attributes && currentProvider.attributes.profile.publicData;
    //const transactionMetaData = currentTransaction?.attributes?.metadata ?? { };
    //const lastMessageWasRead = transactionMetaData?.lastMessageWasRead ?? false;

    //console.log({lastMessageWasRead});

    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const determinePreviewImage = () => {
      const includedImages = jobListing?.included ?? [];
      const listingImages = currentTransaction?.listing?.images ?? [];
      const allImages = [...includedImages, ...listingImages];

      if (allImages.length > 0) {
        return allImages[0]
      }

      return null;
    }

    const firstImage = determinePreviewImage();

    const isExpertListing = transactionReader.isExpert();
    const userId = currentListing?.attributes?.publicData?.userID;
    const documents = this.state.documents;

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptSale(currentTransaction.id)}
        onDeclineSale={() => this.setState({ isDeclineReasonsModalOpen: true })}
      />
    );

    // Industry partner can reply to message if customer has send a private message or accepted the proposal
    const messagesSentByProvider = messages.filter(m => m.sender.id.uuid === currentProvider.id.uuid);
    const isMessageInitializedByProvider = messagesSentByProvider && messagesSentByProvider.length > 0;
    const canSendMessage = isLead ? (
      (isProvider && currentUser.id.uuid === currentProvider.id.uuid) ||
      (isCustomer && currentUser.id.uuid === currentCustomer.id.uuid && isMessageInitializedByProvider)
    ) : true;

    const showSendMessageForm =
      !isDeletedTransaction && !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted && (isAccepted || canSendMessage);
    const showAvatar = isAccepted;

    const sendMessagePlaceholder = isLead ?
      intl.formatMessage(
        { id: 'TransactionPanel.sendMessagePlaceholderLead' }
      ):
      intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' }
      );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    const declineReasons = [
      intl.formatMessage({
        id: 'TransactionPanel.notSatisfiedWithAnswers',
      }),
      intl.formatMessage({
        id: 'TransactionPanel.wrongJobDescription',
      }),
      intl.formatMessage({
        id: 'TransactionPanel.notAvailable',
      }),
    ]

    const rejectLeadProposalReasons = [
      {
        key: '1',
        label: intl.formatMessage({
          id: 'InboxPage.rejectProposalReason1',
        }),
      },
      {
        key: '2',
        label: intl.formatMessage({
          id: 'InboxPage.rejectProposalReason2',
        }),
      },
      {
        key: '3',
        label: intl.formatMessage({
          id: 'InboxPage.rejectProposalReason3',
        }),
      },
      {
        key: '4',
        label: intl.formatMessage({
          id: 'InboxPage.rejectProposalReason4',
        }),
      },
      {
        key: '5',
        label: intl.formatMessage({
          id: 'InboxPage.rejectProposalReason5',
        }),
      },
      {
        key: '6',
        label: intl.formatMessage({
          id: 'InboxPage.rejectProposalReason6',
        }),
      },
    ]

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            {!isExpertListing ? (
              <DetailCardImage
                rootClassName={css.imageWrapperMobile}
                avatarWrapperClassName={css.avatarWrapperMobile}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
              />
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
              isExpertListing={isExpertListing}
              userId={userId}
            />

            {isDeletedTransaction ? (
              <div className={css.note}>
                <FormattedMessage id="TransactionPanel.deletedEquiry" />
              </div>
            ) : null}

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}

            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
              rejectLeadProposalReasons={rejectLeadProposalReasons}
            />
            {showSendMessageForm ? (
              <SendMessageForm
                formId={this.sendMessageFormName}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                inProgress={sendMessageInProgress}
                sendMessageError={sendMessageError}
                onFocus={this.onSendMessageFormFocus}
                onBlur={this.onSendMessageFormBlur}
                onSubmit={this.onMessageSubmit}
                canUploadAttachments={true}
                onFileUploadHandler={this.onFileUploadHandler}
                uploadInProgress={this.state.uploadInProgress}
                intl={intl}
              />
            ) : isAccepted ? (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            ) : null}

            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              {!isExpertListing ? (
                <DetailCardImage
                  avatarWrapperClassName={css.avatarWrapperDesktop}
                  listingTitle={listingTitle}
                  image={firstImage}
                  provider={currentProvider}
                  isCustomer={isCustomer}
                  showAvatar={showAvatar}
                />
              ) : null}

              <SectionListingDescriptionMaybe
                dataReader={transactionReader}
                isManufacturer={isCustomer}
              />

              <SectionOthersProfile dataReader={transactionReader} />

              {documents?.length > 0 ? (
                <div className={css.documentsContainer}>
                  <h2>
                    <FormattedMessage id="OfferForm.documents" />
                  </h2>
                  {documents?.length > 0 ? (
                    <div className={css.uploadedDocuments}>
                      {documents.map((t, index) => {
                        return (
                          <div>
                            <a key={t.public_id} target="_blank" href={t?.secure_url}>
                              {t.original_filename}
                            </a>
                            <div className={css.date}>
                              {intl.formatDate(t.created_at, dateFormatOptions)}
                            </div>
                            {!attachedDocs.includes(t) ? (
                              <div
                                className={css.removeItem}
                                onClick={this.onFileRemove('documents', t.public_id)}
                              >
                                <IconClose size="normal" className={css.removeIcon} />
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              ) : null}

              <BreakdownMaybe
                className={css.breakdownContainer}
                transaction={currentTransaction}
                transactionRole={transactionRole}
              />

              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
        <ReasonsModal
          id="DeclineSaleReasonsModal"
          isOpen={this.state.isDeclineReasonsModalOpen}
          onCloseModal={() => this.setState({ isDeclineReasonsModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmit={this.handleDeclineSale}
          reasons={declineReasons}
          title={intl.formatMessage({
            id: 'TransactionPanel.declineModalTitle',
          })}
          description={intl.formatMessage({
            id: 'TransactionPanel.declineModalDescription',
          })}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
