import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFile.module.css';

const IconFile = props => {
  const { rootClassName, className, pencilClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="752" height="752" version="1.1" viewBox="0 0 752 752" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m253.45 480.69 95.914-171c3.1797-6.5625 3.707-14.098 1.4688-21.039-2.2422-6.9414-7.0742-12.746-13.492-16.211-6.832-4.1641-15.047-5.4141-22.809-3.4688-6.3672 1.6328-11.797 5.7891-15.035 11.512l-91.344 162.86c-1.0117 1.7148-1.2891 3.7617-0.76953 5.6875 0.51563 1.9219 1.7891 3.5547 3.5234 4.5273 1.7383 0.97266 3.793 1.207 5.7031 0.64453 1.9102-0.55859 3.5156-1.8672 4.4492-3.625l91.305-162.85c1.3008-2.2305 3.4531-3.8359 5.9648-4.4414 3.9023-0.91406 8.0117-0.23047 11.41 1.8945 6.375 3.1602 9.2695 10.676 6.6602 17.293l-95.855 170.99c-3.5742 6.2109-9.5195 10.703-16.473 12.445-9.7344 2.3438-20.004 0.69141-28.512-4.5898-16.961-10.078-24.145-30.07-16.02-44.559l100.44-179.15c5.8438-10.195 15.586-17.574 26.984-20.438 15.562-3.7656 31.984-1.1445 45.605 7.2734 27.141 16.141 38.523 48.363 25.375 71.836l-73.051 130.29c-1.9883 3.5625-0.71875 8.0625 2.8398 10.059 3.5547 1.9961 8.0586 0.73828 10.066-2.8164l73.051-130.29c17.02-30.414 3.2617-71.594-30.715-91.758h-0.003906c-17.039-10.461-37.562-13.66-56.977-8.8789-15.254 3.8789-28.273 13.801-36.059 27.484l-100.46 179.14c-12.016 21.461-2.4336 50.383 21.363 64.527 8.332 5.0156 17.855 7.6953 27.578 7.7617 4.1602 0.007813 8.3047-0.52734 12.32-1.5977 10.816-2.7578 20.043-9.8008 25.555-19.508z"/>
        <path d="m588.37 230.78-75.629-74.641c-1.3828-1.3594-3.2422-2.125-5.1797-2.1289h-245.84c-6.9414-0.027344-13.609 2.7031-18.543 7.5859-4.9297 4.8828-7.7266 11.523-7.7695 18.469v35.363c0 4.0859 3.3125 7.3984 7.4023 7.3984 4.0859 0 7.3984-3.3125 7.3984-7.3984v-35.363c0.082031-6.2852 5.2305-11.316 11.512-11.258h242.79l71.281 70.336v332.79c-0.082031 6.2852-5.2305 11.316-11.512 11.254h-302.56c-6.2812 0.0625-11.43-4.9688-11.512-11.254v-41.727c0-4.0859-3.3125-7.3984-7.3984-7.3984-4.0898 0-7.4023 3.3125-7.4023 7.3984v41.727c0.042969 6.9414 2.8398 13.582 7.7695 18.469 4.9336 4.8828 11.602 7.6133 18.543 7.5859h302.56c6.9414 0.027343 13.609-2.7031 18.543-7.5859 4.9297-4.8867 7.7266-11.527 7.7695-18.469v-335.89c-0.003906-1.9844-0.80469-3.8789-2.2227-5.2695z"/>
        <path d="m487 183.61c-4.0859 0-7.3984 3.3125-7.3984 7.3984v59.199c0 1.9609 0.77734 3.8438 2.168 5.2305 1.3867 1.3906 3.2695 2.168 5.2305 2.168h59.199c4.0859 0 7.3984-3.3125 7.3984-7.3984 0-4.0859-3.3125-7.3984-7.3984-7.3984h-51.797v-51.801c0-1.9609-0.78125-3.8438-2.168-5.2305-1.3906-1.3867-3.2695-2.168-5.2344-2.168z"/>
        <path d="m420.4 353.8c0 1.9648 0.78125 3.8477 2.168 5.2344 1.3867 1.3867 3.2695 2.168 5.2344 2.168h88.797c4.0859 0 7.3984-3.3125 7.3984-7.4023 0-4.0859-3.3125-7.3984-7.3984-7.3984h-88.797c-4.0898 0-7.4023 3.3125-7.4023 7.3984z"/>
        <path d="m376 427.8c0 1.9609 0.78125 3.8438 2.168 5.2305s3.2695 2.168 5.2344 2.168h133.2c4.0859 0 7.3984-3.3125 7.3984-7.3984 0-4.0898-3.3125-7.4023-7.3984-7.4023h-133.2c-4.0898 0-7.4023 3.3125-7.4023 7.4023z"/>
        <path d="m516.6 494.4h-207.2c-4.0859 0-7.3984 3.3125-7.3984 7.3984s3.3125 7.3984 7.3984 7.3984h207.2c4.0859 0 7.3984-3.3125 7.3984-7.3984s-3.3125-7.3984-7.3984-7.3984z"/>
    </g>
    </svg>
  );
};

IconFile.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconFile.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconFile;
