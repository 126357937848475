import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from './../../util/reactIntl';
import {getLanguageFromNavigatorOrDefault, changeUILanguage} from '../../ducks/UI.duck';

class ConnectedIntlProvider extends Component {
  componentDidMount() {
      /**
       * Change the language to browsers default one, when language not changed so far
       */
      if(!this.props.languageChanged) {
        // detect language
        const detectedLanguage = getLanguageFromNavigatorOrDefault();
        // change language to detected language
        //-this.props.switchLanguage(detectedLanguage, false);
      }
  }

  shouldComponentUpdate(nextProps, prevState) {
    // render component when newlanguage differs from current language
    if(nextProps.lang) {
      return nextProps.lang !== this.props.lang;
    }
    return false;
  }

  render() {
    return <IntlProvider locale={this.props.lang} messages={this.props.messages}>{this.props.children}</IntlProvider>
  }
}

function mapStateToProps(state) {
  const { lang, messages, languageChanged } = state.UI;
  return { lang, messages, languageChanged };
}

function mapDispatchToProps(dispatch) {
  return {
    switchLanguage: (lang, changedByUser) => dispatch(changeUILanguage(lang, changedByUser))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedIntlProvider);
