import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconDoneJob.module.css';

const IconDoneJob = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg version="1.1" width="45px" height="45px" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.3,28.2l-7.1-7.1l-1.4,1.4l8.5,8.5l14.9-14.9l-1.4-1.4L19.3,28.2z M22.5,0C10.1,0,0,10.1,0,22.5  S10.1,45,22.5,45S45,34.9,45,22.5S34.9,0,22.5,0z M22.5,43C11.2,43,2,33.8,2,22.5S11.2,2,22.5,2S43,11.2,43,22.5S33.8,43,22.5,43z"/>
    </svg>
  );
};

IconDoneJob.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconDoneJob.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconDoneJob;
