import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconTargetFlag.module.css';

const IconTargetFlag = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="45px" height="45px" viewBox="0 0 45 45" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1v43h2V25h32V1H0zm32 12h-6v5h6v5h-6v-5h-6v5h-6v-5H8v5H2v-5h6v-5H2V8h6V3h6v5h6V3h6v5h6v5z"/>
      <path d="M14 13h6v5h-6z"/>
      <path d="M8 8h6v5H8zm12 0h6v5h-6z"/>
    </svg>
  );
};

IconTargetFlag.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconTargetFlag.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconTargetFlag;
