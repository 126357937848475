import { useIntl } from 'react-intl';
import { useTranslation } from '../../hooks';
import { Modal } from '../index';
import css from './Subscription.module.css';

export const MoneyBackModal = ({ isOpen, onClose }) => {
  const intl = useIntl();
  const setTranslations = useTranslation('MoneyBackModal');

  const email = setTranslations('email');

  return (
    <Modal
      id={'moneyBackGuarantee'}
      intl={intl}
      onManageDisableScrolling={() => false}
      onClose={onClose}
      isOpen={isOpen}
      className={css.moneyBackGuarantee}
    >
      <h1>{setTranslations('title')}</h1>

      <h2>{setTranslations('conditions')}</h2>
      <ul>
        <li>{setTranslations('conditionsLine1')}</li>
        <li>{setTranslations('conditionsLine2')}</li>
        <li>{setTranslations('conditionsLine3')}</li>
        <li>{setTranslations('conditionsLine4')}</li>
        <li>{setTranslations('conditionsLine5')}</li>
        <li>{setTranslations('conditionsLine6')}</li>
        <li>{setTranslations('conditionsLine7')}</li>
        <li>{setTranslations('conditionsLine8')}</li>
      </ul>

      <h2>{setTranslations('procedure')}</h2>
      <ul>
        <li>
          {setTranslations('procedureLine1', {
            email: <a href={`mailto:${email}`}>{email}</a>,
          })}
          <ul>
            <li>{setTranslations('procedureLine1a')}</li>
            <li>{setTranslations('procedureLine1b')}</li>
          </ul>
        </li>
        <li>{setTranslations('procedureLine2')}</li>
        <li>{setTranslations('procedureLine3')}</li>
        <li>{setTranslations('procedureLine4')}</li>
      </ul>
    </Modal>
  );
};
