import { useSelector } from 'react-redux';
import { FieldCurrencyInput } from '../../components';
import config from '../../config';
import { selectCurrentUserSubscription } from '../../ducks/user.duck';
import { useStripeCountryDetection, useTranslation } from '../../hooks';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './ProfileSkillsForm.module.css';

const { Money } = sdkTypes;

export const SectionHourlyRate = () => {
  // const [isStripeConnected] = useStripeCountryDetection();
  const subscription = useSelector(selectCurrentUserSubscription);
  const setTranslation = useTranslation('ProfileBConsForm');

  // if (!isStripeConnected) {
  //   return null;
  // }

  const isGoldSubscription = subscription === 'gold';
  const isSilverSubscription = subscription === 'silver';
  const isPayedSubscription = isGoldSubscription || isSilverSubscription;

  if (!isPayedSubscription) {
    return null;
  }

  const hourlyRateMessage = setTranslation('hourlyRateLabel');
  const hourlyRatePlaceholderMessage = setTranslation('hourlyRateLabel');

  return (
    isPayedSubscription && (
      <div className={css.hourlyRate}>
        <FieldCurrencyInput
          id="hourlyRate"
          name="hourlyRate"
          className={css.sectionContainer}
          label={hourlyRateMessage}
          placeholder={hourlyRatePlaceholderMessage}
          currencyConfig={config.currencyConfig}
        />
      </div>
    )
  );
};

SectionHourlyRate.defaultProps = {};
SectionHourlyRate.propTypes = {};
