import { useSelector } from 'react-redux';
import {
  Button,
  IconInfo, NamedLink,
} from '../../components';
import { selectCurrentUserSubscription } from '../../ducks/user.duck';
import { useTranslation } from '../../hooks';
import css from './ProfileSkillsForm.module.css';

export const SilverSubscriptionInformation = () => {
  const subscription = useSelector(selectCurrentUserSubscription);
  const setTranslation = useTranslation('ProfileSkillsPage');

  if (subscription !== 'silver') {
    return null;
  }


  const infos = [
    setTranslation('subscriptionDescription5'),
    setTranslation('subscriptionDescription6'),
  ];

  return (
    <div className={css.subscriptionInformation}>
      <div className={css.iconTipGrid}>
        <IconInfo className={css.iconInfo}/>
        <div className={css.subscriptionTip}>
          {setTranslation('subscriptionTipSilver')}
        </div>
      </div>
      <ul className={css.subscriptionInformationGridBoxSilver}>
        {infos.map((info, idx) =>
          <li key={idx} className={css.subscriptionInformationListItem}>{info}</li>,
        )}
      </ul>
      <NamedLink name="SubscriptionPage" className={css.upgradeButton}>
        {setTranslation('upgradeButtonText')}
      </NamedLink>
    </div>
  );
};
