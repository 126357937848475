import React from 'react';
import css from './PriceList.module.css';

export function PriceListCard({ card }) {
  return (
    <div  className={css.card}>
      <div className={css.cardImage}>
        {card.picture}
      </div>
      <h3 className={css.cardTitle}>{card.title}</h3>
      <p className={css.cardContent}>{card.content}</p>
    </div>
  );
}

PriceListCard.defaultProps = {};
PriceListCard.propTypes = {};
