import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '..';
import moment from "moment-timezone";
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './LeadCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const { Money } = sdkTypes;
const LIGHTSHIFT_FEE = 5900;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'LeadCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'LeadCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const LeadCardComponent = props => {
  const { className, rootClassName, intl, listing, renderSizes, setActiveListing } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '' } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const publicData = currentListing.attributes.publicData;
  const listingEndDateUTC = publicData.applicationDeadline || '';
  const isOutdoor = publicData && publicData.outdoor ? true : false;
  const isIndoor = publicData && publicData.indoor ? true : false;
  const country = publicData && publicData.country ? publicData.country : null;
  const zipCode2Digits = publicData && publicData.postcode ? publicData.postcode.slice(0,2) : null;

  const zipcodePlusCountry = zipCode2Digits && country ? zipCode2Digits + ', ' + country : zipCode2Digits && !country ? zipCode2Digits : country;

  let indoorOutdoorInfo = null;
  if(isOutdoor && !isIndoor){
    indoorOutdoorInfo = <FormattedMessage id="LeadCard.outdoorInfo"/>;
  }
  else if(isIndoor && !isOutdoor){
    indoorOutdoorInfo = <FormattedMessage id="LeadCard.indoorInfo"/>;
  }
  else if(isIndoor && isOutdoor){
    indoorOutdoorInfo = <FormattedMessage id="LeadCard.indoorOutdoorInfo"/>;
  }

    //Timezone and format - listingEndDate
    const format = {
      month: 'short',
      day: 'numeric',
    };

    const LTZ = moment.tz.guess();
    const listingEndDateLTZ = moment.tz(listingEndDateUTC, LTZ).format();
    const formattedApplicationDeadline = intl.formatDate(listingEndDateLTZ, format);

    const applicationDeadlineString = intl.formatMessage(
      { id: 'LeadCard.applicationDeadline' },
      {
        dates: `${formattedApplicationDeadline}`,
      }
    )

  const lightshiftFee = formatMoney(intl, new Money(LIGHTSHIFT_FEE, config.currency))

  const priceCssClass = config.isFreeLeadPeriod
    ? css.priceZero
    : css.price;

  return (
  <div className={classes}>
    <NamedLink className={css.link} name="LeadPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.indoorOutdoorInfo}>
          {indoorOutdoorInfo}
        </div>
        <div className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </div>
        <div className={css.zipCountry}>
          <span>{zipcodePlusCountry}</span>
        </div>
      </div>
      <div className={css.twoColumns}>
        <div className={css.listingEndDate}>
          {applicationDeadlineString}
        </div>
      </div>
    </NamedLink>
    <div className={css.twoColumns}>
      <NamedLink className={css.connectLink} name="LeadPage" params={{ id, slug }}>
        <FormattedMessage id="LeadCard.details" values={{ authorName }}/>
      </NamedLink>
      <span className={priceCssClass}><i>{lightshiftFee}</i></span>
    </div>

    </div>
  );
};

LeadCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

LeadCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(LeadCardComponent);
