import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { Form, Button, FieldRadioButton, FieldDateInput, FieldTimeZoneSelect, AvatarLarge } from '../../components';
import moment from 'moment';

import css from './EditApplicationDeadlineForm.module.css';
const identity = v => v;
const dateFormatOptions = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
};
const EditApplicationDeadlineFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        form,
        formId,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        isDirectConnect,
        expertAuthor,
        directConnectInfo,
        values
      } = formRenderProps;

      const isPrivate = values.jobTypeGroup === 'private' ? true : false;
      const isLead = values.isLead;
      const jobTypeLabel = intl.formatMessage({
        id: 'EditApplicationDeadlineForm.jobTypeLabel',
      });

      const publicLabel = intl.formatMessage({
        id: 'EditApplicationDeadlineForm.publicLabel',
      });

      const privateLabel = intl.formatMessage({
        id: 'EditApplicationDeadlineForm.privateLabel',
      });

      const offerDeadlineLabel = intl.formatMessage({
        id: 'EditApplicationDeadlineForm.offerDeadlineLabel',
      });

      const offerDeadlineRequiredMessage = intl.formatMessage({
        id: 'EditApplicationDeadlineForm.offerDeadlineRequiredMessage',
      });

      const jobTypeTip = isPrivate ? (intl.formatMessage({
        id: 'EditApplicationDeadlineForm.privateTip',
      })) :
      (intl.formatMessage({
        id: 'EditApplicationDeadlineForm.publicTip',
      }));

      const showAsRequired = pristine;
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditApplicationDeadlineForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditApplicationDeadlineForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const dateToChange = isLead ? values.applicationDeadlineChanged : values.listingEndDate;
      const today = moment().startOf('day').toDate();
      const dateToChangeFormatted = moment().startOf('day').toDate();
      const offerDeadlinePlaceholder = intl.formatDate(dateToChangeFormatted, dateFormatOptions);

      const fieldDateInputName = isLead ? "applicationDeadlineChanged" : "listingEndDate";
      const fieldDateInputId =  isLead ? `tenderForm.applicationDeadline`: `tenderForm.listingEndDate`;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <div className={css.offerDeadlineWrapper}>
            <h2>{offerDeadlineLabel}</h2>

            <FieldDateInput
              initialDate={dateToChange || today}
              className={css.bookingDates}
              name={fieldDateInputName}
              id={fieldDateInputId}
              placeholderText={offerDeadlinePlaceholder}
              format={identity}
              useMobileMargins
              validate={required(offerDeadlineRequiredMessage)}
              disabled={false}
            />
          </div>
          <div>
          <FormattedMessage id="EditApplicationDeadlineForm.deadlineTip" />
          </div>
          {
            !isLead ? (
            <div>
              <h2>{jobTypeLabel}</h2>
              <div className={css.twoColumns}>
                <FieldRadioButton
                  className={css.public}
                  id="public"
                  name="jobTypeGroup"
                  label={publicLabel}
                  value="public"
                  showAsRequired={showAsRequired}
                />
                <FieldRadioButton
                  className={css.private}
                  id="private"
                  name="jobTypeGroup"
                  label={privateLabel}
                  value="private"
                  showAsRequired={showAsRequired}
                />
              </div>
              <p className={css.tip}>{jobTypeTip}</p>
            </div>) : null
          }
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditApplicationDeadlineFormComponent.defaultProps = { className: null, fetchErrors: null };

EditApplicationDeadlineFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditApplicationDeadlineFormComponent);
