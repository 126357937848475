import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import css from './Accordion.module.css';
import { FieldCheckboxGroup } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { ignoreNextOnError } from '@sentry/browser/dist/helpers';


const AccordionComponent = props => {
  const {items, setFocus, setHighlight, sortByChecked, clearAll, selectAll, form, clearSelectButtons} = props;

const getCount = (currentCount, key) => {
  if (currentCount > 0){
    return (
        <div className={css.countVisible} key={`${key}.count`}>
          <span>{currentCount}</span>
        </div>
    );
  }
  else {
    return (
      <div className={css.countUnVisible} key={`${key}.count`}></div>
    );
  }}

  return (
    <Accordion
      className = {css.accordion}
      allowZeroExpanded= {true}>
      {items.map(item =>

        <AccordionItem
        className = {css.accordion__item}
        key = {item.id}
      >
        <AccordionItemHeading>
            <AccordionItemButton
              className={css.accordion__button}
              key = {`${item.config.id}.accordionItemButton`}
            >
              {getCount(item.currentCount, item.config.id)}
              <div className={css.headline}>
                <span className = {css.headline} key = {`${item.config.id}.headline`} type='headline'>{item.label}</span>
              </div>
            </AccordionItemButton>
        </AccordionItemHeading>
        { clearSelectButtons ? (
          <AccordionItemPanel
            className={css.accordion__panel}
            >
            <button className={css.selectAllButton} type="button" onClick ={e => selectAll(e, item.id, item.config.options, form)}>
              <span className={css.selectAllButtonText}><FormattedMessage id='AccordionComponent.selectAll'/></span>
            </button>
            <button className={css.selectAllButton} type="button" onClick ={e => clearAll(e, item.id, form)}>
              <span className={css.selectAllButtonText}><FormattedMessage id='AccordionComponent.clearAll'/></span>
            </button>
          </AccordionItemPanel>) : null
        }
          <AccordionItemPanel
            className={css.accordion__panel}
          >
            <FieldCheckboxGroup
              setFocus={setFocus}
              setHighlight={setHighlight}
              setAccordion={true}
              sortByChecked={false}
              className={css.serviceField}
              key={item.id}
              id={item.id}
              name={item.id}
              options={item.config.options}
              type='checkbox'
              label=''
            />
          </AccordionItemPanel>
      </AccordionItem>
        )}

    </Accordion>
  )
};

export default AccordionComponent;
