import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFlag.module.css';

const IconFlag = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="45px" height="45px" viewBox="0 0 45 45" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M34 1h-2H2 0v2 21 2 18h2V26h30 2v-2V3 1h0zM2 24V3h30v21H2h0z"
        fillRule="evenodd"
      />
    </svg>
  );
};

IconFlag.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconFlag.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconFlag;
