import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';

import css from './Overlay.module.css';

const Overlay = props => {
  const { className, rootClassName, message, errorMessage, children, icon } = props;

  const classes = classNames(rootClassName || css.root, className);

  const messageIconCombination = (
    <div className={css.rowContent}>
      <span className={css.icon}>{icon}</span>
      <span className={css.messageRow}>{message}</span> 
    </div>
  )

  return (
    <div
      className={classes}
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <div className={css.overlay} />
      <div className={css.overlayContent}>
        {message && icon ? messageIconCombination : null}
        {errorMessage && !icon ? <div className={css.errorMessage}>{errorMessage}</div> : null}
        {message && !icon ? <div className={css.message}>{message}</div> : null}
        {icon && !message ? <div className={css.icon}>{icon}</div> : null}
        {children}
      </div>
    </div>
  );
};

Overlay.defaultProps = {
  className: null,
  rootClassName: null,
  message: null,
  errorMessage: null,
  children: null,
};

Overlay.propTypes = {
  className: string,
  rootClassName: string,
  message: string,
  errorMessage: string,
  children: node,
};

export default Overlay;
