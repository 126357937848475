import isEmpty from 'lodash/isEmpty';
import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import { createUserWithIdp, apiBaseUrl, logoutDialux } from '../util/api';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import { findOptionsForSelectFilter } from '../util/search';
import config from '../config';
import { denormalisedResponseEntities } from '../util/data';

const authenticated = (authInfo) => authInfo && authInfo.isAnonymous === false;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';

export const LOGOUT_DIALUX_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_DIALUX_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_DIALUX_ERROR = 'app/Auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

export const CONFIRM_REQUEST = 'app/Auth/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'app/Auth/CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'app/Auth/CONFIRM_ERROR';

export const SEND_STRIPE_CUSTOMER_REQUEST = 'app/Auth/CREATE_STRIPE_CUSTOMER_REQUEST';
export const SEND_STRIPE_CUSTOMER_SUCCESS = 'app/Auth/CREATE_STRIPE_CUSTOMER_SUCCESS';
export const SEND_STRIPE_CUSTOMER_ERROR = 'app/Auth/CREATE_STRIPE_CUSTOMER_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  logoutDialuxError: null,
  logoutDialuxInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,

  // confirm (create use with idp)
  confirmError: null,
  confirmInProgress: false,

  // create stripe customer
  sendStripeCustomerInProgress: false,
  sendStripeCustomerError: null,
  submittedCustomer: {},
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        authScopes: payload.scopes,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false, authScopes: [] };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case LOGOUT_DIALUX_REQUEST:
      return { ...state, logoutDialuxInProgress: true, logoutDialuxError: null };
    case LOGOUT_DIALUX_SUCCESS:
      return { ...state, logoutDialuxInProgress: false };
    case LOGOUT_DIALUX_ERROR:
      return { ...state, logoutInProgress: false, logoutDialuxError: payload };

    case SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    case CONFIRM_REQUEST:
      return { ...state, confirmInProgress: true, loginError: null, confirmError: null };
    case CONFIRM_SUCCESS:
      return { ...state, confirmInProgress: false, isAuthenticated: true };
    case CONFIRM_ERROR:
      return { ...state, confirmInProgress: false, confirmError: payload };
    case SEND_STRIPE_CUSTOMER_REQUEST:
      return {
        ...state,
        sendStripeCustomerInProgress: true,
        sendStripeCustomerError: null,
      };
    case SEND_STRIPE_CUSTOMER_SUCCESS:
      return {
        ...state,
        sendStripeCustomerInProgress: false,
        submittedCustomer: payload,
      };
    case SEND_STRIPE_CUSTOMER_ERROR:
      return {
        ...state,
        sendStripeCustomerInProgress: false,
        sendStripeCustomerError: payload,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = (state) => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.Auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = (info) => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = (error) => ({ type: LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = (error) => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const logoutDialuxRequest = () => ({ type: LOGOUT_DIALUX_REQUEST });
export const logoutDialuxSuccess = () => ({ type: LOGOUT_DIALUX_SUCCESS });
export const logoutDialuxError = (error) => ({
  type: LOGOUT_DIALUX_ERROR,
  payload: error,
  error: true,
});

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = (error) => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const confirmRequest = () => ({ type: CONFIRM_REQUEST });
export const confirmSuccess = () => ({ type: CONFIRM_SUCCESS });
export const confirmError = (error) => ({ type: CONFIRM_ERROR, payload: error, error: true });

export const sendStripeCustomerRequest = () => ({ type: SEND_STRIPE_CUSTOMER_REQUEST });
export const sendStripeCustomerSuccess = () => ({ type: SEND_STRIPE_CUSTOMER_SUCCESS });
export const sendStripeCustomerError = (e) => ({
  type: SEND_STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const userLogout = () => ({ type: USER_LOGOUT });

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then((info) => {
      dispatch(authInfoSuccess(info));
    })
    .catch((e) => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

export const login = (username, password) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  return sdk
    .login({ username, password })
    .then(() => dispatch(loginSuccess()))
    .then(() => dispatch(fetchCurrentUser()))
    .catch((e) => dispatch(loginError(storableError(e))));
};

export const logout = () => (dispatch, getState, sdk) => {
  const baseUrl = apiBaseUrl();
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }

  dispatch(logoutDialuxRequest());

  logoutDialux()
    .then(() => {
      dispatch(logoutDialuxSuccess());
    })
    .catch((error) => {
      dispatch(logoutDialuxError(error));
    });

  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // The order of the dispatched actions
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      log.clearUserId();
      dispatch(userLogout());
      //window.location.href = `${baseUrl}/api/auth/dialux/logout`;
    })
    .catch((e) => dispatch(logoutError(storableError(e))));
};

export const signup = (params) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());
  const { email, password, firstName, lastName, agbCheckbox, language, country, ...rest } = params;
  const germanLanguage = ['DE', 'AT', 'CHE'];

  const name = firstName + ' ' + lastName;
  const timestamp = new Date().toISOString();
  const agbVersion = '01042023';
  const agbChecked = { Version: agbVersion, Date: timestamp };
  /*const dseVersion = '05052021';
  const dseChecked = {Version: dseVersion, Date: timestamp}
  */

  const countryOptions = findOptionsForSelectFilter('country', config.profileConfig.filters);
  const countryFiltered = countryOptions.filter((countryOption) => countryOption.key === country);
  const countryCode = countryFiltered[0].code;

  const isGerman = germanLanguage.includes(countryCode);
  const platformLanguage = isGerman ? 'de' : 'en';

  const createUserParams = {
    email,
    password,
    firstName,
    lastName,
    privateData: { agbChecked, language, countryCode, platformLanguage },
    protectedData: { firstName, lastName },
    publicData: { country, ...rest },
  };
  // We must login the user if signup succeeds since the API doesn't
  // do that automatically.
  return sdk.currentUser
    .create(createUserParams)
    .then(() => dispatch(signupSuccess()))
    .then(() => dispatch(login(email, password)))
    .then(() => dispatch(sendStripeCustomer({ name, email, countryCode })))
    .catch((e) => {
      dispatch(signupError(storableError(e)));
      log.error(e, 'signup-failed', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
      });
    });
};

export const signupWithIdp = (params) => (dispatch, getState, sdk) => {
  dispatch(confirmRequest());
  return createUserWithIdp(params)
    .then(() => {
      return dispatch(confirmSuccess());
    })
    .then(() => dispatch(fetchCurrentUser()))
    .then(() => {
      sdk.currentUser.show().then((response) => {
        const email = response.data.data.attributes.email;
        const firstName = response.data.data.attributes.profile.firstName;
        const lastName = response.data.data.attributes.profile.lastName;
        const name = firstName + ' ' + lastName;
        const country = response.data.data.attributes.profile.protectedData.country;
        const countryOptions = findOptionsForSelectFilter('country', config.profileConfig.filters);
        const countryFiltered = countryOptions.filter(
          (countryOption) => countryOption.key === country
        );
        const countryCode = countryFiltered[0].code;
        console.log('send stripe customer');
        dispatch(sendStripeCustomer({ name, email, countryCode }));
      });
    })
    .catch((e) => {
      log.error(e, 'create-user-with-idp-failed', { params });
      return dispatch(confirmError(storableError(e)));
    });
};

export const sendStripeCustomer =
  ({ email }) =>
  (dispatch, getState, sdk) => {
    dispatch(sendStripeCustomerRequest());
    return sdk.stripeCustomer
      .create({ stripeCustomerEmail: email }, { expand: true, include: ['defaultPaymentMethod'] })
      .then((response) => {
        const stripeCustomer = response.data.data;
        dispatch(sendStripeCustomerSuccess(stripeCustomer));
        dispatch(saveStripeCustomerIdRequest(response.data));
        return stripeCustomer;
      })
      .catch((e) => {
        log.error(storableError(e), 'create-stripe-user-failed');
        dispatch(sendStripeCustomerError(storableError(e)));
      });
  };

const saveStripeCustomerIdRequest = (params) => (dispatch, getState, sdk) => {
  const { stripeCustomerId } = params.data.attributes;
  return sdk.currentUser
    .updateProfile(
      { privateData: { stripeCustomerId: stripeCustomerId } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then((response) => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch((e) => {
      dispatch(sendStripeCustomerError(storableError(e)));
      // pass the same error so that the SAVE_BCONS_SUCCESS
      // action will not be fired
      throw e;
    });
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
// Load data checks if a token from DIALux evo has been
export const loadData = params => (dispatch, getState) => {
  if (!hasToken)
    return

  dispatch();


  return Promise.all([

  ]);
};
