import { useIntl } from 'react-intl';

export const useTranslation = (group) => (key, values = undefined) => {
  const intl = useIntl();

  if (typeof key === 'object') {
    return intl.formatMessage(key);
  } else {
    const isPrefixed = key.indexOf('.') > 0;
    let messageId = key;

    if (!isPrefixed) {
      messageId = `${group}.${key}`;
    }

    if (!values) {
      return intl.formatMessage({id: messageId});
    } else {
      return intl.formatMessage({id: messageId}, values);
    }
  }
};
