import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form } from '../../components';

import css from './EditListingLanguageForm.module.css';

const required = value => {
  // Intl Message checkbox required Message
  const errors = {};
  if (value.language.length === 0) {
    errors.emptyForm = 'EditListingLanguageForm.checkboxRequired';
  }
  return errors;
};

export const EditListingLanguageFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    validate={required}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        touched,
      } = formRenderProps;


      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id='EditListingLanguageForm.updateFailed' />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id='EditListingLanguageForm.showListingFailed' />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || formRenderProps.errors.invalid;
      const isAnyTouched = Object.values(touched).includes(true);
      const messageEmptyForm = (formRenderProps.errors.emptyForm && isAnyTouched) ? intl.formatMessage({ id: formRenderProps.errors.emptyForm }) :
        <span>&nbsp;</span>;


      const languageOptions = findOptionsForSelectFilter('language', filterConfig);

      // Intl Message language checkbox
      const languageLabel = intl.formatMessage({
        id: 'EditListingLanguageForm.languageLabel',
      });

      return (
        <Form
          className={classes}
          onSubmit={handleSubmit}
        >
          {errorMessage}
          {errorMessageShowListing}
          <div>
            <p className={css.tooltip}>{languageLabel}</p>
            <FieldCheckboxGroup
              setFocus={true}
              className={css.policy}
              id='language'
              name='language'
              options={languageOptions}
              label=''
            />
          </div>
          <div className={css.error}>
            {messageEmptyForm}
          </div>

          <Button
            className={css.submitButton}
            type='submit'
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingLanguageFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
  filterConfig: config.custom.filters,
};

EditListingLanguageFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingLanguageFormComponent);
