import React, { Component } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
  emailFormatValid,
  phoneNumberFormatValid,
  required,
} from '../../util/validators';
import {
  Button,
  FieldRadioButton,
  FieldSelect,
  FieldTextInput,
  Form,
  LocationAutocompleteInputField,
  PhoneNumberInput,
} from '../../components';

import css from './EditListingLocationForm.module.css';

const identity = v => v;

class EditListingLocationFormComponent extends Component {
  constructor(props) {
    super(props);


  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            className,
            formId,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
            values,
            errors,
          } = formRenderProps;

          const { phoneNumber } = values;

          const onSiteLabel = intl.formatMessage({
            id: 'EditListingLocationForm.onSiteLabel',
          });

          const remoteLabel = intl.formatMessage({
            id: 'EditListingLocationForm.remoteLabel',
          });

          const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
          const addressPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressPlaceholder',
          });
          const addressRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressRequired',
          });
          const addressNotRecognizedMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressNotRecognized',
          });

          const optionalText = intl.formatMessage({
            id: 'EditListingLocationForm.optionalText',
          });

          const buildingMessage = intl.formatMessage(
            { id: 'EditListingLocationForm.building' },
            { optionalText: optionalText },
          );
          const buildingPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.buildingPlaceholder',
          });

          const { updateListingError, showListingsError } = fetchErrors || {};
          const errorMessage = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id='EditListingLocationForm.updateFailed' />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id='EditListingLocationForm.showListingFailed' />
            </p>
          ) : null;

          const countryLabel = intl.formatMessage({
            id: 'ProfileAddressForm.countryLabel',
          });
          const countryPlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.countryPlaceholder',
          });
          const countryRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.countryRequired',
          });
          const invalidCountryMessage = intl.formatMessage({
            id: 'ProfileAddressForm.invalidCountryMessage',
          });
          const countryRequired = required(countryRequiredMessage);
          // company
          const companyLabel = intl.formatMessage({
            id: 'ProfileAddressForm.companyLabel',
          });
          const companyPlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.companyPlaceholder',
          });
          const companyRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.companyRequired',
          });
          const companyRequired = required(companyRequiredMessage);

          // firstName
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileAddressForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.firstNamePlaceholder',
          });
          const firstnameRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.firstnameRequired',
          });
          const firstnameRequired = required(firstnameRequiredMessage);


          // lastName
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileAddressForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.lastNamePlaceholder',
          });
          const lastnameRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.lastnameRequired',
          });
          const lastnameRequired = required(lastnameRequiredMessage);

          // street
          const streetLabel = intl.formatMessage({
            id: 'ProfileAddressForm.streetLabel',
          });
          const streetPlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.streetPlaceholder',
          });
          const streetRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.streetRequired',
          });
          const streetRequired = required(streetRequiredMessage);

          // house number
          const houseNumberLabel = intl.formatMessage({
            id: 'ProfileAddressForm.houseNumberLabel',
          });
          const houseNumberPlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.houseNumberPlaceholder',
          });
          const houseNumberRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.houseNumberRequired',
          });
          const houseNumberRequired = required(houseNumberRequiredMessage);

          // postal code
          const postalCodeLabel = intl.formatMessage({
            id: 'ProfileAddressForm.postalCodeLabel',
          });
          const postalCodePlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.postalCodePlaceholder',
          });
          const postalCodeRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.postalCodeRequired',
          });
          const postalCodeRequired = required(postalCodeRequiredMessage);


          // city
          const cityLabel = intl.formatMessage({
            id: 'ProfileAddressForm.cityLabel',
          });
          const cityPlaceholder = intl.formatMessage({
            id: 'ProfileAddressForm.cityPlaceholder',
          });
          const cityRequiredMessage = intl.formatMessage({
            id: 'ProfileAddressForm.cityRequired',
          });
          const cityRequired = required(cityRequiredMessage);

          const emailLabel = intl.formatMessage({
            id: 'ContactDetailsForm.emailLabel',
          });

          const emailRequiredMessage = intl.formatMessage({
            id: 'ContactDetailsForm.emailRequired',
          });

          const emailRequired = required(emailRequiredMessage);
          const emailInvalidMessage = intl.formatMessage({
            id: 'ContactDetailsForm.emailInvalid',
          });
          const emailValid = emailFormatValid(emailInvalidMessage);

          const emailPlaceholder = '';

          let phoneNumberInfo = <span className={css.errorPhone}>&nbsp;</span>;
          if (errors?.officeDetails?.phoneNumber !== undefined && pristine === false) {
            phoneNumberInfo = (
              <span className={css.errorPhone}>
            <FormattedMessage
              id='ContactDetailsForm.phoneInvalid'
            />
          </span>
            );
          } else {
            phoneNumberInfo = <span className={css.errorPhone}>&nbsp;</span>;
          }

          let phoneNumberSuccess = false;
          if (errors?.officeDetails?.phoneNumber === undefined && phoneNumber !== '') {
            phoneNumberSuccess = true;
          }

          const phonePlaceholder = intl.formatMessage({
            id: 'ContactDetailsForm.phonePlaceholder',
          });

          const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });
          const phoneInvalidMessage = intl.formatMessage({
            id: 'ContactDetailsForm.phoneInvalid',
          });

          const phoneValid = phoneNumberFormatValid(phoneInvalidMessage);

          const countryKey = 'country';
          const countryOptions = findOptionsForSelectFilter(countryKey, config.profileConfig.filters);

          const classes = classNames(css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;
          const showAsRequired = pristine;

          let locationInputField = null;
          let buildingInputField = null;


          if (values.locationGroup === 'onSite') {
            locationInputField =
              <LocationAutocompleteInputField
                className={css.locationAddress}
                inputClassName={css.locationAutocompleteInput}
                iconClassName={css.locationAutocompleteInputIcon}
                predictionsClassName={css.predictionsRoot}
                validClassName={css.validLocation}
                autoFocus
                name='location'
                label={titleRequiredMessage}
                placeholder={addressPlaceholderMessage}
                useDefaultPredictions={false}
                format={identity}
                valueFromForm={values.location}
                validate={composeValidators(
                  autocompleteSearchRequired(addressRequiredMessage),
                  autocompletePlaceSelected(addressNotRecognizedMessage),
                )}
              />;
            buildingInputField =
              <FieldTextInput
                className={css.building}
                type='text'
                name='building'
                id='building'
                label={buildingMessage}
                placeholder={buildingPlaceholderMessage}
              />;
          } else if (values.locationGroup === 'remote') {
            locationInputField = null;
            buildingInputField = null;
          }


          return (
            <Form
              className={classes}
              onSubmit={handleSubmit}>
              {errorMessage}
              {errorMessageShowListing}

              <div className={css.tooltip}>
                <FormattedMessage id='EditListingLocationForm.tooltip' />
              </div>
              <div className={css.twoColumns}>
                <FieldRadioButton
                  className={css.onSite}
                  id='onSite'
                  name='locationGroup'
                  label={onSiteLabel}
                  value='onSite'
                  showAsRequired={showAsRequired}
                />
                <FieldRadioButton
                  className={css.remote}
                  id='remote'
                  name='locationGroup'
                  label={remoteLabel}
                  value='remote'
                  showAsRequired={showAsRequired}
                />
              </div>
              <div className={css.tooltip2}>
                {values.locationGroup === 'onSite' ?
                  <FormattedMessage id='EditListingLocationForm.tooltipLocation' /> : null}
              </div>
              <div>
                {locationInputField}
              </div>
              {buildingInputField}

              <div className={classNames(css.sectionContainer, css.lastSection)}>
                <div className={css.Headline}>
                  <FormattedMessage id='EditListingLocationsForm.officeAddress' />
                </div>
                <div className={css.tooltip}>
                  <FormattedMessage id='EditListingLocationsForm.officeAddressTip' />
                </div>
                <FieldSelect
                  className={css.country}
                  id={formId ? `${formId}.country` : 'country'}
                  name='officeDetails[address][country]'
                  options={countryOptions}
                  validate={countryRequired}
                  label={countryLabel}
                >
                  <option value=''>{countryPlaceholder}</option>
                  {countryOptions.map(o => (
                    <FormattedMessage key={o.key} id={o.translationID}>
                      {txt => <option value={o.value}>{txt}</option>}
                    </FormattedMessage>
                  ))}
                </FieldSelect>
                <FieldTextInput
                  className={css.company}
                  type='text'
                  id={formId ? `${formId}.company` : 'company'}
                  name='officeDetails[company]'
                  autoComplete='company-name'
                  label={companyLabel}
                  placeholder={companyPlaceholder}
                  validate={companyRequired}
                />
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.firstName}
                    type='text'
                    id={formId ? `${formId}.fname` : 'fname'}
                    name='officeDetails[fname]'
                    autoComplete='given-name'
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstnameRequired}
                  />
                  <FieldTextInput
                    className={css.lastName}
                    type='text'
                    id={formId ? `${formId}.lname` : 'lname'}
                    name='officeDetails[lname]'
                    autoComplete='family-name'
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastnameRequired}
                  />
                </div>
                <div className={css.streetContainer}>
                  <FieldTextInput
                    className={css.street}
                    type='text'
                    id={formId ? `${formId}.street` : 'street'}
                    name='officeDetails[address][street]'
                    autoComplete='street-name'
                    label={streetLabel}
                    placeholder={streetPlaceholder}
                    validate={streetRequired}
                  />
                  <FieldTextInput
                    className={css.houseNumber}
                    type='text'
                    id={formId ? `${formId}.houseNumber` : 'houseNumber'}
                    name='officeDetails[address][houseNumber]'
                    autoComplete='house-number'
                    label={houseNumberLabel}
                    placeholder={houseNumberPlaceholder}
                    validate={houseNumberRequired}
                  />
                </div>
                <div className={css.cityContainer}>
                  <FieldTextInput
                    className={css.postalCode}
                    type='text'
                    id={formId ? `${formId}.postalCode` : 'postalCode'}
                    name='officeDetails[address][postalCode]'
                    autoComplete='postal-code'
                    label={postalCodeLabel}
                    placeholder={postalCodePlaceholder}
                    validate={postalCodeRequired}
                  />
                  <FieldTextInput
                    className={css.city}
                    type='text'
                    id={formId ? `${formId}.city` : 'city'}
                    name='officeDetails[address][city]'
                    autoComplete='city'
                    label={cityLabel}
                    placeholder={cityPlaceholder}
                    validate={cityRequired}
                  />
                </div>
                <div className={css.mailPhoneContainer}>
                  <FieldTextInput
                    className={css.email}
                    type='email'
                    name='officeDetails[email]'
                    id={formId ? `${formId}.email` : 'email'}
                    label={emailLabel}
                    placeholder={emailPlaceholder}
                    validate={composeValidators(emailRequired, emailValid)}
                  />
                  <div className={css.phone}>
                    <Field
                      name='officeDetails[phoneNumber]'
                      component={PhoneNumberInput}
                      placeholder={phonePlaceholder}
                      label={phoneLabel}
                      validate={phoneValid}
                      valid={phoneNumberSuccess}
                    />
                    <div className={css.phoneInfo}>
                      {phoneNumberInfo}
                    </div>
                  </div>

                </div>
              </div>
              <Button
                className={css.submitButton}
                type='submit'
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  locationGroup: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};


const EditListingLocationForm = compose(
  injectIntl,
)(EditListingLocationFormComponent);

EditListingLocationForm.displayName = 'EditListingLocationForm';

export default EditListingLocationForm;
