import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, IconExclamationMark } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import css from './AnswerForm.module.css';

const AnswerFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        intl,
        listingTitle,
        authorDisplayName,
        sendAnswerInProgress,
        sendAnswerError,
        userDisplayName,
        question,
      } = fieldRenderProps;

      const header = intl.formatMessage(
        {
          id: 'AnswerForm.header',
        },
        { userDisplayName }
      );

      const label = intl.formatMessage(
        {
          id: 'AnswerForm.messageLabel',
        },
        { question }
      );

      const answerPlaceholder = intl.formatMessage(
        {
          id: 'AnswerForm.messagePlaceholder',
        },
        { authorDisplayName }
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'AnswerForm.messageRequired',
      });
      const answerRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = sendAnswerInProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <IconExclamationMark className={css.icon} />
          <h2 className={css.heading}>
            {header}
          </h2>
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="answer"
            id={formId ? `${formId}.answer` : 'answer'}
            label={label}
            placeholder={answerPlaceholder}
            validate={answerRequired}
          />
          <div className={submitButtonWrapperClassName}>
            {sendAnswerError ? (
              <p className={css.error}>
                <FormattedMessage id="AnswerForm.sendAnswerError" />
              </p>
            ) : null}
              <div className={css.informationText}>
              <FormattedMessage id="AnswerForm.informationText" />
              </div>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="AnswerForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

AnswerFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  sendAnswerInProgress: false,
  sendAnswerError: null,
};

AnswerFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  sendAnswerInProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string,
  sendAnswerError: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const AnswerForm = compose(injectIntl)(AnswerFormComponent);

AnswerForm.displayName = 'AnswerForm';

export default AnswerForm;
