import React, { Component, CSSProperties } from 'react';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Carousel } from 'react-responsive-carousel/lib/js';
import ButtonPrev from './ButtonPrev';
import ButtonNext from './ButtonNext';
import classNames from 'classnames';

import css from './Carousel.module.css';
import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";


const mapToPrefix = key => {
    if(key==='dialux') return 'DialuxFilter.'
    if(key==='others') return 'OthersFilter.'
    if(key==='documentation') return 'DocumentationFilter.'
    if(key==='lightingDesign') return 'LightingDesignFilter.'
    if(key==='photoWork') return 'PhotoWorkFilter.'
    return '';
};

const mapToLabel = key => {
    if(key==='dialux') return 'ProfileSkillsForm.dialuxLabel'
    if(key==='others') return 'ProfileSkillsForm.otherLabel'
    if(key==='documentation') return 'ProfileSkillsForm.documentationLabel'
    if(key==='lightingDesign') return 'ProfileSkillsForm.lightingDesignLabel'
    if(key==='photoWork') return 'ProfileSkillsForm.picturesLabel'
    return '';
};


const arrowStyles = {
    position: 'absolute',
    zIndex: 1,
    top: 'calc(50% - 15px)',
    width: 32,
    height: 32,
    cursor: 'pointer',
    border: 'none',
};

export const CarouselComponent = props => {
    const { className, skills } = props;

    return (
        <Carousel infiniteLoop centerSlidePercentage={28} centerMode={true} showStatus={false} showIndicators={false} showThumbs={false} axis={'horizontal'}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
                <button type="button" onClick={onClickHandler}  style={{ ...arrowStyles, left: 15 }}>
                    <ButtonPrev/>
                </button>
            )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
                <button type="button" onClick={onClickHandler} style={{ ...arrowStyles, right: 15 }}>
                     <ButtonNext/>
                </button>
            )
        }
        >
            {Object.entries(skills)
            .filter(([key, value]) => Array.isArray(value))
            .flatMap( ([key, value]) => {
                const label = mapToLabel(key);
                const prefix = mapToPrefix(key);
                return value.map(v => (
                    <div className={css.skillItem}>
                        <FormattedMessage id={label}/> - <FormattedMessage id={prefix+v}/>
                    </div>
                ))
            })}
        </Carousel>
    );
};

CarouselComponent.defaultProps = {

};

CarouselComponent.propTypes = {

};

export default injectIntl(CarouselComponent);
