import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Modal from '../../components/Modal/Modal';
import {
  closeWorkingExperienceModal,
  deleteWorkingExperience,
  upsertWorkingExperience,
} from '../../ducks/WorkingExperience.duck';
import { WorkingExperienceForm } from './WorkingExperienceForm';

export function WorkingExperienceModal() {
  const dispatch = useDispatch();
  const isOpen = useSelector(x => x.WorkingExperience.isModalOpen);
  const selectedWorkingExperience = useSelector(x => x.WorkingExperience.selectedItem);

  const closeModal = () => {
    dispatch(closeWorkingExperienceModal());
  };

  const onSubmit = (item) => {
    dispatch(upsertWorkingExperience(item));
    dispatch(closeWorkingExperienceModal());
  };

  const onCancelOrDelete = (item) => {
    dispatch(deleteWorkingExperience(item));
  };

  return isOpen && (
    <Modal id='workExpModal' onClose={closeModal} isOpen={true} onManageDisableScrolling={() => {}}>
      <WorkingExperienceForm
        id='workExpForm'
        initialValues={selectedWorkingExperience}
        onSubmit={onSubmit}
        onCancelButtonClicked={onCancelOrDelete}
      ></WorkingExperienceForm>
    </Modal>
  );
}
