import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconArrowLeft.module.css';

const IconArrowLeft = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="100px" height="18px" viewBox="0 0 100 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 9l8.8 8.2 1.4-1.5L4 10h96V8H4l6.2-5.7L8.8.8 0 9z"
        fillRule="evenodd"
      />
    </svg>
  );
};

IconArrowLeft.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconArrowLeft.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconArrowLeft;
