// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';
import request from 'request';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend

  if (typeof location !== 'undefined') {
    return location.origin;
  }

  return window.location.origin;
  //return "http://localhost:4000"
}

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };


  if (typeof window === 'undefined') {

    return new Promise((resolve, reject) => {
      request(url, options, (err, res, body) => {
        const contentTypeHeader = res?.caseless?.get('content-type');
        const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

        if (!!err) {
          reject(err);
        }

        if (contentType === 'application/transit+json') {
          resolve(deserialize(body));
        }

        resolve(body);
      })
    })
  } else {
    return window.fetch(url, options).then(res => {
      const contentTypeHeader = res.headers.get('Content-Type');
      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

      if (res.status >= 400) {
        return res.json().then(data => {
          let e = new Error();
          e = Object.assign(e, data);

          throw e;
        });
      }
      if (contentType === 'application/transit+json') {
        return res.text().then(deserialize);
      } else if (contentType === 'application/json') {
        return res.json();
      }
      return res.text();
    });
  }
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the booking data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const updateProposal = (body) => {
  return post('/api/update-proposal', body);
};

export const getProposals = (body) => {
  return post('/api/get-proposals', body);
};

export const createProposal = (body) => {
  return post('/api/send-proposal', body);
};

export const isProposalSent = (body) => {
  return post('/api/is-proposal-sent', body);
};

export const addQuestion = (body) => {
  return post('/api/send-question', body);
};

export const addAnswer = (body) => {
  return post('/api/answer', body);
};

export const getQuestions = (body) => {
  return post('/api/get-questions', body);
};

export const inviteExperts = (body) => {
  return post('/api/invite-experts', body);
};

export const sendNotificationToExperts = (body) => {
  return post('/api/notify-experts', body);
}

export const getInvitations = (body) => {
  return post('/api/get-invitations', body);
};

export const getTransactions = (body) => {
  return post('/api/get-transactions', body);
};

export const getInteractions = (body) => {
  return post('/api/get-interactions', body);
};

export const createStripeCustomer = (body) => {
  return post('/api/create-stripe-customer', body)
}

export const canEditListingDetails = (body) => {
  return post('/api/can-edit-listing-details', body)
}

export const fetchInteractionNotificationsCount = (body) => {
  return post('/api/fetch-interaction-notifications-count', body)
}

export const archiveListing = (body) => {
  return post('/api/archive-listing', body)
}

export const seenByUser = (body) => {
  return post('/api/seen', body)
}

export const createPaymentIntent = (body) => {
  return post('/api/create-payment-intent', body)
}

export const createStripeSubscription = (body) => {
  return post('/api/create-stripe-subscription', body)
}

export const createStripeCustomerPortalSession = (body) => {
  return post('/api/create-stripe-customer-portal-session', body)
}

export const updateStripeCustomer = (body) => {
  return post('/api/update-stripe-customer', body)
}

export const sendMessage = (body) => {
  return post('/api/send-message', body)
}

export const providerOfficeDetails = (body) => {
  return post('/api/provider-office-details', body)
}

export const getListingPostalCode = (body) => {
  return post('/api/get-listing-postal-code', body)
}

export const seenProposal = (body) => {
  return post('/api/seen-proposal', body)
}

export const logoutDialux = (body) => {
  return post('/api/auth/logoutDialux', body)
}

export const searchFiles = (body) => {
  return post('/api/cloudinary/search-files', body)
}

export const deleteFiles = (body) => {
  return post('/api/cloudinary/delete-files', body)
}

export const updateAttachments = (body) => {
  return post('/api/update-attachments', body)
}

export const getOfficeDetails = (body) => {
  return post('/api/get-office-details', body)
}

export const getUserListingId = (body) => {
  return post('/api/get-user-listing-id', body)
}

export const getFullName = (body) => {
  return post('/api/get-full-name', body)
}
