import { FormattedMessage, intlShape } from './util/reactIntl';
import React, { useState, useEffect } from 'react';
/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'dialux',
    label: <FormattedMessage id={'EditListingCategoriesForm.dialuxLabel'}/>,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_dialux'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'geometry', label: <FormattedMessage id={'DialuxFilter.geometry'} />},
        { key: 'calculation', label: <FormattedMessage id={'DialuxFilter.calculation'} /> },
        { key: 'outputs', label: <FormattedMessage id={'DialuxFilter.outputs'} /> },
      ],
    },
  },
  {
    id: 'lightingDesign',
    label: <FormattedMessage id={'EditListingCategoriesForm.lightingDesignLabel'}/>,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_lightingDesign'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'designConcept', label: <FormattedMessage id={'LightingDesignFilter.designConcept'} />},
        { key: 'executionPlanning', label: <FormattedMessage id={'LightingDesignFilter.executionPlanning'} /> },
        { key: 'productSearch', label: <FormattedMessage id={'LightingDesignFilter.productSearch'} /> },
        { key: 'calculation', label: <FormattedMessage id={'LightingDesignFilter.calculation'} /> },
        { key: 'energyAssessment', label: <FormattedMessage id={'LightingDesignFilter.energyAssessment'} /> },
        { key: 'hcl', label: <FormattedMessage id={'LightingDesignFilter.hcl'} /> },
        { key: 'tendering', label: <FormattedMessage id={'LightingDesignFilter.tendering'} /> },
        { key: 'constructionManagement', label: <FormattedMessage id={'LightingDesignFilter.constructionManagement'} /> },
        { key: 'emergencyLight', label: <FormattedMessage id={'LightingDesignFilter.emergencyLight'} /> },
        { key: 'streetLighting', label: <FormattedMessage id={'LightingDesignFilter.streetLighting'} /> },
        { key: 'daylightPlanning', label: <FormattedMessage id={'LightingDesignFilter.daylightPlanning'} /> },
      ],
    },
  },
  {
    id: 'documentation',
    label:  <FormattedMessage id={'EditListingCategoriesForm.documentationLabel'}/>,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_documentation'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'measurementInventory', label: <FormattedMessage id={'DocumentationFilter.measurementInventory'} />},
        { key: 'expertOpinion', label: <FormattedMessage id={'DocumentationFilter.expertOpinion'} />},
        { key: 'visualisation', label: <FormattedMessage id={'DocumentationFilter.visualisation'} />},
        { key: 'presentation', label: <FormattedMessage id={'DocumentationFilterpresentation'} />},
        { key: 'documentation', label: <FormattedMessage id={'DocumentationFilter.documentation'} />},
      ],
    },
  },
  {
    id: 'photoWork',
    label: <FormattedMessage id={'EditListingCategoriesForm.photoWorkLabel'}/>,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_photoWork'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'shooting', label: <FormattedMessage id={'PhotoWorkFilter.shooting'} />},
        { key: 'studioRecording', label: <FormattedMessage id={'PhotoWorkFilter.studioRecording'} />},
        { key: 'pictureSearch', label: <FormattedMessage id={'PhotoWorkFilter.pictureSearch'} />},
        { key: 'photoEdit', label: <FormattedMessage id={'PhotoWorkFilter.photoEdit'} />},
      ],
    },
  },
  {
    id: 'others',
    label: <FormattedMessage id={'EditListingCategoriesForm.othersLabel'}/>,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_others'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'electricalPlanning', label: <FormattedMessage id={'OthersFilter.electricalPlanning'} />},
        { key: 'lightArt', label: <FormattedMessage id={'OthersFilter.lightArt'} />},
        { key: 'lightControl', label: <FormattedMessage id={'OthersFilter.lightControl'} />},
        { key: 'signage', label: <FormattedMessage id={'OthersFilter.signage'} />},
        { key: 'marketing', label: <FormattedMessage id={'OthersFilter.marketing'} />},
        { key: 'modelMaking', label: <FormattedMessage id={'OthersFilter.modelMaking'} />},
        { key: 'eventTechnology', label: <FormattedMessage id={'OthersFilter.eventTechnology'} />},
        { key: 'translations', label: <FormattedMessage id={'OthersFilter.translations'} />},
        //{ key: 'other', label: <FormattedMessage id={'OthersFilter.other'} />},
      ],
    },
  },
  {
    id: 'language',
    label: <FormattedMessage id={'EditListingLanguagePanel.title'}/>,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_language'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'english', label: <FormattedMessage id={'LanguageFilter.labelEnglish'} />},
        { key: 'german', label: <FormattedMessage id={'LanguageFilter.labelGerman'} />},
        { key: 'french', label: <FormattedMessage id={'LanguageFilter.labelFrench'} />},
        { key: 'spanish', label: <FormattedMessage id={'LanguageFilter.labelSpanish'} />},
        { key: 'italian', label: <FormattedMessage id={'LanguageFilter.labelItalian'} />},
        { key: 'chinese', label: <FormattedMessage id={'LanguageFilter.labelChinese'} />},
        { key: 'portuguese', label: <FormattedMessage id={'LanguageFilter.labelPortuguese'} />},
        { key: 'russian', label: <FormattedMessage id={'LanguageFilter.labelRussian'} />},
      ],
    },
  },
  {
    id: 'payment',
    label: <FormattedMessage id={'EditListingPaymentPanel.title'}/>,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_payment'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'lightshift', label: <FormattedMessage id={'PaymentFilter.labelLightshift'}/>, icon:'/static/icons/payment/Lightshift_Logo.svg'},
        { key: 'mastercard', label: <FormattedMessage id={'PaymentFilter.labelMastercard'}/>, icon:"/static/icons/payment/MasterCard.svg"},
        { key: 'paypal', label: <FormattedMessage id={'PaymentFilter.labelPaypal'}/>, icon:"/static/icons/payment/PayPal.svg"},
        { key: 'visa', label: <FormattedMessage id={'PaymentFilter.labelVisa'} />, icon:"/static/icons/payment/Visa.svg"},
        { key: 'alipay', label: <FormattedMessage id={'PaymentFilter.labelAlipay'} />, icon:"/static/icons/payment/alipay.svg"},
        { key: 'wechatpay', label: <FormattedMessage id={'PaymentFilter.labelWechatpay'} />, icon:"/static/icons/payment/wechatpayment.svg"},
        { key: 'wiretransfer', label: <FormattedMessage id={'PaymentFilter.labelWiretransfer'} />,icon:"/static/icons/payment/wireTransfer.svg"},
      ],
    },
  },
  {
    id: 'locationGroup',
    label: <FormattedMessage id={'FilterForm.executionFilterLabel'}/>,
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_locationGroup'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'remote', label: <FormattedMessage id={'ExecutionFilter.remote'} />},
        { key: 'onSite', label: <FormattedMessage id={'ExecutionFilter.onSite'} />},
      ],
    },
  },
  {
    id: 'deadline',
    label: <FormattedMessage id={'FilterForm.deadlineFilterLabel'}/>,
    type: 'DeadlineFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['pub_listingEndDateUTC'],
    config: {},
  },
  /*{
    id: 'keyword',
    label:  <FormattedMessage id={'FilterForm.keywordFilterLabel'}/>,
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },

  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
   id: 'country',
   label: 'Country',
   type: 'SelectSingleFilter',
   group: 'secondary',
   queryParamNames: ['pub_country'],
   config: {
     // "key" is the option you see in Flex Console.
     // "label" is set here for the UI only.
     // Note: label is not added through the translation files
     // to make filter customizations a bit easier.
     options: [
       { key: 'Deutschland', label: "CountryFilter.labelGermany"},
       { key: 'France', label: "CountryFilter.labelFrance"},
       { key: 'Italia', label: "CountryFilter.labelItaly" },
       { key: 'España', label: "CountryFilter.labelSpain" },
       { key: 'Türkiye', label: "CountryFilter.labelTurkey" },
       { key: 'United Kingdom', label: "CountryFilter.labelUK" },
       { key: 'Sverige', label: "CountryFilter.labelSweden" },
       { key: 'Nederland', label: "CountryFilter.labelNetherlands" },
       { key: 'Brasil', label: "CountryFilter.labelBrazil" },
       { key: 'Rossijskaja Federazija', label: "CountryFilter.labelRussia" },
       { key: 'China', label: "CountryFilter.labelChina" },
       { key: 'México', label: "CountryFilter.labelMexico" },
       { key: 'USA', label: "CountryFilter.labelUSA" },
       { key: 'Colombia', label: "CountryFilter.labelColombia" },
       { key: 'Việt Nam', label: "CountryFilter.labelVietnam" },
       { key: 'Polska', label: "CountryFilter.labelPoland" },
       { key: 'India', label: "CountryFilter.labelIndia" },
       { key: 'Chile', label: "CountryFilter.labelChile" },
       { key: 'Perú', label: "CountryFilter.labelPeru" },
       { key: 'Egypt', label: "CountryFilter.labelEgypt" },
       { key: 'België-Belgique', label: "CountryFilter.labelBelgium" },
       { key: 'Indonesia', label: "CountryFilter.labelIndonesia" },
       { key: 'Ecuador', label: "CountryFilter.labelEcuador" },
       { key: 'Thailand', label: "CountryFilter.labelThailand" },
     ],
   },
  },
  {
   id: 'purpose',
   label: 'Purpose',
   type: 'SelectMultipleFilter',
   group: 'secondary',
   queryParamNames: ['pub_purpose'],
   config: {
     // "key" is the option you see in Flex Console.
     // "label" is set here for the UI only.
     // Note: label is not added through the translation files
     // to make filter customizations a bit easier.
     options: [
       { key: 'modeling', label: <FormattedMessage id={'PurposeFilter.modeling'} />},
       { key: 'visualization', label: <FormattedMessage id={'PurposeFilter.visualization'} /> },
       { key: 'documentation', label: <FormattedMessage id={'PurposeFilter.documentation'} /> },
       { key: 'exports', label: <FormattedMessage id={'PurposeFilter.exports'} /> },
       { key: 'calculation', label: <FormattedMessage id={'PurposeFilter.calculation'} /> },
       { key: 'presentation', label: <FormattedMessage id={'PurposeFilter.presentation'} /> },
       { key: 'planing', label: <FormattedMessage id={'PurposeFilter.planing'} /> },
       { key: 'construction', label: <FormattedMessage id={'PurposeFilter.construction'} /> },
     ],
   },
  },
  {
   id: 'deliveryDate',
   label: 'delivery date',
   type: 'SelectMultipleFilter',
   group: 'secondary',
   queryParamNames: ['pub_deliveryDate'],
   config: {
     // "key" is the option you see in Flex Console.
     // "label" is set here for the UI only.
     // Note: label is not added through the translation files
     // to make filter customizations a bit easier.
     options: [
       { key: '24h', label: <FormattedMessage id={'DeliveryDateFilter.label24h'} />},
       { key: '3d', label: <FormattedMessage id={'DeliveryDate.label3d'} />},
       { key: '7d', label: <FormattedMessage id={'DeliveryDate.label7d'} />},
       { key: 'individual', label: <FormattedMessage id={'DeliveryDate.labelIndividual'} />},
     ],
   },
  },
  {
    id: 'skillSelect',
    label: 'skillSelect',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_skillSelect'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
        // to make filter customizations a bit easier.
        options: [
          { key: 'dialux', label: 'DIALux' },
          { key: 'fotoarbeiten', label: 'Fotoarbeiten' },
          { key: 'elektroplanung', label: 'Elektroplanung' },
          { key: 'kunstlichtSiBel', label: 'Kunstlichtplanung -Sicherheitsbeleuchtung' },
          { key: 'tageslicht', label:  'Tageslichtplanung'},
          { key: 'kunstlichtAllg', label:  'Kunstlichtplanung -Allgemeinbeleuchtung'},
          { key: 'veranstaltungstechnik', label:  'Veranstaltungstechnik'},
          { key: 'signage', label: 'Lichtwerbung / Signage'},
          { key: 'lichtkunst', label: 'Lichtkunst'},
          { key: 'marketing', label: 'Marketing'},
          { key: 'industrie', label: 'Industrie'},

        ],
      },
    },
    {
      id: 'selectLang',
      label: 'selectLang',
      type: 'SelectSingleFilter',
      group: 'primary',
      queryParamNames: ['pub_selectLang'],
      config: {
        // "key" is the option you see in Flex Console.
        // "label" is set here for the UI only.
        // Note: label is not added through the translation files
        // to make filter customizations a bit easier.
        options: [
          { key: 'english', label: <FormattedMessage id={'LanguageFilter.labelEnglish'} />},
          { key: 'german', label: <FormattedMessage id={'LanguageFilter.labelGerman'} />},
          { key: 'french', label: <FormattedMessage id={'LanguageFilter.labelFrench'} />},
          { key: 'spanish', label: <FormattedMessage id={'LanguageFilter.labelSpanish'} />},
          { key: 'italian', label: <FormattedMessage id={'LanguageFilter.labelItalian'} />},
        ],
      },
    },
    {
      id: 'technologySkills',
      label: 'TechnologySkills',
      type: 'SelectMultipleFilter',
      group: 'secondary',
      queryParamNames: ['pub_tecSkills'],
      config: {
        // "key" is the option you see in Flex Console.
        // "label" is set here for the UI only.
        // Note: label is not added through the translation files
        // to make filter customizations a bit easier.
        options: [
          { key: 'lightingDesign',      label: <FormattedMessage id="TechnologySkillsFilter.labelLightingDesign" /> },
          { key: 'productDevelopment',  label: <FormattedMessage id="TechnologySkillsFilter.labelProductDevelopment" /> },
          { key: 'electricalPlanning',  label: <FormattedMessage id="TechnologySkillsFilter.labelElectricalPlanning" /> },
        ],
      },
    },
    {
      id: 'execution',
      label: 'Execution',
      type: 'SelectSingleFilter',
      group: 'primary',
      queryParamNames: ['pub_listingEndDateUTC'],
      config: {
        // "key" is the option you see in Flex Console.
        // "label" is set here for the UI only.
        // Note: label is not added through the translation files
        // to make filter customizations a bit easier.
        options: [
          { key: '2021-07-27T08:44:21Z', label: '2021-07-27T08:44:21Z' },
          { key: 'onSite', label: 'DIALux 4' },
        ],
      },
    },*/
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'pub_listingEndDateUNIX', label: <FormattedMessage id={'sortBy.Newest'} />},
    { key: '-pub_listingEndDateUNIX', label: <FormattedMessage id={'sortBy.Oldest'} />},
    /*{ key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },*/

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    //{ key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
export const titleConfig = [
  {
    id: 'pronoun',
    config: {
      options: [
        { key: 'i', label: <FormattedMessage id={'EditListingDescriptionForm.i'} />},
        { key: 'iAm', label: <FormattedMessage id={'EditListingDescriptionForm.iam'} />},
        { key: 'weAre', label: <FormattedMessage id={'EditListingDescriptionForm.weAre'} /> },
      ],
    },
  },
  {
    id: 'need',
    config: {
      options: [
        { key: 'need', label: <FormattedMessage id={'EditListingDescriptionForm.need'} />},
        { key: 'lookingFor', label: <FormattedMessage id={'EditListingDescriptionForm.lookingFor'} />},
        { key: 'searchingFor', label: <FormattedMessage id={'EditListingDescriptionForm.searchingFor'} /> },
        { key: 'interestedIn', label: <FormattedMessage id={'EditListingDescriptionForm.interestedIn'} /> },
      ],
    },
  },
]
