import { combineReducers } from 'redux';
import { USER_LOGOUT } from './ducks/Auth.duck';
import * as globalReducers from './ducks';
import * as pageReducers from './containers/reducers';

/**
 * Function _createReducer_ combines global reducers (reducers that are used in
 * multiple pages) and reducers that are handling actions happening inside one page container.
 * Since we combineReducers, pageReducers will get page specific key (e.g. SearchPage)
 * which is page specific.
 * Future: this structure could take in asyncReducers, which are changed when you navigate pages.
 */

const reducers = { ...globalReducers, ...pageReducers };



// arrow function to create reducers with nested 
export const createRootReducer = () => combineReducers({
  ...reducers
});

const rootReducer = createRootReducer();

const createReducer = () => {
  return (state, action) => {
    if(action.type !== USER_LOGOUT) return rootReducer(state, action);
    if (typeof window !== 'undefined' && !!window.sessionStorage) {
      window.sessionStorage.clear();
    }

    // keep UI state when user logged out
    return rootReducer({UI: {...state.UI}}, action);
  };
};

export default createReducer;
