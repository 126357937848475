import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFreelancerBadge.module.css';

const IconFreelancerBadge = props => {
  const { rootClassName, className, freelancerBadgeClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <g
        className={freelancerBadgeClassName || css.badge}
        stroke="none"
        fill="ffffff"
        fillRule="evenodd"
      >
      <path d="m15 14.571a7.2943 7.2943 0 0 0 7.2857-7.2857 7.2943 7.2943 0 0 0-7.2857-7.2857 7.2943 7.2943 0 0 0-7.2857 7.2857 7.2943 7.2943 0 0 0 7.2857 7.2857zm0-12.857c3.0686 0 5.5714 2.5029 5.5714 5.5714 0 3.0686-2.5029 5.5714-5.5714 5.5714s-5.5714-2.5029-5.5714-5.5714c0-3.0686 2.5029-5.5714 5.5714-5.5714zm4.3543 15.429h-8.7086c-5.8714 0-10.646 4.7743-10.646 10.646v2.2114h30v-2.2114c0-5.8714-4.7743-10.646-10.646-10.646zm8.9314 11.143h-26.571v-0.49714c0-4.9286 4.0114-8.9314 8.9314-8.9314h8.7c4.9286 0 8.9314 4.0114 8.9314 8.9314v0.49714z"/>
      </g>
    </svg>
  );
};

IconFreelancerBadge.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconFreelancerBadge.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconFreelancerBadge;
