import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPerson.module.css';

const IconPerson = props => {
  const { rootClassName, className, size } = props;
  const classes = classNames(rootClassName || css.root, className);
    return (
      <svg className={classes} width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.4 17.6667L10.6 17.6667C4.73333 17.6667 0 22.4 0 28.2L0 30L30 30L30 28.2C30 22.4 25.2667 17.6667 19.4 17.6667L19.4 17.6667ZM28.6667 28.6667L1.33333 28.6667L1.33333 28.2C1.32796 25.7409 2.30252 23.3807 4.04148 21.6417C5.78044 19.9027 8.14053 18.9281 10.5998 18.9333L19.4 18.9333C21.8593 18.928 24.2194 19.9026 25.9584 21.6416C27.6974 23.3806 28.672 25.7407 28.6667 28.2L28.6667 28.6667L28.6667 28.6667ZM15 14.5333C19.0087 14.5223 22.2557 11.2754 22.2667 7.26666C22.2557 3.25796 19.0087 0.0109908 15 0C10.9913 0.0109844 7.74432 3.25795 7.73333 7.26667C7.74432 11.2754 10.9913 14.5223 15 14.5333L15 14.5333ZM15 1.33333C18.2667 1.33333 20.9333 4 20.9333 7.26667C20.9333 10.5333 18.2667 13.2 15 13.2C11.7333 13.2 9.06667 10.5333 9.06667 7.26667C9.06667 4 11.7333 1.33333 15 1.33333L15 1.33333Z"
          stroke="none"
          fill="#000000"
          fillRule="evenodd"
        />
      </svg>
    );
};

IconPerson.defaultProps = {
  rootClassName: null,
  className: null,
  size: 'big',
};

const { string } = PropTypes;

IconPerson.propTypes = {
  rootClassName: string,
  className: string,
  size: string,
};

export default IconPerson;
